import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import queryString from 'query-string';

import { ActionsDropdownForItem } from 'components/base/ActionsDropdown';
import AuthButton from 'components/base/AuthButton';
import CustomBreadcrumb from 'components/base/CustomBreadcrumb';
import CustomListComponent from 'components/base/CustomListComponent';
import DeletePrompt from 'components/base/DeletePrompt';
import ExportRangeModal from 'components/base/ExportRangeModal';
import ListButtonsGroup from 'components/base/ListButtonsGroup';
import { NavBarNames } from 'config/NavBarNameList';
import { PermissionCodes } from 'config/PermissionCodes';
import BaseListContainer from 'containers/base/BaseListContainer';
import BaseTabListContainer from 'containers/base/BaseTabListContainer';

import Filter from './GiftCardFilter';
import VirtualGiftCardConfigSetting from './VirtualGiftCardConfigSetting';
import { ImportResource } from 'models/DownloadImportModel';

import { createAction } from 'utils';

const mapPropsToState = (state) => ({
  totalCount: state.giftCard?.totalCount,
  pageInfo: state.giftCard?.pageInfo,
  totalPage: state.giftCard?.totalPage,
  displayFields: state.giftCard?.listDisplayFields,
  pagedList: state.giftCard?.pagedList,
  checkedList: state.giftCard?.checkedList,
});

function GiftCardList({
  totalCount,
  pageInfo,
  totalPage,
  displayFields,
  pagedList,
  checkedList,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [showDeleteView, setShowDeleteView] = useState(false);
  const [showVirtualSetting, setShowVirtualSetting] = useState(false);
  const [isExportAll, setIsExportAll] = useState(true)
  const [showExportModal, setShowExportModal] = useState(false)

  const tabs = [
    {
      name: 'List',
      content: (
        <BaseTabListContainer
          hideTab={true}
          pageInfo={pageInfo}
          totalCount={totalCount || 0}
          searchPlaceholder={'Search by Gift card code'}
          filter={{
            hasFilter: true,
            component: Filter,
          }}
          listContent={
            <BaseListContainer
              fields={displayFields}
              model={'giftCard'}
              permissionGroup={PermissionCodes.giftcard}
              dataList={pagedList}
              totalPage={totalPage}
              deleteInfo={{
                data: [],
                title: 'Gift card',
                relatedName: '',
                relatedSections: [],
              }}
              actions={['Detail']}
              useCustomCommonActions={true}
              customCommonActions={(item) => {
                const actions = [
                  {
                    name: 'Detail',
                    action: (item) => {
                      dispatch({ type: `giftCard/clearData` });
                      return history.push(`${location.pathname}/${item.pk}/`);
                    },
                    requires: `view_${PermissionCodes.giftcard}`,
                  },
                ];
                if (item.canEdit) {
                  actions.push({
                    name: 'Edit',
                    action: (item) => {
                      dispatch({ type: `giftCard/clearData` });
                      return history.push(
                        `${location.pathname}/${item.pk}/edit/`,
                      );
                    },
                    requires: `change_${PermissionCodes.giftcard}`,
                  });
                }

                const otherActions = [];

                return (
                  <ActionsDropdownForItem
                    object={item}
                    actions={actions}
                    otherActions={otherActions}
                  />
                );
              }}
            />
          }
        />
      ),
    },
  ];

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    const searchKey = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);
    console.log(searchKey);
    dispatch(
      createAction('giftCard/getList')({
        ...searchDict,
        rank: rank,
        filterName: searchKey,
        page: page,
      }),
    );
  }, [dispatch, location]);

  const handleExport = ()=> {
    if (isExportAll) {
      dispatch(
        createAction('downloadAndImport/createDownloadTask')({
          from: ImportResource.physicalGiftCard,
        }),
      );
      return
    } 
    const parsedSearch = queryString.parse(location.search);
    const code = parsedSearch['search'] || null;
    const createDatePeriod = parsedSearch?.create_date?.split(',');
    const codeIn = (checkedList || []).map((item) => item.pk);
    const statusIn = parsedSearch?.status?.split(',').map((item) => {
      if (item === 'PURCHASED' || item === 'GENERATED') {
        return 'INIT';
      }
      return item;
    });

    dispatch(
      createAction('downloadAndImport/createDownloadTask')({
        from: ImportResource.physicalGiftCard,
        related: {
          creationDateGte: createDatePeriod?.[0],
          creationDateLte: createDatePeriod?.[1],
          codeIcontains: code,
          codeIn: codeIn,
          activePeriodType: parsedSearch?.expiry_date,
          statusIn,
        },
      }),
    );
  }

  const buttons = [
    <ListButtonsGroup
      hideExtraButtonWidth={800}
      hideAllButtonWidth={530}
      primaryButton={
        <AuthButton
          title="Create Physical Gift Card"
          action={() => {
            dispatch(createAction('giftCard/clearData')());
            history.push('/gift_cards/create/');
          }}
          requires={PermissionCodes.changeGiftCard}
        />
      }
      extraButtons={[
        <AuthButton
          customClass="btn-back-button-common btn-download"
          title="Virtual Gift Card Options Configuration"
          action={() => {
            setShowVirtualSetting(true);
          }}
          requires={PermissionCodes.changeVirtualGiftCardConfig}
        />,
        <AuthButton
          customClass="btn-back-button-common"
          title="Export physical gift card"
          action={() => {
            setShowExportModal(true)
          }}
          requires={PermissionCodes.viewGiftCard}
        />,
      ]}
      extraPopContent={[
        {
          requires: PermissionCodes.addExportjob,
          action: () => {},
          content: 'Export report',
        },
        {
          requires: PermissionCodes.addExportjob,
          action: () => {},
          content: 'Export .csv',
        },
      ]}
    />,
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '...',
          title: NavBarNames.giftCards,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={true}
      />
      <ExportRangeModal
        show={showExportModal}
        title={`Export Physical Gift Card `}
        closeAction={() => {
          setShowExportModal(false)
        }}
        rightButton={{
          text: 'Export',
          action: () => {
            handleExport()
            setShowExportModal(false)
          },
          requires: PermissionCodes.addExportjob,
        }}
        isExportAll={isExportAll}
        setIsExportAll={setIsExportAll}
      />
      <DeletePrompt
        data={checkedList}
        show={showDeleteView}
        title={'Gift Card'}
        relatedName=""
        relatedSections={[]}
        onClose={() => setShowDeleteView(false)}
        onConfirm={() => {
          setShowDeleteView(false);
          dispatch(
            createAction('giftCard/delete')({
              afterAction: () => history.push(location),
            }),
          );
        }}
      />
      <VirtualGiftCardConfigSetting
        show={showVirtualSetting}
        setShow={setShowVirtualSetting}
      />
    </>
  );
}

export default connect(mapPropsToState)(GiftCardList);
