import React from 'react';
import config from 'config';

import CreateCampaign from '../containers/engagement/campaign/CreateCampaign';
import CreateCoupon from '../containers/engagement/coupon/CreateCoupon';
import Dashboard from '../containers/dashboard/Dashboard';
import SuperSetDashboard from '../containers/dashboard/SuperSetDashboard';
import CampaignItemOverview from '../containers/engagement/campaign/CampaignItemOverview';
import CouponSetDetail from '../containers/engagement/coupon/CouponSetDetail';
import SingleCouponDetail from '../containers/engagement/coupon/SingleCouponDetail';
import CreateEarningRule from '../containers/engagement/earningRule/CreateEarningRule';
import CreateSegment from '../containers/customers/segments/CreateSegment';
import SegmentDetail from '../containers/customers/segments/SegmentDetail';
import CustomerEditForm from '../containers/customers/customer/CustomerEditForm';
import CreatePointRecord from '../containers/record/pointRecords/CreatePointRecord';
import PointTransactionOverview from '../containers/record/pointRecords/PointTransactionOverview';
import CouponTransactionOverview from '../containers/record/couponRecords/CouponTransactionOverview';
import TransactionOverView from '../containers/record/records/TransactionOverview';
import CreateTransaction from '../containers/record/records/CreateTransaction';
import CreateAdminGroup from '../containers/admin/adminGroups/CreateAdminGroup';
import CreateAdmin from '../containers/admin/admins/CreateAdmin';
import CreateBrand from '../containers/merchants/brands/CreateBrand';
import BrandDetail from '../containers/merchants/brands/BrandDetail';
import CreateMessage from '../containers/engagement/message/CreateMessage';
import MessageOverview from '../containers/engagement/message/MessageOverview';
import AdminDetail from '../containers/admin/admins/AdminDetail';
import CampaignCategoryDetail from '../containers/engagement/campaignCategory/CampaignCategoryDetail';
import CreateCampaignCategory from '../containers/engagement/campaignCategory/CreateCampaignCategory';
import StoreDetail from '../containers/merchants/stores/StoreDetail';
import CreateStore from '../containers/merchants/stores/CreateStore';
import StoreCategoryDetail from '../containers/merchants/storeCategory/StoreCategoryDetail';
import CreateStoreCategory from '../containers/merchants/storeCategory/CreateStoreCategory';
import CreateCustomerGroup from '../containers/customers/customerGroup/CreateCustomerGroup';
import CustomerGroupDetail from '../containers/customers/customerGroup/CustomerGroupDetail';
import { PermissionCodes } from './PermissionCodes';
import CreateBanner from '../containers/homeManage/banners/CreateBanner';
import BannerDetail from '../containers/homeManage/banners/BannerDetail';
import FeaturedCampaignDetail from '../containers/homeManage/featuredCampaigns/FeaturedCampaignDetail';
import CreateLangauge from '../containers/engagement/language/CreateLanguage';
import EditVirtualGiftCard from 'containers/giftcard/EditVirtualGiftCard';
import CreatePhysicalGiftCard from 'containers/giftcard/CreatePhysicalGiftCard';
import LanguageDetail from '../containers/engagement/language/LanguageDetail';
import GiftCardDetail from 'containers/giftcard/GiftCardDetail';
import GiftCardRecordDetail from 'containers/giftcard/GiftCardRecordDetail';
import CreateLevelDiscount from '../containers/engagement/level/CreateLevelDiscount';
import LevelDiscountDetail from '../containers/engagement/level/LevelDiscountDetail';
import CampaignDetail from '../containers/engagement/campaign/CampaignDetail';
import BulkRewardOverview from '../containers/record/bulkRewardRecords/bulkRewardOverview';
import CreateWebview from '../containers/webview/CreateWebview';
import CreateStampCampaign from '../containers/engagement/stampCampaign/CreateStampCampaign';
import StampCampaignItemOverview from '../containers/engagement/stampCampaign/StampCampaignItemOverview';
import StampRecordDetail from '../containers/record/stampRecords/StampRecordDetail';
import StampCampaignDetail from '../containers/engagement/stampCampaign/StampCampaignDetail';
import EarningRuleDetail from '../containers/engagement/earningRule/EarningRuleDetail';
import { NavBarNames } from '../config/NavBarNameList';
import RFMDashboard from '../containers/dashboard/RFMDashboard'
import MessageCreditRecordDetail from 'containers/admin/admins/MessageCreditRecordDetail';
import CustomerDetail from 'containers/customers/customer/CustomerDetail';
import ProductCategoryDetail from 'containers/merchants/productCategory/ProductCategoryDetail';
import ProductSubcategoryDetail from 'containers/merchants/productCategory/ProductSubcategoryDetail';
import CreateCategoryOrSubcategory, { CREATE_CATEGORY_RESOURCE } from 'containers/merchants/productCategory/CreateCategoryOrSubcategory';

export const pageRoutes = [
  {
    path: '/',
    name: 'Home',
    exact: true,
    main: () => <Dashboard />,
  },
  {
    path: '/earn_and_burn',
    name: 'Earn & Burn',
    exact: true,
    main: () => <SuperSetDashboard embedID={config.dashboardCampaignEmbedID} />,
  },
  {
    path: '/demographic_overview',
    name: 'Demographic Overview',
    exact: true,
    main: () => (
      <SuperSetDashboard embedID={config.dashboardDemographicEmbedID} />
    ),
  },
  {
    path: '/transactions_dashboard',
    name: 'Transactions Dashboard',
    exact: true,
    main: () => (
      <SuperSetDashboard embedID={config.dashboardSalesEmbedID} />
    ),
  },
  {
    path: ['/campaigns/create', '/campaigns/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeCampaign,
    main: () => <CreateCampaign />,
  },
  {
    path: ['/campaigns/:id/overview'],
    exact: true,
    name: 'Overview',
    main: () => <CampaignItemOverview />,
  },
  {
    path: ['/campaigns/:id'],
    exact: true,
    codename: PermissionCodes.viewCampaign,
    main: () => <CampaignDetail />,
  },
  {
    path: ['/stamp_campaigns/create', '/stamp_campaigns/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeCampaign,
    main: () => <CreateStampCampaign />,
  },
  {
    path: ['/stamp_campaigns/:id/overview'],
    exact: true,
    name: 'Overview',
    main: () => <StampCampaignItemOverview />,
  },
  {
    path: ['/stamp_campaigns/:id'],
    exact: true,
    codename: PermissionCodes.viewCampaign,
    main: () => <StampCampaignDetail />,
  },
  {
    path: ['/coupons/create', '/coupons/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeCouponTemplate,
    main: () => <CreateCoupon />,
  },
  {
    path: ['/coupons/:id/'],
    exact: true,
    codename: PermissionCodes.viewCouponTemplate,
    main: () => <CouponSetDetail />,
  },
  {
    path: [
      '/coupons/couponSet/:couponSetId/singleCoupon/:singleCouponId',
      '/coupons/:id/:singleCouponId/edit',
    ],
    exact: true,
    codename: PermissionCodes.viewCoupon,
    main: () => <SingleCouponDetail />,
  },
  {
    name: 'Create Earning Rule',
    path: '/earns/create',
    exact: true,
    codename: PermissionCodes.addEarningRule,
    main: () => <CreateEarningRule />,
  },
  {
    name: 'Edit Earning Rule',
    path: ['/earns/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeEarningRule,
    main: () => <CreateEarningRule />,
  },
  {
    path: ['/earns/:id/'],
    exact: true,
    codename: PermissionCodes.viewEarningRule,
    main: () => <EarningRuleDetail />,
  },
  {
    name: 'Create Segment',
    path: ['/segments/create/'],
    exact: true,
    codename: PermissionCodes.addSegment,
    main: () => <CreateSegment />,
  },
  {
    path: ['/segments/:id/'],
    exact: true,
    codename: PermissionCodes.viewSegment,
    main: () => <SegmentDetail />,
  },
  {
    path: ['/segments/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeSegment,
    main: () => <CreateSegment />,
  },

  {
    path: ['/customers/:id/'],
    exact: true,
    codename: PermissionCodes.viewCustomer,
    main: () => <CustomerDetail />,
  },
  {
    path: ['/customers/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeCustomer,
    main: () => <CustomerEditForm />,
  },
  {
    name: 'Update Point record',
    path: ['/point_records/create', '/point_records/:id/edit'],
    exact: true,
    codenames: [
      PermissionCodes.addCustomerPoints,
      PermissionCodes.removeCustomerPoints,
    ],
    main: () => <CreatePointRecord />,
  },
  {
    name: 'View Point record',
    path: ['/point_records/:id/'],
    exact: true,
    codename: PermissionCodes.viewPointtransaction,
    main: () => <PointTransactionOverview />,
  },
  {
    name: '',
    path: ['/bulk_rewards/:id/'],
    exact: true,
    codename: PermissionCodes.viewBulkReward,
    main: () => <BulkRewardOverview />,
  },
  {
    name: 'View Coupon records',
    path: ['/coupon_records/:id/'],
    exact: true,
    codename: PermissionCodes.viewCouponTransaction,
    main: () => <CouponTransactionOverview />,
  },
  {
    name: 'Create Transaction',
    path: ['/transactions/create'],
    exact: true,
    codename: PermissionCodes.addTransaction,
    main: () => <CreateTransaction />,
  },
  {
    name: 'Edit Transaction',
    path: ['/transactions/:id/edit'],
    exact: true,
    codename: PermissionCodes.addTransaction,
    main: () => <CreateTransaction />,
  },
  {
    name: 'View transaction',
    path: ['/transactions/:id/'],
    exact: true,
    codename: PermissionCodes.viewTransaction,
    main: () => <TransactionOverView />,
  },
  {
    name: 'Create Administrators group',
    path: ['/administrator_groups/create'],
    exact: true,
    codename: PermissionCodes.addAdministratorGroup,
    main: () => <CreateAdminGroup />,
  },
  {
    path: ['/administrator_groups/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeAdministratorGroup,
    main: () => <CreateAdminGroup />,
  },
  {
    name: 'Create Administrators',
    path: ['/administrators/create'],
    exact: true,
    codename: PermissionCodes.addAdministrator,
    main: () => <CreateAdmin />,
  },
  {
    path: ['/administrators/:id/'],
    exact: true,
    codename: PermissionCodes.viewAdministrator,
    main: () => <AdminDetail />,
  },
  {
    path: ['/message_credit_records/:id/'],
    exact: true,
    codename: PermissionCodes.viewMessage,
    main: () => <MessageCreditRecordDetail />,
  },
  {
    path: ['/administrators/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeAdministrator,
    main: () => <CreateAdmin />,
  },
  {
    name: 'Create Brand',
    path: ['/brands/create/'],
    exact: true,
    codename: PermissionCodes.changeBrand,
    main: () => <CreateBrand />,
  },
  {
    path: ['/brands/:id/'],
    exact: true,
    codename: PermissionCodes.brand,
    main: () => <BrandDetail />,
  },
  {
    path: ['/brands/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeBrand,
    main: () => <CreateBrand />,
  },
  {
    name: 'Create Message',
    path: ['/messages/create'],
    exact: true,
    codename: PermissionCodes.addMessage,
    main: () => <CreateMessage />,
  },
  {
    path: ['/messages/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeMessage,
    main: () => <CreateMessage />,
  },
  {
    name: 'View Message',
    path: ['/messages/:id/'],
    exact: true,
    codename: PermissionCodes.viewMessage,
    main: () => <MessageOverview />,
  },
  {
    name: 'Create campaign category',
    path: ['/campaign_category/create'],
    exact: true,
    codename: PermissionCodes.addCampaignCategory,
    main: () => <CreateCampaignCategory />,
  },
  {
    path: ['/campaign_category/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeCampaignCategory,
    main: () => <CreateCampaignCategory />,
  },
  {
    path: ['/campaign_category/:id/'],
    exact: true,
    codename: PermissionCodes.viewCampaignCategory,
    main: () => <CampaignCategoryDetail />,
  },

  {
    name: 'Create store',
    path: ['/stores/create'],
    exact: true,
    codename: PermissionCodes.addStore,
    main: () => <CreateStore />,
  },
  {
    path: ['/stores/:id/'],
    exact: true,
    codename: PermissionCodes.viewStore,
    main: () => <StoreDetail />,
  },
  {
    path: ['/stores/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeStore,
    main: () => <CreateStore />,
  },
  {
    name: 'Create store category',
    path: ['/store_category/create'],
    exact: true,
    codename: PermissionCodes.addStorecategory,
    main: () => <CreateStoreCategory />,
  },
  {
    path: ['/store_category/:id/'],
    exact: true,
    codename: PermissionCodes.viewSorecategory,
    main: () => <StoreCategoryDetail />,
  },
  {
    path: ['/store_category/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeStorecategory,
    main: () => <CreateStoreCategory />,
  },
  {
    name: 'Create Customer List',
    path: ['/customer_group/create'],
    exact: true,
    codename: PermissionCodes.addCustomerGroup,
    main: () => <CreateCustomerGroup />,
  },
  {
    path: ['/customer_group/:id/'],
    exact: true,
    codename: PermissionCodes.customergroup,
    main: () => <CustomerGroupDetail />,
  },
  {
    path: ['/customer_group/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeCustomerGroup,
    main: () => <CreateCustomerGroup />,
  },
  {
    name: 'Create Banner',
    path: ['/banners/create/'],
    exact: true,
    codename: PermissionCodes.changeBanner,
    main: () => <CreateBanner />,
  },
  {
    path: ['/banners/:id/'],
    exact: true,
    codename: PermissionCodes.viewBanner,
    main: () => <BannerDetail />,
  },
  {
    name: 'Edit Banner',
    path: ['/banners/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeBanner,
    main: () => <CreateBanner />,
  },
  {
    path: ['/home_campaigns/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeCampaign,
    main: () => <FeaturedCampaignDetail />,
  },
  {
    path: ['/languages/create', '/languages/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeLanguage,
    main: () => <CreateLangauge />,
  },
  {
    path: ['/languages/:id/'],
    exact: true,
    codename: PermissionCodes.viewLanguage,
    main: () => <LanguageDetail />,
  },
  {
    path: ['/gift_cards/create/'],
    exact: true,
    codename: PermissionCodes.changeGiftCard,
    main: () => <CreatePhysicalGiftCard />,
  },
  {
    path: ['/gift_cards/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeGiftCard,
    main: () => <EditVirtualGiftCard />,
  },
  {
    path: ['/gift_cards/:id/'],
    exact: true,
    codename: PermissionCodes.viewGiftCard,
    main: () => <GiftCardDetail />,
  },
  {
    path: ['/gift_card_records/:id/'],
    exact: true,
    codename: PermissionCodes.viewGiftCardRecord,
    main: () => <GiftCardRecordDetail />,
  },
  {
    name: 'Create webview',
    path: ['/webview/create'],
    exact: true,
    codename: PermissionCodes.changeWebview,
    main: () => <CreateWebview />,
  },
  {
    name: 'Edit webview',
    path: ['/webview/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeWebview,
    main: () => <CreateWebview />,
  },
  {
    path: ['/level_benefits/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeLevelDiscount,
    main: () => <CreateLevelDiscount />,
  },
  {
    path: ['/level_benefits/:id/'],
    exact: true,
    codename: PermissionCodes.viewLevelDiscount,
    main: () => <LevelDiscountDetail />,
  },
  {
    name: 'View Stamp Record',
    path: ['/stamp_records/:id/'],
    extra: true,
    codename: PermissionCodes.viewStampRecord,
    main: () => <StampRecordDetail />,
  },
  {
    name: 'Create Product Subcategory',
    path: ['/product_categories/product_subcategories/create'],
    exact: true,
    codename: PermissionCodes.addProductCategory,
    main: () => <CreateCategoryOrSubcategory resource={CREATE_CATEGORY_RESOURCE.productSubcategory} />,
  },
  {
    path: ['/product_categories/product_subcategories/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeProductCategory,
    main: () => <CreateCategoryOrSubcategory resource={CREATE_CATEGORY_RESOURCE.productSubcategory} />,
  },
  {
    path: ['/product_categories/product_subcategories/:id/'],
    extra: true,
    codename: PermissionCodes.viewProductCategory,
    main: () => <ProductSubcategoryDetail />,
  },
  {
    name: 'Create Product Category',
    path: ['/product_categories/create'],
    exact: true,
    codename: PermissionCodes.addProductCategory,
    main: () => <CreateCategoryOrSubcategory resource={CREATE_CATEGORY_RESOURCE.productCategory}/>,
  },
  {
    path: ['/product_categories/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeProductCategory,
    main: () => <CreateCategoryOrSubcategory resource={CREATE_CATEGORY_RESOURCE.productCategory} />,
  },
  {
    path: ['/product_categories/:id/'],
    extra: true,
    codename: PermissionCodes.viewProductCategory,
    main: () => <ProductCategoryDetail />,
  },

];
