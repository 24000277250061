import React, { useState } from "react";
import { Controller, useFormContext } from 'react-hook-form';

import { DropDownWithDefaultSelect } from 'components/base/ActionsDropdown';
import { hasReactHookFormError } from 'components/base/ErrorFieldMessage';
import { CustomTitleLabel } from 'components/earning/CustomBaseComponments';
import { 
  ConditionTag,
  PROGRESSION_POINTS_REACH_TARGET,
  PROGRESSION_POINTS_REACH_TARGET_DISPLAY,
  PROGRESSION_POINTS_REACH_TARGET_NUMBER_TYPE
} from "config/CustomEnums";
import CustomRadios from "components/base/CustomRadios";


const ProgressionPointsReachTargetSection = ({
  title,
  fieldBaseName
}) => {
  const {
    watch,
    setValue,
    control,
    formState: { errors },
    register,
  } = useFormContext();
  const targetFieldName = `${fieldBaseName}.target`;
  const lookupFieldName = `${fieldBaseName}.lookup`;
  const numberTypeFieldName = `${fieldBaseName}.numberType`;
  const minFieldName = `${fieldBaseName}.minNum`;
  const maxFieldName = `${fieldBaseName}.maxNum`;

  const watchTarget = watch(targetFieldName);
  const watchLookup = watch(lookupFieldName);
  const watchNumberType = watch(numberTypeFieldName);
  const valueUnit = watchNumberType === PROGRESSION_POINTS_REACH_TARGET_NUMBER_TYPE.AMOUNT_IN_PP_VALUE ? "pp" : "%";

  const dropDownOptions = (values, setAction) => {
    return values.map((value) => ({
      name: PROGRESSION_POINTS_REACH_TARGET_DISPLAY?.[value],
      action: () => {
        setAction(value);
      },
    }));
  };
  const getInputNumberCount = (lookup) => {
    if (!lookup) {
      return 0;
    }
    if (lookup === ConditionTag.BETWEEN) {
      return 2
    }
    if ([ConditionTag.EQUAL_OR_LESS_THAN, ConditionTag.LESS_THAN].includes(lookup)) {
      return 1
    }
    return 0

  };
  const [showInputNumber, setShowInputNumber] = useState(
    getInputNumberCount(watchLookup),
  );

  const lookupDropDownOptions = [
    ConditionTag.EQUAL_OR_LESS_THAN,
    ConditionTag.BETWEEN,
    ConditionTag.LESS_THAN,
  ].map((lookupItem) => ({
    name: lookupItem,
    action: () => {
      setValue(lookupFieldName, lookupItem, { shouldDirty: true, shouldValidate: true });
      setShowInputNumber(getInputNumberCount(lookupItem));
    },
  }));

  return (
    <div>
      <CustomTitleLabel title={title} />
      <div className="d-flex flex-column">
        <CustomTitleLabel title={"Target"} />
        <Controller
          name={targetFieldName}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <DropDownWithDefaultSelect
              actions={dropDownOptions(
                [
                  PROGRESSION_POINTS_REACH_TARGET.reachNextLevel,
                  PROGRESSION_POINTS_REACH_TARGET.retainCurrentLevel,
                ],
                (value) => {
                  setValue(field.name, value, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                },
              )}
              defaultValue={PROGRESSION_POINTS_REACH_TARGET_DISPLAY?.[watchTarget] || ""}
              className={
                hasReactHookFormError(errors, field.name) ? 'error-field' : ''
              }
            />
          )}
        />
      </div>
      <div className="d-flex flex-column">
        <CustomTitleLabel title={"Amount of progression points"} />
        <div className="d-flex" style={{ alignItems: "flex-end" }}>
          <Controller
            name={numberTypeFieldName}
            control={control}
            render={({ field }) => (
              <CustomRadios
                labelClass="font-weight"
                onChange={(value) => {
                  setValue(field.name, value, { shouldDirty: true });
                }}
                default={watchNumberType || PROGRESSION_POINTS_REACH_TARGET_NUMBER_TYPE.AMOUNT_IN_PERCENTAGE }
                options={[
                  {
                    label: "Amount in percentage",
                    value: PROGRESSION_POINTS_REACH_TARGET_NUMBER_TYPE.AMOUNT_IN_PERCENTAGE,
                  },
                  {
                    label: "Amount in pp value",
                    value: PROGRESSION_POINTS_REACH_TARGET_NUMBER_TYPE.AMOUNT_IN_PP_VALUE,
                  },
                ]}
              />
            )}
          />
        </div>
        <div className="d-flex" style={{ alignItems: "flex-end" }}>
          <Controller
            control={control}
            name={lookupFieldName}
            rules={{ required: true }}
            render={({ field }) => (
              <DropDownWithDefaultSelect
                actions={lookupDropDownOptions}
                defaultValue={watchLookup}
                className={hasReactHookFormError(errors, field.name) ? 'error-field' : ''}
              />
            )}
          />
          <input
            type="number"
            className={`custom-number-input-short ${hasReactHookFormError(errors, minFieldName) ? 'error-field' : ''
              }`}
            {...register(minFieldName, { required: showInputNumber >= 1 })}
            hidden={showInputNumber < 1}
          />
          <label className="section-short-description" hidden={showInputNumber < 1}>{valueUnit}</label>
          <label
            className="help-message margin-left-10-percent margin-right-10-percent"
            hidden={showInputNumber < 2}
          >
            and
          </label>
          <input
            type="number"
            className={`custom-number-input-short ${hasReactHookFormError(errors, maxFieldName) ? 'error-field' : ''
              }`}
            {...register(maxFieldName, { required: showInputNumber >= 2 })}
            hidden={showInputNumber < 2}
          />
          <label className="section-short-description" hidden={showInputNumber < 2}>{valueUnit}</label>
        </div>
      </div>
    </div>
  )
}

export default ProgressionPointsReachTargetSection;