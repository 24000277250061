import React, { useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import { Link } from 'react-router-dom';

import QRCode from 'qrcode.react';

import CustomRichTextViewer from 'components/base/CustomRichTextViewer';
import CustomViewer from 'components/base/CustomViewer';
import AlertInfoPrompt from 'components/base/prompt/AlertInfoPrompt';
import { CustomTitleLabel } from 'components/earning/CustomBaseComponments';
import 'containers/customers/customer/CustomerOverviewDetail.scss';

import { addDomainToImage, isJsonFile } from 'utils';

function MembershipInfoCard({ title, data, fields, extraDataFields }) {
  const tableRef = useRef();

  const [showPrompt, setShowPrompt] = useState(false);
  const [promptInfo, setPromptInfo] = useState({});
  const [tableWidth, setTableWidth] = useState(800);
  const tableOffset = tableRef.current?.offsetWidth;
  useEffect(() => {
    if (tableRef.current?.offsetWidth) {
      setTableWidth(tableRef.current?.offsetWidth);
    }
  }, [tableOffset]);

  const getDisplayValue = (field) => {
    let value = data[field];
    if (Array.isArray(value)) {
      value = value.slice(0, 10).join('\r\n');
    }

    if (field === 'mobileNumber') {
      value = value?.replace('\r', ' ');
    }

    if (extraDataFields && Object.keys(extraDataFields).includes(field)) {
      value = extraDataFields[field];
      if (Array.isArray(value)) {
        value = value.slice(0, 10).join('\r\n');
      }
    }

    return value || '-';
  };

  const getDisplayImage = (field) => {
    if (Array.isArray(data[field])) {
      return (
        <>
          {data[field].map((item) => {
            return (
              <>
                {
                  isJsonFile(item) ?
                    <div
                      style={{
                        maxWidth: 191,
                        maxHeight: 191,
                        marginRight: '20px',
                        marginBottom: '20px',
                      }}
                    >
                      <Lottie
                        alt="img"
                        options={{
                          loop: true,
                          path: addDomainToImage(item),
                        }}
                      />
                    </div>
                    :
                    <img
                      alt="img"
                      src={addDomainToImage(item)}
                      style={{
                        maxWidth: 191,
                        maxHeight: 191,
                        marginRight: '20px',
                        marginBottom: '20px',
                      }}
                    />
                }
              </>
            );
          })}
        </>
      );
    } else {
      return (
        <>
          {
            isJsonFile(getDisplayValue(field)) ?
              <div style={{ maxWidth: 191, maxHeight: 191 }}>
                <Lottie
                  alt="img"
                  options={{
                    loop: true,
                    path: addDomainToImage(getDisplayValue(field)),
                  }}
                />
              </div>
              :
              <img
                alt="img"
                src={addDomainToImage(getDisplayValue(field))}
                style={{ maxWidth: 191, maxHeight: 191 }}
              />
          }
        </>
      )
    }
  };

  const needShowMore = (field) => {
    let value = data[field];
    if (extraDataFields && Object.keys(extraDataFields).includes(field)) {
      value = extraDataFields[field];
    }
    return Array.isArray(value) && value.length > 10;
  };

  const showMore = (field) => {
    let title, subTitle, content;
    if (field === 'coupons') {
      title = 'Coupon';
      subTitle = 'The following coupon belogn to this customer';
    }

    if (field === 'availableCampaign') {
      title = 'Campaign';
      subTitle = 'The user can see the following activities';
    }

    if (field === 'displayPermissions') {
      title = 'Administrator permission';
      subTitle = 'The user has the following permissions';
    }

    let value = data[field];

    if (extraDataFields && Object.keys(extraDataFields).includes(field)) {
      value = extraDataFields[field];
    }

    content = (
      <ul>
        {value.map((item, index) =>
          item.id ? (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              className="channels-show-more-content"
              href={
                field === 'relatedCampaigns' ? `/campaigns/${item.id}/` : '#'
              }
            >
              <li key={index}>{item.name}</li>
            </a>
          ) : (
            <li key={index}>{item}</li>
          ),
        )}
      </ul>
    );

    setPromptInfo({ title, subTitle, content });
    setShowPrompt(true);
  };
  // console.log('@@56: ', tableRef.current);

  const getColumnValue = (column) => {
    if (
      (column.field.includes('photo') ||
        column.field.includes('Photo') ||
        column.field.includes('image') ||
        column.field.includes('Image')) &&
      getDisplayValue(column.field) !== '-'
    ) {
      return getDisplayImage(column.field);
    } else if (
      (column.title === 'Description' ||
        column.title.toLowerCase().indexOf('short description') >= 0 ||
        column.title === 'Offer detail content' ||
        column.title === 'Instruction section content' ||
        column.title.toLowerCase().indexOf('detail section content') >= 0) &&
      getDisplayValue(column.field) !== '-'
    ) {
      // return <CustomViewer initValue={data[column.field]} />;
      return <CustomViewer initValue={getDisplayValue(column.field)} />;
    } else {
      return getDisplayValue(column.field);
    }
  };

  const renderValueSection = (column) => {
    if (column?.customRender) {
      return column.customRender;
    }
    if (column.isRichText) {
      return <CustomRichTextViewer initialValue={data[column.field] || '-'} />;
    }
    if (column.deeplink) {
      return (
        <Link
          to={column.deeplink}
          className="custom-record-normal-font custom-timeline-time-color list-new-line"
        >
          {getColumnValue(column)}
        </Link>
      );
    }
    if (column.customerLink && data["customerId"] && !data["customerIsReset"]) {
      return (
        <Link
          to={`/customers/${data["customerId"]}/`}
          className="custom-record-normal-font custom-timeline-time-color list-new-line"
        >
          {getColumnValue(column)}
        </Link>
      );
    };
    if (column?.isQRCode) {
      return (
        <label className="custom-record-normal-font custom-timeline-time-color list-new-line">
          <QRCode value={getColumnValue(column)} renderAs="svg" />
        </label>
      );
    }
    if (column?.isBoolean) {
      return (
        <label className="custom-record-normal-font custom-timeline-time-color list-new-line">
          {getColumnValue(column) === true || getColumnValue(column) === 1
            ? 'Yes'
            : 'No'}
        </label>
      );
    }
    return (
      <label className="custom-record-normal-font custom-timeline-time-color list-new-line">
        {getColumnValue(column)}
      </label>
    );
  };

  return (
    <>
      <label className="create-section-title">{title}</label>
      <table style={{ tableLayout: 'fixed', width: '100%' }} ref={tableRef}>
        {fields.map((row) => {
          return (
            <tr>
              {row.map((column) => {
                return (
                  <td
                    style={{
                      display: 'inline-grid',
                      width: `${tableWidth / row.length}px`,
                      wordBreak: `break-word`,
                    }}
                  >
                    <CustomTitleLabel title={column.title} />
                    {column.title === 'Brand' && (
                      <label className="tips-message">
                        Once set, cannot be changed.
                      </label>
                    )}
                    {renderValueSection(column)}
                    {needShowMore(column.field) && (
                      <button
                        type="button"
                        className="btn-link reset-button show-more-button"
                        onClick={() => {
                          showMore(column.field, data[column.field]);
                        }}
                      >
                        show more
                      </button>
                    )}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </table>
      <AlertInfoPrompt
        show={showPrompt}
        onCancel={() => setShowPrompt(false)}
        onClose={() => setShowPrompt(false)}
        onConfirm={() => setShowPrompt(false)}
        title={promptInfo.title}
        subTitle={promptInfo.subTitle}
        content={promptInfo.content}
      />
    </>
  );
}

export default MembershipInfoCard;
