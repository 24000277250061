import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Dropdown, Image } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { AmOrPm } from '../../config/CustomEnums';
import { createNumberArray } from '../../utils';
import {
  getCampaignPeriodDate,
  getCampaignPeriodFormatedTime,
  getCurrentDay,
} from '../../utils/TimeFormatUtil';

import './CustomDateTimeSelect.scss';

import DateIcon from '../../assets/images/date_icon.svg';
import DropdownNarrow from '../../assets/images/drop_down_not_show_narrow.svg';
import deleteSelectedButton from '../../assets/images/drop_down_delete_selected.png'

const DropDownType = { hour: 'hour', minutes: 'minutes', seconds: 'seconds', amAndPm: 'amAndPm' };
const DropDownClasses = {
  [DropDownType.hour]: 'custom-hour-select',
  [DropDownType.minutes]: 'custom-minutes-select',
  [DropDownType.seconds]: 'custom-seconds-select',
  [DropDownType.amAndPm]: 'custom-am-pm-select',
};

const CustomDateTimeSelect = forwardRef((props, ref) => {
  const maxDate = props.maxDate;
  const minDate = props.minDate;
  const disabled = props.disabled || false;
  const onTimeChange = props.onTimeChange || (() => { });
  const defaultTime = props.defaultTime || getCurrentDay();
  const hoursList = createNumberArray(11).map((i) => i + 1);
  hoursList.unshift(12);
  const minutesList = createNumberArray(60);
  const secondsList = createNumberArray(60);
  const amWithPm = [AmOrPm.am, AmOrPm.pm];
  const error = props.error;
  const noNeedTime = props.noNeedTime || false;
  const resetDate = props.resetDate || false;
  const { defaultHour, defaultMinute, defaultSecond, defaultAmOrPm } =
    getCampaignPeriodFormatedTime(defaultTime);
  const [selectedHour, setSelectedHour] = useState(defaultHour);
  const [selectedMinute, setSelectedMinute] = useState(defaultMinute);
  const [selectedSecond, setSelectedSecond] = useState(defaultSecond);
  const [selectedAMOrPM, setSelectedAMOrPM] = useState(defaultAmOrPm);
  const [selectedDate, setSelectedDate] = useState(defaultTime);
  const [timeError, setTimeError] = useState(error);
  const [hasSetDefaultTime, setHasSetDefaultTime] = useState(false);
  const [hasResetDate, setHasResetDate] = useState(resetDate && !props.defaultTime);

  const handleChange = (date) => {
    setHasResetDate(false);
    setSelectedDate(date);
  };
  useImperativeHandle(ref, () => {
    return { handleChange };
  });

  const getDefaultDate = () => {
    if (typeof selectedDate === 'string') {
      return new Date(selectedDate);
    }
    return selectedDate;
  };
  useEffect(() => {
    if (!hasSetDefaultTime && props.defaultTime) {
      setSelectedHour(defaultHour);
      setSelectedMinute(defaultMinute);
      setSelectedSecond(defaultSecond);
      setSelectedAMOrPM(defaultAmOrPm);
      setSelectedDate(defaultTime);
      setHasSetDefaultTime(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultTime, hasSetDefaultTime]);

  useEffect(() => {
    setTimeError(error);
    const date = new Date(selectedDate);
    let aditionalHours = selectedHour;
    if (selectedHour === '12' || selectedHour === 12) {
      aditionalHours = 0;
    }
    if (selectedAMOrPM === AmOrPm.pm) {
      if (
        typeof aditionalHours === 'string' ||
        aditionalHours instanceof String
      ) {
        aditionalHours = parseInt(aditionalHours) + 12;
      } else {
        aditionalHours = aditionalHours + 12;
      }
    }
    date.setHours(aditionalHours);
    date.setMinutes(selectedMinute);
    date.setSeconds(selectedSecond);
    date.setMilliseconds(0);
    onTimeChange(hasResetDate ? null : date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, selectedAMOrPM, selectedDate, selectedHour, selectedMinute, selectedSecond]);

  const CustomInputView = ({ value, onClick }) => {
    return (
      <button
        disabled={disabled}
        onClick={onClick}
        className={`custom-date-select-container ${hasResetDate ? 'custom-date-select-container-empty' : ''} select-time-text ${timeError ? 'error-field' : ''}`}
      >
        {hasResetDate ? null : getCampaignPeriodDate(value)}
        <Image src={DateIcon} className="custom-date-select-icon" />
      </button>
    );
  };

  const getDropDownTitles = (type) => {
    let result = selectedAMOrPM;
    if (type === DropDownType.hour) {
      result = selectedHour;
    }
    if (type === DropDownType.minutes) {
      result = selectedMinute;
    }
    if (type === DropDownType.seconds) {
      result = selectedSecond;
    }
    return isNaN(result) || type === DropDownType.hour
      ? result
      : ('0' + result).slice(-2);
  };

  const dorpDownSelect = (type, value) => {
    if (type === DropDownType.hour) {
      setSelectedHour(value);
      return;
    }
    if (type === DropDownType.minutes) {
      setSelectedMinute(value);
      return;
    }
    if (type === DropDownType.seconds) {
      setSelectedSecond(value);
      return;
    }
    setSelectedAMOrPM(value);
  };

  const customDropdownSelected = (options, type) => {
    return (
      <>
        <Dropdown className={`${DropDownClasses[type]}`}>
          <Dropdown.Toggle
            variant="link"
            disabled={disabled}
            className={`custom-hour-minutes-control dropdown-menu-sizing select-time-text ${timeError ? 'error-field' : ''
              }`}
          >
            {getDropDownTitles(type)}
            <Image src={DropdownNarrow} className="custom-date-select-icon" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="custom-hour-minutes-select-dropdown-menu time-select-dropdown-menu">
            {options.map((item, index) => (
              <Dropdown.Item
                className="select-time-text dropdown-item-sizing"
                key={`${index}-${item}`}
                value={item}
                onClick={() => dorpDownSelect(type, item)}
              >
                {isNaN(item) || type === DropDownType.hour
                  ? item
                  : ('0' + item).slice(-2)}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  const hoursSelect = () =>
    customDropdownSelected(hoursList, DropDownType.hour);

  const minutesSelect = () =>
    customDropdownSelected(minutesList, DropDownType.minutes);

  const secondsSelect = () =>
    customDropdownSelected(secondsList, DropDownType.seconds);

  const amAndPmSelect = () =>
    customDropdownSelected(amWithPm, DropDownType.amAndPm);
  return (
    <div className="custom-date-time-select-container">
      <DatePicker
        selected={getDefaultDate()}
        onChange={handleChange}
        maxDate={maxDate}
        minDate={minDate}
        customInput={<CustomInputView />}
      />
      {noNeedTime ? null : (
        <>
          {hoursSelect()}
          <label className="custom-hour-minutes-splite">:</label>
          {minutesSelect()}
          <label className="custom-hour-minutes-splite">:</label>
          {secondsSelect()}
          {amAndPmSelect()}
        </>
      )}
      {
        resetDate
          ? <Image
            src={deleteSelectedButton}
            className="custom-date-time-select-reset-button"
            onClick={(e) => {
              setHasResetDate(true);
              onTimeChange(null);
            }}
          />
          : null
      }
    </div>
  );
});

export default CustomDateTimeSelect;
