import {
  APIStatus,
  CheckStatus,
  EarningRuleLinkedCampaign,
  EarningRuleRewardType,
  EarningRuleType,
  EarningRuleTypeKey,
  LanguageConfig,
  SESSION_KEYS,
} from 'config/CustomEnums';
import { apiWithResponseHandle } from 'models/LoadingUtil';
import { defaultStep, getNewStepConfig } from 'models/StepBarUtil';
import {
  createOrUpdateEarningRule,
  createOrUpdateWebhookAuthenticatedKey,
  getEarningRuleProducts,
  getOneEarningRule,
  getWebhookAuthenticatedKey,
} from 'services/EarningRuleHelper';

import {
  convertNumberToCursor,
  convertPKToId,
  getObjectFromSessionStorage,
  removeFromSessionStorage,
  saveToSessionStorage,
} from 'utils';
import { getReadablePeriod } from 'utils/TimeFormatUtil';

export const EARNING_RULES_SESSION_KEY = SESSION_KEYS.EARNING_RULES_SESSION_KEY;

const stepNames = ['Type', 'Content', 'Properties'];

const getInitialState = () => ({
  currentStep: 0,
  stepConfig: defaultStep(stepNames),
  earningRule: {
    specialSkus: [],
    inputSkus: [],
  },
  skusHaveLoaded: false,
  errorFields: [],
  formChanged: false,
  checked: CheckStatus.initOrNotChecked,
  hasUpdatedDefaultValues: false,
  createStatus: APIStatus.none,
  webhookAuthenticatedKey: {},
  isCopied: false,
});

const postTranslations = (data) => {
  if (!data) {
    return [];
  }
  const languageList = Object.keys(data).filter(
    (language) => language !== LanguageConfig.english,
  );
  const translations = languageList.map((language) => {
    const newData = {
      language,
      name: data?.[language]?.generalName,
      instructionSectionTitle: data?.[language]?.instructionSectionTitle,
      instructionSectionContent: data?.[language]?.instructionSectionContent,
      detailSectionTitle: data?.[language]?.detailSectionTitle,
      detailSectionContent: data?.[language]?.detailSectionContent,
    };
    const id = data?.[language]?.pk;
    if (id) {
      newData.id = id;
    }
    return newData;
  });
  return translations;
};

const getTranslations = (data) => {
  const translationList = data.translations?.edges || [];
  const translation = {};
  translationList.forEach((item) => {
    const language = item.node.language;
    translation[language] = {
      ...item.node,
      generalName: item.node.name,
      // name: item.node.name,
    };
  });
  translation[LanguageConfig.english] = {
    generalName: data.name,
    instructionSectionTitle: data.instructionSectionTitle,
    instructionSectionContent: data.instructionSectionContent,
    detailSectionTitle: data.detailSectionTitle,
    detailSectionContent: data.detailSectionContent,
    pk: data.pk,
  };
  return translation;
};

const getQuantity = (type, rewardType, data) => {
  let quantity = null;
  switch (rewardType) {
    case EarningRuleRewardType.points:
      quantity = data[`${type}TypePointsRewardTypePoints`];
      break;
    case EarningRuleRewardType.stamps:
      quantity = data[`${type}TypeStampRewardTypeQuantity`];
      break;
    default:
      quantity = data[`${type}TypeCouponRewardTypeQuantity`];
  }
  return quantity;
};

const getListStrings = (list) => {
  if (list?.length === 0) {
    return null;
  }
  return list
    ?.map((item) => item.name || item?.node.name || item?.node.levelName)
    .toString();
};

function getMillisecondsFromDate(date) {
  if (!date) {
    return '';
  }
  const momentTime = new Date(date);
  return momentTime;
}

const parseEarningRule = (defaultData) => {
  if (!defaultData) {
    return {};
  }
  let formatData = defaultData;
  let commonData = {};

  let targetCustomerType = 'targetedCustomerGroups';
  if (defaultData?.targetedCustomerGroups?.edges?.length > 0) {
    targetCustomerType = 'targetedCustomerGroups';
  } else if (defaultData?.targetedSegments?.edges?.length > 0) {
    targetCustomerType = 'targetedSegments';
  } else if (defaultData?.targetedLevels?.edges?.length > 0) {
    targetCustomerType = 'targetedLevels';
  }

  if (Object.keys(defaultData).length > 0) {
    const type = EarningRuleTypeKey[defaultData.type];
    formatData = {
      id: defaultData.id,
      pk: defaultData.pk,
      lindedCampaigns: defaultData.campaigns?.edges?.map(item => item?.node),
      lindedStampCampaigns: defaultData.stampTaskRelation?.edges?.map(item => item?.node?.stampCampaign),
      type: defaultData.type,
      generalName: defaultData.name,
      minSpending: defaultData.generalPurchaseTypeMinimumSpending,
      maxSpending: defaultData.generalPurchaseTypeMaximumSpending,
      eligibleDays:
        defaultData.generalPurchaseTypeEligibleNumberOfDaysSincePurchase,
      selectedStores: defaultData.generalPurchaseTypeLimitedToStores?.edges.map(
        (item, index) => ({
          ...item.node,
          displayStoreName: item.node?.name ? `${item.node?.name}${item.node?.visibleInFrontEnd ? '' : '(closed)'}` : null,
        }),
      ),
      isExclusive: defaultData.isExclusive,
      displayIsExclusive: defaultData.isExclusive ? 'Yes' : 'No',
      isExcludeDeliveryCost:
        defaultData.generalPurchaseTypeWillExcludeDeliveryCost,
      isExcludeOtherCharges:
        defaultData.generalPurchaseTypeWillExcludeOtherCharges,
      isInBirthMonth: defaultData.generalPurchaseTypeOnlyForBirthMonth,
      displayIsInBirthMonth: defaultData.generalPurchaseTypeOnlyForBirthMonth
        ? 'Yes'
        : 'No',
      specialSkus: [],
      inputSkus: [],

      referralType: defaultData.memberReferralTypeBeneficiary,
      maxInvitees: defaultData.memberReferralTypeLimit,

      latitude: defaultData.gpsCheckInTypeLatitude,
      longitude: defaultData.gpsCheckInTypeLongitude,
      radius: defaultData.gpsCheckInTypeRadiusInMeter,

      qrCodes: defaultData.qrCodeScanningTypeQrCodes,

      fillingForm: defaultData.fillingFormTypeForm,
      definitionType: defaultData.fillingFormTypeDefinitionType,

      levelGoal: defaultData.levelUpgradeTypeLevelGoal,

      rewardType: defaultData[`${type}TypeRewardType`],
      quantity: getQuantity(
        type,
        defaultData[`${type}TypeRewardType`],
        defaultData,
      ),
      tpeQuantity:
        defaultData[`${type}TypeRewardType`] === EarningRuleRewardType.points
          ? defaultData[`${type}TypePointsRewardTypeTpe`]
          : null,
      coupons: defaultData[`${type}TypeCouponRewardTypeCouponTemplate`],
      translations: getTranslations(defaultData),
      linkedStampCampaign:
        defaultData.linkedCampaignType === 'STAMP_CAMPAIGN' ? true : false,
      displayLinkedStampCampaign:
        defaultData.linkedCampaignType === 'STAMP_CAMPAIGN' ? 'Yes' : 'No',
      targetedCustomerGroups: defaultData.targetedCustomerGroups?.edges?.map(
        (item) => item.node,
      ),
      targetedSegments: defaultData.targetedSegments?.edges?.map(
        (item) => item.node,
      ),
      targetedLevels: defaultData.targetedLevels?.edges?.map((item) => {
        const { node } = item;
        return {
          ...node,
          name: node.levelName,
        };
      }),
      displayTargetCustomerGroups: getListStrings(
        defaultData?.targetedCustomerGroups?.edges || [],
      ),
      displayTargetedSegments: getListStrings(
        defaultData?.targetedSegments?.edges || [],
      ),
      displayTargetedLevels: getListStrings(
        defaultData?.targetedLevels?.edges || [],
      ),
      displayActivePeriod: getReadablePeriod(
        defaultData.startDate,
        defaultData.endDate,
      ),
      activeStartDate: getMillisecondsFromDate(defaultData.startDate),
      activeEndDate:
        getMillisecondsFromDate(defaultData.endDate) ||
        getMillisecondsFromDate(defaultData.startDate),
      activePeriodValues: defaultData.blackoutPeriod?.edges?.map((item) => {
        return {
          startDate: item.node.startDate,
          endDate: item.node.endDate,
          id: item.node.pk,
        };
      }),
      activeWeekday: defaultData.blackoutWeekday,
      isAlwaysActivePeriod: !defaultData.endDate,
      creationDate: defaultData.creationDate,
      targetCustomerType,
    };

    switch (defaultData.type) {
      case EarningRuleType.generalPurchase:
        commonData = {
          quantity:
            defaultData.generalPurchaseTypeRewardType ===
              EarningRuleRewardType.points
              ? defaultData.generalPurchaseTypePointsRewardTypePointsPerXDollarsSpent
              : defaultData.generalPurchaseTypeRewardType ===
                EarningRuleRewardType.stamps
                ? defaultData.generalPurchaseTypeStampRewardTypeStampsPerXDollarsSpent
                : defaultData.generalPurchaseTypeCouponRewardTypeQuantity,
          tpeQuantity:
            defaultData.generalPurchaseTypePointsRewardTypeTpePerXDollarsSpent,
          rewardTypeX:
            defaultData.generalPurchaseTypeRewardType ===
              EarningRuleRewardType.points
              ? defaultData.generalPurchaseTypePointsRewardTypeX
              : defaultData.generalPurchaseTypeStampRewardTypeX,
          displayStampReward: defaultData.generalPurchaseTypeRewardType === EarningRuleRewardType.stamps
            ? `HK$ ${defaultData.generalPurchaseTypeStampRewardTypeStampsPerXDollarsSpent} = ${defaultData.generalPurchaseTypeStampRewardTypeX} stamps`
            : "-",
          rewardTypeTpeX: defaultData.generalPurchaseTypePointsRewardTypeTpeX,
          overallLimit: defaultData[`${type}TypeOverallLimit`],
          perHeadLimit: defaultData[`${type}TypePerHeadLimit`],
          periodicLimit: defaultData[`${type}TypePeriodicLimit`],
          periodicLimitDays:
            defaultData[`${type}TypePeriodicLimitEffectiveNumberOfDays`],
          perHeadPeriodicLimit: defaultData[`${type}TypePerHeadPeriodicLimit`],
          perHeadPeriodicLimitDays:
            defaultData[`${type}TypePerHeadPeriodicLimitEffectiveNumberOfDays`],
        };
        break;
      case EarningRuleType.gpsCheckIn:
      case EarningRuleType.qrCodeScanning:
        commonData = {
          overallLimit: defaultData[`${type}TypeOverallLimit`],
          perHeadLimit: defaultData[`${type}TypePerHeadLimit`],
          periodicLimit: defaultData[`${type}TypePeriodicLimit`],
          periodicLimitDays:
            defaultData[`${type}TypePeriodicLimitEffectiveNumberOfDays`],
          perHeadPeriodicLimit: defaultData[`${type}TypePerHeadPeriodicLimit`],
          perHeadPeriodicLimitDays:
            defaultData[`${type}TypePerHeadPeriodicLimitEffectiveNumberOfDays`],
          displayStampReward: defaultData[`${type}TypeRewardType`] === EarningRuleRewardType.stamps
            ? defaultData[`${type}TypeStampRewardTypeQuantity`] || '-'
            : "-",
        };
        break
      case EarningRuleType.fillingForm:
        commonData = {
          overallLimit: defaultData[`${type}TypeOverallLimit`],
          perHeadLimit: defaultData[`${type}TypePerHeadLimit`],
          periodicLimit: defaultData[`${type}TypePeriodicLimit`],
          periodicLimitDays:
            defaultData[`${type}TypePeriodicLimitEffectiveNumberOfDays`],
          perHeadPeriodicLimit: defaultData[`${type}TypePerHeadPeriodicLimit`],
          perHeadPeriodicLimitDays:
            defaultData[`${type}TypePerHeadPeriodicLimitEffectiveNumberOfDays`],
        };
        break;
      case EarningRuleType.memberReferral:
        commonData = {
          displayStampReward: defaultData.memberReferralTypeRewardType === EarningRuleRewardType.stamps
            ? defaultData.memberReferralTypeStampRewardTypeQuantity || '-'
            : "-",
        };
        break;
      case EarningRuleType.birthday:
        commonData = {};
        break;
      case EarningRuleType.levelUpgrade:
        commonData = {
          perHeadPeriodicLimit: defaultData[`${type}TypePerHeadPeriodicLimit`],
          perHeadPeriodicLimitDays:
            defaultData[`${type}TypePerHeadPeriodicLimitEffectiveNumberOfDays`],
        };
        break;
      default:
        break;
    }
  }

  const earningRule = { ...formatData, ...commonData };
  return earningRule;
};

export default {
  namespace: 'createEarningRules',
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    stepChange(state, { payload }) {
      const isBack = payload.isBack;
      let step = payload.step;
      const isValid = payload.isValid;
      const stepConfig = getNewStepConfig(
        step,
        state.stepConfig,
        !isValid,
        isBack,
      );
      if (isValid) {
        step = isBack ? step - 1 : step + 1;
      }
      console.log('@@106: ', step, '->', isValid, payload);
      return {
        ...state,
        currentStep: step,
        stepConfig,
        createStatus: APIStatus.none,
      };
    },

    loadEarningRuleFromCookie(state, { payload }) {
      const earningRule =
        getObjectFromSessionStorage(EARNING_RULES_SESSION_KEY) || {};
      // const defaultData = payload ? payload.data.earningRule : {};

      // let earningRule = defaultData;
      // if (!tempEarningRule) {
      //   earningRule = parseEarningRule(defaultData)
      // }

      // if (!defaultData.pk) {
      //   earningRule = tempEarningRule || {};
      //   delete earningRule.id;
      //   delete earningRule.pk;
      // }
      console.log('@259 earningRule', earningRule);
      // saveToSessionStorage(EARNING_RULES_SESSION_KEY, earningRule);
      return {
        ...state,
        earningRule,
        hasUpdatedDefaultValues: true,
        skusHaveLoaded: true,
      };
    },
    removeEarningRuleFromCookie(state, { payload }) {
      removeFromSessionStorage(EARNING_RULES_SESSION_KEY);

      return {
        ...state,
        hasUpdatedDefaultValues: false,
      };
    },
    // clearData(state, { payload }) {
    //   return { ...state, ...getInitState() };
    // },

    // saveOrRemoveEarningRuleFromCookie(state, { payload }) {
    //   let campaign = getObjectFromSessionStorage(EARNING_RULES_SESSION_KEY);
    //   if (payload.data) {
    //     campaign = { ...payload.data };
    //     saveToSessionStorage(EARNING_RULES_SESSION_KEY, campaign);
    //   } else {
    //     removeFromSessionStorage(EARNING_RULES_SESSION_KEY);
    //   }
    //   return {
    //     ...state,
    //   };
    // },
    changeVals(state, { payload }) {
      console.log('@@138: vals changed', payload);
      let tempEarningRule = getObjectFromSessionStorage(
        EARNING_RULES_SESSION_KEY,
      );
      if (payload.vals) {
        let data = {};
        if (payload.language) {
          data[payload.language] = {
            ...tempEarningRule[payload.language],
            ...payload.vals,
          };
        } else {
          data = payload.vals;
        }

        tempEarningRule = { ...tempEarningRule, ...data };
        saveToSessionStorage(EARNING_RULES_SESSION_KEY, tempEarningRule);
      }

      return {
        ...state,
        formChanged: true,
      };
    },
    assembleEarningRuleProducts(state, { payload }) {
      const specialSkus = payload.list?.filter((item) => item.node.skuId !== item.node.sku)
        .map((item) => ({
          pk: item.node.skuId,
          name: item.node.sku,
          category: {
            pk: item.node.categoryId,
            name: item.node.categoryName,
          },
        }));
      const inputSkus = payload.list?.filter((item) => item.node.skuId === item.node.sku)
        .map((item) => item.node.sku)

      return {
        ...state,
        earningRule: {
          ...state.earningRule,
          specialSkus: payload.page > 1 ? [...state.earningRule.specialSkus, ...specialSkus] : specialSkus,
          inputSkus: payload.page > 1 ? [...state.earningRule.inputSkus, ...inputSkus] : inputSkus,
        }
      }
    },

    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },

  effects: {
    getOneEarningRule: [
      function* ({ payload }, { call, select, put }) {
        yield put({
          type: 'updateState',
          payload: { createStatus: APIStatus.calling },
        });
        const serviceArgs = [
          getOneEarningRule,
          convertPKToId('EarningRuleNode', payload.id),
        ];
        function* onSuccess(data) {
          console.log(data);
          const earningRule = parseEarningRule(data?.earningRule);

          yield put({
            type: 'updateState',
            payload: {
              earningRule: earningRule,
              createStatus: APIStatus.none,
              hasUpdatedDefaultValues: true,
            },
          });
          const action = payload?.afterAction || (() => { });
          yield action(earningRule);
          yield put({
            type: 'getEarningRuleProducts',
            payload: { earningRuleId: payload.id }
          })
        }

        function* onFailed(data) {
          console.log('@@getOneEarningRule error: ', data);
          yield put({
            type: 'updateState',
            payload: {
              skusHaveLoaded: true,
            }
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed, onFailed);
      },
      { type: 'takeLatest' },
    ],
    getEarningRuleProducts: [
      function* ({ payload }, { call, all, put }) {
        const page = payload.page || 1;
        const pageCursor = page > 1 ? convertNumberToCursor((page - 1) * 100 - 1) : '';
        const serviceArgs = [
          getEarningRuleProducts,
          payload.earningRuleId,
          pageCursor,
        ];
        function* onSuccess(data) {
          const products = data.products;
          yield put({
            type: 'assembleEarningRuleProducts',
            payload: {
              page,
              list: products.edges,
            },
          });
          if (products.pageInfo?.hasNextPage) {
            yield put({
              type: 'getEarningRuleProducts',
              payload: {
                earningRuleId: payload.earningRuleId,
                page: page + 1,
              }
            })
          } else {
            yield put({
              type: 'updateState',
              payload: {
                skusHaveLoaded: true,
              }
            });
          }
        }
        // eslint-disable-next-line require-yield
        function* onFailed(data) {
          console.log('@@getEarningRuleProducts error: ', data);
          yield put({
            type: 'updateState',
            payload: {
              skusHaveLoaded: true,
            }
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed, onFailed);
      },
    ],
    createOrUpdateEarningRule: [
      function* ({ payload }, { call, all, put, select }) {
        // const tempEarningRule = getObjectFromSessionStorage(EARNING_RULES_SESSION_KEY);
        const tempEarningRule = payload.data;
        console.log('@327', tempEarningRule, payload);
        if (!tempEarningRule?.type) {
          return;
        }

        const key = EarningRuleTypeKey[tempEarningRule.type];

        let data = {
          name: tempEarningRule.translations?.en?.generalName,
          type: tempEarningRule.type,
          instructionSectionTitle:
            tempEarningRule.translations?.en?.instructionSectionTitle,
          instructionSectionContent:
            tempEarningRule.translations?.en?.instructionSectionContent,
          detailSectionTitle:
            tempEarningRule.translations?.en?.detailSectionTitle,
          detailSectionContent:
            tempEarningRule.translations?.en?.detailSectionContent,
          translations: postTranslations(tempEarningRule.translations),
          linkedCampaignType: tempEarningRule.linkedStampCampaign
            ? EarningRuleLinkedCampaign.stampCampaign
            : EarningRuleLinkedCampaign.earningCampaign,
        };
        data[`${key}TypeRewardType`] = tempEarningRule.rewardType;
        let rewardData = {};
        if (tempEarningRule.rewardType === EarningRuleRewardType.points) {
          rewardData[`${key}TypePointsRewardTypePoints`] =
            tempEarningRule.quantity;
          rewardData[`${key}TypePointsRewardTypeTpe`] =
            tempEarningRule.tpeQuantity || null;
        } else if (
          tempEarningRule.rewardType === EarningRuleRewardType.stamps
        ) {
          rewardData[`${key}TypeStampRewardTypeQuantity`] =
            tempEarningRule.quantity;
        } else {
          rewardData[`${key}TypeCouponRewardTypeCouponTemplate`] =
            tempEarningRule.coupons.pk;
          rewardData[`${key}TypeCouponRewardTypeQuantity`] =
            tempEarningRule.quantity;
        }

        let values = {};
        const getTargetedCustomerGroups = (tempEarningRule) => {
          if (
            tempEarningRule?.isExclusive &&
            (tempEarningRule?.targetCustomerType === 'targetedCustomerGroups' ||
              !tempEarningRule?.targetCustomerType)
          ) {
            return (
              tempEarningRule?.targetedCustomerGroups?.map((item) => item.pk) ||
              []
            );
          }
          return [];
        };
        const getTargetedSegments = (tempEarningRule) => {
          if (
            tempEarningRule?.isExclusive &&
            tempEarningRule?.targetCustomerType === 'targetedSegments'
          ) {
            return (
              tempEarningRule?.targetedSegments?.map((item) => item.pk) || []
            );
          }
          return [];
        };
        const getTargetedLevels = (tempEarningRule) => {
          if (
            tempEarningRule?.isExclusive &&
            tempEarningRule?.targetCustomerType === 'targetedLevels'
          ) {
            return (
              tempEarningRule?.targetedLevels?.map((item) => item.pk) || []
            );
          }
          return [];
        };
        switch (tempEarningRule.type) {
          case EarningRuleType.newMember:
            values = {
              startDate: tempEarningRule?.isAlwaysActivePeriod
                ? tempEarningRule?.creationDate || new Date()
                : tempEarningRule?.activeStartDate,
              endDate: !tempEarningRule?.isAlwaysActivePeriod
                ? tempEarningRule?.activeEndDate
                : null,
              isExclusive: tempEarningRule?.isExclusive,
              targetedCustomerGroups:
                getTargetedCustomerGroups(tempEarningRule),
              targetedSegments: getTargetedSegments(tempEarningRule),
              targetedLevels: getTargetedLevels(tempEarningRule),
              ...rewardData,
            };
            break;
          case EarningRuleType.generalPurchase:
            const specialSkusFormated =
              tempEarningRule.specialSkus?.map((item) => ({
                skuId: item.pk,
                sku: item.name,
                categoryName: null,
                categoryId: null,
              })) || [];
            const inputSkusFormated =
              tempEarningRule.inputSkus?.filter(Boolean).map((item) => ({
                skuId: item,
                sku: item,
              })) || [];

            values = {
              generalPurchaseTypeMinimumSpending: tempEarningRule.minSpending,
              generalPurchaseTypeMaximumSpending:
                tempEarningRule.maxSpending || null,
              generalPurchaseTypeEligibleNumberOfDaysSincePurchase:
                tempEarningRule.eligibleDays || null,
              generalPurchaseTypeWillExcludeDeliveryCost:
                tempEarningRule.isExcludeDeliveryCost || false,
              generalPurchaseTypeWillExcludeOtherCharges:
                tempEarningRule.isExcludeOtherCharges || false,
              generalPurchaseTypeOnlyForBirthMonth:
                tempEarningRule.isInBirthMonth || false,
              generalPurchaseTypeLimitedToStores: tempEarningRule.selectedStores
                ? tempEarningRule.selectedStores.map((item, index) => item.pk)
                : [],
              generalPurchaseTypeRewardType: tempEarningRule.rewardType,
              // generalPurchaseTypeLimitedSkus: tempEarningRule.specialSkus,
              generalPurchaseTypeLimitedSkus:
                specialSkusFormated.concat(inputSkusFormated),
              generalPurchaseTypeOverallLimit:
                tempEarningRule.overallLimit || null,
              generalPurchaseTypePerHeadLimit:
                tempEarningRule.perHeadLimit || null,
              generalPurchaseTypePeriodicLimit:
                tempEarningRule.periodicLimit || null,
              generalPurchaseTypePeriodicLimitEffectiveNumberOfDays:
                tempEarningRule.periodicLimitDays || null,
              generalPurchaseTypePerHeadPeriodicLimit:
                tempEarningRule.perHeadPeriodicLimit || null,
              generalPurchaseTypePerHeadPeriodicLimitEffectiveNumberOfDays:
                tempEarningRule.perHeadPeriodicLimitDays || null,
              startDate: tempEarningRule?.isAlwaysActivePeriod
                ? tempEarningRule?.creationDate || new Date()
                : tempEarningRule?.activeStartDate,
              endDate: !tempEarningRule?.isAlwaysActivePeriod
                ? tempEarningRule?.activeEndDate
                : null,
              isExclusive: tempEarningRule?.isExclusive,
              targetedCustomerGroups:
                getTargetedCustomerGroups(tempEarningRule),
              targetedSegments: getTargetedSegments(tempEarningRule),
              targetedLevels: getTargetedLevels(tempEarningRule),
            };
            if (tempEarningRule.rewardType === EarningRuleRewardType.coupons) {
              values['generalPurchaseTypeCouponRewardTypeCouponTemplate'] =
                tempEarningRule.coupons.pk;
              values['generalPurchaseTypeCouponRewardTypeQuantity'] =
                tempEarningRule.quantity;
            } else if (
              tempEarningRule.rewardType === EarningRuleRewardType.stamps
            ) {
              values['generalPurchaseTypeStampRewardTypeX'] =
                tempEarningRule.rewardTypeX;
              values[
                'generalPurchaseTypeStampRewardTypeStampsPerXDollarsSpent'
              ] = tempEarningRule.quantity;
            } else {
              values[
                'generalPurchaseTypePointsRewardTypePointsPerXDollarsSpent'
              ] = tempEarningRule.quantity;
              values['generalPurchaseTypePointsRewardTypeX'] =
                tempEarningRule.rewardTypeX;
              values['generalPurchaseTypePointsRewardTypeTpePerXDollarsSpent'] =
                tempEarningRule.tpeQuantity;
              values['generalPurchaseTypePointsRewardTypeTpeX'] =
                tempEarningRule.rewardTypeTpeX;
            }
            break;
          case EarningRuleType.gpsCheckIn:
            values = {
              gpsCheckInTypeLatitude: tempEarningRule.latitude,
              gpsCheckInTypeLongitude: tempEarningRule.longitude,
              gpsCheckInTypeRadiusInMeter: tempEarningRule.radius,
              gpsCheckInTypeOverallLimit: tempEarningRule.overallLimit || null,
              gpsCheckInTypePerHeadLimit: tempEarningRule.perHeadLimit || null,
              gpsCheckInTypePeriodicLimit:
                tempEarningRule.periodicLimit || null,
              gpsCheckInTypePeriodicLimitEffectiveNumberOfDays:
                tempEarningRule.periodicLimitDays || null,
              gpsCheckInTypePerHeadPeriodicLimit:
                tempEarningRule.perHeadPeriodicLimit || null,
              gpsCheckInTypePerHeadPeriodicLimitEffectiveNumberOfDays:
                tempEarningRule.perHeadPeriodicLimitDays || null,
              ...rewardData,
            };
            break;
          case EarningRuleType.memberReferral:
            values = {
              memberReferralTypeBeneficiary:
                tempEarningRule.referralType.toUpperCase(),
              memberReferralTypeLimit: tempEarningRule.maxInvitees || null,
              ...rewardData,
            };
            break;
          case EarningRuleType.birthday:
            values = {
              startDate: tempEarningRule?.isAlwaysActivePeriod
                ? tempEarningRule?.creationDate || new Date()
                : tempEarningRule?.activeStartDate,
              endDate: !tempEarningRule?.isAlwaysActivePeriod
                ? tempEarningRule?.activeEndDate
                : null,
              isExclusive: tempEarningRule?.isExclusive,
              targetedCustomerGroups:
                getTargetedCustomerGroups(tempEarningRule),
              targetedSegments: getTargetedSegments(tempEarningRule),
              targetedLevels: getTargetedLevels(tempEarningRule),
              ...rewardData,
            };
            break;
          case EarningRuleType.qrCodeScanning:
            values = {
              qrCodeScanningTypeQrCodes: tempEarningRule.qrCodes,
              qrCodeScanningTypeOverallLimit:
                tempEarningRule.overallLimit || null,
              qrCodeScanningTypePerHeadLimit:
                tempEarningRule.perHeadLimit || null,
              qrCodeScanningTypePeriodicLimit:
                tempEarningRule.periodicLimit || null,
              qrCodeScanningTypePeriodicLimitEffectiveNumberOfDays:
                tempEarningRule.periodicLimitDays || null,
              qrCodeScanningTypePerHeadPeriodicLimit:
                tempEarningRule.perHeadPeriodicLimit || null,
              qrCodeScanningTypePerHeadPeriodicLimitEffectiveNumberOfDays:
                tempEarningRule.perHeadPeriodicLimitDays || null,
              ...rewardData,
            };
            break;
          case EarningRuleType.fillingForm:
            values = {
              // fillingFormTypeForm: tempEarningRule.fillingForm.pk,
              // fillingFormTypeDefinitionType: tempEarningRule.definitionType,
              fillingFormTypeOverallLimit: tempEarningRule.overallLimit || null,
              fillingFormTypePerHeadLimit: tempEarningRule.perHeadLimit || null,
              fillingFormTypePeriodicLimit:
                tempEarningRule.periodicLimit || null,
              fillingFormTypePeriodicLimitEffectiveNumberOfDays:
                tempEarningRule.periodicLimitDays || null,
              fillingFormTypePerHeadPeriodicLimit:
                tempEarningRule.perHeadPeriodicLimit || null,
              fillingFormTypePerHeadPeriodicLimitEffectiveNumberOfDays:
                tempEarningRule.perHeadPeriodicLimitDays || null,
              ...rewardData,
            };
            break;
          case EarningRuleType.levelUpgrade:
            values = {
              levelUpgradeTypeLevelGoal: tempEarningRule.levelGoal.pk || null,
              levelUpgradeTypePerHeadPeriodicLimit:
                tempEarningRule.perHeadPeriodicLimit || null,
              levelUpgradeTypePerHeadPeriodicLimitEffectiveNumberOfDays:
                tempEarningRule.perHeadPeriodicLimitDays || null,
              startDate: tempEarningRule?.isAlwaysActivePeriod
                ? tempEarningRule?.creationDate || new Date()
                : tempEarningRule?.activeStartDate,
              endDate: !tempEarningRule?.isAlwaysActivePeriod
                ? tempEarningRule?.activeEndDate
                : null,
              isExclusive: tempEarningRule?.isExclusive,
              targetedCustomerGroups:
                getTargetedCustomerGroups(tempEarningRule),
              targetedSegments: getTargetedSegments(tempEarningRule),
              targetedLevels: getTargetedLevels(tempEarningRule),
              ...rewardData,
            };
            break;
          default:
            values = {
              ...rewardData,
            };
            break;
        }

        if (tempEarningRule?.linkedStampCampaign) {
          values['startDate'] = null;
          values['endDate'] = null;
          values['isExclusive'] = false;
          values['targetedCustomerGroups'] = [];
          values['targetedSegments'] = [];
          values['targetedLevels'] = [];
        }
        // let serviceArgs = [createEarningRule, { ...data, ...values }];
        // if (tempEarningRule.pk) {
        //   serviceArgs = [
        //     updateEarningRule,
        //     { id: tempEarningRule.pk, ...data, ...values },
        //   ];
        // }
        if (tempEarningRule.pk) {
          data['id'] = tempEarningRule.pk;
        }
        const blackoutPeriod = (tempEarningRule.activePeriodValues ?? [])
          .filter((item) => {
            return !!item?.startDate && !!item?.endDate;
          })
          .map((item) => {
            const data = { startDate: item.startDate, endDate: item.endDate };
            return data;
          });
        console.log('blackoutPeriod', blackoutPeriod);
        const blackoutWeekday = tempEarningRule.activeWeekday;
        const combineData = {
          ...data,
          ...values,
          blackoutPeriod,
          blackoutWeekday,
        };
        const serviceArgs = [createOrUpdateEarningRule, combineData];
        console.log('@@493: ', combineData);
        const afterAction = payload.afterAction || (() => { });
        function* onSuccess(data) {
          console.log('@@267', data);
          if (
            ('createEarningRule' in data && data.createEarningRule.errors) ||
            ('updateEarningRule' in data && data.updateEarningRule.errors)
          ) {
            yield put({
              type: 'updateState',
              payload: {
                createStatus: APIStatus.failed,
                formChanged: false,
                checked: CheckStatus.initOrNotChecked,
              },
            });
          } else {
            yield put({
              type: 'updateState',
              payload: {
                createStatus: APIStatus.success,
                formChanged: false,
                earningRule: {
                  pk: data.createEarningRule?.node?.pk,
                  type: data.createEarningRule?.node?.type,
                },
                checked: CheckStatus.initOrNotChecked,
              },
            });
          }

          yield put({ type: 'removeEarningRuleFromCookie' });
          afterAction();
        }

        function* onFail(errors) {
          console.log('@275:', errors);

          yield put({
            type: 'updateState',
            payload: {
              createStatus: APIStatus.failed,
              formChanged: false,
              checked: CheckStatus.initOrNotChecked,
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFail);
      },
      { type: 'takeLatest' },
    ],
    getWebhookAuthenticatedKey: [
      function* ({ payload }, { put }) {
        const serviceArgs = [getWebhookAuthenticatedKey, payload.earningRuleId];
        function* onSuccess(data) {
          const webhookAuthenticatedKeyNode =
            data?.authenticatedKeys?.edges[0]?.node;
          yield put({
            type: 'updateState',
            payload: {
              webhookAuthenticatedKey: webhookAuthenticatedKeyNode,
              isCopied: webhookAuthenticatedKeyNode?.isCopied,
            },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    createOrUpdateWebhookAuthenticatedKey: [
      function* ({ payload }, { put }) {
        const afterAction = payload.afterAction || (() => { });
        const serviceArgs = [
          createOrUpdateWebhookAuthenticatedKey,
          { ...payload },
        ];
        function* onSuccess(data) {
          const action = payload?.id ? 'update' : 'create';
          const webhookAuthenticatedKeyNode =
            data?.[`${action}AuthenticatedKey`]?.node;
          yield put({
            type: 'updateState',
            payload: {
              webhookAuthenticatedKey: webhookAuthenticatedKeyNode,
              isCopied: webhookAuthenticatedKeyNode?.isCopied,
            },
          });
          afterAction(webhookAuthenticatedKeyNode);
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
