

export const EditCustomerHandleError = {
  firstName: {
    name: 'firstName',
    message: 'Please provide first name',
  },
  lastName: {
    name: 'lastName',
    message: 'Please provide last name',
  },
  pointTransaction: {
    name: 'pointTransaction',
    message: 'Please provide a point transaction type',
  },
  transactionPoint: {
    name: 'transactionPoint',
    message: 'Please provide a positive number',
  },
  tpeTransaction: {
    name: 'tpeTransaction',
    message: 'Please provide a progression point transaction type',
  },
  transactionTpe: {
    name: 'transactionTpe',
    message: 'Please provide a positive number',
  },
  mobilePhoneNumberCountryCode: {
    name: 'mobilePhoneNumberCountryCode',
    message: 'Please provide country code',
  },
  mobilePhoneNumberSubscriberNumber: {
    name: 'mobilePhoneNumberSubscriberNumber',
    message: 'Please provide phone number',
  },
  mobilePhoneNumberSubscriberNumberInvalid: {
    name: 'mobilePhoneNumberSubscriberNumber',
    message: 'Please provide valid phone number',
  },
  levelRenewalDate: {
    name: 'levelRenewalDate',
    message: 'Please provide valid date',
  },
  emailAddress: {
    name: 'emailAddress',
    message: 'Please provide email',
  },
  adminEditMobile: {
    name: 'adminEditMobile',
    message: 'Invalid or customer already exists',
  },
  adminEditEmail: {
    name: 'adminEditEmail',
    message: 'Invalid or customer already exists',
  },
};

