import { ImageDomain } from '../config/CustomEnums';
import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getBulkRewardList = (afterCoursor = '', search = {}) => {
  let searchString = '';
  let orderBy = '-pk';
  let size = 20;
  if (search?.search) {
    searchString += `, membershipIdIn: "${search.search}"`;
  }
  if (search?.sort) {
    orderBy = search.sort;
  }
  switch (search.type) {
    case 'ADD_POINTS':
      searchString += `, rewardTypeIn: "ADD_POINTS"`;
      break;
    case 'REMOVE_POINTS':
      searchString += `, rewardTypeIn: "REMOVE_POINTS"`;
      break;
    case 'ADD_COUPONS':
      searchString += `, rewardTypeIn: "ADD_COUPONS"`;
      break;
    case 'REMOVE_COUPONS':
      searchString += `, rewardTypeIn: "REMOVE_COUPONS"`;
      break;
    case 'ADD_STAMPS':
      searchString += `, rewardTypeIn: "ADD_STAMPS"`;
      break;
    case 'REMOVE_STAMPS':
      searchString += `, rewardTypeIn: "REMOVE_STAMPS"`;
      break;
    case 'ADD_TPES':
      searchString += `, rewardTypeIn: "ADD_TPES"`;
      break;
    case 'REMOVE_TPES':
        searchString += `, rewardTypeIn: "REMOVE_TPES"`;
        break;
    default:
  }

  const query = `{
        bulkRewardRecords(first:${size},after:"${afterCoursor}",orderBy:"${orderBy}" ${searchString}){
            totalCount
            pageInfo{
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
            edges{
                node{
                    id
                    pk
                    membershipId
                    customer{
                        pk
                        isReset
                    }
                    rewardType
                    addPoints
                    removePoints
                    addTpes
                    removeTpes
                    addCouponSetIds
                    addCouponAmount
                    removeCouponSetIds
                    removeCouponAmount
                    addStampCampaignIds
                    addStampCampaignQuantity
                    removeStampCampaignIds
                    removeStampCampaignQuantity
                }
            }
        }
    }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getBulkRewardRecord = (id) => {
  const query = `{
        bulkRewardRecord(id:"${id}"){
            id
            pk
            rewardType
            addPoints
            removePoints
            addTpes
            removeTpes
            addCouponSetIds
            addCouponAmount
            removeCouponSetIds
            removeCouponAmount
            addStampCampaignIds
            addStampCampaignQuantity
            removeStampCampaignIds
            removeStampCampaignQuantity
            membershipId
            customer{
                pk
                id
                membershipId
                firstName
                lastName
                isReset
            }
            remarks
            creationDate
        }
    }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const TEMPLATE_FILE_PATH = `${ImageDomain}bulkRewardTemplate.csv`;
