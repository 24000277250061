import React, { useEffect, useState } from 'react';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { useHistory, useLocation } from 'react-router-dom';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import { useSelector, useDispatch } from 'react-redux';
import { createAction } from '../../../utils';
import DeletePrompt from '../../../components/base/DeletePrompt';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';
import ListButtonsGroup from '../../../components/base/ListButtonsGroup';
import { ImportResource } from '../../../models/DownloadImportModel';
import { CUSTOMER_GROUP_TEMPLATE_FILE_PATH } from '../../../services/CustomerGroupApiHelper';
import Filter from './Filter';

export default function CustomerGroupList() {
  const queryString = require('query-string');
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const hiddenFileInput = React.useRef(null);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const [uploadOriginFileName, setUploadOriginFileName] = useState();

  const {
    pageInfo,
    totalCount,
    totalPage,
    displayFields,
    groupList,
    checkedList,
    importedFile,
  } = useSelector((state) => ({
    pageInfo: state.customerGroup.pageInfo,
    totalCount: state.customerGroup.totalCount,
    totalPage: state.customerGroup.totalPage,
    displayFields: state.customerGroup.listDisplayFields,
    groupList: state.customerGroup.currentPageGroupList,
    checkedList: state.customerGroup.checkedList,
    importedFile: state.uploadFiles.importedFile,
  }));

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    const search = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);
    dispatch(
      createAction('customerGroup/getCurrentPageList')({
        search,
        rank,
        page,
        moreSearch: { ...searchDict },
      }),
    );
  }, [dispatch, location, queryString]);

  useEffect(() => {
    if (Object.keys(importedFile).length) {
      dispatch({
        type: 'downloadAndImport/importFile',
        payload: {
          from: ImportResource.customerGroup,
          file: importedFile,
          related: {
            fileName: uploadOriginFileName,
          }
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, importedFile]);

  const handleImportClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setUploadOriginFileName(fileUploaded?.name);
    dispatch(
      createAction('uploadFiles/uploadFile')({
        file: fileUploaded,
        source: event,
      }),
    );

    event.target.value = null;
  };

  const handleExportTempalte = () => {
    var eleLink = document.createElement('a');
    eleLink.href = CUSTOMER_GROUP_TEMPLATE_FILE_PATH;
    eleLink.style.display = 'none';
    document.body.appendChild(eleLink);
    eleLink.click();
    document.body.removeChild(eleLink);
  }
  const buttons = [
    <ListButtonsGroup
      hideExtraButtonWidth={500}
      extraButtons={[
        <AuthButton
          customClass="btn-back-button-common btn-download"
          title={'Download Import Form'}
          action={handleExportTempalte}
          requires={PermissionCodes.addExportjob}
        />,
        <AuthButton
          customClass="btn-back-button-common"
          title={'Create Customer List by Import'}
          action={handleImportClick}
          requires={PermissionCodes.addImportjob}
        />,
      ]}
      extraPopContent={[
        {
          requires: PermissionCodes.addExportjob,
          action: handleExportTempalte,
          content: 'Download Import Form',
        },
        {
          requires: PermissionCodes.addImportjob,
          action: handleImportClick,
          content: 'Create Customer List by Import',
        },
      ]}
      primaryButton={
        <AuthButton
        title="Create Customer List"
        action={() => history.push('/customer_group/create')}
        requires={PermissionCodes.addCustomerGroup}
        />
      }
      primaryPopContent={{
        requires: PermissionCodes.addCustomerGroup,
        action: () => history.push('/customer_group/create'),
        content: "Create Customer List",
      }}
    />,
    <input
      type="file"
      ref={hiddenFileInput}
      onChange={handleChange}
      style={{ display: 'none' }}
      accept=".csv"
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => setShowDeletePrompt(true),
      requires: PermissionCodes.deleteCustomerGroup,
    },
  ];
  const tabs = [
    {
      name: 'List of customer groups',
      content: (
        <BaseTabListContainer
          hideTab={true}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          filter={{ hasFilter: true, component: Filter }}
          listContent={
            <BaseListContainer
              fields={displayFields}
              model={'customerGroup'}
              permissionGroup={PermissionCodes.customergroup}
              dataList={groupList}
              totalPage={totalPage ? totalPage : 0}
              deleteInfo={{
                data: [],
                title: 'customer list',
                relatedName: '',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.CUSTOMER_GROUP,
              }}
              actions={['Detail', 'Edit', 'Delete', 'Duplicate']}
            />
          }
        />
      ),
    },
  ];
  return (
    <>
      <CustomListComponent
        caution={{
          detail:
            'Customer list is a group of customers manually selected from known members.',
          title: 'Customer Lists',
        }}
        buttons={buttons}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={false}
        tabs={tabs}
      />
      <DeletePrompt
        title={'customer list'}
        data={checkedList}
        relatedSections={DELETE_RELATED_SECTIONS.CUSTOMER_GROUP}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('customerGroup/delete')({
              afterAction: () => {
                history.push('/customer_group');
              },
            }),
          );
        }}
      />
    </>
  );
}
