import React, { useCallback, useEffect, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { getCurrentRouteName } from 'components/bars/CollapseNavUtil';
import { NavBarNames } from 'config/NavBarNameList';

import './CollapseNav.scss';

function CollapseNav(props) {
  const currentLocation = useLocation();
  const tab = props.tab;
  const [activedSideNav, setActiveSideNav] = useState('');
  const [hasSubMenu, setHasSubMenu] = useState([
    // 'Dashboard',
    'user',
    'Engagement',
  ]);
  const [menus, setMenus] = useState([]);

  const { user } = useSelector((state) => ({
    user: state.users,
  }));

  const setMenu = useCallback(() => {
    let hasPermissionMenus = [
      NavBarNames.customer,
      NavBarNames.campaigns,
      NavBarNames.coupons,
    ];
    if (tab.list) {
      tab.list.forEach((item) => {
        const hasPermission =
          user?.userPermissions?.filter(
            (val) => val.split('_')[1] === item.codename || val.split('_')[1] === item?.overviewCodename,
          ).length > 0;

        if (hasPermission) {
          setHasSubMenu([...hasSubMenu, tab.name]);
          hasPermissionMenus.push(item.name);
        }
      });
    }

    setMenus(hasPermissionMenus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    setActiveSideNav(getCurrentRouteName(currentLocation.pathname));
  }, [currentLocation]);

  useEffect(() => {
    if (!user?.isSuperuser) {
      console.log('@@54: ');
      setMenu();
    }
  }, [user, setMenu]);

  return (
    <>
      {user?.isSuperuser || hasSubMenu.includes(tab.name) ? (
        <div className="side-menu-nav-area">
          <Card className={`side-menu-nav-section `}>
            <Accordion.Toggle
              as={Card.Header}
              variant="link"
              eventKey={tab.name}
              className="side-menu-nav-header"
            >
              {tab.location ? (
                <Link
                  to={tab.location}
                  className={`side-menu-nav-title side-menu-nav-title-link ${
                    activedSideNav === tab.name
                      ? 'side-menu-nav-header-focus'
                      : ''
                  }`}
                >
                  {tab.displayName ? tab.displayName : tab.name.toUpperCase()}
                </Link>
              ) : (
                <label className="side-menu-nav-title">
                  {tab.displayName ? tab.displayName : tab.name.toUpperCase()}
                </label>
              )}
            </Accordion.Toggle>
            {tab.list ? (
              <Accordion.Collapse
                eventKey={`${tab.name}-body`}
                className="side-menu-nav-header show"
              >
                <Card.Body className="side-menu-nav-header">
                  {tab.list?.map((route, index) =>
                    menus.includes(route.name) || user?.isSuperuser ? (
                      route.location ? (
                        <Link
                          key={route.name}
                          to={route.location}
                          className={`side-menu-nav-item side-menu-nav-item-second ${
                            activedSideNav === route.name
                              ? 'side-menu-nav-header-focus'
                              : ''
                          }`}
                        >
                          <div className="side-menu-nav-item-content">
                            {route.name}
                          </div>
                        </Link>
                      ) : (
                        <label
                          key={route.name}
                          className={`side-menu-nav-item side-menu-nav-item-second`}
                        >
                          <div className="side-menu-nav-item-content">
                            {route.name}
                          </div>
                        </label>
                      )
                    ) : null,
                  )}
                </Card.Body>
              </Accordion.Collapse>
            ) : null}
          </Card>
        </div>
      ) : null}
    </>
  );
}
export default CollapseNav;
