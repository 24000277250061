import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseForm from '../../../components/base/v2/BaseForm';
import { createAction } from 'utils';
import CreateCategoryOrSubcategoryStepOne from './CreateCategoryOrSubcategoryStepOne';
import { PRODUCT_LIST_TAB } from './ProductCategoryList';

const mapPropsToState = (state) => ({
  productCategory: state.createProductCategory.productCategory,
  productSubcategory: state.createProductCategory.productSubcategory,
  hasUpdatedDefaultValues: state.createProductCategory.hasUpdatedDefaultValues,
  formHasSubmitted: state.createProductCategory.formHasSubmitted,
});

export const CREATE_CATEGORY_RESOURCE = {
  productCategory: "productCategory",
  productSubcategory: "productSubcategory",
}

const CreateCategoryOrSubcategory = ({
  resource = CREATE_CATEGORY_RESOURCE.productCategory,
  productCategory,
  productSubcategory,
  hasUpdatedDefaultValues,
  formHasSubmitted,
}) => {
  const queryString = require('query-string');
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const defaultValues = resource === CREATE_CATEGORY_RESOURCE.productSubcategory ? productSubcategory : productCategory

  const [completeProcess, setShowCompleteProcess] = useState(false);

  useEffect(() => {
    if (resource === CREATE_CATEGORY_RESOURCE.productCategory) {
      if (params.id) {
        dispatch(createAction('createProductCategory/getOneProductCategory')({
          id: params.id,
        }));
      } else {
        dispatch(createAction('createProductCategory/loadProductCategoryFromCookie')());
      }
      return;
    }
    if (resource === CREATE_CATEGORY_RESOURCE.productSubcategory) {
      if (params.id) {
        dispatch(createAction('createProductCategory/getOneProductSubcategory')({
          id: params.id,
        }));
      } else {
        dispatch(createAction('createProductCategory/loadProductSubcategoryFromCookie')());
      }
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, params.id]);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'createProductCategory/clearData',
        payload: {}
      });
    };
  }, [dispatch]);

  const stepContent = [
    <CreateCategoryOrSubcategoryStepOne
      resource={resource}
      setShowCompleteProcess={setShowCompleteProcess}
    />,
  ];

  const goToCategoryList = () => {
    const hash = resource === CREATE_CATEGORY_RESOURCE.productSubcategory
      ? PRODUCT_LIST_TAB.productSubcategories : PRODUCT_LIST_TAB.productCategories;
    history.push({
      pathname: '/product_categories',
      hash,
      search: queryString.stringify({
        page: 1,
        rank: true,
        search: '',
      }),
    });
  };

  return (
    <>
      <BaseForm
        defaultValues={defaultValues}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        tempSave={(save, getValues) => {
          dispatch({
            type: resource === CREATE_CATEGORY_RESOURCE.productSubcategory
              ? 'brand/saveOrRemoveBrandFromCookie'
              : 'brand/saveOrRemoveBrandFromCookie',
            payload: {
              save,
              values: getValues()
            },
          });
        }}
        stepsConfig={[]}
        content={stepContent}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb name={params.id ? defaultValues?.name || '' : ''} />
        }
        caution={{
          detail: '',
          title: params.id
            ? `Edit ${defaultValues?.name || ""}`
            : `Create Product ${resource === CREATE_CATEGORY_RESOURCE.productSubcategory ? "Subcategory" : "Category"}`,
        }}
        showFinishPop={completeProcess}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: `Product ${resource === CREATE_CATEGORY_RESOURCE.productSubcategory
            ? "Subcategory" : "Category"
            } is successfully created.`,
          steps: null,
          onHide: () => { },
          buttons: [
            {
              text: 'Back to Product Category list',
              action: goToCategoryList,
            },
          ],
        }}
        continueCreateConfig={{
          onConfirm: () => {
            history.push(history.location.state?.from);
          },
          onCancel: goToCategoryList,
          onClose: goToCategoryList,
        }}
        hasleavePagePrompt={params.id}
      />
    </>
  );
}

export default connect(mapPropsToState)(CreateCategoryOrSubcategory);
