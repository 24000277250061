import { getInFilterValue } from '../utils';
import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getHomeBanners = (
  afterCursor,
  nameContains = '',
  contentTypeIn = 'CAMPAIGN',
  isActive,
  sort
) => {
  let isBannerActive = '';
  if (isActive !== null && isActive !== undefined) {
    isBannerActive = `isActive: ${isActive}`;
  }
  let orderBy = "displayPriority"
  if(sort) {
    orderBy = sort
  }
  const query = `{
    homepageBanners(first: 20, after: "${afterCursor}", name: "${nameContains}", orderBy: "${orderBy}", contentTypeIn: ${getInFilterValue(contentTypeIn)}, ${isBannerActive}) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            pk
            coverPhoto
            displayPriority
            contentType
            contentReferenceId
            active
            campaign {
                pk
                name
            }
            translations {
                edges {
                node {
                    language
                    coverPhoto
                    description
                }
                }
            }
          }
        }
      }
      }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateHomeBanners = (values) => {
  const query = `mutation UpdateHomepageBanner($input: UpdateHomepageBannerInput!) {
        updateHomepageBanner(input: $input) {
          success
          node {
              id
              pk
          }
          errors {
            field
            messages
          }
        }
      }
      `;
  const variables = { input: values };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createHomeBanners = (values) => {
  const query = `mutation CreateHomepageBanner($input: CreateHomepageBannerInput!) {
        createHomepageBanner(input: $input) {
            success
            node {
                id
                pk
            }
            errors {
              field
              messages
            }
          }
        }
        `;
  const variables = { input: values };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneHomeBanner = (id) => {
  const query = `{
        homepageBanner(id: "${id}") {
          id
          pk
          coverPhoto
          description
          displayPriority
          contentType
          contentReferenceId
          active
          campaign {
              pk
              name
              startDate
              endDate
              displayStartDate
              displayEndDate
              translations {
                edges {
                  node {
                    pk
                    name
                    language
                  }
                }
              }
          }
          translations {
              edges {
              node {
                  pk
                  language
                  coverPhoto
                  description
              }
              }
          }
        }
      }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteHomeBanners = (ids) => {
  const query = `mutation DeleteHomepageBanners($input: DeleteHomepageBannersInput!) {
        deleteHomepageBanners(input: $input) {
          success
        }
      }
    `;
  const variables = { input: { ids: ids } };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
