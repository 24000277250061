export const PermissionNameMap = {
  "view_customer": "Can view customer section",
  "change_customer": "Can edit customer",
  "add_points": "Can add points to a customer",
  "remove_points": "Can remove points from a customer",
  "view_customeractivitylog": "Can view customer activity logs section",
  "view_segment": "Can view segment section",
  "change_segment": "Can edit segment",
  "view_customergroup": "Can view customer lists section",
  "change_customergroup": "Can edit customer lists",
  "view_campaign": "Can view campaign section",
  "change_campaign": "Can edit campaign",
  "step_draft_campaign_create": "Can save a campaign as a draft",
  "step_draft_campaign_edit": "Can edit a draft campaign",
  "step_draft_campaign_pending": "Can submit a campaign for approval",
  "step_pending_campaign_edit": "Can edit a pending-for-approval campaign",
  "step_pending_campaign_publish": "Can publish a pending-for-approval campaign",
  "step_pending_campaign_withdraw_to_draft": "Can withdraw a pending-for-approval campaign",
  "step_publish_create_temp_campaign": "Can edit a published campaign",
  "step_publish_campaign_withdraw_to_draft": "Can withdraw a published campaign and become draft",
  "step_publish_campaign_withdraw_to_pending": "Can withdraw a published campaign and become pending-for-approval",
  "view_stamp": "Can view stamp campaigns section",
  "change_stamp": "Can edit stamp campaigns",
  "view_stampcampaignreward": "Can view stamp campaign rewards section",
  "change_stampcampaignreward": "Can edit stamp campaign rewards",
  "view_stamptaskrelation": "Can view stamp task relations section",
  "change_stamptaskrelation": "Can edit stamp task relations",
  "view_campaigncategory": "Can view campaign categories section",
  "change_campaigncategory": "Can edit campaign categories",
  "view_message": "Can view messages section",
  "change_message": "Can edit messages",
  "test_message": "Can send message for testing",
  "step_draft_message_create": "Can create a message as a draft",
  "step_draft_message_edit": "Can edit a draft message",
  "step_draft_message_pending": "Can submit a message for approval",
  "step_pending_message_edit": "Can edit a pending-for-approval message",
  "step_pending_message_publish": "Can publish a pending-for-approval message",
  "step_pending_message_withdraw_to_draft": "Can withdraw a pending-for-approval message",
  "step_publish_create_temp_message": "Can edit a published message",
  "step_publish_message_withdraw_to_draft": "Can withdraw a published message (update to draft status)",
  "step_publish_message_withdraw_to_pending": "Can withdraw a published message (update to pending-for-approval status)",
  "view_earningrule": "Can view earning rules section",
  "change_earningrule": "Can edit earning rules",
  "view_coupontemplate": "Can view coupons sections (except single coupon list)",
  "change_coupontemplate": "Can edit coupon set, generate single coupon",
  "view_coupon": "Can view single coupons list",
  "change_coupon": "Can edit, generate single coupons",
  "view_pointssettings": "Can view points settings section",
  "change_pointssettings": "Can edit points settings",
  "view_giftcard": "Can view gift card section",
  "change_giftcard": "Can edit gift card",
  "view_language": "Can view languages section",
  "change_language": "Can edit languages",
  "view_brand": "Can view brands section",
  "change_brand": "Can edit brands",
  "view_store": "Can view stores section",
  "change_store": "Can edit stores",
  "view_storecategory": "Can view stores categories section",
  "change_storecategory": "Can edit stores categories",
  "view_webview": "Can view webview content section",
  "change_webview": "Can edit webview content",
  "change_level": "Can edit level",
  "view_levelssettings": "Can view level settings section",
  "change_levelssettings": "Can edit level settings",
  "view_leveldiscount": "Can view level discount section",
  "change_leveldiscount": "Can edit level discount",
  "view_transaction": "Can view transactions section",
  "change_transaction": "Can edit transactions",
  "view_stamprecord": "Can view stamp records section",
  "change_stamprecord": "Can edit stamp records",
  "view_giftcardrecord": "Can view gift card records section",
  "change_giftcardrecord": "Can edit gift card records",
  "view_pointtransaction": "Can view point records section",
  "view_coupontransaction": "Can view coupon records section",
  "view_administratorgroup": "Can view permission groups section",
  "change_administratorgroup": "Can edit permission groups",
  "view_administrator": "Can view users section",
  "change_administrator": "Can edit both CMS admin users and merchant app users",
  "receive_report_email": "Can receive reminder email",
  "view_homepagebanner": "Can view banners section",
  "change_homepagebanner": "Can edit banners",
  "view_exportjob": "Can view export jobs section",
  "change_exportjob": "Can export",
  "view_importjob": "Can view import jobs section",
  "change_importjob": "Can import",
  "add_productcategory": "Can add product category",
  "change_productcategory": "Can change product category",
  "delete_productcategory": "Can delete product category",
  "view_productcategory": "Can view product category",
  "view_dashboard": "Can view dashboard section and related sub-sections",
};

export const PermissionGroupMap = {
  "view_customer": "customers",
  "change_customer": "customers",
  "add_points": "customers",
  "remove_points": "customers",
  "view_customeractivitylog": "customers",
  "view_segment": "segment",
  "change_segment": "segment",
  "view_customergroup": "customer lists",
  "change_customergroup": "customer lists",
  "view_campaign": "campaign",
  "change_campaign": "campaign",
  "step_draft_campaign_create": "campaign approval",
  "step_draft_campaign_edit": "campaign approval",
  "step_draft_campaign_pending": "campaign approval",
  "step_pending_campaign_edit": "campaign approval",
  "step_pending_campaign_publish": "campaign approval",
  "step_pending_campaign_withdraw_to_draft": "campaign approval",
  "step_publish_create_temp_campaign": "campaign approval",
  "step_publish_campaign_withdraw_to_draft": "campaign approval",
  "step_publish_campaign_withdraw_to_pending": "campaign approval",
  "view_stamp": "campaign",
  "change_stamp": "campaign",
  "view_stampcampaignreward": "campaign",
  "change_stampcampaignreward": "campaign",
  "view_stamptaskrelation": "stamp campaigns",
  "change_stamptaskrelation": "stamp campaigns",
  "view_campaigncategory": "campaign categories",
  "change_campaigncategory": "campaign categories",
  "view_message": "message",
  "change_message": "message",
  "test_message": "message",
  "step_draft_message_create": "message approval",
  "step_draft_message_edit": "message approval",
  "step_draft_message_pending": "message approval",
  "step_pending_message_edit": "message approval",
  "step_pending_message_publish": "message approval",
  "step_pending_message_withdraw_to_draft": "message approval",
  "step_publish_create_temp_message": "message approval",
  "step_publish_message_withdraw_to_draft": "message approval",
  "step_publish_message_withdraw_to_pending": "message approval",
  "view_earningrule": "earning rule",
  "change_earningrule": "earning rule",
  "view_coupontemplate": "coupon",
  "change_coupontemplate": "coupon",
  "view_coupon": "coupon",
  "change_coupon": "coupon",
  "view_pointssettings": "points",
  "change_pointssettings": "points",
  "view_giftcard": "gift cards",
  "change_giftcard": "gift cards",
  "view_language": "languages",
  "change_language": "languages",
  "view_brand": "brands",
  "change_brand": "brands",
  "view_store": "stores",
  "change_store": "stores",
  "view_storecategory": "store categories",
  "change_storecategory": "store categories",
  "view_webview": "webview content",
  "change_webview": "webview content",
  "change_level": "levels",
  "view_levelssettings": "levels",
  "change_levelssettings": "levels",
  "view_leveldiscount": "levels",
  "change_leveldiscount": "levels",
  "view_transaction": "records",
  "change_transaction": "records",
  "view_stamprecord": "records",
  "change_stamprecord": "records",
  "view_giftcardrecord": "records",
  "change_giftcardrecord": "records",
  "view_pointtransaction": "records",
  "view_coupontransaction": "records",
  "view_administratorgroup": "users & permissions",
  "change_administratorgroup": "users & permissions",
  "view_administrator": "users & permissions",
  "change_administrator": "users & permissions",
  "receive_report_email": "users & permissions",
  "view_homepagebanner": "banners",
  "change_homepagebanner": "banners",
  "view_exportjob": "import & export",
  "change_exportjob": "import & export",
  "view_importjob": "import & export",
  "change_importjob": "import & export",
  "add_productcategory": "product category",
  "change_productcategory": "product category",
  "delete_productcategory": "product category",
  "view_productcategory": "product category",
  "view_dashboard": "dashboard",
};

export const PermissionDisplayOrder = [
  "view_customer",
  "change_customer",
  "add_points",
  "remove_points",
  "view_customeractivitylog",
  "view_segment",
  "change_segment",
  "view_customergroup",
  "change_customergroup",
  "view_campaign",
  "change_campaign",
  "step_draft_campaign_create",
  "step_draft_campaign_edit",
  "step_draft_campaign_pending",
  "step_pending_campaign_edit",
  "step_pending_campaign_publish",
  "step_pending_campaign_withdraw_to_draft",
  "step_publish_create_temp_campaign",
  "step_publish_campaign_withdraw_to_draft",
  "step_publish_campaign_withdraw_to_pending",
  "view_stamp",
  "change_stamp",
  "view_stampcampaignreward",
  "change_stampcampaignreward",
  "view_stamptaskrelation",
  "change_stamptaskrelation",
  "view_campaigncategory",
  "change_campaigncategory",
  "view_message",
  "change_message",
  "test_message",
  "step_draft_message_create",
  "step_draft_message_edit",
  "step_draft_message_pending",
  "step_pending_message_edit",
  "step_pending_message_publish",
  "step_pending_message_withdraw_to_draft",
  "step_publish_create_temp_message",
  "step_publish_message_withdraw_to_draft",
  "step_publish_message_withdraw_to_pending",
  "view_earningrule",
  "change_earningrule",
  "view_coupontemplate",
  "change_coupontemplate",
  "view_coupon",
  "change_coupon",
  "view_pointssettings",
  "change_pointssettings",
  "view_giftcard",
  "change_giftcard",
  "view_language",
  "change_language",
  "view_brand",
  "change_brand",
  "view_store",
  "change_store",
  "view_storecategory",
  "change_storecategory",
  "view_webview",
  "change_webview",
  "change_level",
  "view_levelssettings",
  "change_levelssettings",
  "view_leveldiscount",
  "change_leveldiscount",
  "view_transaction",
  "change_transaction",
  "view_stamprecord",
  "change_stamprecord",
  "view_giftcardrecord",
  "change_giftcardrecord",
  "view_pointtransaction",
  "view_coupontransaction",
  "view_administratorgroup",
  "change_administratorgroup",
  "view_administrator",
  "change_administrator",
  "receive_report_email",
  "view_homepagebanner",
  "change_homepagebanner",
  "view_exportjob",
  "change_exportjob",
  "view_importjob",
  "change_importjob",
  "add_productcategory",
  "change_productcategory",
  "delete_productcategory",
  "view_productcategory",
  "view_dashboard",
];
