import React from 'react';
import BasePrompt from '../base/prompt/BasePrompt';

function RedirectToStorePrompt({
  show,
  onHide = (() => { }),
  onRefresh = (() => { }),
}) {
  return (
    <BasePrompt
      show={show}
      closeAction={onHide}
      rightButton={{
        text: 'Go to Stores',
        action: () => {
          window.open('/stores/create', '_blank');
        },
      }}
      leftButton={{
        text: 'Done and refresh',
        action: onRefresh,
      }}
      title={'Go to create a Store?'}
      description={
        'You will leave this page. After you update the store, you can come back to continue.'
      }
    />
  );
}

export default RedirectToStorePrompt;
