import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';


import AuthButton from 'components/base/AuthButton';
import CustomBreadcrumb from 'components/base/CustomBreadcrumb';
import CustomListComponent from 'components/base/CustomListComponent';
import ListButtonsGroup from 'components/base/ListButtonsGroup';
import { NavBarNames } from 'config/NavBarNameList';
import { PermissionCodes } from 'config/PermissionCodes';
import BaseListContainer from 'containers/base/BaseListContainer';
import BaseTabListContainer from 'containers/base/BaseTabListContainer';
import { ImportResource } from 'models/DownloadImportModel';
import { PRODUCT_CATEGORY_TEMPLATE_FILE_PATH } from 'services/ProductCategoryApiHelper';

import { createAction, getHashKeyString } from 'utils';

import AuthButtonWithDropdown from 'components/base/AuthButtonWithDropdown';
import DeletePrompt from 'components/base/DeletePrompt';

export const PRODUCT_LIST_TAB = {
  productCategories: "List of Product Categories",
  productSubcategories: "List of Product Subcategories"
}

const mapPropsToState = (state) => ({
  categoryListDisplayFields: state.productCategoryList.categoryListDisplayFields,
  subcategoryListDisplayFields: state.productCategoryList.subcategoryListDisplayFields,
  productCategoryList: state.productCategoryList.productCategoryList,
  productSubcategoryList: state.productCategoryList.productSubcategoryList,
  totalCount: state.productCategoryList.totalCount,
  totalPage: state.productCategoryList.totalPage,
  pageInfo: state.productCategoryList.pageInfo,
  importedFile: state.uploadFiles.importedFile,
});
const ProductCategoryList = ({
  categoryListDisplayFields,
  subcategoryListDisplayFields,
  productCategoryList,
  productSubcategoryList,
  totalCount,
  totalPage,
  pageInfo,
  importedFile,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const hiddenFileInput = useRef(null);
  const [uploadOriginFileName, setUploadOriginFileName] = useState();
  const [showDeletePrompt, setShowDeletePrompt] = useState({
    show: false
  });

  const activeTab = getHashKeyString(location.hash) || PRODUCT_LIST_TAB.productCategories;

  useEffect(() => {
    const parsedSearch = queryString.parse(location.search);
    const stringRank = parsedSearch['rank'] || 'true';
    const stringPage = parsedSearch['page'] || 1;

    const search = parsedSearch['search'] || '';
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);
    const sort = parsedSearch["sort"] || ""

    if (activeTab === PRODUCT_LIST_TAB.productCategories) {
      dispatch(createAction("productCategoryList/getPagedProductCategories")({
        search,
        rank,
        page,
        sort,
      }))
    }
    if (activeTab === PRODUCT_LIST_TAB.productSubcategories) {
      dispatch(createAction("productCategoryList/getPagedProductSubcategories")(
        {
          search,
          rank,
          page,
          sort,
        }
      ))
    }

    return () => {
      dispatch(createAction("productCategoryList/clearData")())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location, activeTab])

  useEffect(() => {
    if (Object.keys(importedFile).length) {
      dispatch({
        type: 'downloadAndImport/importFile',
        payload: {
          from: ImportResource.productCategorySubcategory,
          file: importedFile,
          related: {
            fileName: uploadOriginFileName,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importedFile]);

  const handleImportClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setUploadOriginFileName(fileUploaded?.name);
    dispatch(
      createAction('uploadFiles/uploadFile')({
        file: fileUploaded,
        source: event,
      }),
    );
    event.target.value = null;
  };

  const handleExportTemplate = () => {
    var eleLink = document.createElement('a');
    eleLink.href = PRODUCT_CATEGORY_TEMPLATE_FILE_PATH;
    eleLink.style.display = 'none';
    document.body.appendChild(eleLink);
    eleLink.click();
    document.body.removeChild(eleLink);
  }

  const topRightButtons = [
    <ListButtonsGroup
      hideExtraButtonWidth={500}
      extraButtons={[
        <AuthButton
          title="Download Import Form"
          customClass="btn-back-button-common btn-download"
          action={handleExportTemplate}
          requires={PermissionCodes.addExportjob}
        />,
        <AuthButton
          customClass="btn-back-button-common btn-download"
          title="Import"
          action={handleImportClick}
          requires={PermissionCodes.addImportjob}
        />,
        <AuthButtonWithDropdown
          className='btn-further'
          permissionRequires={PermissionCodes.addProductCategory}
          title={'Create'}
          options={[
            {
              title: "Product Category",
              action: () => {
                history.push({
                  pathname: '/product_categories/create',
                });
              }
            },
            {
              title: "Product Subcategory",
              action: () => {
                history.push({
                  pathname: '/product_categories/product_subcategories/create',
                });
              }
            },
          ]}
        />,
      ]}
      extraPopContent={[
        {
          requires: PermissionCodes.addExportjob,
          content: 'Download Import Form',
        },
        {
          requires: PermissionCodes.addImportjob,
          content: 'Import',
        },
        {
          requires: PermissionCodes.addProductCategory,
          action: () => {
            history.push({
              pathname: '/product_categories/create',
            });
          },
          content: 'Create Product Category',
        },
        {
          requires: PermissionCodes.addProductCategory,
          action: () => {
            history.push({
              pathname: '/product_subcategories/create',
            });
          },
          content: 'Create Product Subcategory',
        },
      ]}
    />,
    <input
      type="file"
      ref={hiddenFileInput}
      onChange={handleChange}
      style={{ display: 'none' }}
      accept=".csv"
    />,
  ]
  const tabGroupActions = {
    [PRODUCT_LIST_TAB.productCategories]: [{
      name: 'Delete',
      action: () => {
        setShowDeletePrompt({
          show: true,
          title: "product categories",
          action: "productCategoryList/deleteProductCategories"
        });
      },
      requires: PermissionCodes.deleteProductCategory,
    }],
    [PRODUCT_LIST_TAB.productSubcategories]: [{
      name: 'Delete',
      action: () => {
        setShowDeletePrompt({
          show: true,
          title: "product subcategories",
          action: "productCategoryList/deleteProductSubcategories"
        });
      },
      requires: PermissionCodes.deleteProductCategory,
    }],
  }
  const tabs = [
    {
      name: PRODUCT_LIST_TAB.productCategories,
      content: (
        <BaseTabListContainer
          hideTab={true}
          tabs={[]}
          groupActions={tabGroupActions?.[PRODUCT_LIST_TAB.productCategories]}
          pageInfo={pageInfo}
          totalCount={totalCount}
          searchPlaceholder={'Search by name'}
          filter={{
            hasFilter: false,
          }}
          listContent={
            <BaseListContainer
              fields={categoryListDisplayFields}
              dataList={productCategoryList}
              totalPage={totalPage}
              model={'productCategoryList'}
              permissionGroup={PermissionCodes.productCategory}
              actions={['Edit', 'Detail', 'Delete', 'Duplicate']}
              actionDispatchType={{
                "delete": "productCategoryList/deleteProductCategories",
                "duplicate": "productCategoryList/duplicateProductCategory",
              }}
              deleteInfo={{
                data: [],
                title: 'product categories',
                relatedName: '',
                relatedSections: "",
                onComfirm: {},
              }}
              hasOtherActions={true}
              customActions={{
                moveToFirst: (item) => {
                  dispatch(createAction("createProductCategory/updateProductCategoryDisplayOrder")({
                    id: item.pk,
                    displayOrder: 1,
                    afterAction: () => history.push(location),
                  }))
                },
                moveToLast: (item) => {
                  dispatch(createAction("createProductCategory/updateProductCategoryDisplayOrder")({
                    id: item.pk,
                    displayOrder: totalCount,
                    afterAction: () => history.push(location),
                  }))
                },
              }}
            />
          }
        />
      ),
    },
    {
      name: PRODUCT_LIST_TAB.productSubcategories,
      content: (
        <BaseTabListContainer
          hideTab={true}
          tabs={[]}
          groupActions={tabGroupActions?.[PRODUCT_LIST_TAB.productSubcategories]}
          pageInfo={pageInfo}
          totalCount={totalCount}
          searchPlaceholder={'Search by name'}
          filter={{
            hasFilter: false,
          }}
          listContent={
            <BaseListContainer
              customPathname={"product_categories/product_subcategories"}
              fields={subcategoryListDisplayFields}
              dataList={productSubcategoryList}
              totalPage={totalPage}
              model={'productCategoryList'}
              permissionGroup={PermissionCodes.productCategory}
              actions={['Edit', 'Detail', 'Delete', 'Duplicate']}
              actionDispatchType={{
                "delete": "productCategoryList/deleteProductSubcategories",
                "duplicate": "productCategoryList/duplicateProductSubcategory",
              }}
              deleteInfo={{
                data: [],
                title: 'product subcategory ',
                relatedName: '',
                relatedSections: "",
                onComfirm: {},
              }}
              hasOtherActions={true}
              customActions={{
                moveToFirst: (item) => {
                  dispatch(createAction("createProductCategory/updateProductSubcategoryDisplayOrder")({
                    id: item.pk,
                    displayOrder: 1,
                    afterAction: () => history.push(location),
                  }))
                },
                moveToLast: (item) => {
                  dispatch(createAction("createProductCategory/updateProductSubcategoryDisplayOrder")({
                    id: item.pk,
                    displayOrder: totalCount,
                    afterAction: () => history.push(location),
                  }))
                },
              }}
            />
          }
        />
      ),
    },
  ];

  return <>
    <CustomListComponent
      caution={{
        title: NavBarNames.productCategory,
      }}
      defaultActiveKey={activeTab}
      breadcrumb={<CustomBreadcrumb />}
      buttons={topRightButtons}
      tabs={tabs}
      hideTab={false}
      onTabChange={(key) => {
        history.push({ pathname: location.pathname, hash: key });
      }}
    />
    <DeletePrompt
      data={[]}
      title={showDeletePrompt?.title || ''}
      relatedName={''}
      show={showDeletePrompt?.show || false}
      onClose={() => setShowDeletePrompt({show: false})}
      onConfirm={() => {
        setShowDeletePrompt({ show: false });
        dispatch({
          type: showDeletePrompt?.action || '',
          payload: {
            afterAction: () => {
              history.push(location);
            },
          },
        });
      }}
    />
  </>
}
export default connect(mapPropsToState)(ProductCategoryList);
