import React, { Component } from 'react';
import './SingleCouponOverview.scss';
import moment from 'moment';
import { connect } from 'react-redux';
import BaseOverView from './BaseOverview';
import CustomerFilterableDropdown from '../../transactions/CustomerFilterableDropdown';
import { COUPON_TEMPLATE_TYPE, COUPON_FORMATS } from '../../../config/CustomEnums';
@connect(({ customerList, singleCoupon }) => ({
  customerList: customerList.customerList,
  singleCoupon: singleCoupon.singleCoupon,
}))
class SingleCouponOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultOwner: this.props.defaultOwner.owner,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.singleCoupon?.owner?.pk && this.props.singleCoupon?.owner?.pk) {
      this.props.selectedOwner({
        value: this.props.singleCoupon?.owner,
        label: `[${this.props.singleCoupon?.owner?.membershipId}] ${this.props.singleCoupon?.owner?.owner}`
      })
    }
  }

  parseDateTime = (datetimeStr) => {
    return moment(datetimeStr).format('D MMM YYYY (ddd), LT');
  };
  parseCouponExpiryDate = () => {
    const effectiveDate = this.props.singleCoupon?.effectiveDate;
    const expiryDate = this.props.singleCoupon?.expiryDate;
    if (effectiveDate && expiryDate) {
      return `${this.parseDateTime(effectiveDate)} - ${this.parseDateTime(
        expiryDate,
      )}`;
    }
    if (this.props.couponSet.validPeriodType === 'ALL_TIME') {
      return 'All time valid';
    }
    if (this.props.couponSet.validPeriodType === 'ABSOLUTE') {
      return `${effectiveDate ? this.parseDateTime(effectiveDate) : ''} - ${expiryDate ? this.parseDateTime(expiryDate) : ''
        }`;
    }
    if (this.props.couponSet.validPeriodType === 'RELATIVE') {
      return `${this.props.couponSet.numberOfDaysToExpireAfterAcquisition} days since acquiring`;
    }
    return '-';
  };
  parseCouponCodeSetting = (couponCodeSetting) => {
    if (couponCodeSetting === COUPON_FORMATS.COUPON_QR_CODE) {
      return 'QR code'
    }
    if (couponCodeSetting === COUPON_FORMATS.COUPON_BAR_CODE) {
      return 'Barcode'
    }
    if (couponCodeSetting === COUPON_FORMATS.TEXT) {
      return 'Text'
    }
    return '-'
  }
  render() {
    const firstSectionTitle = 'GENERAL';
    const generalTitleList = [
      'ID',
      this.props.singleCoupon.template?.offlineFormats ===
        COUPON_TEMPLATE_TYPE.couponCodeImage
        ? 'Image'
        : 'Serial Number',
      'Reference ID',
      'Brand',
      'Store',
      'Coupon code setting',
      'Barcode format',
      'Import code',
      'Coupon Owner',
      'Transaction type',
      'Coupon Set',
      // 'Date of Purchase',
      // 'Date of Earning',
      // 'Date of Grant',
      'Expiry Date',
      'Create at',
      'Last Modified',
      'Status',
    ];
    const generalValueList = [
      this.props.singleCoupon?.pk,
      this.props.singleCoupon.template?.offlineFormats ===
        COUPON_TEMPLATE_TYPE.couponCodeImage
        ? this.props.singleCoupon?.displayImage
        : this.props.singleCoupon?.serialNumber,
      this.props.singleCoupon?.referenceId || '-',
      this.props.singleCoupon?.template?.brand?.name || '-',
      this.props.singleCoupon?.store?.name || '-',
      this.parseCouponCodeSetting(this.props.singleCoupon?.template?.offlineFormats),
      this.props.singleCoupon?.template?.offlineBarcodeFormat?.name || '-',
      this.props.singleCoupon?.code || '-',
      <CustomerFilterableDropdown
        defaultSelectedCustomer={this.props.defaultOwner}
        selectCustomer={this.props.selectedOwner}
      />,
      this.props.singleCoupon?.transactionType || '-',
      this.props.couponSet?.name
        ? `[ID: ${this.props.couponSet?.pk}] ${this.props.couponSet?.name}`
        : '-',
      // this.props.singleCoupon?.dateOfPurchase
      //   ? this.parseDateTime(this.props.singleCoupon?.dateOfPurchase)
      //   : '-',
      // this.props.singleCoupon?.dateOfEarning
      //   ? this.parseDateTime(this.props.singleCoupon?.dateOfEarning)
      //   : '-',
      // this.props.singleCoupon?.dateOfGrant
      //   ? this.parseDateTime(this.props.singleCoupon?.dateOfGrant)
      //   : '-',
      this.parseCouponExpiryDate(),
      this.parseDateTime(this.props.singleCoupon?.creationDate),
      this.parseDateTime(this.props.singleCoupon?.lastModifiedDate),
      this.props.singleCoupon?.status,
    ];

    // const secondSectionTitle = 'COUPON USAGE RECORD';
    // const usageTitleList = ['Create Date', 'Staff Code'];
    // const usageValueList = [
    //   this.parseDateTime(this.props.singleCoupon?.creationDate),
    //   this.props.singleCoupon?.store?.staffCode || '-',
    // ];
    return (
      <div>
        <BaseOverView
          className={''}
          title={firstSectionTitle}
          itemTitleList={generalTitleList}
          itemValueList={generalValueList}
        />

        {/* <BaseOverView
          className={''}
          title={secondSectionTitle}
          itemTitleList={usageTitleList}
          itemValueList={usageValueList}
        /> */}
      </div>
    );
  }
}

export default SingleCouponOverview;
