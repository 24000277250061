import React, { useEffect, useState } from 'react';
import { Toast } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';

import { defaultDelayTime, ToastType } from 'models/NavBarModel';

import './SaveDiscardToast.scss';

function SaveDiscardToast({
  saveDiscardToastShowing,
}) {
  const [show, setShow] = useState(saveDiscardToastShowing.value);
  const dispatch = useDispatch();
  useEffect(() => {
    setShow(saveDiscardToastShowing.value);
  }, [saveDiscardToastShowing.value]);

  const onToastHide = () => {
    setShow(false);
    dispatch({
      type: 'navBars/initState',
      payload: {},
    });
  };

  return (
    <div className="save-success-toast-container">
      <Toast
        className="save-toast-main-content"
        onClose={onToastHide}
        show={show}
        delay={saveDiscardToastShowing.delayTime || defaultDelayTime}
        autohide
      >
        <Toast.Body className="save-toast-body">
          <label className="save-toast-text">
            {saveDiscardToastShowing.type}
          </label>
          <label onClick={onToastHide} className="save-toast-button">
            Close
          </label>
        </Toast.Body>
      </Toast>
    </div>
  );
}

const mapPropsToState = (state) => ({
  saveDiscardToastShowing: state.navBars.saveDiscardToastShowing,
});

export default connect(mapPropsToState)(SaveDiscardToast);
