export const ToastType = {
  save: 'Saved successfully.',
  discard: 'Changes discarded.',
  couponGenerate: 'Single Coupon generated',
  none: '',
  passwordConfirmation: `The two password fields didn't match.`,
  serverError: 'Server error, please refresh or try again later',
  networkError:
    'The network broke down, please refreshing the page or try again later.',
  copied: 'Copied',
  copySuccess: 'Copy successfully.',
  messageTempSaveSuccess: 'The content has been successfully saved.',
  systemTaskAdded: 'New system task added',
  uploadFailed: 'Upload failed',
};

export const defaultDelayTime = 1200;

const initialState = {
  saveDiscardToastShowing: {
    value: false,
    type: ToastType.none,
    delayTime: defaultDelayTime,
  },
};

export default {
  namespace: 'navBars',
  state: initialState,

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    initState(state, { payload }) {
      return { ...state, ...initialState };
    },
  },
};
