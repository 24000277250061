import Cookies from 'cookies-js';

import {
  baseRefreshToken,
  baseAPIinstance,
  addAuthorization,
  ACCESS_TOKEN,
} from 'services/BaseRefreshHelper';

import { isOpenInOtherSite, getObjectFromSessionStorage } from 'utils';

const GET_BRANDS_PATH = '/graphql';

class APIHelper {
  constructor() {
    const token = isOpenInOtherSite() ? getObjectFromSessionStorage(ACCESS_TOKEN) : Cookies.get(ACCESS_TOKEN);
    addAuthorization(token);
    baseRefreshToken();
  }

  callGraphQLAPI = (payload) => {
    const { query, variables } = payload;
    const graphQL = {
      query: query,
      variables: variables,
    };
    return baseAPIinstance
      .post(GET_BRANDS_PATH, JSON.stringify(graphQL))
      .catch((error) => error.response);
  };

  callGraphQLAPIMultiple = (payloads) => {
    console.log('callGraphQLAPIMultiple:', payloads);
    const queryList = payloads.map((payload) => {
      const graphQL = {
        query: payload.query,
        variables: payload.variables,
      };
      return baseAPIinstance.post(GET_BRANDS_PATH, JSON.stringify(graphQL));
    });
    console.log('queryList:', queryList);
    return baseAPIinstance.all(queryList).catch((errors) => errors);
  };

  callUpLoadFile = (path, body) => {
    console.log('uploadImageCall', path, body);
    const apiResponse = baseAPIinstance
      .post(path, body, {
        headers: { 'Content-Type': '*/*', Accept: '*/*' },
      })
      .catch((error) => ({ ...(error.response || {}) }));
    console.log('uploadImageCall', apiResponse);
    return apiResponse;
  };
}

export const BaseHelper = new APIHelper();
