import React from 'react';
import CampaignSelectDropdown from '../../components/message/CampaignSelectDropdown';
import { listItem } from '../../components/banners/DisplayItems';
import { ReactHookFormImageUploader } from '../base/ImageUploader';
import { LanguageConfig } from '../../config/CustomEnums';
import { getReadablePeriod } from '../../utils/TimeFormatUtil';
import { useFormContext } from 'react-hook-form';
import CustomTitleWithInput from '../base/CustomTitleWithInput';
import { hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import {
  BANNER_CONTENT_TYPE,
} from '../../config/CustomEnums';
import config from 'config';
import i18n from '../../I18n'

export const ContentType = {
  Campaign: 'Campaign',
  Product: 'Product',
};


const CreateBannerContent = ({ language }) => {
  const { watch, setValue, formState } = useFormContext();
  const { errors } = formState;
  const watchBannerType = watch(`contentType`);
  const watchCoverPhotos = watch(`translations.${language}.coverPhotos.0`);
  const watchCampaign = watch(`campaign`);
  const watchOrder = watch(`order`);

  console.log(
    '@@49: ',
    language,
    watchCoverPhotos,
  );

  return (
    <>
      {language === LanguageConfig.english ? (
        <>
          {watchBannerType === BANNER_CONTENT_TYPE.CAMPAIGN ? (
            <>
              <CampaignSelectDropdown
                title='Related campaign'
                defaultValue={{
                  value: watchCampaign,
                  label: watchCampaign?.pk
                    ? `[ID: ${watchCampaign?.pk}] ${watchCampaign?.name}`
                    : watchCampaign?.name,
                }}
                setValue={(value) => {
                  setValue('campaign', value, { shouldDirty: true });
                }}
              />
              <ReactHookFormErrorMessage errors={errors} id='campaign' />
              {listItem('Campaign name', watchCampaign?.name)}
              {listItem('Campaign ID', watchCampaign?.pk)}
              {listItem(
                'Campaign’s active period',
                getReadablePeriod(
                  watchCampaign?.startDate,
                  watchCampaign?.endDate,
                ),
              )}
              {listItem(
                'Campaign’s visible period',
                getReadablePeriod(
                  watchCampaign?.displayStartDate,
                  watchCampaign?.displayEndDate,
                ),
              )}
            </>
          ) : (
            <>
            </>
          )}

          <ReactHookFormErrorMessage errors={errors} id={`publishLocation`} />
        </>
      ) : null}
      <>
        <ReactHookFormImageUploader
          title={'Banner image'}
          uploadSuggest={i18n.t('square_cover_photo_suggest_with_variable', { locale: language, ...config.bannerSize, minWidth: 900 })}
          name={`translations.${language}.coverPhotos.0`}
          minWidth={900}
          minHeight={config.bannerSize.minHeight}
          value={watchCoverPhotos}
          aspect={900 / config.bannerSize.minHeight}
          maxImageNum={1}
          language={language}
        />
      </>

      {language === LanguageConfig.english ? (
        <>
          <CustomTitleWithInput
            title={'Display order'}
            error={{ error: hasError(errors, 'order') }}
            defaultValue={watchOrder}
            setValue={(value) => {
              setValue('order', value, { shouldDirty: true, });
            }}
            type={'number'}
            extra={{
              min: 1,
            }}
          />
          <ReactHookFormErrorMessage errors={errors} id='order' />
          <label className="create-message-suggest">
            {`Minimum value is 1. The smaller value, the higher priority.`}
          </label>
        </>
      ) : null}
    </>
  );
};

export default CreateBannerContent;
