import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Controller, useFormContext } from 'react-hook-form';
import { Image } from 'react-bootstrap';
import { CustomTitleLabel } from 'components/earning/CustomBaseComponments';
import {
  PERIOD_TYPE_DISPLAY,
  PURCHASE_IN_STORE_TRANSACTION_STORE_TYPE,
  PURCHASE_IN_STORE_TRANSACTION_STORE_TYPE_DISPLAY,
  TRANSACTION_TYPE_DISPLAY
} from 'config/CustomEnums';
import InputWithMutipleDropDown from './InputWithMutipleDropDown';
import LookupWithMutipleNumber from './LookupWithMutipleNumber';
import CustomRadios from 'components/base/CustomRadios';
import BaseMultipleSelectorV2 from 'components/base/BaseMultipleSelectorV2';
import BasePrompt from 'components/base/prompt/BasePrompt';
import { PermissionCodes } from 'config/PermissionCodes';
import { DropDownWithDefaultSelect } from 'components/base/ActionsDropdown';
import { hasReactHookFormError } from 'components/base/ErrorFieldMessage';
import BranchLine from 'assets/images/branchLinex3.png';

const PurchaseInStoreSection = ({
  storeList,
  title,
  groupName,
  fieldBaseName
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const isEdit = !!params.id;

  const {
    watch,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();
  const storeTypeFieldName = `${fieldBaseName}.storeType`;
  const storeType = watch(storeTypeFieldName) || PURCHASE_IN_STORE_TRANSACTION_STORE_TYPE['All online stores'];

  const storesFieldName = `${fieldBaseName}.stores`;
  const stores = watch(storesFieldName);

  const typeFieldName = `${fieldBaseName}.type`;
  const type = watch(typeFieldName);

  const [selectedStores, setSelectedStores] = useState([]);
  const [storePage, setStorePage] = useState(1);
  const [showCreateStore, setShowCreateStore] = useState(false);
  const [refreshDate, setRefreshDate] = useState(new Date());

  const dropDownOptions = (values, setAction) => {
    return values.map((value) => ({
      name: value,
      action: () => {
        setAction(value);
      },
    }));
  };

  useEffect(() => {
    dispatch({
      type: 'storeModel/getPagedStoreList',
      payload: { page: storePage, isSelectorLoad: true }
    });
    setStorePage(prevStorePage => prevStorePage + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, refreshDate]);

  useEffect(() => {
    if (selectedStores?.length < 1 && stores?.length > 0) {
      setSelectedStores(stores);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stores]);

  return (
    <div className="d-flex flex-column">
      <InputWithMutipleDropDown
        fieldBaseName={fieldBaseName}
        title={title}
        rangeOptions={[
          PERIOD_TYPE_DISPLAY.ALL_THE_TIME,
          PERIOD_TYPE_DISPLAY.IN_X_NUMBER_OF_DAYS,
          PERIOD_TYPE_DISPLAY.WITHIN,
          PERIOD_TYPE_DISPLAY.EQUAL_TO,
          PERIOD_TYPE_DISPLAY.BETWEEN,
          PERIOD_TYPE_DISPLAY.GREATER_THAN,
          PERIOD_TYPE_DISPLAY.LESS_THAN,
        ]}
        hasPeriodDropdown
      />

      <CustomTitleLabel title="Select stores" />
      <div className="display-flex-area">
        <Controller
          name={storeTypeFieldName}
          control={control}
          render={({ field }) => (
            <CustomRadios
              labelClass="font-weight"
              onChange={(value) => {
                setValue(field.name, value, { shouldDirty: true });
              }}
              default={storeType}
              options={[
                {
                  label: PURCHASE_IN_STORE_TRANSACTION_STORE_TYPE_DISPLAY.ALL_ONLINE_STORE,
                  value: PURCHASE_IN_STORE_TRANSACTION_STORE_TYPE['All online stores'],
                },
                {
                  label: PURCHASE_IN_STORE_TRANSACTION_STORE_TYPE_DISPLAY.ALL_OFFLINE_STORE,
                  value: PURCHASE_IN_STORE_TRANSACTION_STORE_TYPE['All offline stores'],
                },
                {
                  label: PURCHASE_IN_STORE_TRANSACTION_STORE_TYPE_DISPLAY.SPECIFIC_STORE,
                  value: PURCHASE_IN_STORE_TRANSACTION_STORE_TYPE['Specific store(s)'],
                },
              ]}
            />
          )}
        />
      </div>

      {
        storeType === PURCHASE_IN_STORE_TRANSACTION_STORE_TYPE['Specific store(s)']
          ? (
            <BaseMultipleSelectorV2
              title={'Specific store(s)'}
              namespace='storeModel'
              data={{
                sourceData: storeList?.concat(selectedStores),
                targetData: stores,
                targetChange: (value) => {
                  setValue(storesFieldName, value, { shouldDirty: true })
                },
              }}
              custom={{
                customItem: item => item.displayStoreName
              }}
              addButton={{
                title: 'Add Store',
                action: () => {
                  setShowCreateStore(true);
                },
                requires: PermissionCodes.addStore,
              }}
              loadMoreAction={() => {
                dispatch({
                  type: 'storeModel/getPagedStoreList',
                  payload: {
                    isSelectorLoad: true,
                    page: storePage
                  }
                })
                setStorePage(prevStorePage => prevStorePage + 1);
              }
              }
              requires={PermissionCodes.addStore}
            />
          )
          : null
      }

      <CustomTitleLabel title="Amount of transaction (optional)" />
      <div className="d-flex">
        <Controller
          name={typeFieldName}
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <DropDownWithDefaultSelect
              actions={dropDownOptions(
                [
                  TRANSACTION_TYPE_DISPLAY.NUMBER,
                  TRANSACTION_TYPE_DISPLAY.PERCENTAGE,
                ],
                (value) => {
                  setValue(field.name, value, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                },
              )}
              defaultValue={type}
              className={
                hasReactHookFormError(errors, field.name) ? 'error-field' : ''
              }
            />
          )}
        />

        <label className="content-campaign-related-to margin-right-10-percent">
          of transaction
        </label>
      </div>

      <div className="d-flex">
        <Image src={BranchLine} className="content-campaign-branchLine" />
        <div className="segment-with-line-margin">
          <LookupWithMutipleNumber
            fieldBaseName={fieldBaseName}
            groupName={groupName}
            title={null}
            extraSuffix={type === TRANSACTION_TYPE_DISPLAY.PERCENTAGE ? '%' : null}
            notRequired
          />
        </div>
      </div>

      <BasePrompt
        show={showCreateStore}
        closeAction={() => setShowCreateStore(false)}
        leftButton={{
          text: 'Done and refresh',
          action: () => {
            setRefreshDate(new Date());
            setStorePage(1);
            setShowCreateStore(false);
          },
        }}
        rightButton={{
          text: 'create store',
          action: () => {
            window.open('/stores/create', '_blank');
          }
        }}
        title={'Go to create store?'}
        description={'Go to create store'}
      />

    </div>
  );
}

const mapPropsToState = (state) => ({
  storeList: state.storeModel.storeList,
});

export default connect(mapPropsToState)(PurchaseInStoreSection);
