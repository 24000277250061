import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import DeletePrompt from '../../../components/base/DeletePrompt';
import BaseForm from '../../../components/base/v2/BaseForm';
import CreateCustomerGroupStepOne from '../../../components/customerGroup/CreateCustomerGroupStepOne';
import { DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';

export const customerGroupSessionKey = "createCustomerGroup";

function CreateCustomerGroup({
  customerGroup,
  hasUpdatedDefaultValues,
  formSubmitSucceed,
}) {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [formHasSubmitted, setFormHasSubmitted] = useState(false);

  useEffect(() => {
    if (params.id) {
      dispatch({
        type: 'customerGroup/getCustomerGroup',
        payload: {
          id: params.id,
        }
      });
    } else {
      dispatch({
        type: 'customerGroup/loadCustomerGroupFromSession',
        payload: {}
      });
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    dispatch({
      type: 'customerGroup/clearData',
      payload: {}
    });
    return () => {
      dispatch({
        type: 'customerGroup/clearData',
        payload: {}
      });
    };
  }, [dispatch]);

  const stepContent = [
    <CreateCustomerGroupStepOne
      setShowDeletePrompt={setShowDeletePrompt}
      setFormHasSubmitted={()=>setFormHasSubmitted(true)}
    />,
  ];
  return (
    <>
      <BaseForm
        defaultValues={customerGroup}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        tempSave={(save, getValues) => {
          dispatch({
            type: 'customerGroup/saveOrRemoveCustomerGroupFromCookie',
            payload: {
              save,
              values: getValues()
            },
          });
        }}
        showFinishPop={formSubmitSucceed}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Customer list is successfully created.',
          steps: null,
          onHide:()=>{},
          buttons: [
            {
              text: 'Back to customer lists',
              action: () => {
                history.push({
                  pathname: '/customer_group',
                });
              },
            },
          ],
        }}
        continueCreateConfig={{
          onConfirm: () => {
            history.push(history.location.state?.from);
          },
          onCancel: () => history.push('/customer_group'),
          onClose: () => history.push('/customer_group'),
        }}
        content={stepContent}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb name={params.id ? customerGroup.name : ''} />
        }
        caution={{
          detail: '', //Create various customer groups and target them with different experiences.
          title: `${params.id ? customerGroup.name : 'Create Customer List'}`,
        }}
      />
      <DeletePrompt
        title={'customer list'}
        show={showDeletePrompt}
        relatedSections={DELETE_RELATED_SECTIONS.CUSTOMER_GROUP}
        data={customerGroup}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: 'customerGroup/updateState',
            payload: {
              checkedList: [customerGroup],
            }
          });
          dispatch({
            type: 'customerGroup/delete',
            payload: {
              afterAction: () => {
                history.push('/customer_group');
              },
            }
          });
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  customerGroup: state.customerGroup.selectedCustomerGroup,
  hasUpdatedDefaultValues: state.customerGroup.hasUpdatedDefaultValues,
  formSubmitSucceed: state.customerGroup.formHasSubmitted,
});

export default connect(mapPropsToState)(CreateCustomerGroup);
