import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createAction } from 'utils';
import { CustomTitleWithDropDown } from 'components/customer/CustomTitleWithDropDown';

function CouponSelectDropdown(props) {
  const dispatch = useDispatch();
  const title = props.title;
  const defaultValue = props.defaultValue || null;
  const setValue = props.setValue || (() => {});
  const addButtonInfo = props.addButtonInfo || {};
  const onFocus = props.onFocus || (()=>{})
  const description = props.description;
  const refreshDate = props.refreshDate || null;

  const [lastRefreshDate, setLastRefreshDate] = useState(refreshDate);

  const { couponTemplateList } = useSelector((state) => ({
    couponTemplateList: state.couponList?.couponTemplateList,
  }));

  useEffect(() => {
    dispatch(
      createAction('couponList/getCurrentPageTemplateList')({
        isSelectorLoad: true,
      }),
    );
  }, [dispatch]);

  const onSelectChange = (value) => {
    setValue(value?.value);
  };
  return (
    <CustomTitleWithDropDown
      title={title}
      description={description}
      titleStyle={props.isPrompt ? '' : 'create-section-label-no-top-space'}
      defaultValue={defaultValue}
      setValue={onSelectChange}
      source={couponTemplateList}
      labelContainPk
      loadMoreAction={'couponList/getCurrentPageTemplateList'}
      filterAction={'couponList/getCurrentPageTemplateList'}
      errors={props.errors}
      errorName={props.errorName}
      addButtonInfo={addButtonInfo}
      onFocus={onFocus}
      isReset={lastRefreshDate !== refreshDate}
      resetChange={() => {
        setLastRefreshDate(refreshDate);
      }}
    />
  );
}

export default CouponSelectDropdown;
