import {
  APIStatus,
  ActionType,
  LanguageConfig,
  MessageBackendChannel,
  MessageChannel,
  MessageDefaultSteps,
  MessageStepConfig,
  SESSION_KEYS,
} from 'config/CustomEnums';
import { loading } from 'models/LoadingUtil';
import { apiWithResponseHandle } from 'models/LoadingUtil';
import { ToastType } from 'models/NavBarModel';
import { defaultStep, getNewStepConfig } from 'models/StepBarUtil';
import {
  createMessage,
  createTempMessage,
  estimateMessageCredit,
  rejectPendingForApprovalMessage,
  sendTestMessageBeforeCreate,
  sendTestMessageBeforeUpdate,
  updateApprovalStatus,
  updateMessage,
} from 'services/MessageApiHelper';

import {
  getFileNameFromUrl,
  getObjectFromSessionStorage,
  removeFromSessionStorage,
  saveToSessionStorage,
} from 'utils';

export const sessionDataKey = {
  objectKey: SESSION_KEYS.CREATE_MESSAGE_SESSION_KEY,
  stepEndKey: SESSION_KEYS.CREATE_MESSAGE_STEP_END_SESSION_KEY,
  originalData: SESSION_KEYS.CREATE_MESSAGE_ORIGINAL_DATA_SESSION_KEY,
};

const getMessageInitState = () => {
  return {
    pk: null,
    id: null,
    name: null,
    content: null,
    photo: null,
    channels: [],
    relatedCampaign: {
      pk: null,
      name: null,
    },
    targetCustomerGroup: null,
    targetedSegments: null,
    scheduledDate: null,
    translations: {},
    formHasSubmitted: false,
    hasUpdatedDefaultValues: false,
  };
};

const getInitialState = () => {
  return {
    message: getMessageInitState(),
    errorFields: [],
    selectedChannels: [],
    stepConfig: defaultStep(MessageDefaultSteps),
    currentStep: 0,
    languageTag: LanguageConfig.english,
    createStatus: APIStatus.none,
    scheduledMessage: false,
  };
};

const parseChannels = (channels = []) => {
  console.log('channels', channels);
  const resultData = channels?.map((item) => {
    if (item === MessageChannel.inbox) {
      return MessageBackendChannel.inbox;
    }
    if (item === MessageChannel.whatsapp) {
      return MessageBackendChannel.whatsapp;
    }
    if (item === MessageChannel.sms) {
      return MessageBackendChannel.sms;
    }
    if (item === MessageChannel.email) {
      return MessageBackendChannel.email;
    }
    if (item === MessageChannel.push) {
      return MessageBackendChannel.push;
    }
    if (item === MessageChannel.all) {
      return MessageBackendChannel.all;
    }
    if (item === MessageChannel.allWhatsapp) {
      return MessageBackendChannel.allWhatsapp;
    }
    return null;
  });
  console.log('resultData', resultData);
  return resultData;
};

const parseMessageInputBody = (values, languages) => {
  const parsedChannels = parseChannels(values.channels || []);

  const photo = values.translations?.[LanguageConfig.english]?.photo?.value;
  const whatsappImage =
    values.translations?.[LanguageConfig.english]?.whatsappImage?.value;

  let inputBody = {
    name: values.name,
    isDirectMarketing: !!values.isDirectMarketing,
    channels: parsedChannels,
    scheduledDate: values.scheduledDate,
    title: values.translations?.[LanguageConfig.english]?.name,
    content: values.translations?.[LanguageConfig.english]?.content,
    photo: photo ? getFileNameFromUrl(photo) : null,
    emailTitle: values.translations?.[LanguageConfig.english]?.emailTitle,
    emailContent: values.translations?.[LanguageConfig.english]?.emailContent,
    smsContent: values.translations?.[LanguageConfig.english]?.smsContent,

    whatsappTemplateJson: JSON.stringify(
      values.translations?.[LanguageConfig.english]?.whatsappTemplate || {},
    ),
    whatsappTemplateName:
      values.translations?.[LanguageConfig.english]?.whatsappTemplate?.name,
    whatsappImage: whatsappImage ? getFileNameFromUrl(whatsappImage) : null,
    whatsappHeaderArgs:
      values.translations?.[LanguageConfig.english]?.whatsappHeader || [],
    whatsappBodyArgs:
      values.translations?.[LanguageConfig.english]?.whatsappBody || [],

    isExclusive:
      values.targetCustomerGroup?.length > 0 ||
      values.targetedSegments?.length > 0,
    isStartWithTemplate: values.isStartWithTemplate || false,
    messageTemplate: values?.messageTemplate?.value?.pk,
    targetedCustomerGroups:
      values.targetCustomerGroup?.length > 0 && !values?.sendToAll && !values?.recipientsFollowCampaign
        ? values.targetCustomerGroup.map((item) => item.pk)
        : [],
    targetedSegments:
      values.targetedSegments?.length > 0 && !values?.sendToAll && !values?.recipientsFollowCampaign
        ? values.targetedSegments.map((item) => item.pk)
        : [],
    campaign: values.relatedCampaign?.pk,
    sendToAll: values?.sendToAll,
    recipientsFollowCampaign: values?.recipientsFollowCampaign,
  };

  let translations = [];
  languages.forEach((language) => {
    if (language.code !== LanguageConfig.english) {
      const translationValues = values.translations?.[language.code];
      const translationPhoto = translationValues?.photo?.value;
      const translationWhatsappImage = translationValues?.whatsappImage?.value;
      const translationData = {
        id: translationValues?.pk,
        language: language.code,
        title: translationValues?.name,
        content: translationValues?.content,
        photo: translationPhoto ? getFileNameFromUrl(translationPhoto) : null,
        emailTitle: translationValues?.emailTitle,
        emailContent: translationValues?.emailContent,
        smsContent: translationValues?.smsContent,

        whatsappTemplateJson: JSON.stringify(
          translationValues?.whatsappTemplate || {},
        ),
        whatsappTemplateName: translationValues?.whatsappTemplate?.name,
        whatsappImage: translationWhatsappImage
          ? getFileNameFromUrl(translationWhatsappImage)
          : null,
        whatsappHeaderArgs: translationValues?.whatsappHeader || [],
        whatsappBodyArgs: translationValues?.whatsappBody || [],
      };
      translations.push(translationData);
    }
  });
  inputBody.translations = translations;
  return inputBody;
};

export default {
  namespace: 'createMessage',
  state: getInitialState(),

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },

    updateStepConfig(state, { payload }) {
      const { selectedChannels } = payload;
      const newStepNames = [];
      for (const config of MessageStepConfig) {
        if (config.show(selectedChannels)) {
          newStepNames.push(config.name);
        }
      }
      return {
        ...state,
        selectedChannels,
        stepConfig: defaultStep(newStepNames),
      };
    },

    updateMessageState(state, { payload }) {
      const message = {
        ...state.message,
        ...payload,
      };
      saveToSessionStorage(sessionDataKey.objectKey, message);
      return {
        ...state,
        message: message,
      };
    },

    saveOrRemoveMessageFromCookie(state, { payload }) {
      if (payload.save) {
        if (payload.values) {
          saveToSessionStorage(sessionDataKey.originalData, payload.values);
        }
      } else {
        removeFromSessionStorage(sessionDataKey.originalData);
        removeFromSessionStorage(sessionDataKey.objectKey);
      }
      saveToSessionStorage(sessionDataKey.stepEndKey, true);
      return {
        ...state,
      };
    },

    stepChange(state, { payload }) {
      const isBack = payload.isBack;
      let step = payload.step;
      const isValid = payload.isValid;
      const stepConfig = getNewStepConfig(
        step,
        state.stepConfig,
        !isValid,
        isBack,
      );
      if (isValid) {
        step = isBack ? step - 1 : step + 1;
      }
      return {
        ...state,
        currentStep: step,
        stepConfig,
      };
    },

    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },

  effects: {
    loadMessageFromCookie: [
      function* ({ payload }, { put }) {
        const message = getObjectFromSessionStorage(sessionDataKey.originalData);
        if (!message) {
          return;
        }
        saveToSessionStorage(sessionDataKey.originalData, message);
        saveToSessionStorage(sessionDataKey.objectKey, message);

        yield put({
          type: 'editMessage',
          payload: {
            message,
            loadFromCookie: true,
          }
        })
      }
    ],
    editMessage: [
      function* ({ payload }, { select, put }) {
        const { message, loadFromCookie } = payload;
        const updatePayload = {
          message,
          hasUpdatedDefaultValues: true,
        };
        if (!loadFromCookie) {
          updatePayload["createStatus"] = APIStatus.success;
        };
        yield put({
          type: 'updateState',
          payload: updatePayload,
        });
        const selectedChannels = message.channels || [];
        yield put({
          type: 'updateStepConfig',
          payload: {
            selectedChannels: selectedChannels,
          },
        });
      },
      { type: 'takeLatest' },
    ],

    testMessageSend: [
      function* ({ payload }, { select }) {
        const { values } = payload;
        const languages = yield select((state) => state.language.allList);
        const message = yield select((state) => state.createMessage.message);
        const customer = payload.customer;
        const isEdit = payload.isEdit;
        let inputBody = parseMessageInputBody(values, languages);
        inputBody = {
          ...inputBody,
          customer: customer.pk,
        };
        let testService = null;
        if (isEdit) {
          inputBody.id = message.pk;
          testService = sendTestMessageBeforeUpdate;
        } else {
          testService = sendTestMessageBeforeCreate;
        }
        const serviceArgs = [testService, inputBody];
        function* onSuccess() {
          const afterActions = payload.afterActions || (() => { });
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    saveAsDraftMessage: [
      function* ({ payload }, { all, select, put }) {
        const languages = yield select((state) => state.language.allList);
        const message = yield select((state) => state.createMessage.message);
        const { values } = payload;
        let messagePk = message.pk;
        if (!messagePk) {
          messagePk = values?.tempSavePk;
        }

        const inputBody = parseMessageInputBody(values, languages);
        const uploadData = {
          ...inputBody,
        };
        let serviceArgs = [createMessage, uploadData];
        if (!!messagePk) {
          uploadData.id = messagePk;
          serviceArgs = [updateMessage, uploadData];
        }
        uploadData.skipValidate = true;
        saveToSessionStorage(sessionDataKey.stepEndKey, true);
        function* onSuccess(data) {
          const messageData = !!messagePk
            ? data?.updateMessage?.node
            : data?.createMessage?.node;
          const tempSavePk = messageData.pk;
          console.log('messageData.pk', tempSavePk);
          console.log('messageData', messageData);
          const translationPks = {};

          languages?.length > 0 &&
            languages.forEach((language) => {
              if (
                !language?.code ||
                language?.code === LanguageConfig.english
              ) {
                return;
              }
              const translationPk = messageData.translations.edges
                .filter((item) => item.node.language === language.code)
                ?.map((item) => item.node.pk)?.[0];
              translationPks[language.code] = translationPk;
            });

          console.log('messageData translationPks', translationPks);
          yield all([
            put({
              type: 'navBars/updateState',
              payload: {
                saveDiscardToastShowing: {
                  value: true,
                  type: ToastType.messageTempSaveSuccess,
                },
              },
            }),
          ]);
          removeFromSessionStorage(sessionDataKey.objectKey);
          const afterActions = payload.afterActions || ((tempSavePk) => { });
          yield afterActions(tempSavePk, translationPks);
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    createOrUpdateMessage: [
      function* ({ payload }, { all, select, put }) {
        const { values } = payload;
        const languages = yield select((state) => state.language.allList);
        const message = yield select((state) => state.createMessage.message);
        let messagePk = message.pk;
        if (!messagePk) {
          messagePk = values?.tempSavePk;
        }

        const inputBody = parseMessageInputBody(values, languages);
        console.log('inputBody', inputBody);
        const uploadData = {
          ...inputBody,
        };
        let serviceArgs = [createMessage, uploadData];
        if (payload.actionType === ActionType.tempForPulished) {
          uploadData.originMessage = messagePk;
          uploadData.translations = uploadData.translations?.map((item) => {
            delete item.id;
            return {
              ...item,
            };
          });
          serviceArgs = [createTempMessage, uploadData];
        } else if (!!messagePk) {
          uploadData.id = messagePk;
          serviceArgs = [updateMessage, uploadData];
        }
        saveToSessionStorage(sessionDataKey.stepEndKey, true);
        function* onSuccess(data) {
          const messageData =
            data[
              `${messagePk
                ? payload.actionType === ActionType.tempForPulished
                  ? 'createTemp'
                  : 'update'
                : 'create'
              }Message`
            ].node;
          const needUpdateApprovalStatus =
            payload.actionType !== undefined &&
            payload.actionType !== ActionType.save &&
            payload.actionType !== ActionType.update &&
            payload.actionType !== ActionType.tempForPulished;
          yield all([
            put({
              type: 'updateState',
              payload: {
                scheduledMessage: true,
                formHasSubmitted: true,
              },
            }),
            put({
              type: 'updateMessageState',
              payload: {
                ...values,
                pk: messageData.pk,
                id: messageData.id,
              },
            }),
            needUpdateApprovalStatus &&
            put({
              type: 'updateApprovalStatus',
              payload: {
                // ...payload,
                data: {
                  pk: messageData.pk,
                  approvalStatus: messageData.approvalStatus,
                },
                actionType: payload?.actionType,
              },
            }),
          ]);
          removeFromSessionStorage(sessionDataKey.objectKey);
          const afterActions = payload.afterActions || (() => { });
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    updateApprovalStatus: [
      function* ({ payload }, { all, select, put }) {
        const afterAction = payload.afterActions || (() => { });
        const campaignPk = payload.data?.pk;
        let serviceArgs = [
          updateApprovalStatus,
          { id: campaignPk },
          payload.data?.approvalStatus,
          payload.actionType,
        ];
        function* onSuccess(data) {
          console.log('@1295', data);
          yield all([
            put({
              type: 'updateState',
              payload: {
                createStatus: APIStatus.success,
                formHasSubmitted: true,
              },
            }),
          ]);
          afterAction();
        }

        function* onError(response) {
          yield put({
            type: 'updateState',
            payload: {
              createStatus: APIStatus.failed,
            },
          });
          afterAction();
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
    ],

    rejectMessage: [
      function* ({ payload }, { all, put }) {
        const { id, message } = payload;
        const afterAction = payload.afterAction || (() => { });
        const serviceArgs = [rejectPendingForApprovalMessage, { id, message }];
        function* onSuccess(data) {
          yield all([
            put({
              type: 'updateState',
              payload: {
                createStatus: APIStatus.success,
                formHasSubmitted: true,
              },
            }),
          ]);
          afterAction();
        }

        function* onError(response) {
          yield put({
            type: 'updateState',
            payload: {
              createStatus: APIStatus.failed,
              formHasSubmitted: true,
            },
          });
          afterAction();
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],

    estimateMessageCredit: [
      function* ({ payload }, { all, put }) {
        const { channels, sendToAll, segments, customerGroups, recipientsFollowCampaign, linkedCampaignId } = payload;
        const parsedChannels = parseChannels(channels);
        const afterAction = payload.afterAction || (() => { });
        const serviceArgs = [
          estimateMessageCredit,
          { channels: parsedChannels, sendToAll, segments, customerGroups, recipientsFollowCampaign, linkedCampaignId },
        ];
        function* onSuccess(data) {
          const estimateCredit = data?.estimateMessageCredit?.credit || 0;
          const estimateSmsCredit = data?.estimateMessageCredit?.smsCredit || 0;
          const estimateWhatsappCredit = data?.estimateMessageCredit?.whatsappCredit || 0;
          const estimateEmailCredit = data?.estimateMessageCredit?.emailCredit || 0;
          console.log("estimateCredit:", data?.estimateMessageCredit)
          yield all([
            put({
              type: 'updateState',
              payload: {
                estimateCredit,
                estimateSmsCredit,
                estimateWhatsappCredit,
                estimateEmailCredit,
              },
            }),
          ]);
          afterAction();
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
