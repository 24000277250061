import React from "react";

import BasePrompt from "./prompt/BasePrompt";
import CustomRadios from "./CustomRadios";

const ExportRangeModal = ({
  show = false,
  title = '',
  closeAction = () => { },
  rightButton = {},
  isExportAll = true,
  setIsExportAll = () => { },
}) => {
  return (
    <BasePrompt
      customClass={'export-report-modal'}
      show={show}
      title={title || 'Export'}
      description={'Select the export range'}
      closeAction={closeAction}
      otherBody={() => (
        <div className="d-flex">
          <CustomRadios
            onChange={(value) => {
              setIsExportAll(value);
            }}
            default={isExportAll}
            options={[
              {
                label: 'All items',
                value: true,
              },
              {
                label: 'All filtered items from the list',
                value: false,
              },
            ]}
          />
        </div>)
      }
      rightButton={rightButton}
    />)
}

export default ExportRangeModal;