export const EarningRuleErrorHandleField = {
  generalName: {
    name: 'generalName',
    message: 'Please provide name and ensure the length not more than 200.',
  },
  birthday: {
    name: 'birthday',
    message: 'Please provide birthday period.',
  },
  birthStart: {
    name: 'birthday',
    message: 'Please provide birthday period.',
  },
  birthEnd: {
    name: 'birthday',
    message: 'Please provide birthday period.',
  },
  minSpending: {
    name: 'minSpending',
    message: 'Please provide minSpending value.',
  },
  maxSpending: {
    name: 'maxSpending',
    message: 'The minimum value must be greater than the maximum value',
  },
  shortDescription: {
    name: 'shortDescription',
    message: 'Please provide short description.',
  },
  customerGroup: {
    name: 'customerGroup',
    message: 'Please provide customer list.',
  },
  coupons: {
    name: 'coupons',
    message: 'Please select coupon.',
  },
  rewardType: {
    name: 'rewardType',
    message: 'Please select reward type.',
  },
  quantity: {
    name: 'quantity',
    message: 'Please provide required/valid quantity.',
  },
  rewardTypeX: {
    name: 'rewardTypeX',
    message: 'Please provide required points.',
  },
  latitude: {
    name: 'latitude',
    message: 'Please provide required latitude.',
  },
  latitudeLimit: {
    name: 'latitudeLimit',
    message: 'Please make sure the length before dot shoud not longer than 3.',
  },
  longitude: {
    name: 'longitude',
    message: 'Please provide required longitude.',
  },
  longitudeLimit: {
    name: 'longitudeLimit',
    message: 'Please make sure the length before dot shoud not longer than 3.',
  },
  radius: {
    name: 'radius',
    message: 'Please provide required radius.',
  },
  qrCodes: {
    name: 'qrCodes',
    message: 'Please provide required qrcodes.',
  },
  fillingForm: {
    name: 'fillingForm',
    message: 'Please provide required filling form',
  },
  definitionType: {
    name: 'definitionType',
    message: 'Please provide required definition type',
  },
  linkStampCampaign:{
    name:'linkedStampCampaign',
    message:'Webhook does not support stamp campaign.'
  },
  pointReward:{
    name: 'pointReward',
    message: 'Please choose at lease one of points and progression points to fill.'
  },
  tpe:{
    name:'tpe',
    message:'Progression Points fields are not filled correctly.'
  },
  point:{
    name: 'point',
    message: 'Points fields are not filled correctly.'
  },
  levelGoal: {
    name: 'levelGoal',
    message: 'Please select a level goal'
  },
  activeEndDate: {
    name: 'activeEndDate',
    message:
      'The end date & time of a campaign cannot be on or before the start date and time.',
  },
};
