import moment from 'moment';

import { SESSION_KEYS } from 'config/CustomEnums';
import { createModel } from 'models/BaseModel';
import {
  getGiftCardRecordList,
  getGiftCardRecord,
  parseGiftCardAction,
  parseGiftCardType,
} from 'services/GiftCardAPIHelper';
import { TimeFormater, formatDate } from 'utils/TimeFormatUtil';

const parseListItem = (data) => {
  console.log(data);
  const giftCard = data.giftCard;
  const virtualConfigVariant = giftCard.virtualConfigVariant;
  return {
    id: data.id,
    pk: data.pk,
    type: giftCard.type,
    displayType: parseGiftCardType(giftCard.type),
    name: giftCard.code,
    sourceName: giftCard.code,
    cashValue: `$ ${giftCard.cashValue}`,
    action: data.action,
    displayAction: parseGiftCardAction(data.action),
    expiryAt: formatDate(
      giftCard.expireAt,
      TimeFormater.dayMonthYear,
    ),
    creationAt: formatDate(
      data.creationDate,
      TimeFormater.dayMonthWeek,
    ),
  };
}

const parseDetail = (data) => {
  const simpleParseData = parseListItem(data);
  const giftCard = data.giftCard;
  const recipientContact = giftCard?.sendMethod === 'email'
    ? giftCard?.recipientContact
    : giftCard?.recipientMobile;
  return {
    ...simpleParseData,
    senderContact: giftCard?.senderContact,
    shortLink: giftCard?.shortLink,
    code: giftCard?.code,
    recipientContact,
    recipientMembershipId: giftCard?.recipient?.membershipId,
  };
};

export default createModel({
  namespace: 'giftCardRecord',
  states: {
    listDisplayFields: [
      { displayName: 'ID', fieldName: 'pk', },
      { displayName: 'Gift card code', fieldName: 'name', },
      { displayName: 'Type', fieldName: 'displayType', },
      {
        displayName: 'Cash value',
        fieldName: 'cashValue',
        orderField: 'cash_value',
      },
      {
        displayName: 'Points expiry date',
        fieldName: 'expiryAt',
        orderField: 'expire_at',
      },
      {
        displayName: 'Creation at',
        fieldName: 'creationAt',
        orderField: 'creation_date',
      },
      { displayName: 'status', fieldName: 'displayAction', },
    ],
    detail: {
      sourceName: '',
      parameter: '',
    },
  },
  reducers: {},
  params: {
    sessionKey: SESSION_KEYS.GIFTCARD_RECORD_SESSION_KEY,
    dataKey: SESSION_KEYS.GIFTCARD_RECORD_DATA_SESSION_KEY,
    listAPI: getGiftCardRecordList,
    parse: (data) => data?.giftCardRecords.edges.map(
      (item) => parseListItem(item.node)
    ),
    pkNode: 'GiftCardRecordNode',
    detailAPI: getGiftCardRecord,
    parseDetail: (data) => parseDetail(data.giftCardRecord),
    objectKey: 'giftCardRecord',
  },
  effects: {
  },
});
