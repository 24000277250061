import { SIGN_UP_METHOD } from 'config/CustomEnums';
import { EditCustomerHandleError } from '../../../components/customer/EditCustomerHandleError';

export function getErrorList({
  firstName,
  lastName,
  pointTransaction,
  transactionPoint,
  tpeTransaction,
  transactionTpe,
  signUpMethod,
  mobilePhoneNumberCountryCode,
  mobilePhoneNumberSubscriberNumber,
  levelRenewalDate,
  emailAddress,
}) {
  const errorList = [];

  if (firstName == null || firstName === '') {
    errorList.push({
      name: EditCustomerHandleError.firstName.name,
      message: EditCustomerHandleError.firstName.message,
    });
  }
  if (lastName == null || lastName === '') {
    errorList.push({
      name: EditCustomerHandleError.lastName.name,
      message: EditCustomerHandleError.lastName.message,
    });
  }
  if (
    (transactionPoint != null && transactionPoint !== '')
    || (pointTransaction != null && pointTransaction?.value != null && pointTransaction !== '')
  ) {
    const transactionPointInt = parseInt(transactionPoint);
    if (isNaN(transactionPointInt) || transactionPointInt <= 0) {
      errorList.push({
        name: EditCustomerHandleError.transactionPoint.name,
        message: EditCustomerHandleError.transactionPoint.message,
      });
    }
    if (pointTransaction == null || pointTransaction.value == null) {
      errorList.push({
        name: EditCustomerHandleError.pointTransaction.name,
        message: EditCustomerHandleError.pointTransaction.message,
      });
    }
  }

  if (
    (transactionTpe != null && transactionTpe !== '')
    || (tpeTransaction != null && tpeTransaction?.value != null && tpeTransaction !== '')
  ) {
    const transactionTpeInt = parseInt(transactionTpe);
    if (isNaN(transactionTpeInt) || transactionTpeInt <= 0) {
      errorList.push({
        name: EditCustomerHandleError.transactionTpe.name,
        message: EditCustomerHandleError.transactionTpe.message,
      });
    }
    if (tpeTransaction == null || tpeTransaction.value == null) {
      errorList.push({
        name: EditCustomerHandleError.tpeTransaction.name,
        message: EditCustomerHandleError.tpeTransaction.message,
      });
    }
  }

  if (!mobilePhoneNumberCountryCode && mobilePhoneNumberSubscriberNumber) {
    errorList.push({
      name: EditCustomerHandleError.mobilePhoneNumberCountryCode.name,
      message: EditCustomerHandleError.mobilePhoneNumberCountryCode.message,
    });
  }
  if (mobilePhoneNumberCountryCode && !mobilePhoneNumberSubscriberNumber) {
    errorList.push({
      name: EditCustomerHandleError.mobilePhoneNumberSubscriberNumber.name,
      message: EditCustomerHandleError.mobilePhoneNumberSubscriberNumber.message,
    });
  }

  if (mobilePhoneNumberCountryCode && mobilePhoneNumberSubscriberNumber) {
    const length = mobilePhoneNumberSubscriberNumber.length;
    if (mobilePhoneNumberCountryCode === '852' || mobilePhoneNumberCountryCode === '853') {
      if (length !== 8) {
        errorList.push({
          name: EditCustomerHandleError.mobilePhoneNumberSubscriberNumberInvalid.name,
          message: EditCustomerHandleError.mobilePhoneNumberSubscriberNumberInvalid.message,
        });
      }
    } else if (mobilePhoneNumberCountryCode === '86') {
      if (length !== 11) {
        errorList.push({
          name: EditCustomerHandleError.mobilePhoneNumberSubscriberNumberInvalid.name,
          message: EditCustomerHandleError.mobilePhoneNumberSubscriberNumberInvalid.message,
        });
      }
    }
  }

  if (
    !mobilePhoneNumberCountryCode &&
    !mobilePhoneNumberSubscriberNumber &&
    !emailAddress
  ) {
    errorList.push({
      name: EditCustomerHandleError.mobilePhoneNumberCountryCode.name,
      message: EditCustomerHandleError.mobilePhoneNumberCountryCode.message,
    });
    errorList.push({
      name: EditCustomerHandleError.mobilePhoneNumberSubscriberNumber.name,
      message: EditCustomerHandleError.mobilePhoneNumberSubscriberNumber.message,
    });
    errorList.push({
      name: EditCustomerHandleError.emailAddress.name,
      message: EditCustomerHandleError.emailAddress.message,
    });
  }

  if (levelRenewalDate) {
    const renewalDate = new Date(levelRenewalDate);
    const renewalDateWithoutTime = new Date(renewalDate.getFullYear(), renewalDate.getMonth(), renewalDate.getDate());
    const today = new Date();
    const todayWithoutTime = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    if (renewalDateWithoutTime < todayWithoutTime) {
      errorList.push({
        name: EditCustomerHandleError.levelRenewalDate.name,
        message: EditCustomerHandleError.levelRenewalDate.message,
      });
    }
  }

  return errorList;
}


function firstStepValidate({ getValues, setError }) {
  const [
    firstName,
    lastName,
    pointTransaction,
    transactionPoint,
    tpeTransaction,
    transactionTpe,
    signUpMethod,
    mobilePhoneNumberCountryCode,
    mobilePhoneNumberSubscriberNumber,
    emailAddress,
  ] = getValues([
    'firstName',
    'lastName',
    'pointTransaction',
    'transactionPoint',
    'tpeTransaction',
    'transactionTpe',
    'signUpMethod',
    'mobilePhoneNumberCountryCode',
    'mobilePhoneNumberSubscriberNumber',
    'emailAddress',
  ]);
  const errorList = getErrorList({
    firstName,
    lastName,
    pointTransaction,
    transactionPoint,
    tpeTransaction,
    transactionTpe,
    signUpMethod,
    mobilePhoneNumberCountryCode,
    mobilePhoneNumberSubscriberNumber,
    emailAddress,
  });

  errorList.forEach((error) => {
    setError(
      error.name,
      {
        type: 'require',
        message: error.message,
      },
      { shouldFocus: true, },
    );
  });
  return !errorList.length;
}

export function validate({ getValues, setError, clearErrors, step }) {
  console.log('@71 customerValidate:', getValues());
  clearErrors();
  switch (step) {
    default:
    case 0:
      const validStepOne = firstStepValidate({ getValues, setError });
      return validStepOne;
  }
}
