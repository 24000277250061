import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { OnlyBackButton } from '../../../components/base/BottomStepComponent';
import ContentSections from '../../../components/base/ContentSections';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import { pointRecordQueryKey } from '../../../components/pointRecord/RecordsUrlConfig';
import queryString from 'querystring';

const BULK_REWARD_TYPE = {
  ADD_POINTS: 'Add points',
  REMOVE_POINTS: 'Remove points',
  ADD_COUPONS: 'Add coupons',
  REMOVE_COUPONS: 'Remove coupons',
  ADD_STAMPS: 'Add Stamps',
  REMOVE_STAMPS: 'Remove Stamps',
  ADD_TPES: 'Add progress points',
  REMOVE_TPES: 'Remove progress points',
};

function BulkRewardOverview() {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { bulkReward } = useSelector((state) => {
    return { bulkReward: state.bulkReward.detail };
  });

  useEffect(() => {
    const id = params.id;
    dispatch({
      type: 'bulkReward/getOneDetail',
      payload: { id: id },
    });
  }, [dispatch, params.id]);

  const getDetailSectionFieldsWithType = (rewardType) => {
    let extraFields = [];
    switch (rewardType) {
      case BULK_REWARD_TYPE.ADD_POINTS:
        extraFields = extraFields.concat([
          [{ title: 'Value', field: 'addPoints' }],
        ]);
        break;
      case BULK_REWARD_TYPE.REMOVE_POINTS:
        extraFields = extraFields.concat([
          [{ title: 'Value', field: 'removePoints' }],
        ]);
        break;
      case BULK_REWARD_TYPE.ADD_COUPONS:
        extraFields = extraFields.concat([
          [{ title: 'Coupon Set', field: 'addCouponSetIds' }],
          [{ title: 'Add Coupon Amount', field: 'addCouponAmount' }],
        ]);
        break;
      case BULK_REWARD_TYPE.REMOVE_COUPONS:
        extraFields = extraFields.concat([
          [{ title: 'Coupon Set', field: 'removeCouponSetIds' }],
          [{ title: 'Remove Coupon Amount', field: 'removeCouponAmount' }],
        ]);
        break;
      case BULK_REWARD_TYPE.ADD_STAMPS:
        extraFields = extraFields.concat([
          [{ title: 'Stamp campaign', field: 'addStampCampaignIds' }],
          [{ title: 'Add Stamp Quantity', field: 'addStampCampaignQuantity' }],
        ]);
        break;
      case BULK_REWARD_TYPE.REMOVE_STAMPS:
        extraFields = extraFields.concat([
          [{ title: 'Stamp campaign', field: 'removeStampCampaignIds' }],
          [{ title: 'Remove Stamp Quantity', field: 'removeStampCampaignQuantity' }],
        ]);
        break;
      case BULK_REWARD_TYPE.ADD_TPES:
        extraFields = extraFields.concat([
          [{ title: 'Value', field: 'addTpes' }],
        ]);
        break;
      case BULK_REWARD_TYPE.REMOVE_TPES:
        extraFields = extraFields.concat([
          [{ title: 'Value', field: 'removeTpes' }],
        ]);
        break;
      default:
    }
    return extraFields;
  };

  const tabs = [
    {
      name: '',
      content: (
        <div className="scroll-container-common">
          <ContentSections
            sections={[
              <MembershipInfoCard
                title={'BULK REWARD RECORD DETAILS'}
                data={bulkReward}
                fields={[
                  [{ title: 'Name(Preferred name)', field: 'customerName' }],
                  [{ title: 'Membership ID', field: 'membershipId', customerLink: true }],
                  [{ title: 'Bulk reward type', field: 'rewardTypeString' }],
                  [{ title: 'Remarks', field: 'remarks' }],
                  [{ title: 'Create Date', field: 'creationDate' }],
                ]}
              />,
              <MembershipInfoCard
                title={'ADD POINTS'}
                data={bulkReward}
                fields={getDetailSectionFieldsWithType(
                  BULK_REWARD_TYPE.ADD_POINTS,
                )}
              />,
              <MembershipInfoCard
                title={'REMOVE POINTS'}
                data={bulkReward}
                fields={getDetailSectionFieldsWithType(
                  BULK_REWARD_TYPE.REMOVE_POINTS,
                )}
              />,
              <MembershipInfoCard
                title={'ADD PROGESS POINTS'}
                data={bulkReward}
                fields={getDetailSectionFieldsWithType(
                  BULK_REWARD_TYPE.ADD_TPES,
                )}
              />,
              <MembershipInfoCard
                title={'REMOVE PROGRESS POINTS'}
                data={bulkReward}
                fields={getDetailSectionFieldsWithType(
                  BULK_REWARD_TYPE.REMOVE_TPES,
                )}
              />,
              <MembershipInfoCard
                title={'ADD COUPONS'}
                data={bulkReward}
                fields={getDetailSectionFieldsWithType(
                  BULK_REWARD_TYPE.ADD_COUPONS,
                )}
              />,
              <MembershipInfoCard
                title={'REMOVE COUPONS'}
                data={bulkReward}
                fields={getDetailSectionFieldsWithType(
                  BULK_REWARD_TYPE.REMOVE_COUPONS,
                )}
              />,
              <MembershipInfoCard
                title={'ADD STAMPS'}
                data={bulkReward}
                fields={getDetailSectionFieldsWithType(
                  BULK_REWARD_TYPE.ADD_STAMPS,
                )}
              />,
              <MembershipInfoCard
                title={'REMOVE STAMPS'}
                data={bulkReward}
                fields={getDetailSectionFieldsWithType(
                  BULK_REWARD_TYPE.REMOVE_STAMPS,
                )}
              />,
            ]}
            hidePreview={true}
            sectionStyle="transaction-record-detail-section"
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          title: 'View Bulk Reward Record',
          detail: '',
        }}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
        buttons={[]}
      />
      <OnlyBackButton
        backAction={() => {
          history.push({
            pathname: '/bulk_rewards',
            search: queryString.stringify({
              [pointRecordQueryKey.page]: 1,
              [pointRecordQueryKey.rank]: true,
              [pointRecordQueryKey.searchKey]: '',
              [pointRecordQueryKey.tab]: 'all',
            }),
          });
        }}
      />
    </>
  );
}

export default BulkRewardOverview;
