import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import ContentSections from 'components/base/ContentSections';
import CustomBreadcrumb from 'components/base/CustomBreadcrumb';
import BaseForm from 'components/base/v2/BaseForm';
import ContentSection from 'components/segment/ContentSection';
import OverviewSection from 'components/segment/OverviewSection';
import { SavedStatus } from 'config/CustomEnums';
import { segmentSessionKey } from 'models/SegmentModel';
import Loading from 'components/base/Loading';
import { removeFromSessionStorage, saveToSessionStorage } from 'utils';

import './CreateSegment.scss';

// const dataArray = [];

function CreateSegment({
  savedSuccess,
  // checked,
  tempSegment,
  hasUpdatedDefaultValues,
  formHasSubmitted,
}) {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const afterAction = () => {
    setLoading(false);
  };

  useEffect(() => {
    dispatch({
      type: 'segments/clearData',
    });
    const segmentId = params.id;
    if (segmentId) {
      removeFromSessionStorage(segmentSessionKey);
      dispatch({
        type: 'segments/getOneSegment',
        payload: {
          id: segmentId,
          afterAction,
        },
      });
    } else {
      dispatch({
        type: 'segments/loadSegmentFromCookie',
        payload: {
          afterAction,
        },
      });
    }
    dispatch({
      type: 'levels/getLevelList',
    });
    dispatch({
      type: 'settings/getCountryCodeList',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (
      savedSuccess === SavedStatus.savedWithSuccess &&
      history.location.pathname.includes('edit')
    ) {
      history.push('/segments');
    }
  }, [savedSuccess, history]);

  const sections = loading
    ? [<Loading />]
    : [
      <OverviewSection />,
      <ContentSection
        defaultValue={tempSegment}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
      />,
    ];

  const stepSet = [
    <>
      <ContentSections sections={sections} hidePreview={true} />
    </>,
  ];

  const caution = () => {
    return {
      detail: '', //The customers need to fulfill all the fields below in order to enter this segment.
      title: params.id ? 'Edit Segment' : 'Create Segment',
      model: 'segments',
    };
  };

  return (
    <div className="segment">
      <BaseForm
        defaultValues={tempSegment}
        formHasSubmitted={formHasSubmitted}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        tempSave={(save, getValues) => {
          if (save) {
            saveToSessionStorage(segmentSessionKey, getValues());
          } else {
            dispatch({
              type: 'segments/removeFromCookie',
            });
          }
        }}
        showFinishPop={savedSuccess === SavedStatus.savedWithSuccess}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Segment is successfully created.',
          steps: null,
          onHide: () => { },
          buttons: [
            {
              text: 'Back to segment list',
              action: () => {
                history.push({
                  pathname: '/segments',
                });
              },
            },
          ],
        }}
        continueCreateConfig={{
          onConfirm: () => {
            history.push(history.location.state?.from);
          },
          onCancel: () => history.push('/segments'),
          onClose: () => history.push('/segments'),
        }}
        content={stepSet}
        breadcrumb={
          <CustomBreadcrumb
            name={params.id ? tempSegment?.name : 'Create Segment'}
          />
        }
        currentStep={0}
        caution={caution()}
      />

      {/* <ScrollableComponent
        tempSave={(save) => {
          if (!save) {
            dispatch({
              type: 'segments/removeFromCookie',
            });
          }
        }}
        showFinishPop={savedSuccess === SavedStatus.savedWithSuccess}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Segment is successfully created.',
          steps: null,
          buttons: [
            {
              text: 'Back to segment list',
              action: () => {
                history.push({
                  pathname: '/segments',
                });
              },
            },
          ],
        }}
        continueCreateConfig={{
          onConfirm: () => {
            history.push(history.location.state?.from);
          },
          onCancel: () => history.push('/segments'),
          onClose: () => history.push('/segments'),
        }}
        content={stepSet}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb
            name={params.id ? tempSegment?.name : 'Create Segment'}
          />
        }
        caution={caution()}
      /> */}
    </div>
  );
}

const mapPropsToState = (state) => ({
  savedSuccess: state.segments.saved,
  checked: state.segments.checked,
  tempSegment: state.segments.segment,
  hasUpdatedDefaultValues: state.segments.hasUpdatedDefaultValues,
  formHasSubmitted: state.segments.formHasSubmitted,
});

export default connect(mapPropsToState)(CreateSegment);
