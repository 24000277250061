import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Dropdown, Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import i18n from 'I18n';

import CampaignFilterableSelectModal from 'components/campaign/CampaignFilterableSelectModal';
import { AppCampaignDetailUrl, CampaignDetailUrl } from 'config/CustomEnums';
import { WhatsappInsertParameterOptions } from 'config/MessageEmail';

import { rewritePermission } from 'utils';
import { getCampaignActivePeriodDateTime } from 'utils/TimeFormatUtil';

import deleteSelectedButton from 'assets/images/drop_down_delete_selected.png';
import NarrowUp from 'assets/images/drop_down_not_show_narrow.svg';
import OmitIcon from 'assets/images/omit_dropdown_icon.svg';

import '../base/FilterableDropdown.scss';
import './ActionsDropdown.scss';

const CustomGroupToggle = forwardRef(({ children, onClick }, ref) => (
  <div
    className="filterable-toogle-area filterable-toogle-area-special "
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

const CustomItemToggle = forwardRef(({ onClick }, ref) => (
  <Image
    src={OmitIcon}
    className="dropdown-for-item-toggle sort-disabled"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  />
));

export function ActionsDropdownForGroup(props) {
  const options = props.actions || [];
  const [value, setValue] = useState();
  const location = useLocation();

  const { isSuperuser, userPermissions } = useSelector((state) => ({
    isSuperuser: state.users.isSuperuser,
    userPermissions: state.users.userPermissions,
  }));

  const permissionedActions = () => {
    if (isSuperuser) return options;

    let permissionedActions = [];
    options.forEach((action) => {
      const newRequires = rewritePermission(action.requires);
      if (userPermissions.includes(newRequires) || !action.requires) {
        permissionedActions.push(action);
      }
    });

    return permissionedActions;
  };

  useEffect(() => {
    setValue('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  return (
    <>
      <label className="dropdown-for-group-title">Action</label>
      <ToggleDropdown
        options={permissionedActions()}
        toggleText={value ? `${value}` : 'Please select'}
        dropdownClass={'dropdown-for-group-container'}
        optionChange={(option) => setValue(option.name)}
      />
    </>
  );
}

export function DropDownWithDefaultSelect({
  actions: options,
  defaultValue,
  name,
  className = "",
  imageClass = "",
  splitAfterIndex = [],
}) {
  const [value, setValue] = useState(
    defaultValue ? defaultValue : options?.[0]?.name,
  );

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <ToggleDropdown
      options={options}
      toggleText={value}
      dropdownClass={`dropdown-section ${className}`}
      imageClass={imageClass || ''}
      name={name}
      optionChange={(option) => setValue(option.name)}
      splitAfterIndex={splitAfterIndex}
    />
  );
}

export function OnlyDropdown({ toggleText, options, setValue }) {
  const dropDownOptions = (options, setValue) => {
    return options.map((option) => ({
      name: option.name,
      action: () => {
        setValue(option.value);
      },
    }));
  };

  return (
    <ToggleDropdown
      options={dropDownOptions(options, setValue)}
      toggleText={toggleText}
      dropdownClass={`dropdown-section`}
      name={'name'}
    />
  );
}

function ToggleDropdown({
  options,
  toggleText,
  dropdownClass,
  imageClass,
  name,
  optionChange = ()=>{},
  splitAfterIndex = [],
}) {
  const [isOpen, setIsOpen] = useState();
  return (
    <>
      <Dropdown
        name={name}
        onToggle={(isOpen) => {
          setIsOpen(isOpen);
        }}
        className={dropdownClass}
      >
        <Dropdown.Toggle as={CustomGroupToggle}>
          <>
            <label
              className={`btn-link-text-label ${
                toggleText && toggleText !== 'Please select'
                  ? 'btn-link-text-label-active'
                  : ''
              }`}
            >
              {toggleText}
            </label>
            <Image
              src={NarrowUp}
              className={`filterable-toogle-area-indicator ${
                isOpen ? 'rtoate180' : ''
              } ${imageClass}`}
            />
          </>
        </Dropdown.Toggle>

        <Dropdown.Menu
          className="filterable-dropdown-menu"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            margin: 0,
            right: 'auto',
            bottom: 'auto',
          }}
        >
          <BaseDropItem
            options={options}
            extraAction={(option) => optionChange(option)}
            splitAfterIndex={splitAfterIndex}
          />
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export const InsertParamDropdown = ({
  dropdownClass,
  imageClass,
  name,
  language,
  value = '',
  setValue = () => {},
}) => {
  const inputRef = useRef(null);

  const [isOpen, setIsOpen] = useState();
  const [show, setShow] = useState(false);
  const [type, setType] = useState();

  const onToggle = (isOpen) => {
    inputRef.current.blur();
    setIsOpen(isOpen);
  };

  const insertValue = (text) => {
    const originText = inputRef.current.value;
    setValue(`${originText}${text}`);
  };

  // useEffect(() => {
  //   console.log('kevin@214', name, typeof value, value);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [value]);

  return (
    <>
      <Dropdown
        className={`dropdown-section insert-param ${dropdownClass}`}
        onToggle={(isOpen) => onToggle(isOpen)}
      >
        <Dropdown.Toggle as={CustomGroupToggle}>
          <>
            <input
              ref={inputRef}
              className="insert-param-custom-input"
              name={name}
              type="text"
              placeholder={'Please input/insert'}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={({ target }) => setValue(target.value)}
              value={value}
            />
            {value && (
              <Image
                src={deleteSelectedButton}
                className="filterable-dropdown-delete-selected"
                onClick={(e) => {
                  e.stopPropagation();
                  setValue('');
                }}
              />
            )}
            <div className="divider" />
            <label className={'btn-link-text-label btn-link-text-label-active'}>
              {'Insert parameters'}
            </label>
            <Image
              src={NarrowUp}
              className={`filterable-toogle-area-indicator ${
                isOpen ? 'rtoate180' : ''
              } ${imageClass}`}
            />
          </>
        </Dropdown.Toggle>

        <Dropdown.Menu
          className="filterable-dropdown-menu"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            margin: 0,
            right: 'auto',
            bottom: 'auto',
          }}
        >
          <BaseDropItem
            options={WhatsappInsertParameterOptions}
            extraAction={(option) => {
              if (
                [
                  '{{CampaignUrlForWeb}}',
                  '{{CampaignDeepLinkForApp}}',
                  '{{CampaignActiveStart}}',
                  '{{CampaignActiveEnd}}',
                ].includes(option.value)
              ) {
                setType(option.value);
                setShow(true);
              } else {
                insertValue(option.value);
                setType(null);
              }
            }}
          />
        </Dropdown.Menu>
      </Dropdown>
      <CampaignFilterableSelectModal
        show={show}
        type={type}
        onClose={() => {
          setShow(false);
          setType(null);
        }}
        onSelect={(campaignData) => {
          if (type === '{{CampaignUrlForWeb}}') {
            insertValue(`${CampaignDetailUrl}${campaignData.pk}`);
          } else if (type === '{{CampaignDeepLinkForApp}}') {
            insertValue(`${AppCampaignDetailUrl}${campaignData.pk}`);
          } else if (type === '{{CampaignActiveStart}}') {
            if (!campaignData.startDate) {
              insertValue(i18n.t('allTimeActive', { locale: language }));
            } else {
              insertValue(
                getCampaignActivePeriodDateTime(
                  campaignData.startDate,
                  language,
                ),
              );
            }
          } else if (type === '{{CampaignActiveEnd}}') {
            if (!campaignData.endDate) {
              insertValue(i18n.t('allTimeActive', { locale: language }));
            } else {
              insertValue(
                getCampaignActivePeriodDateTime(campaignData.endDate, language),
              );
            }
          }
          setShow(false);
          setType(null);
        }}
      />
    </>
  );
};

function BaseDropItem({
  options,
  object,
  extraAction = (() => { }),
  splitAfterIndex=[],
}) {
  return options?.map((option, index) => (
    <>
      <Dropdown.Item
        key={`${index}-${option.name}`}
        className={`filterable-item ${option.disabled ? 'dropdown-item-disabled' : ''}`}
        onSelect={(eventKey) => {
          if (option.disabled) {
            return;
          };
          if (option.action) {
            option.action(object);
          }
          extraAction(option);
        }}
        eventKey={index}
        href={option?.href}
      >
        {option.name}
      </Dropdown.Item>
      {splitAfterIndex?.length > 0 && splitAfterIndex.includes(index) && <Dropdown.Divider />}
    </>
  ));
}

function ActionsForDowndownItem(props) {
  const title = props.title;
  const options = props.options;
  const object = props.object;
  return options.length > 0 ? (
    <>
      <label className="dropdown-for-item-title">{title}</label>
      <BaseDropItem options={options} object={object} />
    </>
  ) : null;
}

export function ActionsDropdownForItem(props) {
  const { isSuperuser, userPermissions } = useSelector((state) => ({
    isSuperuser: state.users.isSuperuser,
    userPermissions: state.users.userPermissions,
  }));

  const permissionedActions = (actions) => {
    const originalActions = actions || [];

    if (isSuperuser) return originalActions;

    let permissionedActions = [];
    originalActions.forEach((action) => {
      if (Array.isArray(action.requires)) {
        const newRequires = action.requires.map((permission) => {
          return rewritePermission(permission);
        });
        const ownedPermissions = newRequires.filter((permission) => {
          return userPermissions.indexOf(permission) > -1;
        });
        if (
          (ownedPermissions?.length > 0 && action?.isOnlyNeedOnePermission) ||
          ownedPermissions?.length === newRequires?.length
        ) {
          permissionedActions.push(action);
          return;
        }
        return;
      }

      const newRequires = rewritePermission(action.requires);

      if (userPermissions.includes(newRequires) || !newRequires) {
        permissionedActions.push(action);
      }
    });

    return permissionedActions;
  };

  const options = permissionedActions(props.actions);
  const otherOptions = permissionedActions(props.otherActions);

  const object = props.object || '';

  const onToggle = props.onToggle;
  const popperConfig = {
    strategy: 'fixed',
    modifiers: [
      {
        name: 'computeStyles',
        options: {
          gpuAcceleration: false,
          adaptive: false,
        },
      },
    ],
  };
  return (
    <>
      <Dropdown drop="left" onToggle={onToggle}>
        <Dropdown.Toggle as={CustomItemToggle} />
        {options.length <= 0 && otherOptions.length <= 0 ? null : (
          <Dropdown.Menu
            className="dropdown-for-item-menu"
            // style={{
            //   // position: 'absolute',
            //   top: 0,
            //   left: 0,
            //   margin: 0,
            //   right: 'auto',
            //   bottom: 'auto',
            // }}
            popperConfig={popperConfig}
          >
            <ActionsForDowndownItem
              title="Actions"
              options={options}
              object={object}
            />
            {options.length > 0 && otherOptions.length > 0 ? (
              <Dropdown.Divider className="dropdown-for-item-divider" />
            ) : null}
            <ActionsForDowndownItem
              title="Others"
              options={otherOptions}
              object={object}
            />
          </Dropdown.Menu>
        )}
      </Dropdown>
    </>
  );
}
