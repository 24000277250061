import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { DropDownWithDefaultSelect } from 'components/base/ActionsDropdown';
import { hasReactHookFormError } from 'components/base/ErrorFieldMessage';
import { CustomTitleLabel } from 'components/earning/CustomBaseComponments';
import { POINTS_TO_EXPIRE_DATE_TYPE_DISPLAY } from 'config/CustomEnums';

const PointsToExpireSection = ({
  title,
  groupName,
  fieldBaseName
}) => {
  const {
    watch,
    setValue,
    control,
    formState: { errors },
    register,
  } = useFormContext();
  const dateTypeFieldName = `${fieldBaseName}.dateType`;
  const dateType = watch(dateTypeFieldName) || POINTS_TO_EXPIRE_DATE_TYPE_DISPLAY.DAY;
  const numberFieldName = `${fieldBaseName}.number`;

  const dropDownOptions = (values, setAction) => {
    return values.map((value) => ({
      name: value,
      action: () => {
        setAction(value);
      },
    }));
  };

  return (
    <div>
      <CustomTitleLabel title={title} />
      <div>
        <CustomTitleLabel title="Time before the points expire" />
      </div>
      <div className="d-flex flex-column">
        <div style={{ display: 'flex' }}>
          <input
            type="number"
            className={`custom-number-input-short margin-right-10-percent ${hasReactHookFormError(errors, numberFieldName) ? 'error-field' : ''}`}
            {...register(numberFieldName, { required: true })}
          />

          <Controller
            name={dateTypeFieldName}
            control={control}
            render={({ field }) => (
              <DropDownWithDefaultSelect
                actions={dropDownOptions(
                  [
                    POINTS_TO_EXPIRE_DATE_TYPE_DISPLAY.DAY,
                    POINTS_TO_EXPIRE_DATE_TYPE_DISPLAY.WEEK,
                  ],
                  (value) => {
                    setValue(field.name, value, {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  },
                )}
                defaultValue={dateType}
                className={
                  hasReactHookFormError(errors, field.name) ? 'error-field' : ''
                }
              />
            )}
          />

          <label className="content-campaign-related-to margin-right-10-percent">
            before expire
          </label>
        </div>
      </div>
    </div>
  );
}

export default PointsToExpireSection;
