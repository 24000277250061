import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import CustomEditor from 'components/base/CustomEditor';
import {
  CustomTitleWithDropDownAndInput,
} from './CustomTitleWithDropDown';
import CustomTitleWithSwitch from '../base/CustomTitleWithSwitch';
import BaseMultipleSelectorV2 from 'components/base/BaseMultipleSelectorV2';
import { PermissionCodes } from '../../config/PermissionCodes';
import CustomerFilterableDropdown from '../transactions/CustomerFilterableDropdown';
import { hasError, errorMessage, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import '../../containers/customers/customer/CustomerOverviewDetail.scss';
import CustomRadioWithTitleAndRelated from './CustomRadioWithTitleAndRelated';
import AuthButton from 'components/base/AuthButton';


function MembershipInfoEditCard({
  user,
  title,
}) {
  const dispatch = useDispatch();

  const { watch, setValue, formState } = useFormContext();
  const { errors } = formState;

  const watchReferrerUser = watch('referrerUser');
  const watchIsForcedInactive = watch('isForcedInactive');
  const watchOwnedCoupons = watch('ownedCoupons');
  const watchRemovedCoupons = watch('removedCoupons');
  const watchInGroups = watch('inGroups');
  const watchLeaveGroups = watch('leaveGroups');

  const [editCouponType, setEditCouponType] = useState("remove");

  useEffect(() => {
    dispatch({
      type: 'campaignList/getCampaignList',
      payload: {}
    });
  }, [dispatch]);

  const getPointTransactionSource = () => {
    let source = [];
    const permissions = user.userPermissions;
    if (permissions.includes(PermissionCodes.addCustomerPoints)) {
      source.push({ name: 'Add transaction', pk: 'ADD_POINTS' });
    }
    if (permissions.includes(PermissionCodes.removeCustomerPoints)) {
      source.push({ name: 'Remove transaction', pk: 'REMOVE_POINTS' });
    }
    return source;
  };

  return (
    <div>
      <label className="create-section-title">{title}</label>

      {/* <CustomTitleWithDropDown
        title={'Current level'}
        source={levelList}
        defaultValue={{
          label: currentLevel?.levelName,
          value: currentLevel,
        }}
        needFilter={false}
        setValue={setCurrentLevel}
        disabled={true}
        error={{}}
      /> */}

      <CustomTitleWithDropDownAndInput
        title="Change points transaction(Optional)"
        source={getPointTransactionSource()}
        defaultValue={''}
        needFilter={false}
        unit="Points"
        customClass="edit-custom-transaction-selector"
        setValue={(value) => {
          setValue('pointTransaction', value, { shouldDirty: true });
        }}
        setInputValue={(value) => {
          setValue('transactionPoint', value, { shouldDirty: true });
        }}
      />
      <ReactHookFormErrorMessage errors={errors} id='pointTransaction' />
      <ReactHookFormErrorMessage errors={errors} id='transactionPoint' />
      <CustomTitleWithDropDownAndInput
        title="Change progression points transaction(Optional)"
        source={getPointTransactionSource()}
        defaultValue={''}
        needFilter={false}
        unit="Progression Points"
        customClass="edit-custom-transaction-selector"
        setValue={(value) => {
          setValue('tpeTransaction', value, { shouldDirty: true })
        }}
        setInputValue={(value) => {
          setValue('transactionTpe', value, { shouldDirty: true })
        }}
      />
      <ReactHookFormErrorMessage errors={errors} id='tpeTransaction' />
      <ReactHookFormErrorMessage errors={errors} id='transactionTpe' />
      <CustomTitleLabel title="Points transaction remark(Optional)" />
      <CustomEditor
        initialValue={''}
        onValueChange={(value) => {
          setValue('pointTransactionRemark', value, { shouldDirty: true });
        }}
        onFocus={() => { }}
        errorMessage={''}
        error={''}
      />
      <ReactHookFormErrorMessage errors={errors} id='pointTransactionRemark' />

      <CustomRadioWithTitleAndRelated
        title="Edit coupon purpose"
        options={[
          { label: 'Remove coupon', value: 'remove' },
          { label: 'Add coupon', value: 'add' },
        ]}
        defaultValue={editCouponType}
        setValue={(value) => {
          setEditCouponType(value);
          if (value === "add") {
            setValue('removedCoupons', [], { shouldDirty: true, });
          }
        }}
      />

      {
        editCouponType === "remove" ? (
          <BaseMultipleSelectorV2
            title={'Select coupons to remove from the customer'}
            tips={
              'When the coupon is moved to the right table, the selected coupons will be removed from the customer’s wallet.'
            }
            data={{
              sourceData: watchOwnedCoupons,
              targetData: watchRemovedCoupons,
              targetChange: (value) => {
                setValue('removedCoupons', value, { shouldDirty: true, });
              },
            }}
            groupKey="groupKey"
            error={{
              error: hasError(errors, 'removedCoupons'),
              id: 'removedCoupons',
              message: errorMessage(errors, 'removedCoupons'),
            }}
          />
        ) : (
          <>
            <label class="help-message customer-detail-add-coupon-help-message">
              Go to Coupon section and select single coupon in a coupon set to update the coupon to the customer.
            </label>
            <AuthButton
              title="Go to coupon set list"
              requires={[PermissionCodes.viewCouponTemplate, PermissionCodes.changeCouponTemplate]}
              customClass={'general-section-add-new btn-add-button-common'}
              action={() => {
                window.open(encodeURI('/coupons#List of Coupon sets'), '_blank');
              }}
            />
          </>
        )
      }

      <BaseMultipleSelectorV2
        title={'Customer Lists(Optional)'}
        data={{
          sourceData: watchInGroups,
          targetData: watchLeaveGroups,
          targetChange: (value) => {
            setValue('leaveGroups', value, { shouldDirty: true });
          },
        }}
        error={{
          error: hasError(errors, 'leaveGroups'),
          id: 'leaveGroups',
          message: errorMessage(errors, 'leaveGroups'),
        }}
      />

      <CustomerFilterableDropdown
        title={'Referrer(Optional)'}
        defaultSelectedCustomer={watchReferrerUser}
        selectCustomer={(value) => {
          setValue('referrerUser', value, { shouldDirty: true });
        }}
      />

      {/* <CustomTitleWithDropDown
        title={'Referred by campaign(Optional)'}
        source={campaignList}
        defaultValue={referrerCampaign}
        setValue={setReferrerCampaign}
      /> */}

      <CustomTitleWithSwitch
        title={'Is forced inactive'}
        defaultValue={watchIsForcedInactive}
        setValue={(value) => {
          setValue('isForcedInactive', value, { shouldDirty: true });
        }}
      />
    </div>
  );
}

const mapPropsToState = (state) => ({
  user: state.users,
});

export default connect(mapPropsToState)(MembershipInfoEditCard);

