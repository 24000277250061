import React, { useEffect, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import ChangePasswordPrompt from 'components/admin/ChangePasswordPrompt';
import { SectionTopTitle } from 'components/base/CommonComponent';
import { FieldControl } from 'components/base/CommonComponent';
import CustomRadios from 'components/base/CustomRadios';
import { ReactHookFormErrorMessage } from 'components/base/ErrorFieldMessage';
import InputFieldControl from 'components/base/InputFieldControl';
import BasePrompt from 'components/base/prompt/BasePrompt';
import BrandSelectDropdown from 'components/campaign/campaignCreation/BrandSelectDropdown';
import { CustomTitleLabel } from 'components/earning/CustomBaseComponments';
import StoreSelectDropdown from 'components/transactions/StoreSelectDropdown';
import { ADMINISTRATOR_TYPES } from 'config/CustomEnums';
import { PermissionCodes } from 'config/PermissionCodes';
import { AdminErrorHandleFields } from 'containers/admin/admins/AdminErrorHandleFields';
import { ToastType } from 'models/NavBarModel';

import { createAction } from 'utils';

export const ADMINISTRATOR_TYPES_CONFIG = [
  {
    label: 'CMS Admin Users',
    value: ADMINISTRATOR_TYPES.CMS_ADMINISTRATOR,
  },
  {
    label: 'Merchant App Users',
    value: ADMINISTRATOR_TYPES.MERCHANT_APP_ACCOUNT,
  },
];

const ChangePasswordCom = ({ changeAction }) => {
  return (
    <div className="d-flex flex-column">
      <CustomTitleLabel title="Password" />
      <label className="tips-message">
        Raw passwords are not stored, so there is no way to see this user's
        password.
      </label>
      <label className="tips-message">{'*'.repeat(10)}</label>
      <Button className="btn-back-button-common" onClick={changeAction}>
        Change
      </Button>
    </div>
  );
};

function GeneralSections({
  adminName,
  adminType,
}) {
  const params = useParams();
  const { id } = params;
  const [showChangePassPrompt, setShowChangePassPrompt] = useState(false);
  const { watch, setValue, formState: { errors } } = useFormContext();
  const watchName = watch('name');
  const watchPassword = watch('password');
  const watchConfirmPassword = watch('confirmPassword');
  const watchAdminType = adminType;
  const watchBrand = watch('brand');
  const watchStore = watch('store');
  const history = useHistory();
  const dispatch = useDispatch();
  const [showCreateBrandPrompt, setShowCreateBrandPrompt] = useState(false);
  const [refreshDate, setRefreshDate] = useState(new Date());

  useEffect(() => {
    if (watchBrand?.pk > 0) {
      dispatch(
        createAction('storeModel/getPagedStoreList')({
          brand: watchBrand.pk,
          isSimpleNode: true,
          isOnlineStore: false,
          isSelectorLoad: true,
          search: '',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchBrand])

  const passwordRex = /\D/g;
  const nameSection = (
    <InputFieldControl
      name={'name'}
      title={'Name'}
      rules={{ required: AdminErrorHandleFields.required.username }}
      value={watchName}
      setValue={(value) => {
        setValue('name', value, { shouldDirty: true });
      }}
    />
  );
  const passwordSection = (
    <InputFieldControl
      name={'password'}
      title={'Password'}
      autoComplete="new-password"
      tips="Password at least 8 characters, can't be entirely numeric."
      type="password"
      rules={{
        validate: {
          lessThanEight: (value) => {
            return (
              value?.length >= 8 ||
              AdminErrorHandleFields.others.password.length
            );
          },
          allNumeric: (value) => {
            return (
              passwordRex.test(value) ||
              AdminErrorHandleFields.others.password.numeric
            );
          },
        },
      }}
      value={watchPassword}
      setValue={(value) => {
        setValue('password', value, { shouldDirty: true });
      }}
    />
  );
  const confirmPasswordSection = (
    <InputFieldControl
      name={'confirmPassword'}
      title={'Password confirmation'}
      tips="Enter the same password as before, for verification"
      type="password"
      autoComplete="new-password"
      rules={{
        validate: {
          lessThanEight: (value) => {
            return (
              value?.length ||
              AdminErrorHandleFields.required.passwordConfirmation
            );
          },
          allNumeric: (value) => {
            if (watchPassword !== value) {
              setValue('password', '', { shouldDirty: true });
              setValue('confirmPassword', '', { shouldDirty: true });
              dispatch({
                type: 'navBars/updateState',
                payload: {
                  saveDiscardToastShowing: {
                    value: true,
                    type: ToastType.passwordConfirmation,
                  },
                },
              });
            }
            return (
              watchPassword === value ||
              'Confirmation password should be same with password entered before.'
            );
          },
        },
      }}
      value={watchConfirmPassword}
      setValue={(value) => {
        setValue('confirmPassword', value, { shouldDirty: true });
      }}
    />
  );
  const setRelatedBrands = (value) => {
    setValue('brand', value?.value || undefined, { shouldDirty: true })
    if (!value?.value?.pk) {
      dispatch(createAction('storeModel/clearData')())
    }
    setValue('store', undefined, { shouldDirty: true })
  }
  const setRelatedStore = (value) => {
    setValue('store', value || undefined, { shouldDirty: true })
  }
  const relatedBrandSection = (
    <FieldControl
      name="brand"
      rules={{ required: AdminErrorHandleFields.required.brand }}
      render={() => (<>
        <BrandSelectDropdown
          title='Related brand'
          defaultValue={{
            label: watchBrand?.name,
            value: watchBrand
          }}
          onSelectChange={setRelatedBrands}
          addButton={{
            action: () => {
              setShowCreateBrandPrompt(true);
            },
            title: 'Create Now',
            requires: PermissionCodes.addBrand,
            customClass: 'general-section-add-new-brand btn-add-button-common',
          }}
          errors={Object.keys(errors)}
          errorName={'brand'}
          editable={true}
          defaultFilter={{
            isSimpleNode: true
          }}
          refreshDate={refreshDate}
        />
        <BasePrompt
          show={showCreateBrandPrompt}
          closeAction={() => {
            setShowCreateBrandPrompt(false)
          }}
          leftButton={{
            text: 'Done and refresh',
            action: () => {
              setRefreshDate(new Date());
              setShowCreateBrandPrompt(false);
            },
          }}
          rightButton={{
            text: `Go to create brand`,
            action: () => {
              window.open('/brands/create', '_blank');
            },
          }}
          title={`Go to create a brand?`}
          description={`You will leave the coupon creation process. After you create a new brand, you can back to this page.`}
        />
      </>
      )}
    />
  )
  const relatedStoreSection = (
    <FieldControl
      name="store"
      rules={{ required: AdminErrorHandleFields.required.store }}
      render={() => (
        <StoreSelectDropdown
          title='Related store'
          selectedStore={{
            displayStoreName: watchStore?.displayStoreName,
            value: watchStore,
          }}
          selectStore={setRelatedStore}
          hideAddButton={false}
          addButtonRequires={PermissionCodes.addStore}
          defaultFilter={{
            brand: watchBrand?.pk || -1,
            isSimpleNode: true,
            isOnlineStore: false
          }}
          errors={Object.keys(errors)}
          errorName={'store'}
        />
      )}
    />
  )
  const convertAdminType = (adminType) => {
    if (!adminType) {
      return '-'
    }
    const displayAdminType = ADMINISTRATOR_TYPES_CONFIG.find((item) => item.value === adminType)
    return displayAdminType?.label || '-'
  };
  return (
    <>
      <SectionTopTitle title="General" />
      {nameSection}
      {id ? (
        <div className="d-flex flex-column">
          <CustomTitleLabel title="Administrator type" />
          <label className="tips-message">
            Once set, cannot be changed.
          </label>
          <label className="custom-record-normal-font custom-timeline-time-color list-new-line">
            {convertAdminType(watchAdminType)}
          </label>
        </div>
      ) : null}

      {watchAdminType === ADMINISTRATOR_TYPES.MERCHANT_APP_ACCOUNT ? (
        <>
          {relatedBrandSection}
          {relatedStoreSection}
        </>
      ) : null}
      {id ? (
        <ChangePasswordCom changeAction={() => setShowChangePassPrompt(true)} />
      ) : (
        <>
          {passwordSection}
          {confirmPasswordSection}
        </>
      )}

      <ChangePasswordPrompt
        admin={{ username: adminName, pk: id }}
        show={showChangePassPrompt}
        onClose={() => setShowChangePassPrompt(false)}
        onConfirm={() => setShowChangePassPrompt(false)}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  adminName: state.admin.oneAdmin.name,
})
export default connect(mapPropsToState)(GeneralSections);
