import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import AuthButton from 'components/base/AuthButton';
import CustomBreadcrumb from 'components/base/CustomBreadcrumb';
import CustomListComponent from 'components/base/CustomListComponent';
import {
  CouponURLFragments,
  CouponURLQueryKeys,
} from 'components/coupon/CouponUrlConfig';
import GenerateCouponView from 'components/coupon/couponCreation/GenerateCouponView';
import CouponTemplateOverview from 'components/coupon/couponList/CouponTemplateOverview';
import SingleCouponList from 'components/coupon/couponList/SingleCouponList';
import { couponSetDetailBreads } from 'config/CustomBreadConfig';
import { COUPON_SET_MEDIUM, COUPON_SET_TYPES } from 'config/CustomEnums';
import { PermissionCodes } from 'config/PermissionCodes';

import { createAction, getHashKeyString } from 'utils';

const queryString = require('query-string');
@connect(({ couponList, users }) => ({
  ...couponList,
  isSuperuser: users.isSuperuser,
  userPermissions: users.userPermissions,
}))
class CouponSetDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
    };
    this.couponSetPK = props.match.params.id;
  }
  componentDidMount() {
    this.props.dispatch(
      createAction('couponList/getCouponSet')({
        couponSetPK: this.props.match.params.id,
        notSaveToSession: true,
      }),
    );
  }

  componentWillUnmount() {
    this.props.dispatch({ type: 'couponList/clearData' });
    this.props.dispatch({ type: 'singleCoupon/clearData' });
  }
  getImportActionButtonTitle = () => {
    let couponeSetTypePrefix = 'offline';
    if (this.props.couponSet?.mediumType === COUPON_SET_MEDIUM.ONLINE) {
      couponeSetTypePrefix = 'online';
    }
    if (
      this.props.couponSet?.[`${couponeSetTypePrefix}CouponSetType`] ===
      COUPON_SET_TYPES.IMPORT_EXISTING_COUPON
    ) {
      return 'Import Coupons';
    }
    return 'Generate Coupons';
  };

  render() {
    const tabs = [
      {
        name: CouponURLFragments.overview,
        content: <CouponTemplateOverview couponSet={this.props.couponSet} />,
      },
      this.props.isSuperuser ||
      this.props.userPermissions.filter(
        (val) => val.split('_')[1] === PermissionCodes.coupon,
      ).length > 0
        ? {
            name: CouponURLFragments.list,
            content: <SingleCouponList couponSet={this.props.couponSet} />,
          }
        : {},
    ];

    const buttons = [
      this.props.couponSet?.offlineCouponSetType ||
      this.props.couponSet?.onlineCouponSetType ? (
        <AuthButton
          title={this.getImportActionButtonTitle()}
          customClass="btn-back-button-common btn-download"
          action={() => {
            this.setState({ modalShow: true });
            this.props.dispatch(
              createAction('createCoupon/updateGenerateCouponState')({
                showGenerateCouponPop: true,
                cleanGenerateCoupon: true,
                // notSaveToSession: true,
              }),
            );
          }}
          requires={PermissionCodes.addCoupon}
        />
      ) : null,
      <AuthButton
        title="Edit"
        action={() => {
          // this.props.dispatch(
          //   createAction('createCoupon/updateCouponTemplateWithSetData')(
          //     this.props.couponSet,
          //   ),
          // );
          this.props.history.push({
            pathname: `/coupons/${this.couponSetPK}/edit`,
            state: {
              isFromEdit: true,
              couponSetID: this.props.couponSet.id,
            },
          });
        }}
        requires={PermissionCodes.changeCouponTemplate}
      />,
    ];
    const defaultActiveKey =
      getHashKeyString(this.props.location.hash) || CouponURLFragments.overview;
    return (
      <>
        <CustomListComponent
          breadcrumb={
            <CustomBreadcrumb
              breadcrumbs={couponSetDetailBreads(this.props.couponSet.name)}
            />
          }
          defaultActiveKey={defaultActiveKey}
          onTabChange={(key) => {
            let search = '';
            if (key !== CouponURLFragments.overview) {
              search = queryString.stringify({
                [CouponURLQueryKeys.page]: 1,
                [CouponURLQueryKeys.searchKey]: '',
                [CouponURLQueryKeys.rank]: true,
              });
            }
            this.props.history.push({
              pathname: `/coupons/${this.props.match.params.id}`,
              hash: key,
              search: search,
            });
          }}
          buttons={buttons}
          tabs={tabs}
          title={this.props.couponSet?.name}
        />
        <GenerateCouponView toSingleCouponList={true} />
      </>
    );
  }
}

export default withRouter(CouponSetDetail);
