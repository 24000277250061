import React from 'react';

import CustomTitleLabelWithEditorBar from 'components/base/CustomTitleLabelWithEditorBar';
import ProfileTextField from 'components/customer/additionalData/ProfileTextField';

const ProfileOtherFieldSection = ({
  otherFields,
  newUserCustomData,
  setNewUserCustomData,
}) => {
  const setValue = (v, fieldName) => {
    const value = {
      ...newUserCustomData,
    };
    value[fieldName] = v;
    setNewUserCustomData(value);
  };
  if (otherFields?.length > 0) {
    return (
      <>
        <CustomTitleLabelWithEditorBar title={'Other fields'} />
        {otherFields?.map((fieldName) => {
          const defaultValue = newUserCustomData?.[fieldName];
          return (
            <ProfileTextField
              key={fieldName}
              fieldName={fieldName}
              displayTitle={fieldName}
              defaultValue={defaultValue}
              setValue={(v) => setValue(v, fieldName)}
            />
          );
        })}
      </>
    );
  }
  return null;
};

export default ProfileOtherFieldSection;
