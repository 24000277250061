import React, { useMemo } from 'react';

import CustomTitleWithInput from 'components/base/CustomTitleWithInput';
import {
  ReactHookFormErrorMessage,
  errorMessage,
  hasError,
} from 'components/base/ErrorFieldMessage';

const ProfileTextField = ({
  fieldName,
  displayTitle,
  mandatory,
  isNumeral,
  defaultValue,
  setValue,
}) => {
  const error = useMemo(() => {
    if (mandatory && !defaultValue) {
      return {
        id: fieldName,
        error: true,
        message: 'Mandatory field',
      };
    }
    return {};
  }, [defaultValue, mandatory, fieldName]);
  return (
    <>
      <CustomTitleWithInput
        title={displayTitle}
        per={'Key'}
        type={'text'}
        defaultValue={fieldName}
        disabled
        buttonNewLine
      />
      <CustomTitleWithInput
        per={'Value'}
        type={isNumeral ? 'number' : 'text'}
        setValue={setValue}
        defaultValue={defaultValue}
        error={error}
        buttonNewLine
      />
    </>
  );
};

export default ProfileTextField;
