import React from 'react';
import { useFormContext } from 'react-hook-form';

import CustomTitleWithInput from '../base/CustomTitleWithInput';
import { hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import '../../containers/customers/customer/CustomerOverviewDetail.scss';
import { listItem } from '../../components/banners/DisplayItems';
import {
  CustomTitleWithDropDownAndInput,
} from './CustomTitleWithDropDown';
import { SIGN_UP_METHOD } from 'config/CustomEnums';
import { EmailSection, MobileSection } from 'components/customerDetail/ContactSection';
import { connect } from 'react-redux';


function ContactInfoEditCard({
  errorsList,
  title
}) {

  const { watch, setValue, formState } = useFormContext();
  const { errors } = formState;

  const phoneCountryCodeField = 'mobilePhoneNumberCountryCode';
  const phoneNumberField = 'mobilePhoneNumberSubscriberNumber';
  const emailField = 'emailAddress';
  // const socialMediaField = 'socialMedia';
  // const socialMediaInputField = 'socialMediaInput';
  const addressField = 'address';
  const typeField = 'signUpMethod';
  const watchMobilePhoneNumberCountryCode = watch(phoneCountryCodeField);
  const watchMobilePhoneNumberSubscriberNumber = watch(phoneNumberField);
  const watchEmailAddress = watch(emailField);
  // const watchSocialMedia = watch(socialMediaField);
  // const watchSocialMediaInput = watch(socialMediaInputField);
  const watchAddress = watch(addressField);
  const watchType = watch(typeField);
  const isTypeEmail = [SIGN_UP_METHOD.EMAIL_ADDRESS, SIGN_UP_METHOD.MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS].includes(watchType);
  const isTypePhone = [SIGN_UP_METHOD.MOBILE_PHONE_NUMBER, SIGN_UP_METHOD.MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS].includes(watchType);
  const mobileNumber = watch("mobileNumber");
  const signUpMethodDisplay = watch('signUpMethodDisplay');

  return (
    <>
      <label className="create-section-title">{title}</label>

      {listItem('Sign up Method', signUpMethodDisplay)}

      {
        watchType === SIGN_UP_METHOD.EMAIL_ADDRESS
          ? <>
            <EmailSection
              signUpMethod={watchType}
              email={watchEmailAddress}
              setEmail={(value) => {
                setValue(emailField, value, { shouldDirty: true });
              }}
              errors={errorsList}
              formErrors={errors}
            />
            <MobileSection
              signUpMethod={watchType}
              countryCode={watchMobilePhoneNumberCountryCode}
              setCountryCode={(value) => {
                setValue(phoneCountryCodeField, value, { shouldDirty: true });
              }}
              subscriberNumber={watchMobilePhoneNumberSubscriberNumber}
              setSubscriberNumber={(value) => {
                setValue(phoneNumberField, value, { shouldDirty: true });
              }}
              errors={errorsList}
              formErrors={errors}
            />
          </>
          : <>
            <MobileSection
              signUpMethod={watchType}
              countryCode={watchMobilePhoneNumberCountryCode}
              setCountryCode={(value) => {
                setValue(phoneCountryCodeField, value, { shouldDirty: true });
              }}
              subscriberNumber={watchMobilePhoneNumberSubscriberNumber}
              setSubscriberNumber={(value) => {
                setValue(phoneNumberField, value, { shouldDirty: true });
              }}
              errors={errorsList}
              formErrors={errors}
            />
            <EmailSection
              signUpMethod={watchType}
              email={watchEmailAddress}
              setEmail={(value) => {
                setValue(emailField, value, { shouldDirty: true });
              }}
              errors={errorsList}
              formErrors={errors}
            />
          </>
      }

      {/* <CustomRadioWithTitleAndRelated
        title="Social media (optional)"
        options={[
          { label: 'Facebook', value: 'Facebook' },
          { label: 'Wechat', value: 'Wechat' },
        ]}
        defaultValue={watchSocialMedia}
        setValue={(value) => {
          setValue(socialMediaField, value, { shouldDirty: true });
        }}
        related={{
          type: 'input',
          defaultValue: watchSocialMediaInput,
          setValue: (value) => {
            setValue(socialMediaInputField, value, { shouldDirty: true });
          },
        }}
      /> */}

      <CustomTitleWithInput
        title={'Address (optional)'}
        defaultValue={watchAddress}
        useDefaultValue={false}
        setValue={(value) => {
          setValue(addressField, value, { shouldDirty: true });
        }}
        error={{ error: hasError(errors, addressField) }}
      />
      <ReactHookFormErrorMessage errors={errors} id={addressField} />
    </>
  );
}

const mapPropsToState = (state) => ({
  errorsList: state.customerList.errorsList,
});

export default connect(mapPropsToState)(ContactInfoEditCard);

