import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import queryString from 'query-string';

import DeletePrompt from 'components/base/DeletePrompt';
import AuthButton from 'components/base/AuthButton';
import CustomBreadcrumb from 'components/base/CustomBreadcrumb';
import CustomListComponent from 'components/base/CustomListComponent';
import ListButtonsGroup from 'components/base/ListButtonsGroup';
import BaseTabListContainer from 'containers/base/BaseTabListContainer';
import BaseListContainer from 'containers/base/BaseListContainer';
import { DELETE_RELATED_SECTIONS } from 'config/CustomEnums';
import { PermissionCodes } from 'config/PermissionCodes';
import { NavBarNames } from 'config/NavBarNameList';

import { createAction } from 'utils';

import Filter from './Filter';

function AdminList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    listFields,
    dataList,
    pageInfo,
    totalPage,
    totalCount,
    checkedList,
  } = useSelector((state) => ({
    listFields: state.admin.listDisplayFields,
    dataList: state.admin.adminList,
    pageInfo: state.admin.pageInfo,
    totalPage: state.admin.totalPage,
    totalCount: state.admin.totalCount,
    checkedList: state.admin.checkedList,
  }));

  const buttons = [
    <ListButtonsGroup
      hideExtraButtonWidth={580}
      hideAllButtonWidth={580}
      extraButtons={
        <AuthButton
          title="Create Merchant App Users"
          action={() => {
            history.push({
              pathname: '/administrators/create',
              search: queryString.stringify({
                type: '1',
              }),
            });
          }}
          requires={PermissionCodes.addAdministrator}
        />
      }
      primaryButton={
        <AuthButton
          title="Create CMS Admin Users"
          action={() => {
            history.push({
              pathname: '/administrators/create',
              search: queryString.stringify({
                type: '0',
              }),
            });
          }}
          requires={PermissionCodes.addAdministrator}
        />
      }
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        // setShowPrompt(true);
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.deleteAdministrator,
    },
  ];

  const tabs = [
    {
      name: '',
      content: (
        <BaseTabListContainer
          hideTab={true}
          tabs={[]}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          filter={{
            hasFilter: true,
            component: Filter,
          }}
          listContent={
            <BaseListContainer
              fields={listFields}
              dataList={dataList}
              totalPage={totalPage}
              model={'admin'}
              permissionGroup={PermissionCodes.administrator}
              deleteInfo={{
                data: [],
                title: 'administrator',
                relatedName: '',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.ADMIN,
              }}
              hasOtherActions={true}
              actions={['Edit', 'Detail', 'Delete']}
              customActions={{
                deactive: (item) => {
                  dispatch(
                    createAction('admin/updateAdminStatus')({
                      data: {
                        isActive: !item.isActive,
                        id: item.pk,
                      },
                      afterAction: () => history.push(location),
                    }),
                  );
                },
              }}
            />
          }
        />
      ),
    },
  ];

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [activeTab, setActiveTab] = useState(tabs[0].name);

  // useEffect(() => {
  //   dispatch(
  //     createAction('admin/getPagedAdmins')({
  //       page: 0,
  //       reverse: true,
  //       type: 'all',
  //       search: '',
  //     }),
  //   );
  // }, [dispatch]);

  useEffect(() => {
    dispatch({ type: 'admin/clearData' });

    const querySearch = queryString.parse(location.search);
    const searchKey = querySearch['search'];
    const stringRank = querySearch['rank'] || 'true';
    const stringPage = querySearch['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);

    dispatch(
      createAction('admin/getPagedAdmins')({
        ...querySearch,
        page,
        reverse: rank,
        search: searchKey,
      }),
    );
  }, [dispatch, location]);

  return (
    <>
      <CustomListComponent
        caution={{
          detail:
            'The administrator can be used to manage registered users or other administrators with certain permissions',
          title: NavBarNames.admin,
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={true}
        onTabChange={(key) => {
          setActiveTab(key);
          //   let search = '';
          //   if (key !== 'Overview') {
          //     // search = queryString.stringify({
          //     //   [URLFilterKeys.type]: CampaignType.allTypes,
          //     //   [URLFilterKeys.page]: 1,
          //     //   [URLFilterKeys.searchKey]: '',
          //     //   [URLFilterKeys.rank]: true,
          //     // });
          //   }
          //   history.push({ pathname: '/customer', hash: key, search: search });
        }}
      />
      <DeletePrompt
        data={checkedList}
        title={'administrators'}
        relatedName={''}
        relatedSections={DELETE_RELATED_SECTIONS.ADMIN}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('admin/delete')({
              afterAction: () => {
                history.push(location);
              },
            }),
          );
        }}
      />
    </>
  );
}

export default AdminList;
