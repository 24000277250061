import React from 'react';
import { connect, useDispatch } from 'react-redux';
import './GenerateCouponView.scss';
import { createAction, getFileNameFromUrl } from '../../../utils';
import {
  APIStatus,
  COUPON_SET_MEDIUM,
} from '../../../config/CustomEnums';
import CustomFileUploader from '../../base/CustomFileUploader';
import { CouponURLFragments } from '../CouponUrlConfig';
import BasePrompt from '../../base/prompt/BasePrompt';
import { ImportResource } from '../../../models/DownloadImportModel';
import { useHistory } from 'react-router';

const GenerateCouponView = ({
  toSingleCouponList = false,
  showGenerateCouponPop,
  couponTemplate,
  useGenerateCouponInfo = false,
  generateCouponInfo,
}) => {
  const {
    applicableStores,
    brand: relateBrand,
    couponTemplatePk,
    mediumType,
    name: couponTemplateName,
    offlineShouldImportByFile,
    onlineShouldImportByFile,
    shouldBeUsedInStores,
    showOnlineOfflineInput,
    isDifferentCodeForOnlineOffline,
  } = useGenerateCouponInfo ? generateCouponInfo : couponTemplate;


  const dispatch = useDispatch();
  const history = useHistory();

  console.log("@87", showGenerateCouponPop)
  const relateBrandView = () => {
    if (relateBrand) {
      return (
        <div className="second-section">
          <div className="second-title-section">
            <div className="second-section-title">Related brand</div>
          </div>
          <div className="second-section-content">
            {relateBrand.name}
          </div>
        </div>
      );
    }
  };
  console.log("@23", generateCouponInfo)

  const updateGenerateCouponState = (data) => {
    dispatch(
      createAction('createCoupon/updateGenerateCouponState')({...data}),
    );
  }

  const modalOnHide = () => (
    updateGenerateCouponState({ showGenerateCouponPop: false,})
  )

  const cleanGenerateData = () => {
    let generateData = { templateId: couponTemplatePk }
    let relatedFile = null
    if (shouldBeUsedInStores) {
      const couponStoreOptions = generateCouponInfo?.couponStoreOptions
      generateData['couponStoreOptions'] = Object.keys(couponStoreOptions)?.map((storeId) => {
        if (couponStoreOptions[storeId]?.quantity) {
          return { storeId, quantity: couponStoreOptions[storeId]?.quantity }
        }
        if (couponStoreOptions[storeId]?.importFile) {
          return { storeId, importFile: getFileNameFromUrl(couponStoreOptions[storeId]?.importFile) }
        }
        return {};
      })
    } else if (showOnlineOfflineInput ||
      (!showOnlineOfflineInput && [COUPON_SET_MEDIUM.ONLINE, COUPON_SET_MEDIUM.OFFLINE].includes(mediumType))
    ) {
      if (onlineShouldImportByFile) {
        generateData['onlineCouponImportFile'] = getFileNameFromUrl(generateCouponInfo?.onlineCouponImportFile)
      } else {
        generateData['onlineCouponQuantity'] = generateCouponInfo?.onlineCouponQuantity
      }
      if (offlineShouldImportByFile) {
        generateData['offlineCouponImportFile'] = getFileNameFromUrl(generateCouponInfo?.offlineCouponImportFile)
      } else {
        generateData['offlineCouponQuantity'] = generateCouponInfo?.offlineCouponQuantity
      }
    } else {
      if (offlineShouldImportByFile) {
        relatedFile = generateCouponInfo?.couponImportFile
      } else {
        generateData['couponQuantity'] = generateCouponInfo?.couponQuantity
      }
    }
    return { generateData, relatedFile };
  }

  const generateCouponButtonClicked = () => {
    const { generateData: importTaskRelateData, relatedFile } = cleanGenerateData()
    const successAction = () => {
      if (
        history.location.pathname.includes('edit') ||
        toSingleCouponList
      ) {
        history.push({
          pathname: `/coupons/${couponTemplatePk}`,
          hash: CouponURLFragments.list,
        });
      } 
      dispatch({
        type: 'createCoupon/updateState',
        payload: {
          generateCouponStatus: APIStatus.success,
          couponGenerated: true,
        },
      });
      dispatch(createAction('downloadAndImport/showTasks')({}));
    };

    // let errors = {}
    // if (
    //   offlineCouponTemplateType === COUPON_SET_TYPES.SYSTEM_GENERATED ||
    //   (offlineCouponTemplateType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON && offlineCouponCodeType === COUPON_CODE_TYPE.GENERIC)
    // ) {
    //   let from = ImportResource.generateCodeCoupon;
    //   if (
    //     offlineCouponTemplateType ===
    //       COUPON_SET_TYPES.IMPORT_EXISTING_COUPON &&
    //     offlineCouponCodeType === COUPON_CODE_TYPE.GENERIC
    //   ) {
    //     from = ImportResource.generateGenericCoupon;
    //   }
    //   if (!/^\+?\d+$/.test(generateCouponInfo.quantity) || parseInt(generateCouponInfo.quantity) < 0) {
    //     errors["quantity"] = 'Please enter valid quantity.'
    //   }
    //   if (Object.getOwnPropertyNames(errors).length) {
    //     updateGenerateCouponState({
    //       errorMessage: errors,
    //     })
    //     return;
    //   }
    //   dispatch(
    //     createAction('downloadAndImport/importFile')({
    //       from,
    //       related: {
    //         templateId: couponTemplatePk,
    //         couponQuantity: parseInt(generateCouponInfo.quantity),
    //         storeId: generateCouponInfo.currentSelectedStore?.storePK
    //       },
    //       successAction,
    //     }),
    //   );
    //   modalOnHide();
    // } else {
    //   if (Object.getOwnPropertyNames(errors).length) {
    //     updateGenerateCouponState({
    //       errorMessage: errors,
    //     })
    //     return;
    //   }

    dispatch(
      createAction('downloadAndImport/importFile')({
        from: ImportResource.coupon,
        file: { import: relatedFile },
        related: importTaskRelateData,
        successAction,
      }),
    );
    modalOnHide();
  }

  const allStoreGenerateCouponItem = (couponMedium, isByImportFile = false) => {
    let displayStoreType = '';
    let uploadedFileKey = '';
    let getUploadedFileDict = () => { };
    let getInputValue = () => { };
    let setInputValue = () => { };

    switch (couponMedium) {
      case COUPON_SET_MEDIUM.OFFLINE:
        displayStoreType = 'offline'
        uploadedFileKey = 'offlineCouponImportFile'
        getUploadedFileDict = (uploadedFile) => {
          return { offlineCouponImportFile: uploadedFile }
        }
        getInputValue = () => { return generateCouponInfo?.offlineCouponQuantity }
        setInputValue = (value) => {
          dispatch(
            createAction('createCoupon/updateGenerateCouponState')({ offlineCouponQuantity: value }),
          );
        }
        break;
      case COUPON_SET_MEDIUM.ONLINE:
        displayStoreType = 'online'
        uploadedFileKey = 'onlineCouponImportFile'
        getUploadedFileDict = (uploadedFile) => {
          return { onlineCouponImportFile: uploadedFile }
        }
        getInputValue = () => { return generateCouponInfo?.onlineCouponQuantity }
        setInputValue = (value) => {
          dispatch(
            createAction('createCoupon/updateGenerateCouponState')({ onlineCouponQuantity: value }),
          );
        }
        break;
      case COUPON_SET_MEDIUM.ONLIEN_OR_OFFLINE:
        displayStoreType = 'online and offline'
        uploadedFileKey = 'couponImportFile'
        getUploadedFileDict = (uploadedFile) => {
          return {
            couponImportFile: uploadedFile,
          }
        }
        getInputValue = () => { return generateCouponInfo?.couponQuantity }
        setInputValue = (value) => {
          dispatch(
            createAction('createCoupon/updateGenerateCouponState')({ couponQuantity: value }),
          );
        }
        break;
      default:
    }
    return (
      <div className='d-flex generate-coupon-store'>
        <div className='second-section-content'>
          {`All ${displayStoreType} store`}
        </div>
        {isByImportFile ?
          <div>
            <CustomFileUploader
              title="Select files"
              accept=".csv"
              showFileName={true}
              removeUploadedFileAction={() => {
                dispatch(
                  createAction('createCoupon/updateGenerateCouponState')({ [uploadedFileKey]: '' }),
                );
              }}
              afterUploadAction={(uploadedFile) => {
                dispatch(
                  createAction('createCoupon/updateGenerateCouponState')(getUploadedFileDict(uploadedFile?.import)),
                );
              }}
            />
          </div> :
          <input
            className='day-input-fields'
            type="number"
            onChange={(e) => {
              const { value } = e.target
              setInputValue(value > 100000 ? 100000 : value)
            }}
            value={getInputValue()}
            min={1}
            max={100000}
          />
        }
      </div>
    )
  }

  const generateCouponStoreItem = (store, isByImportFile = false) => {
    return (
      <div className='d-flex generate-coupon-store'>
        <div className='second-section-content'>{store?.displayStoreName}</div>
        {isByImportFile ?
          <div>
            <CustomFileUploader
              title="Select files"
              accept=".csv"
              showFileName={true}
              removeUploadedFileAction={() => {
                dispatch(
                  createAction('createCoupon/updateGenerateCouponState')({
                    couponStoreOptions: {
                      ...generateCouponInfo?.couponStoreOptions,
                      [store?.pk]: {}
                    }
                  }),
                );
              }}
              afterUploadAction={(uploadedFile) => {
                dispatch(
                  createAction('createCoupon/updateGenerateCouponState')({
                    couponStoreOptions: {
                      ...generateCouponInfo?.couponStoreOptions,
                      [store?.pk]: { importFile: uploadedFile?.import }
                    }
                  }),
                );
              }}
            />
          </div> :
          <input
            className='day-input-fields'
            type="number"
            onChange={(e) => {
              const { value } = e.target
              dispatch(
                createAction('createCoupon/updateGenerateCouponState')({
                  couponStoreOptions: {
                    ...generateCouponInfo?.couponStoreOptions,
                    [store?.pk]: { quantity: value > 100000 ? 100000 : value }
                  }
                }),
              );
            }}
            value={generateCouponInfo?.couponStoreOptions?.[store?.pk]?.quantity}
            min={1}
            max={100000}
          />
        }
      </div>
    )

  }

  const applicableStoresGenerateView = (stores, isByImportFile) => {
    if (stores?.length < 1) {
      return <div className='second-section-content'>{'No store currently selected'}</div>
    }
    return stores?.map((store) => (
      generateCouponStoreItem(store, isByImportFile)
    ))
  }

  const offlineCouponApplicableStoresGenerateView = () => {
    if (![COUPON_SET_MEDIUM.OFFLINE, COUPON_SET_MEDIUM.ONLIEN_OR_OFFLINE].includes(mediumType)) {
      return;
    }
    let offlineApplicableStores = applicableStores;
    if (mediumType === COUPON_SET_MEDIUM.ONLIEN_OR_OFFLINE) {
      offlineApplicableStores = applicableStores?.filter((item) => !item?.isOnlineStore)
    }
    const isGenerateAllStore = !shouldBeUsedInStores
    const isByImportFile = offlineShouldImportByFile
    return (
      <div>
        <div className='second-section-title generate-coupon-second-title'>
          {'Offline store'}
        </div>
        <div className='base-prompt-tips generate-coupon-second-tips'>
          {isByImportFile ?
            'Please select the .csv files which you want to import.' :
            'Please set the number of coupons to generate. You can set to max 100,000 if there is no specific limit.'
          }
        </div>
        {isGenerateAllStore ?
          allStoreGenerateCouponItem(COUPON_SET_MEDIUM.OFFLINE, isByImportFile) :
          applicableStoresGenerateView(offlineApplicableStores, isByImportFile)
        }
      </div>
    )
  }

  const onlineCouponApplicableStoresGenerateView = () => {
    if (![COUPON_SET_MEDIUM.ONLINE, COUPON_SET_MEDIUM.ONLIEN_OR_OFFLINE].includes(mediumType)) {
      return;
    }
    let onlineApplicableStores = applicableStores;
    if (mediumType === COUPON_SET_MEDIUM.ONLIEN_OR_OFFLINE) {
      onlineApplicableStores = applicableStores?.filter((item) => item?.isOnlineStore)
    }
    const isGenerateAllStore = !shouldBeUsedInStores
    const isByImportFile = onlineShouldImportByFile
    return (
      <div>
        <div className='second-section-title generate-coupon-second-title'>
          {'Online store'}
        </div>
        <div className='base-prompt-tips generate-coupon-second-tips'>
          {isByImportFile ?
            'Please select the .csv files which you want to import.' :
            'Please set the number of coupons to generate. You can set to max 100,000 if there is no specific limit.'
          }
        </div>
        {isGenerateAllStore ?
          allStoreGenerateCouponItem(COUPON_SET_MEDIUM.ONLINE, isByImportFile) :
          applicableStoresGenerateView(onlineApplicableStores, isByImportFile)
        }
      </div>
    )
  }

  const importOrGenerateCodeView = () => {
    switch (mediumType) {
      case COUPON_SET_MEDIUM.OFFLINE:
        return offlineCouponApplicableStoresGenerateView()
      case COUPON_SET_MEDIUM.ONLINE:
        return onlineCouponApplicableStoresGenerateView()
      case COUPON_SET_MEDIUM.ONLIEN_OR_OFFLINE:
        if (showOnlineOfflineInput) {
          return (
            <>
              {onlineCouponApplicableStoresGenerateView()}
              <div style={{ margin: "16px 0" }} className='create-section-split-line' />
              {offlineCouponApplicableStoresGenerateView()}
            </>
          )
        }
        if (!shouldBeUsedInStores && !isDifferentCodeForOnlineOffline) {
          return (
            <div>
              <div className='second-section-title generate-coupon-second-title'>
                {'Both online and offline store'}
              </div>
              <div className='base-prompt-tips generate-coupon-second-tips'>
                {offlineShouldImportByFile ?
                  'Please select the .csv files which you want to import.' :
                  'Please set the number of coupons to generate. You can set to max 100,000 if there is no specific limit.'
                }
              </div>
              {allStoreGenerateCouponItem(COUPON_SET_MEDIUM.ONLIEN_OR_OFFLINE, offlineShouldImportByFile)}
            </div>
          )
        }
        return (
          <>
            {onlineCouponApplicableStoresGenerateView()}
            <div style={{ margin: "16px 0" }} className='create-section-split-line' />
            {offlineCouponApplicableStoresGenerateView()}
          </>
        )
      default:
        return;
    }
  }

  const isConfirmButtonDisabled = () => {
    if (shouldBeUsedInStores) {
      const couponStoreOptions = generateCouponInfo?.couponStoreOptions
      let isDisabled = true
      if (!couponStoreOptions) {
        return true
      }
      Object.values(couponStoreOptions).forEach((couponStoreValue) => {
        if (Boolean(couponStoreValue?.quantity) || Boolean(couponStoreValue?.importFile)) {
          isDisabled = false
        }
      });
      return isDisabled
    } else if (showOnlineOfflineInput) {
      if ((onlineShouldImportByFile && generateCouponInfo?.onlineCouponImportFile)
        || generateCouponInfo?.onlineCouponQuantity) {
        return false
      }
      if ((offlineShouldImportByFile && generateCouponInfo?.offlineCouponImportFile)
        || generateCouponInfo?.offlineCouponQuantity) {
        return false
      }
    } else if (mediumType === COUPON_SET_MEDIUM.ONLINE) {
      if ((onlineShouldImportByFile && generateCouponInfo?.onlineCouponImportFile)
        || generateCouponInfo?.onlineCouponQuantity) {
        return false
      }
    } else if (mediumType === COUPON_SET_MEDIUM.OFFLINE) {
      if ((offlineShouldImportByFile && generateCouponInfo?.offlineCouponImportFile)
        || generateCouponInfo?.offlineCouponQuantity) {
        return false
      }
    } else if (mediumType === COUPON_SET_MEDIUM.ONLIEN_OR_OFFLINE) {
      if ((offlineShouldImportByFile && generateCouponInfo?.couponImportFile)
        || generateCouponInfo?.couponQuantity) {
        return false
      }
    }
    return true
  }

  const otherBody = () => {
    return (
      <>
        {relateBrandView()}
        <div className="second-section">
          <div className="second-title-section">
            <div className="second-section-title">Import/Generate code</div>
          </div>
          <div className='import-generate-code-scrollable-container'>
            {importOrGenerateCodeView()}
          </div>
        </div>
      </>
    );
  };

  return (
    <BasePrompt
      customClass={'generate-modal'}
      show={showGenerateCouponPop}
      closeAction={() => {
        modalOnHide();
        dispatch({
          type: 'uploadFiles/updateState',
          payload: { importedFile: null },
        });
      }}
      rightButton={{
        text: `Confirm`,
        action: generateCouponButtonClicked,
        disabled: isConfirmButtonDisabled(),
      }}
      title={`Import/Generate single coupons`}
      tips={'If the coupon set is in inactive status, all the new generated or imported coupon will also be in inactive status.'}
      backdrop={'static'}
      description={`Import/Generate single coupons by using the coupon set "${couponTemplateName}"`}
      otherBody={otherBody}
    />
  );

}

const mapPropsToState = (state) => ({
  showGenerateCouponPop: state.createCoupon.generateCoupon?.showGenerateCouponPop,
  couponTemplate: {
    applicableStores: state.createCoupon.couponTemplate?.selectedStores || [],
    brand: state.createCoupon.couponTemplate?.selectedBrand,
    couponRedemptionMethod: state.createCoupon.couponTemplate?.offlineRedemptionMethod,
    couponTemplatePk: state.createCoupon.couponTemplate?.pk,
    mediumType: state.createCoupon.couponTemplate?.mediumType,
    name: state.createCoupon.couponTemplate?.name || '',
    offlineCouponCodeType: state.createCoupon.couponTemplate?.offlineCouponCodeType,
    offlineCouponTemplateType: state.createCoupon.couponTemplate?.offlineCouponSetType,
    offlineShouldImportByFile: state.createCoupon.couponTemplate?.offlineShouldImportByFile,
    onlineShouldImportByFile: state.createCoupon.couponTemplate?.onlineShouldImportByFile,
    shouldBeUsedInStores: state.createCoupon.couponTemplate?.shouldBeUsedInStores,
    showOnlineOfflineInput: state.createCoupon.couponTemplate?.showOnlineOfflineInput,
    isDifferentCodeForOnlineOffline: state.createCoupon.couponTemplate?.isDifferentCodeForOnlineOffline,
  },
  generateCouponInfo: state.createCoupon.generateCoupon || {},
})
export default connect(mapPropsToState)(GenerateCouponView);
