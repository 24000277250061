import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SearchIcon from '../../assets/images/drop_down_filter.svg';
import './ListFilterComponent.scss';
import { Image, Form } from 'react-bootstrap';

function ListFilterComponent(props) {
  const queryString = require('query-string');
  const location = useLocation();

  const onSearch = props.onSearch || (() => {});

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const searchKey = form.searchKey.value;
    // eslint-disable-next-line no-useless-escape
    onSearch(searchKey.replace(/\\/g,'\\\\').replace(/\"/g,'\\\"'));
  };
  const [searchKey, setSearchKey] = useState();
  useEffect(() => {
    setSearchKey(queryString.parse(location.search)['search']?.replace(/\\\\/g, '\\').replace(/\\"/g, '"') || '');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const { searchPlaceholder } = props;
  // console.log('@@19: ', defaultValue, searchKey);
  return (
    <Form
      autoComplete="off"
      noValidate
      className={`list-filter-list-search-section ${props.customClass}`}
      onSubmit={handleSubmit}
    >
      <Image src={SearchIcon} className="list-filter-search-icon" />
      <input
        name="searchKey"
        value={searchKey}
        onChange={({ target }) => setSearchKey(target.value)}
        type="text"
        className="list-filter-search-field"
        placeholder={!!searchPlaceholder ? searchPlaceholder : "Search by name"}
      />
    </Form>
  );
}

export default ListFilterComponent;
