import { ImageDomain } from 'config/CustomEnums';
import { BaseHelper } from 'services/BaseGraphQLAPIHelper';

export const PRODUCT_CATEGORY_TEMPLATE_FILE_PATH = `${ImageDomain}import_product_category_template.csv`;

const PRODUCT_CATEGORY_CONTENT = `
id
pk
name
displayOrder
productSubcategories {
  edges {
    node {
      id
      pk
      name
    }
  }
}
linkedPhysicalStoreProductCategories {
  edges {
    node {
      id
      pk
      name
    }
  }
}
linkedPhysicalStoreProductSubcategories {
  edges {
    node {
      id
      pk
      name
    }
  }
}
linkedOnlineStoreCollections
linkedOnlineStoreTags
translations {
  edges {
    node {
      id
      pk
      name
      language
    }
  }
}
`
const SIMPLE_PRODUCT_CATEGORY_CONTENT = `
id
pk
name
displayOrder
productSubcategories {
  edges {
    node {
      id
      pk
      name
    }
  }
}
`
const PRODUCT_SUBCATEGORY_CONTENT = `
id
pk
name
displayOrder
productCategories {
  edges {
    node {
      id
      pk
      name
    }
  }
}
linkedPhysicalStoreProductSubcategories {
  edges {
    node {
      id
      pk
      name
    }
  }
}
linkedOnlineStoreTags
translations {
  edges {
    node {
      id
      pk
      name
      language
    }
  }
}
`

export const getPagedPhysicalStoreCategories = (afterCursor = "", search) => {
  let searchString = ""
  const query = `{
    physicalStoreCategories(first: 20, after:"${afterCursor}"${searchString}) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          name
        }
      }
    }
  }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
}

export const getPagedPhysicalStoreSubcategories = (afterCursor = "", search) => {
  let searchString = ""
  const query = `{
    physicalStoreSubcategories(first: 20, after:"${afterCursor}"${searchString}) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          name
        }
      }
    }
  }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
}

export const getOneProductCategory = (id) => {
  const query = `
  {
    productCategory(id: "${id}") {
     ${PRODUCT_CATEGORY_CONTENT}
    }
  }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
}

export const getPagedProductCategories = (afterCursor = "", search, rank, sort) => {
  let orderBy = rank ? '-pk' : 'pk';;
  if (sort) {
    orderBy = sort;
  }
  let searchString = `, orderBy:"${orderBy}"`
  if (search) {
    searchString += `nameIcontains: "${search}"`
  }

  const query = `{
    productCategories(first: 20, after:"${afterCursor}"${searchString}) {
      totalCount  
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ${PRODUCT_CATEGORY_CONTENT}
        }
      }
    }
  }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
}

export const getOneProductSubcategory = (id) => {
  const query = `
  {
    productSubcategory(id: "${id}") {
      ${PRODUCT_SUBCATEGORY_CONTENT}
    }
  }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
}

export const getPagedProductSubcategories = (afterCursor = "", search, rank, sort) => {
  let orderBy = rank ? '-pk' : 'pk';;
  if (sort) {
    orderBy = sort;
  }
  let searchString = `, orderBy:"${orderBy}"`
  if (search) {
    searchString += `nameIcontains: "${search}"`
  }

  const query = `{
    productSubcategories(first: 20, after:"${afterCursor}"${searchString}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ${PRODUCT_SUBCATEGORY_CONTENT}
        }
      }
    }
  }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
}

export const getAllPhysicalStoreCategory = () => {
  const query = `{
    physicalStoreCategories(first: 100, orderBy: "pk") {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        pk
        name
      }
    }
  }
}`;

  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
}

export const getAllPhysicalStoreSubcategory = () => {
  const query = `{
    physicalStoreSubcategories(first: 100, orderBy: "pk") {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        pk
        name
      }
    }
  }
}`;

  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
}
export const getAllOnlineStoreCollections = (payload) => {
  const input = {}
  if (payload?.search) {
    input.nameIcontains = payload.search
  }
  const query = `
  mutation getOnlineStoreAllCollections($input: GetOnlineStoreCollectionsInput!) {
    getOnlineStoreAllCollections(input: $input) {
      success
      collections
    }
  }
  `;

  const variables = {
    input
  };
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
}

export const getAllOnlineStoreTags = (payload) => {
  const input = {}
  if (payload?.search) {
    input.nameIcontains = payload.search
  }
  const query = `
  mutation getOnlineStoreAllProductTags($input: GetOnlineStoreProductTagsInput!) {
    getOnlineStoreAllProductTags(input: $input) {
      success
      productTags
    }
  }
  `;

  const variables = {
    input: input
  };
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
}

export const getSimpleProductCategoriesAndSubcategories = () => {
  const query = `
  {
    productCategories(first: 100, orderBy: "pk") {
      edges {
        node {
          id
          pk
          name
        }
      }
    }
    productSubcategories(first: 100, orderBy: "pk") {
      edges {
        node {
          id
          pk
          name
        }
      }
    }
  }`;

  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
}

export const duplicateProductCategory = (productCategoryPk) => {
  const query = `
   mutation CopyProductCategory($input: CopyProductCategoryInput!) {
    copyProductCategory(input: $input) {
       success
     }
   }`;
  const variables = {
    input: {
      id: productCategoryPk,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
}

export const duplicateProductSubcategory = (productSubcategoryPk) => {
  const query = `
   mutation CopyProductSubcategory($input: CopyProductSubcategoryInput!) {
    copyProductSubcategory(input: $input) {
       success
     }
   }`;
  const variables = {
    input: {
      id: productSubcategoryPk,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
}

export const createOrUpdateProductCategory = (inputBody) => {
  const actionType = inputBody?.id ? "Update" : "Create"
  const query = `
    mutation ${actionType.toLocaleLowerCase()}ProductCategory($input:${actionType}ProductCategoryInput!) {
      ${actionType.toLocaleLowerCase()}ProductCategory(input: $input) {
        success
      }
    }
  `;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
}

export const deleteProductCategories = (inputBody) => {
  const query = `
  mutation DeleteProductCategories($input: DeleteProductCategoriesInput!) {
    deleteProductCategories(input: $input) {
      success
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
}

export const createOrUpdateProductSubcategory = (inputBody) => {
  const actionType = inputBody?.id ? "Update" : "Create"
  const query = `
    mutation ${actionType.toLocaleLowerCase()}ProductSubcategory($input:${actionType}ProductSubcategoryInput!) {
      ${actionType.toLocaleLowerCase()}ProductSubcategory(input: $input) {
        success
      }
    }
  `;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
}

export const deleteProductSubcategories = (inputBody) => {
  const query = `
  mutation DeleteProductSubcategories($input: DeleteProductSubcategoriesInput!) {
    deleteProductSubcategories(input: $input) {
      success
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
}