import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import AuthButton from 'components/base/AuthButton';
import ErrorFieldMessage, { hasError } from 'components/base/ErrorFieldMessage';
import FilterableDropdown from 'components/base/FilterableDropdown';
import FilterableDropdownWithoutLabel from 'components/base/FilterableDropdownWithoutLabel';
import { CustomTitleLabel } from 'components/earning/CustomBaseComponments';

import { createAction } from 'utils';

import './CustomTitleWithDropDown.scss';

export function CustomTitleWithDropDown({
  alwaysHint = null,
  customClass = '',
  customStyles = { display: 'grid', width: '100%' },
  needFilter,
  placeholder = '',
  defaultValue = null,
  title,
  description,
  source = [],
  labelContainPk = false,
  setValue = () => {},
  errors = [],
  errorName,
  disabled = false,
  onFocus = () => {},
  addButtonInfo = {},
  allType = false,
  disableAutoFocus = false,
  disableDropDown = false,
  loadMoreAction,
  customFilter,
  filterAction,
  defaultFilter = {},
  notShowLabel = false,
  customItem = () => {},
  isReset,
  titleStyle,
  multiple,
  toolTips,
  resetChange,
}) {
  const dispatch = useDispatch();

  const existError = Array.isArray(errors)
    ? errors.includes(errorName || title?.toLowerCase())
    : hasError(errors, errorName || title?.toLowerCase());

  const filterComponent = {};
  filterComponent.name = notShowLabel
    ? FilterableDropdownWithoutLabel
    : FilterableDropdown;

  const onSelectChange = (value) => {
    setValue(value);
  };
  const options = source?.map((item) => {
    return {
      value: item,
      label: customItem(item)
        ? customItem(item)
        : labelContainPk
        ? `[ID: ${item?.pk}] ${item?.name}`
        : item?.name,
    };
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [filterKey, setFilterKey] = useState('');

  useEffect(() => {
    if (loadMoreAction) {
      dispatch(
        createAction(loadMoreAction)({
          page: currentPage,
          search: filterKey,
          searchKey: filterKey,
          isSelectorLoad: true,
          type: allType ? 'AllTypes' : '',
          ...defaultFilter,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isReset && loadMoreAction) {
      setCurrentPage(1);
      dispatch(
        createAction(loadMoreAction)({
          page: 1,
          search: filterKey,
          searchKey: filterKey,
          isSelectorLoad: true,
          type: allType ? 'AllTypes' : '',
          ...defaultFilter,
        }),
      );
      resetChange && resetChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReset]);

  const loadMore = () => {
    if (!loadMoreAction) {
      return;
    }
    const nextPage = currentPage + 1;
    console.log('@@55: ', nextPage, filterKey);
    dispatch(
      createAction(loadMoreAction)({
        page: nextPage,
        search: filterKey,
        searchKey: filterKey,
        isSelectorLoad: true,
        type: allType ? 'AllTypes' : '',
        ...defaultFilter,
      }),
    );

    setCurrentPage(nextPage);
  };

  const filter = (search) => {
    let nextPage = currentPage + 1;
    if (search !== filterKey || (notShowLabel && search === '')) {
      nextPage = 1;
    }
    console.log('@@55: ', search, nextPage);
    dispatch(
      createAction(filterAction)({
        page: nextPage,
        search: search,
        searchKey: search,
        isSelectorLoad: true,
        type: allType ? 'AllTypes' : '',
        ...defaultFilter,
      }),
    );

    setCurrentPage(nextPage);
    setFilterKey(search);
  };

  return (
    <div style={customStyles}>
      {title ? <CustomTitleLabel className={titleStyle} title={title} /> : null}
      {description ? (
        <label className="tips-message">{description}</label>
      ) : null}
      {disabled ? (
        <label className="custom-record-normal-font custom-timeline-time-color">
          {defaultValue?.label}
        </label>
      ) : (
        <>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <filterComponent.name
              alwaysHint={alwaysHint}
              selectChange={onSelectChange}
              isPromptDropdown={true}
              options={options}
              defaultValue={defaultValue}
              needFilter={needFilter}
              customClass={`custom-title-dropdown ${customClass} ${
                existError ? 'error-field' : ''
              }`}
              onToggle={onFocus}
              loadMoreAction={loadMore}
              filterAction={
                typeof filterAction === 'function'
                  ? filterAction
                  : customFilter || filter
              }
              placeholder={placeholder}
              disableAutoFocus={disableAutoFocus}
              disableDropDown={disableDropDown}
              muitipleSelect={multiple}
              toolTips={toolTips}
            />
            <AuthButton {...addButtonInfo} />
          </div>
          <ErrorFieldMessage
            error={existError}
            id={errorName || title?.toLowerCase()}
            message={`Please provide ${errorName || title?.toLowerCase()}`}
            extra={'list-new-line'}
          />
        </>
      )}
    </div>
  );
}

export function CustomTitleWithDropDownAndInput(props) {
  const { needFilter, customClass, unit, inputCustomClass } = props;
  const defaultValue = props.defaultValue || null;
  const defaultInputValue = props.defaultInputValue || null;
  const title = props.title;
  const description = props.description || null;
  const source = props.source || [];
  const setValue = props.setValue || (() => {});
  const setInputValue = props.setInputValue || (() => {});
  const errors = props.errors || [];
  const disabled = props.disabled || false;
  const onFocus = props.onFocus || (() => {});
  const inputOnFocusChange = props.inputOnFocusChange || (() => {});

  const onSelectChange = (value) => {
    setValue(value);
  };
  const options = source?.map((item) => {
    return { value: item.pk, label: item?.name };
  });

  const isError = (field) => {
    return errors.includes(field);
  };

  return (
    <div className="d-flex flex-column">
      <CustomTitleLabel title={title} />
      {description ? (
        <label className="tips-message">{description}</label>
      ) : null}
      {disabled ? (
        <label className="custom-record-normal-font custom-timeline-time-color">
          {defaultValue.label} {defaultInputValue}
        </label>
      ) : (
        <>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <FilterableDropdown
              customClass={`custom-title-dropdown ${customClass} ${
                isError(title.toLowerCase()) ? 'error-field' : ''
              }}`}
              selectChange={onSelectChange}
              isPromptDropdown={false}
              options={options}
              defaultValue={defaultValue}
              needFilter={needFilter}
              onToggle={onFocus}
            />

            <input
              type="number"
              onChange={({ target }) => setInputValue(target.value)}
              className={`custom-number-input-long ${inputCustomClass} ${
                isError(title.toLowerCase()) ? 'error-field' : ''
              }`}
              value={defaultInputValue}
              onFocus={inputOnFocusChange}
            />
            <label className="help-message">{unit}</label>
          </div>
          <ErrorFieldMessage
            error={isError(title.toLowerCase())}
            id={title.toLowerCase()}
            message={`Please provide ${title.toLowerCase()}`}
          />
        </>
      )}
    </div>
  );
}
