import { COUPON_SET_MEDIUM, COUPON_VIRTUAL_REAL_TYPE, EarningRuleTypeKey, LanguageConfig } from 'config/CustomEnums';
import {
  OFFLINE_COUPON_REDEMPTION_METHOD_CONFIG,
  OFFLINE_COUPON_TYPES_CONFIG,
} from 'containers/engagement/coupon/CreateCouponStepOne';
import { parseBrand, parseStore } from 'models/BrandModel';

import { addDomainToImage } from 'utils';

function getCouponTemplateTranslation(node) {
  const language = node.language || LanguageConfig.english;
  let otherPhotos = [];
  if (addDomainToImage(node.detailPhoto1)) {
    otherPhotos.push(addDomainToImage(node.detailPhoto1));
  }
  if (addDomainToImage(node.detailPhoto2)) {
    otherPhotos.push(addDomainToImage(node.detailPhoto2));
  }
  if (addDomainToImage(node.detailPhoto3)) {
    otherPhotos.push(addDomainToImage(node.detailPhoto3));
  }
  if (addDomainToImage(node.detailPhoto4)) {
    otherPhotos.push(addDomainToImage(node.detailPhoto4));
  }
  return {
    [language]: {
      name: node.name,
      shortDescription: node.shortDescription,
      id: node.id,
      pk: node.pk,
      instructionSectionTitle: node.instructionSectionTitle,
      instructionSectionContent: node.instructionSectionContent,
      detailSectionTitle: node.detailSectionTitle,
      detailSectionContent: node.detailSectionContent,
      coverPhoto: addDomainToImage(node.coverPhoto),
      otherPhoto: otherPhotos,
    },
  };
}

export function parseApplicationStore(applicableStores) {
  let selectedStores = applicableStores?.edges;
  if (selectedStores && selectedStores.length > 0) {
    selectedStores = selectedStores.map((store) => parseStore(store));
  }
  return selectedStores;
}

function parseCouponSetAllEarningRules(data) {
  const earningRuleFields = [
    `${EarningRuleTypeKey.GENERAL_PURCHASE}TypeEarningRules`,
    `${EarningRuleTypeKey.MEMBER_REFERRAL}TypeEarningRules`,
    `${EarningRuleTypeKey.BIRTHDAY}TypeEarningRules`,
    `${EarningRuleTypeKey.QR_CODE_SCANNING}TypeEarningRules`,
    `${EarningRuleTypeKey.GPS_CHECK_IN}TypeEarningRules`,
    `${EarningRuleTypeKey.NEW_MEMBER}TypeEarningRules`,
    `fillingFormTypeTypeEarningRules`,
    `${EarningRuleTypeKey.LEVEL_UPGRADE}TypeEarningRules`,
  ]
  let conponSetLinkedEarningRules = []
  earningRuleFields.forEach(earningRuleField=> {
    if (data?.[earningRuleField]?.edges?.length > 0) {
      conponSetLinkedEarningRules = [
        ...conponSetLinkedEarningRules,
        ...data?.[earningRuleField].edges.map(item => item?.node)
      ]
    }
  })
  return conponSetLinkedEarningRules
}

export function parseCouponSet(data) {
  if (!data) {
    return;
  }
  const translationEdges = data.translations?.edges;
  const enTranslation = getCouponTemplateTranslation(data);
  let reducedTranslation = null;
  if (translationEdges) {
    const chineseTranslation = translationEdges.map((item) => {
      return getCouponTemplateTranslation(item.node);
    });
    reducedTranslation = chineseTranslation.reduce(function (obj, item) {
      return {
        ...obj,
        ...item,
      };
    }, {});
  }

  const selectedBrand = data.brand;
  const selectedStores = parseApplicationStore(data.applicableStores);

  const linkedEarningRules = parseCouponSetAllEarningRules(data);

  let parsedCouponSetLinkedEarningCampaign = {}
  linkedEarningRules?.length > 0 && linkedEarningRules.forEach(earningRule => {
    earningRule?.campaigns?.edges?.length > 0 && earningRule.campaigns.edges.forEach(item => {
      const campaign = item.node
      if (!campaign?.pk) {
        return
      }
      if (campaign.pk in parsedCouponSetLinkedEarningCampaign) {
        parsedCouponSetLinkedEarningCampaign[campaign.pk]["earningRules"] = {
          pk: earningRule.pk,
          name: earningRule.name
        }
        return
      }
      parsedCouponSetLinkedEarningCampaign[campaign.pk] = {
        ...campaign,
        earningRules: [{
          pk: earningRule.pk,
          name: earningRule.name
        }]
      }
    })
  })  
  return {
    id: data.id,
    pk: data.pk,
    title: `[ID: ${data.pk}] ${data.name}`,
    name: data.name,
    lastModifiedDate: data.lastModifiedDate,
    displayLastModifiedDate: parseCouponSetListDate(data.lastModifiedDate),
    creationDate: data.creationDate,
    displayCreationDate: parseCouponSetListDate(data.creationDate),
    linkedCampaigns: [
      ...(data.campaigns?.edges?.map(item => item?.node) || []),
      ...Object.values(parsedCouponSetLinkedEarningCampaign)
    ],
    linkedStampCampaigns: data.stampRewards?.edges?.map(item => item?.node?.linkedStampCampaign),
    linkedCampaignsName: data.linkedCampaignsName,
    shortDescription: data.shortDescription,
    applyCoverPhotoToAllLanguage: data.applyCoverPhotoToAllLanguage,
    applyDetailPhotosToAllLanguage: data.applyDetailPhotosToAllLanguage,
    absoluteEffectiveDate: data.absoluteEffectiveDate,
    absoluteExpiryDate: data.absoluteExpiryDate,
    validPeriodValues: data.blackoutPeriod?.edges?.map((item) => {
      return {
        startDate: item.node.startDate,
        endDate: item.node.endDate,
        id: item.node.pk,
      };
    }),
    validWeekday: data.blackoutWeekday,
    status: data.status,
    stock: data.stock,
    onlineStock: data.onlineStock,
    offlineStock: data.offlineStock,
    totalNumberOfGeneratedCoupons: data.totalNumberOfGeneratedCoupons,
    totalNumberOfGeneratedOnlineCoupons:
      data.totalNumberOfGeneratedOnlineCoupons,
    totalNumberOfGeneratedOfflineCoupons:
      data.totalNumberOfGeneratedOfflineCoupons,
    validPeriodType: data.validPeriodType,
    numberOfDaysToExpireAfterAcquisition:
      data.numberOfDaysToExpireAfterAcquisition,
    totalNumberOfAcquiredCoupons: data.totalNumberOfAcquiredCoupons,
    totalNumberOfUsedCoupons: data.totalNumberOfUsedCoupons,
    translations: {
      ...enTranslation,
      ...reducedTranslation,
    },
    selectedBrand: selectedBrand ? parseBrand(selectedBrand) : null,
    selectedStores: selectedStores,
    shouldBeUsedInStores: data.shouldUseInStore,
    isForcedInactive: data.isForcedInactive,
    expiryDate: parseExpiredDate(data),

    offlineRedemptionMethod: data.offlineRedemptionMethod,
    redemptionMethodDisplay:
      OFFLINE_COUPON_REDEMPTION_METHOD_CONFIG.filter(
        (item) => item.value === data.offlineRedemptionMethod,
      )?.[0]?.label || '',
    offlineFormats: data.offlineFormats,
    offlineBarcodeFormat: data?.offlineBarcodeFormat,
    offlineCouponSetType: data.offlineCouponTemplateType,
    couponSetTypeDisplay:
      OFFLINE_COUPON_TYPES_CONFIG.filter(
        (item) => item.id === data.offlineCouponTemplateType,
      )?.[0]?.title || '',
    offlineCouponCodeType: data.offlineCouponCodeType,
    offlineGenericCode: data.offlineGenericCode,
    offlineShowCopiableString: data.offlineShowCopiableString,
    offlineShouldImportByFile: data?.offlineShouldImportByFile,
    mediumType: data.mediumType,
    isDifferentCodeForOnlineOffline: data.isDifferentCodeForOnlineOffline,
    onlineCouponSetType: data.onlineCouponTemplateType,
    onlineRedemptionMethod: data.onlineRedemptionMethod,
    onlineFormats: data.onlineFormats,
    onlineCouponCodeType: data.onlineCouponCodeType,
    onlineGenericCode: data.onlineGenericCode,
    onlineShouldImportByFile: data?.onlineShouldImportByFile,
    onlineAndOfflineFormats: data.offlineFormats || data.onlineFormats,
    onlineAndOfflineCouponCodeType:
      data.offlineCouponCodeType || data.onlineCouponCodeType,
    onlineAndOfflineGenericCode:
      data.offlineGenericCode || data.onlineGenericCode,
    showOnlineOfflineInput: data.showOnlineOfflineInput,
    linkedEarningRules
  };
}

export const parseExpiredDate = (couponTemplate) => {
  if (couponTemplate.validPeriodType === 'ABSOLUTE') {
    const startDate = parseCouponSetListDate(
      couponTemplate.absoluteEffectiveDate,
    );
    const endDate = parseCouponSetListDate(couponTemplate.absoluteExpiryDate);
    return `${startDate} - ${endDate}`;
  } else if (couponTemplate.validPeriodType === 'RELATIVE') {
    return `${couponTemplate.numberOfDaysToExpireAfterAcquisition} days since acquiring`;
  } else {
    return `All time valid`;
  }
};

export function assembleCouponTemplates(couponTemplatesData) {
  return couponTemplatesData.map((data) => {
    return parseCouponSet(data.node);
  });
}

const parseCouponExpiryDate = (couponSet, coupon) => {
  const effectiveDate = coupon.effectiveDate;
  const expiryDate = coupon.expiryDate;
  if (effectiveDate && expiryDate) {
    return `${parseCouponSetListDate(effectiveDate)} - ${parseCouponSetListDate(
      expiryDate,
    )}`;
  }
  if (couponSet?.validPeriodType === 'ALL_TIME') {
    return 'All time valid';
  }
  if (couponSet?.validPeriodType === 'ABSOLUTE') {
    return `${effectiveDate ? parseCouponSetListDate(effectiveDate) : ''} - ${
      expiryDate ? parseCouponSetListDate(expiryDate) : ''
    }`;
  }
  if (couponSet?.validPeriodType === 'RELATIVE') {
    return `${couponSet.numberOfDaysToExpireAfterAcquisition} days since acquiring`;
  }
  return '-';
};

export const parseSingleCoupon = (couponSet, singleCouponData) => {
  const ownerObject = singleCouponData.node.owner;
  let owner = '-';
  if (ownerObject) {
    owner = `${ownerObject.firstName} ${ownerObject.lastName}`;
  }

  const virtualRealType = singleCouponData.node.virtualRealType;
  const couldSelectStore = virtualRealType === COUPON_VIRTUAL_REAL_TYPE.real 
    || virtualRealType === COUPON_VIRTUAL_REAL_TYPE.virtual;
  let couponDisplayId = `For use: ${singleCouponData.node.pk}`;
  if (virtualRealType === COUPON_VIRTUAL_REAL_TYPE.real) {
    if (singleCouponData.node.virtualCoupon?.pk) {
      couponDisplayId += `,\n(For acquire: ${singleCouponData.node.virtualCoupon?.pk})`;
    }
  } else if (virtualRealType === COUPON_VIRTUAL_REAL_TYPE.virtual) {
    couponDisplayId = `For acquire: ${singleCouponData.node.pk}`;
  }

  let selectedStores = parseApplicationStore(
    couponSet.shouldUseInStore
    ? couponSet.applicableStores 
    : couponSet.brand?.stores
  ) || [];

  if (couponSet?.mediumType === COUPON_SET_MEDIUM.OFFLINE) {
    selectedStores = selectedStores.filter(item => !item.isOnlineStore);
  } else if (couponSet?.mediumType === COUPON_SET_MEDIUM.ONLINE) {
    selectedStores = selectedStores.filter(item => item.isOnlineStore);
  };

  return {
    ...singleCouponData,
    id: singleCouponData.node.id,
    pk: singleCouponData.node.pk,
    name: singleCouponData.node.serialNumber,
    serialNumber: singleCouponData.node.serialNumber,
    owner: { ...singleCouponData.node.owner, owner: owner },
    customerName: owner,
    expiryDate: singleCouponData.node.expiryDate,
    displayExpiryDate: parseCouponExpiryDate(couponSet, singleCouponData.node),
    creationDate: singleCouponData.node.creationDate,
    displayCreationDate: parseCouponSetListDate(
      singleCouponData.node.creationDate,
    ),
    lastModifiedDate: singleCouponData.node.lastModifiedDate,
    displayLastModifiedDate: parseCouponSetListDate(
      singleCouponData.node.lastModifiedDate,
    ),
    dateOfPurchase: singleCouponData.node.dateOfPurchase,
    dateOfEarning: singleCouponData.node.dateOfEarning,
    dateOfGrant: singleCouponData.node.dateOfGrant,
    isUsed: singleCouponData.node.isUsed,
    isExpired: singleCouponData.node.isExpired,
    isActive: singleCouponData.node.isActive,
    isForcedInactive: singleCouponData.node.isForcedInactive,
    isEffective: singleCouponData.node.isEffective,
    effectiveDate: singleCouponData.node.effectiveDate,
    store: {
      ...singleCouponData.node.store,
      displayStoreName: singleCouponData.node.store?.name 
        ? `${singleCouponData.node.store?.name}${singleCouponData.node.store?.visibleInFrontEnd ? '' : '(closed)'}` 
        : null
    },
    referenceId: singleCouponData.node.referenceId,
    templateId: couponSet.pk,
    templateName: couponSet.name,
    coverPhoto: couponSet.coverPhoto,
    templateBrand: couponSet.brand?.name,
    selectedStores,
    virtualRealType,
    couldSelectStore,
    couponDisplayId,
  };
};

export const parseCouponSetListDate = (dateString) => {
  if (!dateString || dateString === '') {
    return '';
  }
  const options = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  };
  const date = new Date(dateString);
  const format = new Intl.DateTimeFormat('en', options);
  const [{ value: weekday }, , { value: month }, , { value: day }, ,] =
    format.formatToParts(date);
  const year = date.getFullYear();
  const currentYear = new Date().getFullYear();
  if (currentYear !== year) {
    return `${day} ${month} ${year}`;
  }
  return `${day} ${month} (${weekday})`;
};

export const parseCouponSetOverviewDatetime = (datetimeString) => {
  const datetime = new Date(datetimeString);
  const weekday = new Intl.DateTimeFormat('en', { weekday: 'short' }).format(
    datetime,
  );
  let localeString = datetime.toLocaleString('en');
  localeString = localeString.replace(',', `(${weekday})`);
  return localeString;
};
