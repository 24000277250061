import React from 'react';
import { Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { checkHasPermission } from 'utils';


export const TitleWithButton = ({
  containerExtraClassName,
  title,
  titleExtraClassName,
  firstButtonImage,
  firstButtonContent,
  firstButtonOnClick,
  firstButtonRequires,
  secondButtonImage,
  secondButtonContent,
  secondButtonOnClick,
  secondButtonRequires,
}) => {
  const { user, customer } = useSelector((state) => ({
    user: state.users,
    customer: state.customerList.customer,
  }));

  const hasPermission = (requires) => {
    if (!requires) {
      return true;
    };

    let newRequires = requires;
    if (!Array.isArray(requires)) {
      newRequires = [requires];
    }

    return (
      newRequires.filter((val) => checkHasPermission(user, val)).length > 0
    );
  };


  return (
    <div className={`customer-detail-edit-title-with-button ${containerExtraClassName}`}>
      <label className={`create-section-label customer-detail-edit-title ${titleExtraClassName}`}>{title}</label>
      <div className='customer-detail-edit-button-container'>
        {
          firstButtonContent && hasPermission(firstButtonRequires) && !customer.isDeleted
            ? <button
              type="button"
              class="custom-upload-btn customer-detail-edit-button"
              onClick={firstButtonOnClick}
            >
              {
                firstButtonImage
                  ? <Image src={firstButtonImage} className="customer-detail-edit-image" />
                  : null
              }
              <label className='customer-detail-button-label'>{firstButtonContent}</label>
            </button>
            : null
        }
        {
          secondButtonContent && hasPermission(secondButtonRequires)
            ? <button
              type="button"
              class="custom-upload-btn customer-detail-edit-button-second"
              onClick={secondButtonOnClick}
            >
              {secondButtonImage && <Image src={secondButtonImage} className="customer-detail-edit-image" />}
              <label className='customer-detail-button-label'>{secondButtonContent}</label>
            </button>
            : null
        }
      </div>
    </div>
  )
}
