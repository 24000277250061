import { BaseHelper } from 'services/BaseGraphQLAPIHelper';

import { getInFilterValue } from 'utils';

export const ADMIN_PAGE_SIZE = 20;


const listFieldNode = `
node {
  id
  pk
  adminType
  store {
    id
    pk
    name
    visibleInFrontEnd
  }
  brand {
    id
    pk
    name
  }
  username
  firstName
  lastName
  email
  isActive
  dateJoined
  isStaff
  lastLogin
  isSuperuser
  userPermissions(first: 10) {
    edges {
      node {
        id
        pk
        name
        codename
      }
    }
  }
  groups(first: 10) {
    edges {
      node {
        id
        pk
        name
      }
    }
  }
}
`
const simpleListFieldNode = `
node {
  id
  pk
  adminType
  store {
    id
    pk
    name
    visibleInFrontEnd
  }
  brand {
    id
    pk
    name
  }
  username
  firstName
  lastName
  email
  isActive
  dateJoined
  isStaff
}
`
export const getPagedAdmins = (afterCursor, search = {}) => {
  let searchString = `, orderBy: "${search.reverse ? '-pk' : 'pk'}"`;
  if (search?.sort) {
    searchString = `, orderBy: "${search.sort}"`;
  }
  if (search.search) {
    searchString += `, nameIcontains: "${search.search}"`;
  }
  if (search.admin_type) {
    searchString += `, adminTypeIn: ${getInFilterValue(search.admin_type)}`;
  }
  if (search.status) {
    searchString += `, isActive: ${search.status}`;
  }

  const query = `{
    administrators(first: ${ADMIN_PAGE_SIZE}, after:"${afterCursor}"${searchString}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        ${listFieldNode}
      }
    }
  }
  `;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getAllAdmins = () => {
  const query = `
  {
    administrators(first: 100) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        ${listFieldNode}
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteAdmin = (pks) => {
  const query = `mutation DeleteAdministrators($input: DeleteAdministratorsInput!) {
    deleteAdministrators(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ids: pks,
    },
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateAdminStatus = (isActive, pk) => {
  const query = ``;

  const variables = {
    input: {
      id: pk,
    },
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const duplicateAdmin = (pk) => {
  const query = ``;

  const variables = {
    input: {
      id: pk,
    },
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneAdmin = (id) => {
  const query = `{
    administrator(id: "${id}") {
      id
      pk
      adminType
      store {
        id
        pk
        name
        visibleInFrontEnd
      }
      brand {
        id
        pk
        name
      }
      username
      firstName
      lastName
      email
      isActive
      dateJoined
      isStaff
      lastLogin
      isSuperuser
      groups(first: 20) {
        edges {
          node {
            id
            pk
            name
            permissions(first: 100) {
              edges {
                node {
                  id
                  pk
                  name
                  codename
                  adminGroupName
                }
              }
            }
          }
        }
      }
      userPermissions(first: 100) {
        edges {
          node {
            id
            pk
            name
            codename
          }
        }
      }


    }
  }
  `;

  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateOrCreateAdmin = (values) => {
  let query = `mutation CreateAdministrator($input: CreateAdministratorInput!) {
    createAdministrator(input: $input) {
      ${simpleListFieldNode}
      errors {
        field
        messages
      }
    }
  }
  `;

  if (values.id) {
    query = `mutation UpdateAdministrator($input: UpdateAdministratorInput!) {
      updateAdministrator(input: $input) {
        ${simpleListFieldNode}
        errors {
          field
          messages
        }
      }
    }

    `;
  }
  console.log(values);
  const variables = {
    input: values,
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const changePassword = (values) => {
  const codeNode = `
  node {
    code
  }`
  const query = `mutation ChangeAdministratorPassword($input: ChangeAdministratorPasswordInput!) {
    changeAdministratorPassword(input: $input) {
      success
      ${values?.needLogin ? codeNode : ''}
      errors {
        field
        messages
      }
    }
  }
  `;

  const variables = { input: values };

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getAdminLoginMethod = (username) => {
  const query = `{ 
    administrator2fas(nameExact: "${username}") {
      edges{
        node{
          pk
          methods
        } 
      } 
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const sendOtpCode = (values) => {
  const query = `mutation SendOTPCode($input: SendOTPCodeInput!){
    sendOtpCode(input: $input) { 
      success
      errors{
        field 
        messages
      } 
    } 
  }`;
  const variables = { input: values };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
