import { BaseHelper } from 'services/BaseGraphQLAPIHelper';

export const getLevels = (payload) => {
  const query = `{
    levels(first: 10, orderBy:"pk") {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          levelName
          translations(first: 10) {
            edges {
              node {
                levelName
              }
            }
          }
          segmentlevelcharacteristicSet(first: 10) {
            edges {
              node {
                conditionGroup {
                  id
                  pk
                  displayOrder
                  segment {
                      pk
                      name
                      customers(first: 10) {
                          edges {
                              node {
                                  id
                                  pk
                                  firstName
                                  lastName
                              }
                          }
                      }
                  }
                }
              }
            }
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

const NODE_CONTENT = `{
  id
  pk
  discount
  freeDeliveryOnShopfify
  level {
    id
    pk
    levelName
  }
}`;


export const getLevelDiscountList = (afterCursor, moreSearch) => {
  let filter = '';
  if (moreSearch.filterName || moreSearch.search) {
    filter = `, nameIcontains:"${moreSearch.filterName || moreSearch.search}"`;
  }

  let orderBy = moreSearch.rank ? '-pk' : 'pk';

  const query = `{
    levelDiscounts(first: ${moreSearch?.isAll ? 100 : 20}, after:"${afterCursor}", orderBy:"${orderBy}"${filter}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node ${NODE_CONTENT}
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getLevelDiscount = (id) => {
  const query = `{
    levelDiscount(id: "${id}") ${NODE_CONTENT}
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateLevelDiscount = (values) => {
  const action = 'Update';
  const query = `
    mutation ${action}LevelDiscount($input: ${action}LevelDiscountInput!) {
      ${action.toLowerCase()}LevelDiscount(input: $input) {
        success
        node ${NODE_CONTENT}
        errors {
          field
          messages
        }
      }
    }
  `;
  const variables = { input: values };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
