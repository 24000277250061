import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import queryString from 'querystring';

import AuthButton from 'components/base/AuthButton';
import CustomBreadcrumb from 'components/base/CustomBreadcrumb';
import CustomListComponent from 'components/base/CustomListComponent';
import ListButtonsGroup from 'components/base/ListButtonsGroup';
import { PermissionCodes } from 'config/PermissionCodes';
import BaseListContainer from 'containers/base/BaseListContainer';
import BaseTabListContainer from 'containers/base/BaseTabListContainer';
import Filter from 'containers/record/bulkRewardRecords/Filter';
import { ImportResource } from 'models/DownloadImportModel';
import { TEMPLATE_FILE_PATH } from 'services/BulkRewardRecordApiHelp';

const mapPropsToState = (state) => ({
  displayFields: state.bulkReward.listDisplayFields,
  dataList: state.bulkReward.pagedList,
  pageInfo: state.bulkReward.pageInfo,
  totalCount: state.bulkReward.totalCount,
  totalPage: state.bulkReward.totalPage,
  importedFile: state.uploadFiles.importedFile,
});

const BulkRewardRecordList = ({
  displayFields,
  dataList,
  totalCount,
  pageInfo,
  totalPage,
  importedFile,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  // const [sortDisabled, setSortDisabled] = useState(false);
  const sortDisabled = false;
  const searchDict = queryString.parse(location.search.slice(1));
  const currentPage = searchDict?.page;
  const searchKey = searchDict?.search;
  const hiddenFileInput = useRef(null);
  const fetchData = () => {
    dispatch({
      type: 'bulkReward/getList',
      payload: {
        page: currentPage,
        searchKey,
        ...searchDict,
      },
    });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (importedFile) {
      dispatch({
        type: 'downloadAndImport/importFile',
        payload: {
          from: ImportResource.bulkReward,
          file: importedFile,
        },
      });
    }
  }, [importedFile]);

  const exportTemplateAction = () => {
    var eleLink = document.createElement('a');
    eleLink.href = TEMPLATE_FILE_PATH;
    eleLink.style.display = 'none';
    document.body.appendChild(eleLink);
    eleLink.click();
    document.body.removeChild(eleLink);
  };
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    dispatch({
      type: 'uploadFiles/uploadFile',
      payload: {
        file: fileUploaded,
        source: event,
      },
    });
    event.target.value = null;
  };

  const handleImportClick = (event) => {
    hiddenFileInput.current.click();
  };

  const buttons = [
    <ListButtonsGroup
      hideExtraButtonWidth={600}
      hideAllButtonWidth={530}
      extraButtons={[
        <AuthButton
          title="Download Update Form"
          customClass="btn-back-button-common btn-download"
          action={exportTemplateAction}
          isLink
        />,
      ]}
      extraPopContent={[
        {
          requires: PermissionCodes.addExportjob,
          action: exportTemplateAction,
          content: 'Download Update Form',
        },
      ]}
      primaryButton={
        <AuthButton
          title="Batch Update"
          action={handleImportClick}
          requires={[
            PermissionCodes.addImportjob,
            PermissionCodes.changeCoupon,
            PermissionCodes.addCustomerPoints,
            PermissionCodes.removeCustomerPoints,
          ]}
        />
      }
      primaryPopContent={{
        requires: [
          PermissionCodes.addImportjob,
          PermissionCodes.changeCoupon,
          PermissionCodes.addCustomerPoints,
          PermissionCodes.removeCustomerPoints,
        ],
        action: handleImportClick,
        content: 'Batch Update',
      }}
    />,
    <input
      type="file"
      ref={hiddenFileInput}
      onChange={handleChange}
      style={{ display: 'none' }}
      accept=".csv"
    />,
  ];
  const typeTabs = [
    { key: 'all', name: 'All Types' },
    { key: 'ADD_POINTS', name: 'Add Points' },
    { key: 'REMOVE_POINTS', name: 'Remove Points' },
    { key: 'ADD_TPES', name: 'Add Progress Points' },
    { key: 'REMOVE_TPES', name: 'Remove Progress Points' },
    { key: 'ADD_COUPONS', name: 'Add Coupons' },
    { key: 'REMOVE_COUPONS', name: 'Remove Coupons' },
    { key: 'ADD_STAMPS', name: 'Add Stamps' },
    { key: 'REMOVE_STAMPS', name: 'Remove Stamps' },
  ];
  const tabs = [
    {
      name: 'List of Bulk Rewards',
      content: (
        <BaseTabListContainer
          hideTab={false}
          maxTabWidth={300}
          tabs={typeTabs}
          groupActions={[]}
          pageInfo={pageInfo}
          totalCount={totalCount}
          searchPlaceholder={'Search by membership id'}
          filter={{
            hasFilter: true,
            component: Filter,
          }}
          listContent={
            <BaseListContainer
              fields={displayFields}
              dataList={dataList}
              totalPage={totalPage}
              model={'bulkReward'}
              actions={['Detail']}
              hideActios={false}
              permissionGroup={PermissionCodes.bulkReward}
              deleteInfo={{
                data: [],
                title: 'bulkReward',
                relatedName: '',
                conConfirm: {},
              }}
              sortDisabled={sortDisabled}
              rankDisabled
            />
          }
        />
      ),
    },
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          title: 'Bulk rewards update',
          detail: 'Bulk rewards update via import csv file.',
        }}
        buttons={buttons}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
      />
    </>
  );
};

export default connect(mapPropsToState)(BulkRewardRecordList);
