import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { createAction } from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { ADMINISTRATOR_TYPES } from '../../../config/CustomEnums';

function AdminDetail() {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const { admin } = useSelector((state) => ({
    admin: state.admin.oneAdmin || {},
  }));

  const getSections = () => {
    const stateFields = [[{ title: 'Active', field: 'displayActive' }]]
    const generalFields = [
      [{ title: 'Name', field: 'username' }],
      [{ title: 'Admin type', field: 'displayAdminType' }],
      [{ title: 'Password', field: 'displayPassword' }],
    ]
    if (admin.adminType === ADMINISTRATOR_TYPES.MERCHANT_APP_ACCOUNT) {
      generalFields.splice(
        2,
        0,
        [{ title: 'Related brand', field: 'displayBrandName' }],
        [{ title: 'Related store', field: 'displayStoreName' }]
      )
    } else {
      stateFields.push([{ title: 'Superadmin status', field: 'displayIsSuper' }])
    }
    let sections = [
      <MembershipInfoCard
        title={'General'}
        data={admin}
        fields={generalFields}
      />,
      <MembershipInfoCard
        title={'Personal Information'}
        data={admin}
        fields={[
          [{ title: 'First name', field: 'firstName' }],
          [{ title: 'Last name', field: 'lastName' }],
          [{ title: 'Email address', field: 'email' }],
        ]}
      />,
      <MembershipInfoCard
        title={'Status'}
        data={admin}
        fields={stateFields}
      />,
      <MembershipInfoCard
        title={'Important dates'}
        data={admin}
        fields={[
          [{ title: 'Last login', field: 'displayLastLogin' }],
          [{ title: 'Date joined', field: 'displayDateJoined' }],
        ]}
      />,
    ];

    if (!admin.isSuperuser && admin.adminType === ADMINISTRATOR_TYPES.CMS_ADMINISTRATOR) {
      sections.splice(
        3,
        0,
        <MembershipInfoCard
          title={'Permission'}
          data={admin}
          fields={[
            [{ title: 'Permission Groups', field: 'displayGroups' }],
            [
              {
                title: 'Administrator permission',
                field: 'displayPermissions',
              },
            ],
          ]}
        />,
      );
    }

    return sections;
  };

  const tabs = [
    {
      name: 'Detail',
      content: (
        <div className="scroll-container-common">
          <ContentSections
            sections={getSections()}
            hidePreview={true}
          // sectionStyle="detail-info-section-item"
          />
        </div>
      ),
    },
  ];

  const buttons = [
    <AuthButton
      title="Edit"
      action={() => {
        dispatch({ type: 'admin/clearData' });
        history.push({
          pathname: 'edit/',
        });
      }}
      requires={PermissionCodes.changeAdministrator}
    />,
  ];

  useEffect(() => {
    dispatch({ type: 'admin/clearData' });
  }, [dispatch]);

  useEffect(() => {
    const id = params.id;
    dispatch(createAction('admin/getOneAdmin')({ id, type: 'detail' }));
  }, [dispatch, params]);

  return (
    <div className="customer">
      <CustomListComponent
        caution={{
          detail: '',
          title: admin.name,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb name={admin.name} />}
        buttons={buttons}
        tabs={tabs}
      />
    </div>
  );
}

export default AdminDetail;
