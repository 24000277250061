import React, { useEffect, useRef, useState } from 'react';

const SinglePersonalInfo = ({ title, content, displayBlock = false }) => {
  return (
    <div className='customer-detail-info-container'>
      <label className={`customer-detail-info-title ${displayBlock ? 'customer-detail-info-title-block' : ''}`}>{title}</label>
      {
        Array.isArray(content)
          ? content.map((item, index) => (
            <label
              key={`${index}`}
              className={`customer-detail-info-content ${displayBlock ? 'customer-detail-info-content-block' : ''}`}
            >
              {item || '-'}
            </label>
          ))
          : <label className={`customer-detail-info-content ${displayBlock ? 'customer-detail-info-content-block' : ''}`}>
            {content || '-'}
          </label>
      }
    </div>
  );
};

const PersonalInformationFields = ({ customer, fields }) => {
  const tableRef = useRef();
  const [tableWidth, setTableWidth] = useState(800);
  const tableOffset = tableRef.current?.offsetWidth;
  useEffect(() => {
    if (tableRef.current?.offsetWidth) {
      setTableWidth(tableRef.current?.offsetWidth);
    }
  }, [tableOffset]);
  return (
    <table
      style={{ tableLayout: "fixed", width: "100%" }}
      ref={tableRef}
    >
      <tbody>
        {fields.map((row, index) => {
          return (
            <tr key={`${index}`}>
              {row.map((column) => {
                return (
                  <td
                    key={`${column.field}`}
                    style={{
                      display: 'inline-grid',
                      width: `${tableWidth / row.length}px`,
                      wordBreak: `break-word`,
                    }}
                  >
                    <SinglePersonalInfo
                      title={column.title}
                      content={customer[column.field]}
                      displayBlock={column.displayBlock}
                    />
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default PersonalInformationFields;
