import {
  getSegmentList,
  getOneSegment,
  createOrUpdateSegment,
  deleteSegments,
} from '../services/SegmentAPIHelper';
import {
  CheckStatus,
  SavedStatus,
  ConditionTag,
  MESSAGE_TRACKING_TYPE_DISPLAY,
  MESSAGE_TRACKING_TYPE,
  TRANSACTION_TYPE,
  TRANSACTION_TYPE_DISPLAY,
  PURCHASE_IN_STORE_TRANSACTION_STORE_TYPE,
  POINTS_TO_EXPIRE_DATE_TYPE_DISPLAY,
  POINTS_TO_EXPIRE_DATE_TYPE,
  PERIOD_TYPE,
  PERIOD_TYPE_DISPLAY,
  PROGRESSION_POINTS_REACH_TARGET_NUMBER_TYPE,
} from '../config/CustomEnums';
import { apiWithResponseHandle, loading } from './LoadingUtil';
import {
  convertCursorToNumber,
  convertNumberToCursor,
  delay,
  convertPKToId,
  saveToSessionStorage,
  removeFromSessionStorage,
  getObjectFromSessionStorage,
} from 'utils';
import { createModel } from './BaseModel';
import { TimeFormater, formatDate } from 'utils/TimeFormatUtil';
import { capitalizeFirstLetter } from 'utils/StringUtil';
import { additionDataLookupConfig } from 'components/segment/AdditionalDataSection';

export const segmentSessionKey = 'tempSegment';


export const contentEnum = {
  gender: 'Gender',
  birthday: 'Birthday',
  age: 'Age',
  countryCode: 'Country code',
  signupAnniversary: 'Signup anniversary (in year)',
  level: 'Level',
  lastPurchase: 'Last purchase',
  numberOfTransaction: 'Number of transaction',
  totalSpending: 'Total spending',
  averageTransaction: 'Average transaction value',
  newMember: 'Sign up member',
  newSignupWithReferral: 'Sign up member via referral',
  numberOfLogin: 'Number of login',
  lastLogin: 'Last login',
  campaignViewed: 'Campaign viewed',
  acquireOrUsedCoupon: 'Acquire or Used Coupon',
  earnReward: 'Earning activities',
  products: 'Special product SKUs',
  eComBindStatus: 'Shopify account bound',
  champions: 'Champions',
  loyalCustomer: 'Loyal Customer',
  potentialLoyalist: 'Potential Loyalist',
  newCustomers: 'New Customers',
  promising: 'Promising',
  needsAttention: 'Needs Attention',
  aboutToSleep: 'About to Sleep',
  cantLooseThem: "Can't Lose Them",
  atRisk: 'At Risk',
  hibernating: 'Hibernating',
  productCategory: 'Product category and subcategory',
  discountLover: 'Transaction with discount',
  additionalDataFromCustomers: 'Additional data from customers',
  additionalDataFromTransactionRecords: 'Additional data from transaction records',
  additionalDataFromPurchasedItems: 'Additional data from purchased items',
  accountStatusActive: 'Active',
  accountStatusTemporaryEarning: 'Temporary Earning',
  accountStatusTemporary: 'Temporary',
  messageTracking: 'Opened or clicked a message',
  purchaseInStore: 'Purchase in specific stores',
  earnedPointsInLifetime: 'Earned an amount of points in lifetime',
  pointsToExpire: 'Points soon to expire',
  ppAlmostReachTarget:'Current progression points almost reach the target',
};


export const contentGroupEnum = {
  [contentEnum.gender]: 'genderCharacteristic',
  [contentEnum.birthday]: 'monthOfBirthCharacteristic',
  [contentEnum.age]: 'ageCharacteristic',
  [contentEnum.countryCode]: 'countryCodeCharacteristic',
  [contentEnum.signupAnniversary]: 'signUpAnniversaryCharacteristic',
  [contentEnum.level]: 'levelCharacteristic',
  [contentEnum.lastPurchase]: 'hasRecentPurchaseCharacteristic',
  [contentEnum.numberOfTransaction]: 'numberOfTransactionsCharacteristic',
  [contentEnum.totalSpending]: 'totalSpendingCharacteristic',
  [contentEnum.averageTransaction]: 'averageTransactionValueCharacteristic',
  [contentEnum.newMember]: 'signUpMemberCharacteristic',
  [contentEnum.newSignupWithReferral]: 'signUpReferralCharacteristic',
  [contentEnum.numberOfLogin]: 'loginTimeCharacteristic',
  [contentEnum.lastLogin]: 'lastLoginCharacteristic',
  [contentEnum.campaignViewed]: 'viewCampaignCharacteristic',
  [contentEnum.acquireOrUsedCoupon]: 'couponCharacteristic',
  [contentEnum.earnReward]: 'earningCampaignCharacteristic',
  [contentEnum.products]: 'productCharacteristic',
  [contentEnum.eComBindStatus]: 'bindingShopifyCharacteristic',
  [contentEnum.champions]: 'recencyFrequencyMonetaryScoreCharacteristic',
  [contentEnum.loyalCustomer]: 'recencyFrequencyMonetaryScoreCharacteristic',
  [contentEnum.potentialLoyalist]: 'recencyFrequencyMonetaryScoreCharacteristic',
  [contentEnum.newCustomers]: 'recencyFrequencyMonetaryScoreCharacteristic',
  [contentEnum.promising]: 'recencyFrequencyMonetaryScoreCharacteristic',
  [contentEnum.needsAttention]: 'recencyFrequencyMonetaryScoreCharacteristic',
  [contentEnum.aboutToSleep]: 'recencyFrequencyMonetaryScoreCharacteristic',
  [contentEnum.cantLooseThem]: 'recencyFrequencyMonetaryScoreCharacteristic',
  [contentEnum.atRisk]: 'recencyFrequencyMonetaryScoreCharacteristic',
  [contentEnum.hibernating]: 'recencyFrequencyMonetaryScoreCharacteristic',
  [contentEnum.productCategory]: 'productCategoryCharacteristic',
  [contentEnum.discountLover]: 'discountLoverCharacteristic',
  [contentEnum.additionalDataFromCustomers]: 'customerAdditionalDataCharacteristic',
  [contentEnum.additionalDataFromTransactionRecords]: 'transactionAdditionalDataCharacteristic',
  [contentEnum.additionalDataFromPurchasedItems]: 'purchasedItemAdditionalDataCharacteristic',
  [contentEnum.accountStatusActive]: 'accountStatusCharacteristic',
  [contentEnum.accountStatusTemporaryEarning]: 'accountStatusCharacteristic',
  [contentEnum.accountStatusTemporary]: 'accountStatusCharacteristic',
  [contentEnum.messageTracking]: 'segmentMessageTrackingCharacteristic',
  [contentEnum.purchaseInStore]: 'segmentPurchaseInStoreCharacteristic',
  [contentEnum.earnedPointsInLifetime]: 'segmentEarnedPointsInLifetimeCharacteristic',
  [contentEnum.pointsToExpire]: 'segmentPointsToExpireCharacteristic',
  [contentEnum.ppAlmostReachTarget]: 'segmentPpReachTargetCharacteristic',
};

export const scoreDefinition = {
  CHAMPIONS: {
    value: "CHAMPIONS",
    name: "Champions",
  },
  LOYAL_CUSTOMER: {
    value: "LOYAL_CUSTOMER",
    name: "Loyal Customer",
  },
  POTENTIAL_LOYALIST: {
    value: "POTENTIAL_LOYALIST",
    name: "Potential Loyalist",
  },
  NEW_CUSTOMERS: {
    value: "NEW_CUSTOMERS",
    name: "New Customers",
  },
  PROMISING: {
    value: "PROMISING",
    name: "Promising",
  },
  NEEDS_ATTENTION: {
    value: "NEEDS_ATTENTION",
    name: "Needs Attention",
  },
  ABOUT_TO_SLEEP: {
    value: "ABOUT_TO_SLEEP",
    name: "About to Sleep",
  },
  CANT_LOSE_THEM: {
    value: "CANT_LOSE_THEM",
    name: "Can't Lose Them",
  },
  AT_RISK: {
    value: "AT_RISK",
    name: "At Risk",
  },
  HIBERNATING: {
    value: "HIBERNATING",
    name: "Hibernating",
  },
};

export const scoreDefinitionList = Object.values(scoreDefinition);

export const scoreDefinitionNameList = scoreDefinitionList.map(item => item.name);

const accountStatusDefinition = {
  ACTIVE: {
    value: "ACTIVE",
    name: contentEnum.accountStatusActive,
    groupName: contentGroupEnum[contentEnum.accountStatusActive]
  },
  TEMPORARY_EARNING: {
    value: "TEMPORARY_EARNING",
    name: contentEnum.accountStatusTemporaryEarning,
    groupName: contentGroupEnum[contentEnum.accountStatusTemporaryEarning]
  },
  TEMPORARY: {
    value: "TEMPORARY",
    name: contentEnum.accountStatusTemporary,
    groupName: contentGroupEnum[contentEnum.accountStatusTemporary]
  },
}

export const accountStatusDefinitionList = Object.values(accountStatusDefinition);

export const accountStatusDefinitionNameList = accountStatusDefinitionList.map(item => item.name);


function formatLookupWithSelector(source, key, selectorKey) {
  if (source[key]) {
    console.log('kevin@262', key, selectorKey, source[key][selectorKey]);
    return {
      lookup: ConditionTag[source[key].lookup],
      selected: source[key][selectorKey]
        ? source[key][selectorKey].edges.map((item) => ({
          pk: item.node.pk,
          name:
            key === 'countryCodeCharacteristic'
              ? '+' + item.node.name
              : item.node.name === 'Not disclosed'
                ? 'Prefer not to say'
                : item.node.name,
          value: item.node.pk,
        }))
        : [],
    };
  }
}

function formatLookupWithMinMax(source, key, subKey) {
  if (source[key]) {
    return {
      lookup: ConditionTag[source[key].lookup],
      minNum:
        source[key].lookup === "BETWEEN" ?
          source[key][`minimum${capitalizeFirstLetter(subKey)}`]
          : source[key][subKey],
      maxNum: source[key][`maximum${capitalizeFirstLetter(subKey)}`],
    };
  }
}

const getInitialState = () => ({
  segmentList: [],
  segment: {},
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  currentLastCursor: '',
  currentPage: 0,
  totalPage: 0,
  totalCount: 0,
  checkedList: [],
  saved: SavedStatus.init,
  checked: CheckStatus.initOrNotChecked,
  errorFields: [],
  createErrorDict: {},
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Name', fieldName: 'name', orderField: 'name' },
    { displayName: 'Description', fieldName: 'description' },
    { displayName: 'Number of customers', fieldName: 'totalCustomers' },
  ],
  tempSegment: {},
  hasUpdatedDefaultValues: false,
  formHasSubmitted: false,
});

const parseSegmentPeriod = (contentCondition) => {
  if (
    contentCondition.periodRange &&
    contentCondition.periodRange !== 'Please select'
  ) {
    if (
      [
        PERIOD_TYPE_DISPLAY.ALL_THE_TIME,
        PERIOD_TYPE_DISPLAY.IN_X_NUMBER_OF_DAYS,
        PERIOD_TYPE_DISPLAY.EQUAL_TO,
        PERIOD_TYPE_DISPLAY.BETWEEN,
        PERIOD_TYPE_DISPLAY.GREATER_THAN,
        PERIOD_TYPE_DISPLAY.LESS_THAN,
      ].includes(contentCondition.periodRange)
    ) {
      return contentCondition.periodRange.toUpperCase().replace(/ /g, '_');
    } else if (
      contentCondition.periodDays &&
      contentCondition.periodDays !== 'Please select'
    ) {
      let periodDays =
        PERIOD_TYPE.Within +
        '_' +
        contentCondition.periodDays.toUpperCase().replace(/ /g, '_');
      if (periodDays.indexOf('YEAR') > 0) {
        periodDays += 'S';
      }

      return periodDays;
    }
  }

  return null;
};

const parseSegmentPeriodDate = (contentCondition) => {
  const periodRange = contentCondition.periodRange;

  let date = null;
  let minimumDate = null;
  let maximumDate = null;

  if (periodRange === PERIOD_TYPE_DISPLAY.BETWEEN) {
    minimumDate = contentCondition.minDate;
    maximumDate = contentCondition.maxDate;
  } else if (
    [
      PERIOD_TYPE_DISPLAY.EQUAL_TO,
      PERIOD_TYPE_DISPLAY.GREATER_THAN,
      PERIOD_TYPE_DISPLAY.LESS_THAN,
    ].includes(periodRange)
  ) {
    date = contentCondition.minDate;
  };

  return {
    date,
    minimumDate,
    maximumDate,
  }
};

const parseNumberOfDays = (numberOfDays) => {
  if (numberOfDays === null || numberOfDays === undefined || numberOfDays === '') {
    return null;
  };
  return numberOfDays
};

const parseSegmentMutipleSelectorCondition = (contentCondition, key) => {
  let condition = null;
  if (contentCondition.lookup) {
    const lookup = contentCondition.lookup.toUpperCase().replace(/ /g, '_');
    if (contentCondition.lookup !== 'In') {
      condition = { lookup };
    } else if (contentCondition.selected.length > 0) {
      let value = contentCondition.selected.map((item) =>
        key === 'levels' ? item.pk : item.value,
      );
      condition = { lookup, [key]: value };
    }
  }

  return condition;
};

const parseSegmentDataRangeCondition = (contentCondition, key) => {
  let condition = null;
  if (contentCondition.lookup) {
    const lookup = contentCondition.lookup.toUpperCase().replace(/ /g, '_');

    if (lookup.indexOf('EMPTY') >= 0) {
      condition = { lookup };
    } else if (lookup.indexOf('BETWEEN') >= 0) {
      if (
        (parseInt(contentCondition.minNum) === 0 ||
          contentCondition.minNum > 0) &&
        (parseInt(contentCondition.maxNum) === 0 || contentCondition.maxNum > 0)
      ) {
        condition = {
          lookup,
          [`minimum${capitalizeFirstLetter(key)}`]: contentCondition.minNum,
          [`maximum${capitalizeFirstLetter(key)}`]: contentCondition.maxNum,
        };
      }
    } else if (
      parseInt(contentCondition.minNum) === 0 ||
      contentCondition.minNum > 0
    ) {
      condition = {
        lookup,
        [key]: contentCondition.minNum,
      };
    }
  }

  return condition;
};

const parseAdditionDataCondition = (contentCondition) => {
  if (!contentCondition?.lookup) {
    return {}
  }
  const lookup = contentCondition.lookup.toUpperCase().replace(/ /g, '_');
  if (additionDataLookupConfig.emptyOption.includes(contentCondition.lookup)) {
    return { lookup, fieldName: contentCondition?.title || "", }
  }
  if (additionDataLookupConfig.textValueOption.includes(contentCondition.lookup)) {
    return {
      lookup,
      fieldName: contentCondition?.title || "",
      textValue: contentCondition?.containText || "",
    }
  }
  if (additionDataLookupConfig.numericValueOption.includes(contentCondition.lookup)) {
    if (lookup.indexOf("BETWEEN") > -1) {
      return {
        lookup,
        fieldName: contentCondition?.title || "",
        minimumValue: contentCondition?.minNum ? contentCondition.minNum : contentCondition?.minNum === 0 ? 0 : null,
        maximumValue: contentCondition?.maxNum ? contentCondition.maxNum : contentCondition?.maxNum === 0 ? 0 : null,
      }
    }
    return {
      lookup,
      fieldName: contentCondition?.title || "",
      numberValue: contentCondition?.minNum ? contentCondition.minNum : contentCondition?.minNum === 0 ? 0 : null,
    }
  }
  return {}
}

const getPeriodRange = (period) => {
  if (!period) {
    return null;
  }
  if (period.indexOf('WITHIN') >= 0) {
    return 'Within';
  };
  return capitalizeFirstLetter(
    period.toLowerCase().replace(/_/g, ' '),
  );
};

const getPeriodDays = (period) => {
  if (!period) {
    return null;
  };
  let periodDays = '';
  if (period?.indexOf('WITHIN') >= 0) {
    periodDays = period.replace('WITHIN_', '').replace(/_/g, ' ').toLowerCase();

    if (periodDays.indexOf('s') > 0) {
      periodDays = periodDays.replace('years', 'year');
    }
  }

  return periodDays;
};

const getPeriod = (data = {}) => {
  const {
    period,
    date,
    minimumDate,
    maximumDate,
    inXNumberOfDays,
  } = data;

  return {
    periodRange: getPeriodRange(period),
    periodDays: getPeriodDays(period),
    minDate:
      period === PERIOD_TYPE.Between
        ? minimumDate
        : date,
    maxDate: maximumDate,
    numberOfDays: inXNumberOfDays,
  };
};

const getProductCategory = (selectedCategories = []) => {
  if (selectedCategories?.length < 1) {
    return {
      productCategories: [],
      productSubategories: [],
    }
  }
  const productCategoryIds = [];
  const productSubcategoryIds = [];
  selectedCategories.forEach((item) => {
    if (item?.groupKey?.pk === "product category") {
      productCategoryIds.push(item?.realPk)
    } else if (item?.groupKey?.pk === "product subcategory") {
      productSubcategoryIds.push(item?.realPk)
    }
  })
  return {
    productCategories: productCategoryIds,
    productSubcategories: productSubcategoryIds,
  }
};

const parseProductsCategory = (data) => {
  const productCategories = data?.productCategories?.edges?.map(item => {
    if (!item?.node) {
      return
    }
    return {
      pk: `product category_${item.node?.pk}`,
      name: item.node?.name,
      realPk: item.node?.pk,
      groupKey: {
        pk: 'product category',
        name: 'Product category',
      },
    }
  })
  const productSubcategories = data?.productSubcategories?.edges?.map(item => {
    if (!item?.node) {
      return
    }
    return {
      pk: `product subcategory_${item.node?.pk}`,
      name: item.node?.name,
      realPk: item.node?.pk,
      groupKey: {
        pk: 'product subcategory',
        name: 'Product subcategory',
      },
    }
  })
  const selectedCategoriesAndSubCategories = [
    ...productCategories,
    ...productSubcategories
  ]
  return selectedCategoriesAndSubCategories
};

export default createModel({
  namespace: 'segments',
  states: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },

    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },

    loadSegmentFromCookie(state, { payload }) {
      const segment = getObjectFromSessionStorage(segmentSessionKey) || {};
      const serverData = payload?.data?.segment;

      let formatData = {};
      let tempData = {};
      if (serverData) {
        // console.log('@@serverData: ', serverData);
        const conditionGroups = serverData.conditionGroups?.edges.map(
          (item, index) => {
            const node = item.node;
            let content = {};
            let conditions = {};
            let displayOrderList = [];
            Object.entries(node).map(([key, value]) => {
              // console.log('@@369: ', key, value);
              if (!value || Object.keys(value).length <= 0) {
                // console.log('@@369: ', key);
                return undefined;
              }
              let displayOrder = value.displayOrder;
              while (displayOrder in conditions) {
                displayOrder += 1;
              }
              if (displayOrder !== undefined) {
                displayOrderList.push(displayOrder);
              }

              // console.log('@@369-1: ', key);
              switch (key) {
                case contentGroupEnum[contentEnum.gender]:
                  conditions[displayOrder] = {
                    ...value,
                    [contentGroupEnum[contentEnum.gender]]: {
                      ...formatLookupWithSelector(node, key, 'genders')
                    },
                    'title': contentEnum.gender,
                  };
                  break;
                case contentGroupEnum[contentEnum.birthday]:
                  console.log("@556", conditions, displayOrder);
                  conditions[displayOrder] = {
                    ...value,
                    [contentGroupEnum[contentEnum.birthday]]: {
                      ...formatLookupWithSelector(node, key, 'months'),
                    },
                    title: contentEnum.birthday,
                  };
                  break;
                case contentGroupEnum[contentEnum.age]:
                  conditions[displayOrder] = {
                    ...node[key],
                    [contentGroupEnum[contentEnum.age]]: { ...formatLookupWithMinMax(node, key, 'age') },
                    title: contentEnum.age,
                  };
                  break;
                case contentGroupEnum[contentEnum.countryCode]:
                  conditions[displayOrder] = {
                    ...node[key],
                    [contentGroupEnum[contentEnum.countryCode]]: {
                      ...formatLookupWithSelector(node, key, 'countryCodes'),
                    },
                    title: contentEnum.countryCode
                  };
                  break;
                case contentGroupEnum[contentEnum.signupAnniversary]:
                  conditions[displayOrder] = {
                    ...node[key],
                    [contentGroupEnum[contentEnum.signupAnniversary]]: {
                      ...formatLookupWithMinMax(node, key, 'numberOfYears'),
                    },
                    title: contentEnum.signupAnniversary,
                  };
                  break;
                case contentGroupEnum[contentEnum.totalSpending]:
                case contentGroupEnum[contentEnum.averageTransaction]:
                  conditions[displayOrder] = {
                    ...node[key],
                    [key]: {
                      ...formatLookupWithMinMax(node, key, 'dollars'),
                      transactionTypes: node[key].transactionTypes?.map((type) => ({ value: type, pk: type })),
                    },
                    title: key === contentGroupEnum[contentEnum.totalSpending] ?
                      contentEnum.totalSpending : contentEnum.averageTransaction
                  }
                  break;
                case contentGroupEnum[contentEnum.level]:
                  console.log(
                    '@@407: ',
                    node[key].levels.edges.map((item) => item.node),
                  );
                  conditions[displayOrder] = {
                    ...node[key],
                    [key]: {
                      lookup: node[key].levels.edges.length > 0 ? 'In' : '',
                      selected: node[key].levels.edges.map((item) => ({
                        ...item.node,
                        label: item.node.levelName,
                        value: item.node,
                        name: item.node.levelName,
                      })),
                    },
                    title: contentEnum.level,
                  };
                  break;
                case contentGroupEnum[contentEnum.lastPurchase]:
                  conditions[displayOrder] = {
                    [key]: {
                      ...getPeriod(node[key]),
                      transactionTypes: node[key].transactionTypes?.map((type) => ({ value: type, pk: type })),
                    },
                    title: contentEnum.lastPurchase
                  }
                  break;
                case contentGroupEnum[contentEnum.numberOfTransaction]:
                  conditions[displayOrder] = {
                    ...node[key],
                    title: contentEnum.numberOfTransaction,
                    [key]: {
                      transactionTypes: node[key].transactionTypes?.map((type) => ({ value: type, pk: type })),
                      ...getPeriod(node[key]),
                      quantity: node[key].quantity,
                      type: node[key].type
                        ? TRANSACTION_TYPE_DISPLAY[node[key].type]
                        : null,
                      lookup: ConditionTag[node[key].lookup],
                      minNum:
                        node[key].lookup === "BETWEEN" ?
                          node[key]["minimumQuantity"]
                          : node[key]["quantity"],
                      maxNum: node[key]["maximumQuantity"],
                    },
                  };
                  break;
                case contentGroupEnum[contentEnum.newMember]:
                  conditions[displayOrder] = {
                    ...node[key],
                    title: contentEnum.newMember,
                    [key]: {
                      ...getPeriod(node[key]),
                    },
                  };
                  break;
                case contentGroupEnum[contentEnum.lastLogin]:
                  conditions[displayOrder] = {
                    ...node[key],
                    title: contentEnum.lastLogin,
                    [key]: {
                      ...getPeriod(node[key]),
                    },
                  };
                  break;
                case contentGroupEnum[contentEnum.newSignupWithReferral]:
                  conditions[displayOrder] = {
                    ...node[key],
                    title: contentEnum.newSignupWithReferral,
                    [key]: {
                      enable: node.signUpReferralCharacteristic.invited,
                    }
                  };
                  break;
                case contentGroupEnum[contentEnum.eComBindStatus]:
                  conditions[displayOrder] = {
                    ...node[key],
                    title: contentEnum.eComBindStatus,
                    [key]: {
                      enable: node.bindingShopifyCharacteristic.bound,
                    }
                  };
                  break;
                case contentGroupEnum[contentEnum.numberOfLogin]:
                  conditions[displayOrder] = {
                    ...node[key],
                    title: contentEnum.numberOfLogin,
                    [key]: {
                      numberOfLogin: node.loginTimeCharacteristic.times,
                    }
                  }
                  break;
                case contentGroupEnum[contentEnum.products]:
                  conditions[displayOrder] = {
                    ...node[key],
                    [key]: {
                      productSkus: node.productCharacteristic.productsSku.join(','),
                      transactionTypes: node[key].transactionTypes?.map((type) => ({ value: type, pk: type })),
                    },
                    title: contentEnum.products,
                  };
                  break;
                case contentGroupEnum[contentEnum.earnReward]:
                  conditions[displayOrder] = {
                    ...node[key],
                    title: contentEnum.earnReward,
                    [key]: {
                      viewType: capitalizeFirstLetter(
                        node[key].catalogType.replace(/_/g, ' ').toLowerCase(),
                      ),
                      viewValue:
                        node[key].catalogType === 'CAMPAIGN'
                          ? {
                            label: node[key].campaign.name,
                            value: node[key].campaign,
                          }
                          : {
                            label: node[key].campaignCategory.name,
                            value: node[key].campaignCategory,
                          },
                      viewedTimeType: 'VIEW_TIMES',
                      viewTimes: {
                        number: node[key].numberOfParticipateTimes,
                        ...getPeriod(node[key]),
                      },
                    }
                  };
                  break;
                case contentGroupEnum[contentEnum.acquireOrUsedCoupon]:
                  conditions[displayOrder] = {
                    ...node[key],
                    title: contentEnum.acquireOrUsedCoupon,
                    [key]: {
                      behavioralType: node[key].behavioralType,
                      couponSet: {
                        label: `[ID: ${node[key].couponSet?.pk}] ${node[key].couponSet?.name}`,
                        value: { pk: node[key].couponSet?.pk, name: node[key].couponSet?.name },
                      },
                      behaviorTimes: {
                        number: node[key].numberOfBehavioralTimes,
                        ...getPeriod(node[key]),
                      },
                    }
                  };
                  break
                case contentGroupEnum[contentEnum.campaignViewed]:
                  conditions[displayOrder] = {
                    ...node[key],
                    title: contentEnum.campaignViewed,
                    [key]: {
                      viewType: capitalizeFirstLetter(
                        node[key].catalogType.replace(/_/g, ' ').toLowerCase(),
                      ),
                      viewValue:
                        node[key].catalogType === 'CAMPAIGN'
                          ? {
                            label: node[key].campaign.name,
                            value: node[key].campaign,
                          }
                          : {
                            label: node[key].campaignCategory.name,
                            value: node[key].campaignCategory,
                          },
                      viewedTimeType: node[key].viewCampaignType,
                      viewTimes: {
                        number:
                          node[key].viewCampaignType === 'VIEW_TIMES'
                            ? node[key].numberOfViewCampaignTimes
                            : node[key].stayPeriod,
                        ...getPeriod(node[key]),
                        ...node[key].viewCampaignType === 'STAY_TIME'
                          ? {
                            periodRange: capitalizeFirstLetter(node[key].stayPeriodType.toLowerCase())
                          }
                          : {},
                      },
                    }
                  };
                  break;
                case 'behavioralCampaignCharacteristics':
                  break;
                case contentGroupEnum[contentEnum.champions]:
                  conditions[displayOrder] = {
                    ...node[key],
                    [key]: {
                      scoreDefinition: node[key].scoreDefinition,
                    },
                    title: scoreDefinition?.[node[key]?.scoreDefinition]?.name,
                  };
                  break;
                case contentGroupEnum[contentEnum.productCategory]:
                  conditions[displayOrder] = {
                    ...node[key],
                    [key]: {
                      selectedCategoriesAndSubCategories: parseProductsCategory(node.productCategoryCharacteristic),
                      transactionTypes: node[key].transactionTypes?.map((type) => ({ value: type, pk: type })),
                    },
                    title: contentEnum.productCategory,
                  };
                  break;
                case contentGroupEnum[contentEnum.discountLover]:
                  conditions[displayOrder] = {
                    ...node[key],
                    title: contentEnum.discountLover,
                    [key]: {
                      ...getPeriod(node[key]),
                      times: node.discountLoverCharacteristic.times,
                      type: node[key].type
                        ? TRANSACTION_TYPE_DISPLAY[node[key].type]
                        : null,
                      lookup: ConditionTag[node[key].lookup],
                      minNum:
                        node[key].lookup === "BETWEEN" ?
                          node[key]["minimumTimes"]
                          : node[key]["times"],
                      maxNum: node[key]["maximumTimes"],
                    },
                  };
                  break;
                case contentGroupEnum[contentEnum.additionalDataFromCustomers]:
                  conditions[displayOrder] = {
                    ...node[key],
                    title: contentEnum.additionalDataFromCustomers,
                    [key]: {
                      title: node[key]?.fieldName || '',
                      containText: node[key]?.textValue || '',
                      lookup: ConditionTag[node[key].lookup],
                      minNum:
                        node[key].lookup === "BETWEEN" ?
                          node[key]["minimumValue"]
                          : node[key]["numberValue"],
                      maxNum: node[key]["maximumValue"],
                    },
                  };
                  break;
                case contentGroupEnum[contentEnum.additionalDataFromPurchasedItems]:
                  conditions[displayOrder] = {
                    ...node[key],
                    title: contentEnum.additionalDataFromPurchasedItems,
                    [key]: {
                      title: node[key]?.fieldName || '',
                      containText: node[key]?.textValue || '',
                      lookup: ConditionTag[node[key].lookup],
                      minNum:
                        node[key].lookup === "BETWEEN" ?
                          node[key]["minimumValue"]
                          : node[key]["numberValue"],
                      maxNum: node[key]["maximumValue"],
                    },
                  };
                  break;
                case contentGroupEnum[contentEnum.additionalDataFromTransactionRecords]:
                  conditions[displayOrder] = {
                    ...node[key],
                    title: contentEnum.additionalDataFromTransactionRecords,
                    [key]: {
                      title: node[key]?.fieldName || '',
                      containText: node[key]?.textValue || '',
                      lookup: ConditionTag[node[key].lookup],
                      minNum:
                        node[key].lookup === "BETWEEN" ?
                          node[key]["minimumValue"]
                          : node[key]["numberValue"],
                      maxNum: node[key]["maximumValue"],
                    },
                  };
                  break;
                case contentGroupEnum[contentEnum.accountStatusActive]:
                case contentGroupEnum[contentEnum.accountStatusTemporaryEarning]:
                case contentGroupEnum[contentEnum.accountStatusTemporary]:
                  conditions[displayOrder] = {
                    ...node[key],
                    [key]: {
                      accountStatus: node[key].accountStatus,
                    },
                    title: accountStatusDefinition?.[node[key]?.accountStatus]?.name,
                  };
                  break;
                case contentGroupEnum[contentEnum.messageTracking]:
                  conditions[displayOrder] = {
                    ...node[key],
                    title: contentEnum.messageTracking,
                    [key]: {
                      trackingType: node[key].trackingType 
                        ? MESSAGE_TRACKING_TYPE_DISPLAY[node[key].trackingType] 
                        : null,
                      message: {
                          label: node[key].message.name,
                          value: node[key].message,
                        }
                    },
                  };
                  break;
                case contentGroupEnum[contentEnum.purchaseInStore]:
                  conditions[displayOrder] = {
                    ...node[key],
                    title: contentEnum.purchaseInStore,
                    [key]: {
                      ...getPeriod(node[key]),
                      storeType: node[key].storeType || null,
                      stores: node[key].stores
                        ? node[key].stores?.edges?.map(item => ({
                          pk: item.node.pk,
                          name: item.node.name,
                          displayStoreName: `${item.node.name}${item.node.visibleInFrontEnd ? '' : '(closed)'}`,
                          value: item.node.pk,
                        }))
                        : [],
                      type: node[key].type
                        ? TRANSACTION_TYPE_DISPLAY[node[key].type]
                        : null,
                      lookup: ConditionTag[node[key].lookup],
                      times: node[key].times,
                      minNum:
                        node[key].lookup === "BETWEEN" ?
                          node[key]["minimumTimes"]
                          : node[key]["times"],
                      maxNum: node[key]["maximumTimes"],
                    },
                  };
                  break;
                  case contentGroupEnum[contentEnum.earnedPointsInLifetime]:
                    conditions[displayOrder] = {
                      ...node[key],
                      title: contentEnum.earnedPointsInLifetime,
                      [key]: {
                        ...formatLookupWithMinMax(node, key, 'points')
                      },
                    };
                    break;
                  case contentGroupEnum[contentEnum.pointsToExpire]:
                    conditions[displayOrder] = {
                      ...node[key],
                      title: contentEnum.pointsToExpire,
                      [key]: {
                        dateType: node[key].dateType
                          ? POINTS_TO_EXPIRE_DATE_TYPE_DISPLAY[node[key].dateType]
                          : null,
                        number: node[key].number,
                      },
                    };
                    break;
                case contentGroupEnum[contentEnum.ppAlmostReachTarget]:
                  conditions[displayOrder] = {
                    ...node[key],
                    title: contentEnum.ppAlmostReachTarget,
                    [key]: {
                      target: node[key].target,
                      numberType: node[key].numberType,
                      ...formatLookupWithMinMax(node, key, 'progressionPoints')
                    },
                  };
                  break;
                default:
                  // console.log('@@518: ', key, node[key], value);
                  content[key] = value;
                  break;
              }
              return undefined;
            });
            // console.log('@@217: ', content);
            // return {
            //   [index + 1]: {
            //     ...item.node,
            //     ...content,
            //   },
            // };
            displayOrderList.sort();

            // console.log('@@527: ', content, conditions, displayOrderList);
            return {
              // ...item.node,
              ...content,
              conditions: [...displayOrderList.map((displayOrderNumber) => conditions[displayOrderNumber])],
            };
          },
        );

        formatData = {
          id: serverData.id,
          pk: serverData.pk,
          name: serverData.name,
          generalName: serverData.name,
          conditionGroups: conditionGroups,
          defaultSegment: serverData.defaultSegment,
          lastUpdateCustomersDate: serverData.lastUpdateCustomersDate,
          displayLastUpdateCustomersDate: formatDate(serverData.lastUpdateCustomersDate, TimeFormater.dayMonthYearWeekTimeA)
        };

        if (!payload.isView) {
          tempData = {
            id: serverData.id,
            pk: serverData.pk,
            name: serverData.name,
            generalName: serverData.name,
            conditionGroups: Object.assign(
              {},
              ...conditionGroups.map((condition, index) => ({
                [index + 1]: condition,
              })),
            ),
          };
          saveToSessionStorage(segmentSessionKey, tempData);
        }

        console.log('@@578: ', formatData);
      } else {
        if ('conditionGroups' in segment && Array.isArray(segment['conditionGroups'])) {
          formatData = {
            ...segment,
          };
        }
        delete formatData.pk;
        delete formatData.id;

        // saveToSessionStorage(segmentSessionKey, formatData);
      }

      const numberOfCustomers = payload?.data?.segment?.totalCustomers || '0';
      const description = payload?.data?.segment?.description;

      if (payload?.afterAction) {
        payload.afterAction();
      };

      return {
        ...state,
        segment: {
          ...formatData,
          numberOfCustomers,
          description,
        },
        tempSegment: tempData,
        hasUpdatedDefaultValues: true,
      };
    },
    removeFromCookie(state, { payload }) {
      removeFromSessionStorage(segmentSessionKey);

      return {
        ...state,
        tempSegment: {},
      };
    },
  },
  params: {
    listAPI: getSegmentList,
    objectKey: 'segments',
    parse: (data) => {
      return data.segments.edges.map(item => item.node)
    },
  },
  effects: {
    getSegmentsList: [
      function* ({ payload }, { call, select, put }) {
        // yield put({
        //   type: 'loading/updateState',
        //   payload: { status: APIStatus.calling },
        // });

        const page = payload.page;
        const pageCursor = payload.page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const serviceArgs = [getSegmentList, pageCursor, payload];
        console.log(
          '@@423: ',
          page,
          pageCursor,
          payload.reverse,
          payload.type,
          payload.search,
          payload.dateOfBirthRange,
        );
        function* onSuccess(data) {
          console.log('@@115: ', data);
          const pageInfo = data?.segments?.pageInfo;

          const currentLastCursor = pageInfo?.endCursor;
          const totalCount = data?.segments?.totalCount;
          const segmentList = data?.segments?.edges.map((item, index) => ({
            ...item.node,
            totalCustomers: item.node.totalCustomers || "0",
          }));
          yield put({
            type: 'updateState',
            payload: {
              segmentList,
              pageInfo: {
                startCursor: convertCursorToNumber(pageInfo.startCursor) + 1,
                endCursor: convertCursorToNumber(pageInfo.endCursor) + 1,
              },
              currentLastCursor,
              totalCount,
              totalPage: Math.ceil(totalCount / 20),
            },
          });

          // yield put({
          //   type: 'loading/updateState',
          //   payload: { status: APIStatus.success },
          // });
        }
        // function* onFailed(data) {
        //   yield put({
        //     type: 'loading/updateState',
        //     payload: { status: APIStatus.failed },
        //   });
        // }
        yield payload?.isSelectorLoad
          ? apiWithResponseHandle(serviceArgs, onSuccess)
          : loading(serviceArgs, onSuccess)
      },
      { type: 'takeLatest' },
    ],
    delete: [
      function* ({ payload }, { select, put, all }) {
        const { checkedSegments } = yield select((state) => ({
          checkedSegments: state.segments.checkedList,
        }));

        let pks = [];
        // let deleteCampaignIds = [];
        checkedSegments.forEach((item) => {
          pks.push(item.pk);

          // item.node.campaigns.edges.forEach((campaign) => {
          //   deleteCampaignIds.push(campaign.node.pk);
          // });
        });

        const serviceArgs = [deleteSegments, pks];
        const afterAction = payload.afterAction || (() => { });
        function* onSuccess(data) {
          console.log('@@153: ', data);
          yield all([
            put({
              type: 'segments/updateState',
              payload: { checkedList: [] },
            }),
          ]);
          yield delay(1000);
          afterAction();
        }
        // eslint-disable-next-line require-yield
        function* onFailed(data) {
          console.log('@@122: ', data);
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    getOneSegment: [
      function* ({ payload }, { call, select, put }) {
        const serviceArgs = [
          getOneSegment,
          convertPKToId('SegmentNode', payload.id),
        ];
        function* onSuccess(data) {
          console.log('@@115: ', data);
          yield put({
            type: 'loadSegmentFromCookie',
            payload: {
              data,
              isView: payload.view || false,
            },
          });
        }
        // eslint-disable-next-line require-yield
        function* onFailed(data) {
          console.log('@@122: ', data);
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
        if (payload.afterAction) {
          payload.afterAction();
        };
      },
      { type: 'takeLatest' },
    ],
    createOrUpdateSegment: [
      function* ({ payload }, { call, select, put }) {
        // const segment = getObjectFromSessionStorage(segmentSessionKey);
        const { name, conditionGroups = [], pk } = payload;
        const serverSegment = yield select((state) => state.segments.segment);

        let serverGroupIds = [];
        let existingGroupIds = [];
        if ('conditionGroups' in serverSegment) {
          serverGroupIds = serverSegment.conditionGroups.map((group) => group.pk)
        }
        console.log('start creare or update segment: ', serverSegment, serverGroupIds);
        let parsedConditionGroups = [];
        let groupIndex = 0;
        let recencyFrequencyMonetaryNumberList = [];
        let isMoreThanOneAccountStatus = false;

        conditionGroups.forEach((group, index) => {
          const { conditions, pk, displayOrder = null } = group;
          if ((conditions || []).length === 0) {
            return;
          }
          const oneConditionGroup = { displayOrder: displayOrder === null ? groupIndex : displayOrder };
          groupIndex += 1;
          let recencyFrequencyMonetaryNumber = 0;
          let accountStatusNumber = 0;
          
          conditions.forEach((condition, conditionIndex) => {
            const rawData = { ...condition };
            if (!('title' in rawData)) {
              return
            }
            const groupName = contentGroupEnum[rawData.title];
            const groupData = rawData[groupName];
            switch (groupName) {
              case contentGroupEnum[contentEnum.newSignupWithReferral]:
                let invited = groupData.enable;
                if (invited === undefined) {
                  invited = false;
                }
                oneConditionGroup[groupName] = {
                  invited,
                };
                break;
              case contentGroupEnum[contentEnum.eComBindStatus]:
                let bound = groupData.enable;
                if (bound === undefined) {
                  bound = false;
                }
                oneConditionGroup[groupName] = {
                  bound,
                };
                break;
              case contentGroupEnum[contentEnum.lastPurchase]:
                oneConditionGroup[groupName] = {
                  period: parseSegmentPeriod(groupData),
                  ...parseSegmentPeriodDate(groupData),
                  inXNumberOfDays: parseNumberOfDays(groupData.numberOfDays),
                  transactionTypes: groupData.transactionTypes?.map((type) => type.value),
                };
                break;
              case contentGroupEnum[contentEnum.numberOfLogin]:
                oneConditionGroup[groupName] = {
                  times: groupData.numberOfLogin,
                };
                break;
              case contentGroupEnum[contentEnum.products]:
                oneConditionGroup[groupName] = {
                  productsSku: groupData.productSkus.trim().split(','),
                  transactionTypes: groupData.transactionTypes?.map((type) => type.value),
                };
                break;
              case contentGroupEnum[contentEnum.gender]:
                oneConditionGroup[
                  groupName
                ] = parseSegmentMutipleSelectorCondition(
                  groupData,
                  'genders',
                );
                break;
              case contentGroupEnum[contentEnum.birthday]:
                oneConditionGroup[
                  groupName
                ] = parseSegmentMutipleSelectorCondition(
                  groupData,
                  'months',
                );
                break;
              case contentGroupEnum[contentEnum.countryCode]:
                oneConditionGroup[
                  groupName
                ] = parseSegmentMutipleSelectorCondition(
                  groupData,
                  'countryCodes',
                );
                break;
              case contentGroupEnum[contentEnum.level]:
                // valueKey = 'levels';
                oneConditionGroup[
                  groupName
                ] = parseSegmentMutipleSelectorCondition(
                  groupData,
                  'levels',
                );
                delete oneConditionGroup[groupName].lookup;
                break;
              case contentGroupEnum[contentEnum.age]:
                // valueKey = 'age';
                oneConditionGroup[
                  groupName
                ] = parseSegmentDataRangeCondition(groupData, 'age');
                break;
              case contentGroupEnum[contentEnum.signupAnniversary]:
                // valueKey = 'numberOfYears';
                oneConditionGroup[
                  groupName
                ] = parseSegmentDataRangeCondition(
                  groupData,
                  'numberOfYears',
                );
                break;
              case contentGroupEnum[contentEnum.totalSpending]:
              case contentGroupEnum[contentEnum.averageTransaction]:
                // valueKey = 'dollars';
                oneConditionGroup[
                  groupName
                ] = {
                  ...parseSegmentDataRangeCondition(
                    groupData,
                    'dollars',
                  ),
                  transactionTypes: groupData.transactionTypes?.map((type) => type.value),
                };
                break;
              case contentGroupEnum[contentEnum.numberOfTransaction]:
                oneConditionGroup[groupName] = {
                  period: parseSegmentPeriod(groupData),
                  ...parseSegmentPeriodDate(groupData),
                  inXNumberOfDays: parseNumberOfDays(groupData.numberOfDays),
                  transactionTypes: groupData.transactionTypes?.map((type) => type.value),
                  type: TRANSACTION_TYPE[groupData.type] || null,
                  ...parseSegmentDataRangeCondition(
                    groupData,
                    'quantity',
                  ),
                };
                break;
              case contentGroupEnum[contentEnum.newMember]:
              case contentGroupEnum[contentEnum.lastLogin]:
                oneConditionGroup[groupName] = {
                  period: parseSegmentPeriod(groupData),
                  ...parseSegmentPeriodDate(groupData),
                };
                break;
              case contentGroupEnum[contentEnum.earnReward]:
              case contentGroupEnum[contentEnum.campaignViewed]:
                let campaignCondition = {};
                if (
                  groupData.viewType &&
                  groupData.viewValue?.label
                ) {
                  campaignCondition = {
                    catalogType: groupData.viewType
                      .toUpperCase()
                      .replace(/ /g, '_'),
                  };
                  if (groupData.viewType === 'Campaign') {
                    campaignCondition.campaign =
                      groupData.viewValue.value.pk;
                  } else {
                    campaignCondition.campaignCategory =
                      groupData.viewValue.value.pk;
                  }
                }

                if (groupName === 'viewCampaignCharacteristic')
                  campaignCondition.viewCampaignType =
                    groupData.viewedTimeType || 'VIEW_TIMES';
                if (
                  groupName === 'viewCampaignCharacteristic' &&
                  groupData.viewedTimeType === 'STAY_TIME'
                ) {
                  if (
                    groupData.viewTimes.periodRange &&
                    (groupData.viewTimes.number > 0 ||
                      parseInt(groupData.viewTimes.number) === 0)
                  ) {
                    oneConditionGroup[groupName] = {
                      ...campaignCondition,
                      stayPeriodType: groupData.viewTimes.periodRange.toUpperCase(),
                      stayPeriod: groupData.viewTimes.number,
                    };
                  }
                } else {
                  const period = parseSegmentPeriod(groupData.viewTimes);
                  const periodDate = parseSegmentPeriodDate(groupData.viewTimes);
                  if (
                    groupData.viewTimes.number > 0 ||
                    parseInt(groupData.viewTimes.number) === 0 ||
                    ((groupData.viewTimes.viewTimes > 0 ||
                      parseInt(groupData.viewTimes.viewTimes) === 0) &&
                      period)
                  )
                    oneConditionGroup[groupName] = {
                      ...campaignCondition,
                      period: period,
                      ...periodDate,
                      ...([
                        'earningCampaignCharacteristic',
                        'couponCampaignCharacteristic',
                      ].includes(groupName)
                        ? {
                          numberOfParticipateTimes:
                            groupData.viewTimes.number
                        }
                        : {
                          numberOfViewCampaignTimes: groupData.viewTimes.number,
                        }),
                    };
                }
                break;
              case contentGroupEnum[contentEnum.acquireOrUsedCoupon]:
                oneConditionGroup[groupName] = {
                  behavioralType: groupData.behavioralType.toUpperCase().replace(/ /g, '_'),
                  numberOfBehavioralTimes: groupData.behaviorTimes.number,
                  period: parseSegmentPeriod(groupData.behaviorTimes),
                  ...parseSegmentPeriodDate(groupData.behaviorTimes),
                  couponSet: groupData.couponSet?.value?.pk,
                };
                break;
              case contentGroupEnum[contentEnum.champions]:
                oneConditionGroup[contentGroupEnum[contentEnum.champions]] = {
                  scoreDefinition: groupData.scoreDefinition,
                };
                recencyFrequencyMonetaryNumber += 1;
                break;
              case contentGroupEnum[contentEnum.productCategory]:
                oneConditionGroup[groupName] = {
                  ...getProductCategory(groupData.selectedCategoriesAndSubCategories),
                  transactionTypes: groupData.transactionTypes?.map((type) => type.value),
                };
                break;
              case contentGroupEnum[contentEnum.discountLover]:
                oneConditionGroup[groupName] = {
                  period: parseSegmentPeriod(groupData),
                  ...parseSegmentPeriodDate(groupData),
                  inXNumberOfDays: parseNumberOfDays(groupData.numberOfDays),
                  type: TRANSACTION_TYPE[groupData.type] || null,
                  ...parseSegmentDataRangeCondition(
                    groupData,
                    'times',
                  ),
                };
                break;
              case contentGroupEnum[contentEnum.additionalDataFromCustomers]:
              case contentGroupEnum[contentEnum.additionalDataFromPurchasedItems]:
              case contentGroupEnum[contentEnum.additionalDataFromTransactionRecords]:
                oneConditionGroup[groupName] = {
                  ...parseAdditionDataCondition(groupData)
                };
                break;
              case contentGroupEnum[contentEnum.accountStatusActive]:
              case contentGroupEnum[contentEnum.accountStatusTemporaryEarning]:
              case contentGroupEnum[contentEnum.accountStatusTemporary]:
                oneConditionGroup[groupName] = {
                  accountStatus: groupData.accountStatus,
                };
                accountStatusNumber += 1
                break
              case contentGroupEnum[contentEnum.messageTracking]:
                oneConditionGroup[groupName] = {
                  trackingType: MESSAGE_TRACKING_TYPE[groupData.trackingType],
                  message: groupData.message?.value?.pk,
                };
                break;
              case contentGroupEnum[contentEnum.purchaseInStore]:
                const resetStores = [
                  PURCHASE_IN_STORE_TRANSACTION_STORE_TYPE['All online stores'],
                  PURCHASE_IN_STORE_TRANSACTION_STORE_TYPE['All offline stores'],
                ].includes(groupData.storeType);
                oneConditionGroup[groupName] = {
                  period: parseSegmentPeriod(groupData),
                  ...parseSegmentPeriodDate(groupData),
                  inXNumberOfDays: parseNumberOfDays(groupData.numberOfDays),
                  storeType: groupData.storeType || PURCHASE_IN_STORE_TRANSACTION_STORE_TYPE['All online stores'],
                  type: TRANSACTION_TYPE[groupData.type] || null,
                  stores: resetStores
                    ? []
                    : groupData.stores?.map(item => item.pk) || [],
                  ...parseSegmentDataRangeCondition(
                    groupData,
                    'times',
                  ),
                };
                break;
                case contentGroupEnum[contentEnum.earnedPointsInLifetime]:
                  oneConditionGroup[
                    groupName
                  ] = parseSegmentDataRangeCondition(groupData, 'points');
                  break;
                case contentGroupEnum[contentEnum.pointsToExpire]:
                  oneConditionGroup[groupName] = {
                    dateType: POINTS_TO_EXPIRE_DATE_TYPE[groupData.dateType] || POINTS_TO_EXPIRE_DATE_TYPE['day(s)'],
                    number: groupData.number,
                  };
                  break;
              case contentGroupEnum[contentEnum.ppAlmostReachTarget]:
                const lookup = groupData.lookup.toUpperCase().replace(/ /g, '_');
                const minimumProgressionPoints = parseInt(groupData?.minNum) === 0 ? 0 : parseInt(groupData?.minNum) || null
                const maximumProgressionPoints = parseInt(groupData?.maxNum) === 0 ? 0 : parseInt(groupData?.maxNum) || null
                oneConditionGroup[groupName] = {
                  target: groupData?.target,
                  lookup: lookup,
                  numberType: groupData?.numberType || PROGRESSION_POINTS_REACH_TARGET_NUMBER_TYPE.AMOUNT_IN_PERCENTAGE,
                  ...lookup === "BETWEEN" ? {
                    minimumProgressionPoints,
                    maximumProgressionPoints,
                  } : {
                    progressionPoints: minimumProgressionPoints,
                  }
                };
                break;
              default:
                break;
            }
            if (groupName in oneConditionGroup) {
              oneConditionGroup[groupName].displayOrder = conditionIndex;
            }
          });

          recencyFrequencyMonetaryNumberList.push(recencyFrequencyMonetaryNumber)
          if (accountStatusNumber > 1) {
            isMoreThanOneAccountStatus = true
          }
          if (pk) {
            oneConditionGroup.id = pk;
            existingGroupIds.push(pk);
          }
          parsedConditionGroups.push(oneConditionGroup);
        })

        // Could not add two rfm in one condition
        if (recencyFrequencyMonetaryNumberList.find(item => item > 1)) {
          return;
        }
        if (isMoreThanOneAccountStatus) {
          return;
        }

        const data = {
          name,
          conditionGroups: parsedConditionGroups,
        };
        if (pk) {
          data.id = pk;
          const conditionGroupsToDelete = serverGroupIds.filter((el) => !existingGroupIds.includes(el));
          data.conditionGroupsToDelete = conditionGroupsToDelete;
        }
        const serviceArgs = [createOrUpdateSegment, data];
        function* onSuccess(data) {

          if (
            ('createSegment' in data && data.createSegment.errors) ||
            ('updateSegment' in data && data.updateSegment.errors)
          ) {
            yield put({
              type: 'updateState',
              payload: { saved: SavedStatus.savedWithFail },
            });
          } else {
            yield put({
              type: 'updateState',
              payload: {
                saved: SavedStatus.savedWithSuccess,
                formHasSubmitted: true,
              },
            });

            yield put({ type: 'removeFromCookie' });
          }
        }
        function* onFailed(data) {
          console.log('@@122: ', data);
          yield put({
            type: 'updateState',
            payload: { saved: SavedStatus.savedWithFail },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
  },
});
