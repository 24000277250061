import React, { useEffect, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import BaseMultipleSelectorV2 from 'components/base/BaseMultipleSelectorV2';
import CustomTitleWithSwitch from 'components/base/CustomTitleWithSwitch';
import BasePrompt from 'components/base/prompt/BasePrompt';
import TargetCustomerRadios from 'components/message/TargetCustomerRadios';
import { PermissionCodes } from 'config/PermissionCodes';

import { createAction } from 'utils';

import '../campaign/campaignCreation/PropertiesGeneralSection.scss';
import './RewardSection.scss';

const EarningNewPromptType = {
  segments: 'segments',
  levels: 'levels',
  customerGroup: 'customer groups',
  none: 'none',
};

const mapPropsToState = (state) => ({
  customerGroupList: state.customerGroup.notPagedAllList,
  segmentList: state.segments.notPagedAllList,
  levelList: state.levels.levelList,
});

function TargetCustomersSection({ customerGroupList, segmentList, levelList }) {
  const { watch, setValue } = useFormContext();
  const dispatch = useDispatch();
  const history = useHistory();
  const [createNewType, setCreateNewType] = useState(EarningNewPromptType.none);
  const [refreshDate, setRefreshDate] = useState(new Date());

  const isExclusive = watch('isExclusive') || false;
  const targetedCustomerGroups = watch('targetedCustomerGroups') || [];
  const targetedSegments = watch('targetedSegments') || [];
  const targetedLevels = watch('targetedLevels') || [];
  const targetCustomerType = watch('targetCustomerType');

  useEffect(() => {
    dispatch(createAction('customerGroup/getAllList')({ isAll: true }));
    dispatch(createAction('segments/getAllList')({}));
    dispatch(createAction('levels/getLevelList')({}));
  }, [dispatch, refreshDate]);

  const getCreateNewPromptContent = () => {
    let title = 'segment';
    let button = 'Segment';
    let pathname = '/segments/create/';
    if (createNewType === EarningNewPromptType.customerGroup) {
      title = 'customer list';
      button = 'Customer List';
      pathname = '/customer_group/create';
    }
    return {
      title: `Go to create ${title} ?`,
      description: `You will leave earning rule creation process.`,
      button: `Go to Create ${button}`,
      action: () => {
        window.open(pathname, '_blank');
      },
    };
  };

  return (
    <>
      <label className="create-section-title">{'Target customer'}</label>
      <label className="section-short-description">
        {
          'Select the target customers if this earning rule is exclusively for a specific customer list, segment, or customer level. By default, the earning rule applies to everyone.'
        }
      </label>

      <CustomTitleWithSwitch
        title={'Exclusive'}
        tips={
          'If the toggle is turned on, the earning rule will only be effective for customers from the selected customer list, segment, and levels. Note that the segment may take time to update, which could affect the final rewards delivered to the customers.'
        }
        defaultValue={isExclusive}
        setValue={(isChecked) => {
          setValue('isExclusive', isChecked, { shouldDirty: true });
        }}
        error={''}
      />
      <Collapse in={isExclusive}>
        <div>
          <TargetCustomerRadios
            radiosChanged={(value) => {
              setValue('targetCustomerType', value, { shouldDirty: true });
            }}
            defaultTarget={targetCustomerType}
          />
          {targetCustomerType === 'targetedSegments' && (
            <BaseMultipleSelectorV2
              title={'Target segments'}
              data={{
                sourceData: segmentList,
                targetData: targetedSegments,
                targetChange: (options) => {
                  setValue('targetedSegments', options, { shouldDirty: true });
                },
              }}
              addButton={{
                title: 'Add Segments',
                action: () => setCreateNewType(EarningNewPromptType.segments),
              }}
              requires={PermissionCodes.addSegment}
            />
          )}
          {targetCustomerType === 'targetedLevels' && (
            <BaseMultipleSelectorV2
              title={'Target levels'}
              data={{
                sourceData: levelList,
                targetData: targetedLevels,
                targetChange: (options) => {
                  setValue('targetedLevels', options, {
                    shouldDirty: true,
                  });
                },
              }}
              requires={PermissionCodes.addCustomerGroup}
            />
          )}
          {(targetCustomerType === 'targetedCustomerGroups' || !targetCustomerType) && (
            <BaseMultipleSelectorV2
              title={'Target customer groups'}
              data={{
                sourceData: customerGroupList,
                targetData: targetedCustomerGroups,
                targetChange: (options) => {
                  setValue('targetedCustomerGroups', options, {
                    shouldDirty: true,
                  });
                },
              }}
              addButton={{
                title: 'Add Customer List',
                action: () =>
                  setCreateNewType(EarningNewPromptType.customerGroup),
              }}
              requires={PermissionCodes.addCustomerGroup}
            />
          )}
        </div>
      </Collapse>

      <BasePrompt
        show={createNewType !== EarningNewPromptType.none}
        closeAction={() => setCreateNewType(EarningNewPromptType.none)}
        leftButton={{
          text: 'Done and refresh',
          action: () => {
            setRefreshDate(new Date());
            setCreateNewType(EarningNewPromptType.none)
          },
        }}
        rightButton={{
          text: getCreateNewPromptContent().button,
          action: getCreateNewPromptContent().action,
        }}
        title={getCreateNewPromptContent().title}
        description={getCreateNewPromptContent().description}
      />
    </>
  );
}

export default connect(mapPropsToState)(TargetCustomersSection);
