import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import CustomDateTimeSelect from 'components/base/CustomDateTimeSelect';
import CustomEditor from 'components/base/CustomEditor';
import {
  ReactHookFormErrorMessage,
  hasError,
} from 'components/base/ErrorFieldMessage';
import { CustomTitleLabel } from 'components/earning/CustomBaseComponments';
import { PermissionCodes } from 'config/PermissionCodes';
import {
  TRANSACTION_EVENT_TYPE,
  TRANSACTION_RECORD_TYPE,
} from 'containers/record/records/TransactionList';
import { CreateTransactionError } from 'models/CreateTransactionModel';

import CustomerFilterableDropdown from './CustomerFilterableDropdown';
import OfflineEventTypeSelectDropdown from './OfflineEventTypeSelectDropdown';
import StoreSelectDropdown from './StoreSelectDropdown';

function TransactionGeneralSection() {
  const location = useLocation();

  const { watch, setValue, formState } = useFormContext();
  const { errors } = formState;
  const errorFields = Object.keys(errors);

  const customerField = 'customer';
  const transactionDateField = 'transactionDate';
  const purchasedDateField = 'purchasedDate';
  const storeField = 'store';
  const posInvoiceIdField = 'posInvoiceId';
  const invoiceIdField = 'invoiceId';
  const remarksField = 'remarks';
  const offlineEventTypeField = 'offlineEventType';
  const displayOfflineEventTypeField = 'displayOfflineEventType';
  const staffNameField = 'staffName';
  const refundedInvoiceIdField = 'refundedInvoiceId';

  const transactionType = watch('transactionDisplayType');
  const selectedCustomer = watch(customerField);
  const selectedStore = watch(storeField);
  const transactionDate = watch(transactionDateField);
  const purchasedDate = watch(purchasedDateField);
  const offlineEventType = watch(offlineEventTypeField);
  const offlineDisplayEventType = watch(displayOfflineEventTypeField);
  const staffName = watch(staffNameField);
  const posInvoiceId = watch(posInvoiceIdField);
  const invoiceId = watch(invoiceIdField);
  const remarks = watch(remarksField);
  const refundedInvoiceId = watch(refundedInvoiceIdField);
  const showRefundSection =
    transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS &&
    [
      TRANSACTION_EVENT_TYPE.TYPE_REFUNDED,
      TRANSACTION_EVENT_TYPE.TYPE_PARTIALLY_REFUNDED,
      TRANSACTION_EVENT_TYPE.TYPE_EXCHANGE,
    ].includes(offlineDisplayEventType);
  const isCreate = location.pathname.includes('create');

  return (
    <>
      <div className="first-section-title">GENERAL</div>
      <CustomerFilterableDropdown
        title={'Customer'}
        errorName={customerField}
        errorMessage={CreateTransactionError.customer.message}
        errorFields={errorFields}
        defaultSelectedCustomer={
          selectedCustomer?.pk
            ? {
                label: selectedCustomer?.owner,
                value: selectedCustomer,
              }
            : null
        }
        needLevelFilter={true}
        selectCustomer={(customer) => {
          setValue(customerField, customer.value, { shouldDirty: true });
        }}
        moreSearch={{ isDropdownNode: true }}
      />
      <div>
        <CustomTitleLabel title="Transaction date" />
        <CustomDateTimeSelect
          defaultTime={new Date(transactionDate)}
          error={hasError(errors, transactionDateField)}
          onTimeChange={(date) => {
            setValue(transactionDateField, date, { shouldDirty: true });
          }}
          noNeedTime={
            transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS ||
            transactionType ===
              TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM
          }
        />
        <ReactHookFormErrorMessage errors={errors} id={transactionDateField} />
      </div>
      <div>
        <StoreSelectDropdown
          selectedStore={selectedStore}
          selectStore={(store) => {
            setValue(storeField, store, { shouldDirty: true });
          }}
          hideAddButton={false}
          addButtonRequires={PermissionCodes.addStore}
          defaultFilter={{ isSimpleNode: true }}
        />
        <ReactHookFormErrorMessage errors={errors} id={storeField} />
      </div>
      <div>
        <CustomTitleLabel title={'Invoice id'} />
        <div>
          {transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS ? (
            <input
              type="text"
              className={`text-input-field ${
                hasError(errors, posInvoiceIdField) ? 'error-field' : ''
              }`}
              onChange={(e) => {
                const value = e.target.value;
                setValue(posInvoiceIdField, value, { shouldDirty: true });
              }}
              value={posInvoiceId}
            />
          ) : null}
          {transactionType ===
          TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM ? (
            <input
              type="text"
              className={`text-input-field ${
                hasError(errors, invoiceIdField) ? 'error-field' : ''
              }`}
              onChange={(e) => {
                const value = e.target.value;
                setValue(invoiceIdField, value, { shouldDirty: true });
              }}
              value={invoiceId}
            />
          ) : null}
        </div>
        <div>
          <ReactHookFormErrorMessage errors={errors} id={posInvoiceIdField} />
          <ReactHookFormErrorMessage errors={errors} id={invoiceIdField} />
        </div>
      </div>
      {transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM &&
      isCreate ? null : (
        <div>
          <OfflineEventTypeSelectDropdown
            isCreate={isCreate}
            transactionType={transactionType}
            defaultEventType={offlineEventType}
            defaultDisplayEventType={offlineDisplayEventType}
            selectEventType={(item) => {
              setValue(offlineEventTypeField, item.value, {
                shouldDirty: true,
              });
              setValue(displayOfflineEventTypeField, item.label, {
                shouldDirty: true,
              });
            }}
          />
          <ReactHookFormErrorMessage
            errors={errors}
            id={offlineEventTypeField}
          />
        </div>
      )}

      {
        transactionType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS
          && offlineDisplayEventType === TRANSACTION_EVENT_TYPE.TYPE_PAID
        ? <>
            <CustomTitleLabel title="Transaction purchased date (optional)" />
            <CustomDateTimeSelect
              defaultTime={purchasedDate ? new Date(purchasedDate) : null}
              resetDate
              error={null}
              onTimeChange={(date) => {
                setValue(purchasedDateField, date, { shouldDirty: true });
              }}
              noNeedTime
            />
        </>
        : null
      }

      {showRefundSection ? (
        <div>
          <CustomTitleLabel title="Refunded invoice id" />
          <div>
            <input
              type="text"
              className={`text-input-field ${
                hasError(errors, refundedInvoiceIdField) ? 'error-field' : ''
              }`}
              onChange={(e) => {
                const value = e.target.value;
                setValue(refundedInvoiceIdField, value, { shouldDirty: true });
              }}
              value={refundedInvoiceId}
            />
          </div>
        </div>
      ) : null}
      <div>
        {/* <StaffNameSelectDropdown
          selectedStaff={staffName}
          selectStaff={(data) => {
            dispatch(
              createAction('createTransaction/updateTransactionState')({
                staffName: data,
              }),
            );
          }}
        /> */}
        <CustomTitleLabel title="Staff name" />
        <div>
          <input
            type="text"
            className={`text-input-field ${
              hasError(errors, staffNameField) ? 'error-field' : ''
            }`}
            onChange={(e) => {
              const value = e.target.value;
              setValue(staffNameField, value, { shouldDirty: true });
            }}
            value={staffName || ''}
          />
        </div>
        <ReactHookFormErrorMessage errors={errors} id={staffNameField} />
      </div>
      <div>
        <CustomTitleLabel title="Remarks (optional)" />
        <CustomEditor
          initialValue={remarks}
          onValueChange={(data) => {
            setValue(remarksField, data, { shouldDirty: true });
          }}
        />
      </div>
    </>
  );
}

export default TransactionGeneralSection;
