import React, { useEffect, useState } from 'react';
import { Dropdown, Image } from 'react-bootstrap';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { connect } from 'react-redux';

import CustomSwitchButton from 'components/base/CustomSwitchButton';
import ErrorFieldMessage, {
  CustomErrorMessage,
  hasReactHookFormError,
} from 'components/base/ErrorFieldMessage';
import BasePrompt from 'components/base/prompt/BasePrompt';
import { CustomTitleLabel } from 'components/earning/CustomBaseComponments';
import {
  GenderOptions,
  MonthOptions,
  PERIOD_TYPE_DISPLAY,
} from 'config/CustomEnums';
import {
  accountStatusDefinitionList,
  accountStatusDefinitionNameList,
  contentEnum,
  contentGroupEnum,
  scoreDefinitionList,
  scoreDefinitionNameList,
} from 'models/SegmentModel';

import AdditionalDataSection from './AdditionalDataSection';
import CampaignBehavioralSection from './CampaignBehavioralSection';
import CouponBehavioralSection from './CouponBehavioralSection';
import DiscountLoverSection from './DiscountLoverSection';
import DropdownToMutipleSelector from './DropdownToMutipleSelector';
import InputWithMutipleDropDown from './InputWithMutipleDropDown';
import LastPurchaseSection from './LastPurchaseSection';
import LookupWithMutipleNumber from './LookupWithMutipleNumber';
import MessageTrackingSection from './MessageTrackingSection';
import NumberOfTransactionSection from './NumberOfTransactionsSection';
import PointsToExpireSection from './PointsToExpireSection';
import ProductCategoryMultipleSelector from './ProductCategoryMultipleSelector';
import ProgressionPointsReachTargetSection from './ProgressionPointsReachTargetSection';
import PurchaseInStoreSection from './PurchaseInStoreSection';
import TransactionTypeMutipleSelector from './TransactionTypeMutipleSelector';

import 'utils/BtnCommonTheme.scss';

import DeleteIcon from 'assets/images/deleteIcon.png';
import Search from 'assets/images/drop_down_filter.svg';
import NarrowUp from 'assets/images/drop_down_not_show_narrow.svg';

import './Segment.scss';

const contentSections = {
  Demographic: [
    contentEnum.gender,
    contentEnum.birthday,
    contentEnum.age,
    contentEnum.countryCode,
    contentEnum.signupAnniversary,
  ],
  Behavioral: [
    contentEnum.level,
    contentEnum.lastPurchase,
    contentEnum.numberOfTransaction,
    contentEnum.totalSpending,
    contentEnum.averageTransaction,
    contentEnum.discountLover,
    contentEnum.purchaseInStore,
    contentEnum.newMember,
    contentEnum.newSignupWithReferral,
    contentEnum.numberOfLogin,
    contentEnum.lastLogin,
    contentEnum.campaignViewed,
    contentEnum.acquireOrUsedCoupon,
    contentEnum.earnReward,
    contentEnum.earnedPointsInLifetime,
    contentEnum.pointsToExpire,
    contentEnum.ppAlmostReachTarget,
    contentEnum.products,
    contentEnum.eComBindStatus,
    contentEnum.productCategory,
    contentEnum.messageTracking,
  ],
  RFM: scoreDefinitionNameList,
  'Account status': [
    contentEnum.accountStatusActive,
    contentEnum.accountStatusTemporaryEarning,
    contentEnum.accountStatusTemporary,
  ],
  'Additional data': [
    contentEnum.additionalDataFromCustomers,
    contentEnum.additionalDataFromTransactionRecords,
    contentEnum.additionalDataFromPurchasedItems,
  ],
};

function ContentFilterDropDown(props) {
  const sectionSelectAction = props.addContent || (() => {});
  const settedParams = props.settedParams || [];

  const [isOpen, setIsOpen] = useState({
    Demographic: false,
    Behavioral: false,
  });
  const openNarrow = (section) => {
    setIsOpen({ ...isOpen, [section]: !isOpen[section] });
  };
  const [contentSelections, setContentSelections] = useState(contentSections);
  const [filterKey, setFilterKey] = useState('');
  const [selectedSections, setSelectedSections] = useState(settedParams);

  useEffect(() => {
    setSelectedSections(props.settedParams);
  }, [props.settedParams]);

  useEffect(() => {
    setFilterKey('');
    setContentSelections(contentSections);
  }, [props.show]);

  return (
    <div className="content-add-button-selection-area">
      {/* <input className="content-add-button-selection-filter" /> */}
      <div className="filterable-search-bar content-add-button-selection-filter">
        <Image
          src={Search}
          className="filterable-search-bar-icon search_bar_icon"
        />
        <input
          autoFocus
          className="filterable-search-bar-input"
          placeholder="Search for..."
          onChange={(e) => {
            const value = e.target.value;
            setFilterKey(value);

            let filteredResult = {};
            Object.keys(contentSections).map(
              (key) =>
                (filteredResult[key] = contentSections[key].filter(
                  (val) => val.toLowerCase().indexOf(value.toLowerCase()) >= 0,
                )),
            );
            setContentSelections(filteredResult);
          }}
          value={filterKey}
        />
      </div>
      <div className="content-add-button-selection-section-area">
        {Object.keys(contentSelections).map((section) => {
          return (
            <>
              <ul className="content-add-button-selection-section">
                <label>{section}</label>
                <button
                  className="reset-button margin-left-auto"
                  onClick={(e) => {
                    e.preventDefault();
                    openNarrow(section);
                  }}
                >
                  <Image
                    src={NarrowUp}
                    className={`filterable-toogle-area-indicator ${
                      isOpen[section] ? 'rtoate180' : ''
                    }`}
                  />
                </button>
              </ul>

              {isOpen[section]
                ? contentSelections[section].map((val, index) => {
                    return selectedSections.includes(val) ? null : (
                      <Dropdown.Item
                        onClick={() => {
                          sectionSelectAction(val);
                          setSelectedSections([...selectedSections, val]);
                        }}
                      >
                        <div className="display-flex-area">
                          <div className="li-icon" />
                          <label className="li-label">{val}</label>
                        </div>
                      </Dropdown.Item>
                      // <div
                      //   className="display-flex-area drop-item"
                      //   onClick={() => {
                      //     sectionSelectAction(val);
                      //     setSelectedSections([...selectedSections, val]);
                      //   }}
                      //   role="button"
                      // >
                      //   <div className="li-icon" />
                      //   <label className="li-label">{val}</label>
                      // </div>
                    );
                  })
                : null}
            </>
          );
        })}
      </div>
    </div>
  );
}

const getDropdownToMutipleSelector = (
  subIndex,
  groupName,
  title,
  options,
  conditionName,
) => {
  const fieldBaseName = `${conditionName}.${subIndex}.${groupName}`;
  return (
    <DropdownToMutipleSelector
      fieldBaseName={fieldBaseName}
      title={title}
      mutipleSelector={{
        options,
      }}
    />
  );
};

const getLookupWithMutipleNumber = (
  subIndex,
  groupName,
  title,
  conditionName,
) => {
  const fieldBaseName = `${conditionName}.${subIndex}.${groupName}`;
  return (
    <LookupWithMutipleNumber
      fieldBaseName={fieldBaseName}
      groupName={groupName}
      title={title}
    />
  );
};

const getTimePeriodInput = (
  subIndex,
  groupName,
  title,
  hasNumber,
  rangeOptions,
  conditionName,
) => {
  const fieldBaseName = `${conditionName}.${subIndex}.${groupName}`;
  return (
    <InputWithMutipleDropDown
      fieldBaseName={fieldBaseName}
      title={title}
      hasNumber={hasNumber}
      rangeOptions={rangeOptions}
      hasPeriodDropdown
    />
  );
};

const FormSwitch = ({ fieldName }) => {
  const { setValue, control } = useFormContext();
  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field }) => (
        <CustomSwitchButton
          defaultChecked={field.value}
          onChange={(isChecked) => {
            setValue(field.name, isChecked, { shouldDirty: true });
          }}
        />
      )}
    />
  );
};

const getCustomTitleWithSwitch = (
  subIndex,
  groupName,
  title,
  tips,
  conditionName,
) => {
  const fieldBaseName = `${conditionName}.${subIndex}.${groupName}`;
  return (
    <div className="d-flex flex-column">
      <CustomTitleLabel title={title} />
      <FormSwitch fieldName={`${fieldBaseName}.enable`} />
      {tips ? <label className="tips-message">{tips}</label> : null}
    </div>
  );
};

const getCampaignView = (subIndex, groupName, title, conditionName) => {
  const fieldBaseName = `${conditionName}.${subIndex}.${groupName}`;
  return (
    <CampaignBehavioralSection
      title={title}
      groupName={groupName}
      fieldBaseName={fieldBaseName}
    />
  );
};

const getAcquireOrUsedCouponView = (
  subIndex,
  groupName,
  title,
  conditionName,
) => {
  const fieldBaseName = `${conditionName}.${subIndex}.${groupName}`;
  return (
    <CouponBehavioralSection title={title} fieldBaseName={fieldBaseName} />
  );
};

const getViewWithTransactionTypeFilter = (
  subIndex,
  subRenderFunc,
  subRenderArgs,
  groupName,
  conditionName,
) => {
  const fieldBaseName = `${conditionName}.${subIndex}.${groupName}`;
  return (
    <div>
      {subRenderFunc(subIndex, ...subRenderArgs)}
      <TransactionTypeMutipleSelector fieldBaseName={fieldBaseName} />
    </div>
  );
};

const getProductCategory = (subIndex, groupName, conditionName) => {
  const fieldBaseName = `${conditionName}.${subIndex}.${groupName}`;
  return (
    <div className="d-flex flex-column">
      <ProductCategoryMultipleSelector fieldBaseName={fieldBaseName} />
      <TransactionTypeMutipleSelector fieldBaseName={fieldBaseName} />
    </div>
  );
};

const getNumberOfTransactionSection = (
  subIndex,
  title,
  groupName,
  conditionName,
) => {
  const fieldBaseName = `${conditionName}.${subIndex}.${groupName}`;
  return (
    <NumberOfTransactionSection
      title={title}
      groupName={groupName}
      fieldBaseName={fieldBaseName}
    />
  );
};

const getDiscountLoverSection = (subIndex, title, groupName, conditionName) => {
  const fieldBaseName = `${conditionName}.${subIndex}.${groupName}`;
  return (
    <DiscountLoverSection
      title={title}
      groupName={groupName}
      fieldBaseName={fieldBaseName}
    />
  );
};

const getPurchaseInStoreSection = (
  subIndex,
  title,
  groupName,
  conditionName,
) => {
  const fieldBaseName = `${conditionName}.${subIndex}.${groupName}`;
  return (
    <PurchaseInStoreSection
      title={title}
      groupName={groupName}
      fieldBaseName={fieldBaseName}
    />
  );
};

const getAdditionalDataSection = (
  subIndex,
  title,
  groupName,
  conditionName,
) => {
  const fieldBaseName = `${conditionName}.${subIndex}.${groupName}`;
  return (
    <AdditionalDataSection
      title={title}
      groupName={groupName}
      fieldBaseName={fieldBaseName}
    />
  );
};

const getMessageTrackingSection = (
  subIndex,
  title,
  groupName,
  conditionName,
) => {
  const fieldBaseName = `${conditionName}.${subIndex}.${groupName}`;
  return (
    <MessageTrackingSection
      title={title}
      groupName={groupName}
      fieldBaseName={fieldBaseName}
    />
  );
};

const getPointsToExpireSection = (
  subIndex,
  title,
  groupName,
  conditionName,
) => {
  const fieldBaseName = `${conditionName}.${subIndex}.${groupName}`;
  return (
    <PointsToExpireSection
      title={title}
      groupName={groupName}
      fieldBaseName={fieldBaseName}
    />
  );
};

const getLastPurchaseSection = (subIndex, title, groupName, conditionName) => {
  const fieldBaseName = `${conditionName}.${subIndex}.${groupName}`;
  return (
    <LastPurchaseSection
      title={title}
      groupName={groupName}
      fieldBaseName={fieldBaseName}
    />
  );
};

const getPpReachTargetSection = (subIndex, title, groupName, conditionName) => {
  const fieldBaseName = `${conditionName}.${subIndex}.${groupName}`;
  return (
    <ProgressionPointsReachTargetSection
      title={title}
      fieldBaseName={fieldBaseName}
    />
  );
};

const CustomFieldController = ({ fieldName, value }) => {
  const { setValue, control } = useFormContext();

  useEffect(() => {
    setValue(fieldName, value, { shouldDirty: false });
  }, []);

  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field }) => <></>}
    />
  );
};

const getCustomRecencyFrequencyMonetaryTitle = (
  subIndex,
  conditionName,
  scoreDefinition,
  name,
) => {
  const fieldName = `${conditionName}.${subIndex}.${
    contentGroupEnum[contentEnum.champions]
  }.scoreDefinition`;

  return (
    <div>
      <CustomTitleLabel title={`RFM - ${name}`} />
      <CustomFieldController fieldName={fieldName} value={scoreDefinition} />
    </div>
  );
};

const getRecencyFrequencyMonetaryFieldNumber = (fields = []) => {
  let number = 0;
  fields.forEach((item) => {
    if (scoreDefinitionNameList.indexOf(item.title) > -1) {
      number += 1;
    }
  });
  return number;
};

const getAccountStatusTitle = (
  subIndex,
  conditionName,
  accountStatusDefinition,
  name,
  groupName,
) => {
  const fieldName = `${conditionName}.${subIndex}.${groupName}.accountStatus`;
  return (
    <div>
      <CustomTitleLabel title={`Account status: ${name}`} />
      <CustomFieldController
        fieldName={fieldName}
        value={accountStatusDefinition}
      />
    </div>
  );
};

const getAccountStatusFieldNumber = (fields = []) => {
  let number = 0;
  fields.forEach((item) => {
    if (accountStatusDefinitionNameList.indexOf(item.title) > -1) {
      number += 1;
    }
  });
  return number;
};

function ContentItemSection({ index, levels, countryCodes, removeConditionGroups }) {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();
  const conditionIndex = index + 1;
  const conditionName = `conditionGroups.${index}.conditions`;
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: conditionName,
  });
  const [showResetPrompt, setShowResetPrompt] = useState(false);
  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const contentSectionComponment = {
    [contentEnum.gender]: {
      renderer: getDropdownToMutipleSelector,
      rendererArgs: [
        contentGroupEnum[contentEnum.gender],
        contentEnum.gender,
        GenderOptions,
        conditionName,
      ],
    },
    [contentEnum.birthday]: {
      renderer: getDropdownToMutipleSelector,
      rendererArgs: [
        contentGroupEnum[contentEnum.birthday],
        contentEnum.birthday,
        MonthOptions,
        conditionName,
      ],
    },
    [contentEnum.age]: {
      renderer: getLookupWithMutipleNumber,
      rendererArgs: [
        contentGroupEnum[contentEnum.age],
        contentEnum.age,
        conditionName,
      ],
    },
    [contentEnum.countryCode]: {
      renderer: getDropdownToMutipleSelector,
      rendererArgs: [
        contentGroupEnum[contentEnum.countryCode],
        contentEnum.countryCode,
        countryCodes,
        conditionName,
      ],
    },
    [contentEnum.signupAnniversary]: {
      renderer: getLookupWithMutipleNumber,
      rendererArgs: [
        contentGroupEnum[contentEnum.signupAnniversary],
        contentEnum.signupAnniversary,
        conditionName,
      ],
    },
    [contentEnum.level]: {
      renderer: getDropdownToMutipleSelector,
      rendererArgs: [
        contentGroupEnum[contentEnum.level],
        contentEnum.level,
        levels,
        conditionName,
      ],
    },

    [contentEnum.lastPurchase]: {
      renderer: getLastPurchaseSection,
      rendererArgs: [
        contentEnum.lastPurchase,
        contentGroupEnum[contentEnum.lastPurchase],
        conditionName,
      ],
    },
    [contentEnum.numberOfTransaction]: {
      renderer: getNumberOfTransactionSection,
      rendererArgs: [
        contentEnum.numberOfTransaction,
        contentGroupEnum[contentEnum.numberOfTransaction],
        conditionName,
      ],
    },
    [contentEnum.totalSpending]: {
      renderer: getViewWithTransactionTypeFilter,
      rendererArgs: [
        getLookupWithMutipleNumber,
        [
          contentGroupEnum[contentEnum.totalSpending],
          contentEnum.totalSpending,
          conditionName,
        ],
        contentGroupEnum[contentEnum.totalSpending],
        conditionName,
      ],
    },
    [contentEnum.averageTransaction]: {
      renderer: getViewWithTransactionTypeFilter,
      rendererArgs: [
        getLookupWithMutipleNumber,
        [
          contentGroupEnum[contentEnum.averageTransaction],
          contentEnum.averageTransaction,
          conditionName,
        ],
        contentGroupEnum[contentEnum.averageTransaction],
        conditionName,
      ],
    },
    [contentEnum.newMember]: {
      renderer: getTimePeriodInput,
      rendererArgs: [
        contentGroupEnum[contentEnum.newMember],
        contentEnum.newMember,
        false,
        [
          PERIOD_TYPE_DISPLAY.WITHIN,
          PERIOD_TYPE_DISPLAY.EQUAL_TO,
          PERIOD_TYPE_DISPLAY.BETWEEN,
          PERIOD_TYPE_DISPLAY.GREATER_THAN,
          PERIOD_TYPE_DISPLAY.LESS_THAN,
        ],
        conditionName,
      ],
    },
    [contentEnum.newSignupWithReferral]: {
      renderer: getCustomTitleWithSwitch,
      rendererArgs: [
        contentGroupEnum[contentEnum.newSignupWithReferral],
        contentEnum.newSignupWithReferral,
        null,
        conditionName,
      ],
    },
    [contentEnum.numberOfLogin]: {
      renderer: (subIndex) => {
        const fieldBaseName = `${conditionName}.${subIndex}.${
          contentGroupEnum[contentEnum.numberOfLogin]
        }`;
        const fieldName = `${fieldBaseName}.numberOfLogin`;
        return (
          <div className="d-flex flex-column">
            <CustomTitleLabel title={contentEnum.numberOfLogin} />
            <div style={{ display: 'flex' }}>
              <input
                type="number"
                min={'0'}
                className={`custom-number-input-short ${
                  hasReactHookFormError(errors, fieldName) ? 'error-field' : ''
                }`}
                {...register(fieldName, {
                  required: 'This field is required.',
                })}
              />
              <label className="help-message margin-left-10-percent margin-top-8-percent">
                times
              </label>
            </div>
            <CustomErrorMessage name={fieldName} />
          </div>
        );
      },
      rendererArgs: [],
    },
    [contentEnum.lastLogin]: {
      renderer: getTimePeriodInput,
      rendererArgs: [
        contentGroupEnum[contentEnum.lastLogin],
        contentEnum.lastLogin,
        false,
        null,
        conditionName,
      ],
    },
    [contentEnum.campaignViewed]: {
      renderer: getCampaignView,
      rendererArgs: [
        contentGroupEnum[contentEnum.campaignViewed],
        contentEnum.campaignViewed,
        conditionName,
      ],
    },
    [contentEnum.acquireOrUsedCoupon]: {
      renderer: getAcquireOrUsedCouponView,
      rendererArgs: [
        contentGroupEnum[contentEnum.acquireOrUsedCoupon],
        contentEnum.acquireOrUsedCoupon,
        conditionName,
      ],
    },
    [contentEnum.earnReward]: {
      renderer: getCampaignView,
      rendererArgs: [
        contentGroupEnum[contentEnum.earnReward],
        contentEnum.earnReward,
        conditionName,
      ],
    },
    [contentEnum.earnedPointsInLifetime]: {
      renderer: getLookupWithMutipleNumber,
      rendererArgs: [
        contentGroupEnum[contentEnum.earnedPointsInLifetime],
        contentEnum.earnedPointsInLifetime,
        conditionName,
      ],
    },
    [contentEnum.pointsToExpire]: {
      renderer: getPointsToExpireSection,
      rendererArgs: [
        contentEnum.pointsToExpire,
        contentGroupEnum[contentEnum.pointsToExpire],
        conditionName,
      ],
    },
    [contentEnum.ppAlmostReachTarget]: {
      renderer: getPpReachTargetSection,
      rendererArgs: [
        contentEnum.ppAlmostReachTarget,
        contentGroupEnum[contentEnum.ppAlmostReachTarget],
        conditionName,
      ],
    },
    [contentEnum.products]: {
      renderer: (subIndex) => {
        const fieldBaseName = `${conditionName}.${subIndex}.${
          contentGroupEnum[contentEnum.products]
        }`;
        const fieldName = `${fieldBaseName}.productSkus`;
        return (
          <div className="d-flex flex-column">
            <CustomTitleLabel title={contentEnum.products} />
            <textarea
              className={`custom-textarea-input-box custom-textarea-input-font ${
                hasReactHookFormError(errors, fieldName) ? 'error-field' : ''
              }`}
              {...register(fieldName, { required: 'This field is required.' })}
            />
            <CustomErrorMessage name={fieldName} />
            <TransactionTypeMutipleSelector fieldBaseName={fieldBaseName} />
          </div>
        );
      },
      rendererArgs: [],
    },
    [contentEnum.eComBindStatus]: {
      renderer: getCustomTitleWithSwitch,
      rendererArgs: [
        contentGroupEnum[contentEnum.eComBindStatus],
        contentEnum.eComBindStatus,
        null,
        conditionName,
      ],
    },
    [contentEnum.productCategory]: {
      renderer: getProductCategory,
      rendererArgs: [
        contentGroupEnum[contentEnum.productCategory],
        conditionName,
      ],
    },
    [contentEnum.discountLover]: {
      renderer: getDiscountLoverSection,
      rendererArgs: [
        contentEnum.discountLover,
        contentGroupEnum[contentEnum.discountLover],
        conditionName,
      ],
    },
    [contentEnum.purchaseInStore]: {
      renderer: getPurchaseInStoreSection,
      rendererArgs: [
        contentEnum.purchaseInStore,
        contentGroupEnum[contentEnum.purchaseInStore],
        conditionName,
      ],
    },
    [contentEnum.additionalDataFromCustomers]: {
      renderer: getAdditionalDataSection,
      rendererArgs: [
        contentEnum.additionalDataFromCustomers,
        contentGroupEnum[contentEnum.additionalDataFromCustomers],
        conditionName,
      ],
    },
    [contentEnum.additionalDataFromTransactionRecords]: {
      renderer: getAdditionalDataSection,
      rendererArgs: [
        contentEnum.additionalDataFromTransactionRecords,
        contentGroupEnum[contentEnum.additionalDataFromTransactionRecords],
        conditionName,
      ],
    },
    [contentEnum.additionalDataFromPurchasedItems]: {
      renderer: getAdditionalDataSection,
      rendererArgs: [
        contentEnum.additionalDataFromPurchasedItems,
        contentGroupEnum[contentEnum.additionalDataFromPurchasedItems],
        conditionName,
      ],
    },
    [contentEnum.messageTracking]: {
      renderer: getMessageTrackingSection,
      rendererArgs: [
        contentEnum.messageTracking,
        contentGroupEnum[contentEnum.messageTracking],
        conditionName,
      ],
    },
  };

  scoreDefinitionList.forEach((item) => {
    contentSectionComponment[item.name] = {
      renderer: getCustomRecencyFrequencyMonetaryTitle,
      rendererArgs: [conditionName, item.value, item.name],
    };
  });
  accountStatusDefinitionList.forEach((item) => {
    contentSectionComponment[item.name] = {
      renderer: getAccountStatusTitle,
      rendererArgs: [conditionName, item.value, item.name, item.groupName],
    };
  });
  const recencyFrequencyMonetaryFieldNumber =
    getRecencyFrequencyMonetaryFieldNumber(fields);
  const cccountStatusFieldNumber = getAccountStatusFieldNumber(fields);

  return (
    <>
      <div className="content-area">
        <div className="title-in-block">
          <label className="create-section-title">
            AND Condition group {conditionIndex}
          </label>
          {fields.length > 0 ? (
            <button
              type="button"
              className="btn-link reset-button"
              onClick={() => setShowResetPrompt(true)}
            >
              Reset
            </button>
          ) : null}
          <button
            className="delete-condition-group-button"
            type="button"
            onClick={() => {
              replace([]);
              removeConditionGroups(index);
            }}
          >
            <Image src={DeleteIcon} className="content-delete-button" />
          </button>
        </div>
        <div className="title-error-area">
          <ErrorFieldMessage
            id={`condition-group-${conditionIndex}-rfm-error`}
            error={recencyFrequencyMonetaryFieldNumber > 1}
            message='There will be no result if multiple RFM segments are selected with "AND" relationships. Try using "OR" relationships instead or selecting a single RFM segment only.'
          />
          <ErrorFieldMessage
            id={`condition-group-${conditionIndex}-account-status-error`}
            error={cccountStatusFieldNumber > 1}
            message='There will be no result if multiple Account status segments are selected with "AND" relationships. Try using "OR" relationships instead or selecting a single Account status segment only.'
          />
        </div>
        {fields.map((item, subIndex) => {
          const componmentConfig = contentSectionComponment[item.title];
          return (
            <>
              <div className="display-flex-area and-condition">
                {componmentConfig &&
                  componmentConfig.renderer(
                    subIndex,
                    ...componmentConfig.rendererArgs,
                  )}
                <button
                  className="reset-button content-delete-button-area"
                  type="button"
                  onClick={() => {
                    remove(subIndex);
                  }}
                >
                  <Image src={DeleteIcon} className="content-delete-button" />
                </button>
              </div>
              <div className="content-condition-area">
                <div className="content-condition-dash"></div>
                <label>AND</label>
              </div>
            </>
          );
        })}
        <Dropdown
          onToggle={(isOpen) => {
            setIsShowDropdown(isOpen);
          }}
        >
          <Dropdown.Toggle
            className="btn-back-button-common content-add-button"
            variant="none"
            id={`dropdown-${conditionIndex}`}
          >
            + Add AND Condition
          </Dropdown.Toggle>

          <Dropdown.Menu style={{ zIndex: 2 }}>
            <ContentFilterDropDown
              show={isShowDropdown}
              addContent={(section) => {
                append({ title: section });
              }}
              settedParams={fields?.map((item) => item.title)}
            />
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <BasePrompt
        show={showResetPrompt}
        title={`Reset condition group ${conditionIndex}`}
        description={'Did you confirm to reset this content section?'}
        closeAction={() => setShowResetPrompt(false)}
        leftButton={{
          text: 'Cancel',
          action: () => {
            setShowResetPrompt(false);
          },
        }}
        rightButton={{
          text: 'Delete',
          action: () => {
            replace([]);
            setShowResetPrompt(false);
          },
        }}
      />

      <div className="content-condition-area content-condition-or-area">
        <div className="content-condition-solid"></div>
        <label>OR</label>
      </div>
    </>
  );
}
const mapPropsToState = (state) => ({
  levels: state.levels.levelList,
  countryCodes: state.settings.countryCodes,
  // segment: state.segments.tempSegment,
  // createErrorDict: state.segments.createErrorDict,
  // tempSegmentConditionGroup: state.segments.tempSegment?.conditionGroup,
});

export default connect(mapPropsToState)(ContentItemSection);
