import { BaseHelper } from './BaseGraphQLAPIHelper';

const SEGMENT_CONTENT = `
conditionGroups(orderBy: "displayOrder") {
  edges {
      node {
        pk
        id
        displayOrder
        genderCharacteristic {
            id
            pk
            lookup
            genders(first: 10) {
                edges {
                    node {
                        id
                        pk
                        name
                    }
                }
            }
            displayOrder
        }
        monthOfBirthCharacteristic {
            id
            pk
            lookup
            months(first: 10) {
                edges {
                    node {
                        id
                        pk
                        name
                    }
                }
            }
            displayOrder
        }
        ageCharacteristic {
            id
            pk
            lookup
            age
            minimumAge
            maximumAge
            displayOrder
        }
        signUpAnniversaryCharacteristic {
            id
            pk
            lookup
            numberOfYears
            minimumNumberOfYears
            maximumNumberOfYears
            displayOrder
        }
        countryCodeCharacteristic {
            id
            pk
            lookup
            countryCodes(first: 10) {
                edges {
                    node {
                        id
                        pk
                        name
                    }
                }
            }
            displayOrder
        }
        hasRecentPurchaseCharacteristic {
            id
            pk
            period
            date
            minimumDate
            maximumDate
            inXNumberOfDays
            transactionTypes
            displayOrder
        }
        numberOfTransactionsCharacteristic {
            id
            pk
            period
            date
            minimumDate
            maximumDate
            inXNumberOfDays
            type
            lookup
            quantity
            minimumQuantity
            maximumQuantity
            displayOrder
            transactionTypes
        }
        totalSpendingCharacteristic {
            id
            pk
            lookup
            dollars
            minimumDollars
            maximumDollars
            transactionTypes
            displayOrder
        }
        averageTransactionValueCharacteristic {
            id
            pk
            lookup
            dollars
            minimumDollars
            maximumDollars
            transactionTypes
            displayOrder
        }
        levelCharacteristic {
          id
          pk
          levels(first: 10) {
              edges {
                  node {
                      id
                      pk
                      levelName
                      translations(first: 10) {
                          edges {
                              node {
                                  levelName
                                  levelBenefitUrl
                              }
                          }
                      }
                  }
              }
          }
          displayOrder
        }
        signUpMemberCharacteristic {
            pk
            period
            date
            minimumDate
            maximumDate
            displayOrder
        }
        signUpReferralCharacteristic {
            pk
            invited
            displayOrder
        }
        lastLoginCharacteristic {
            pk
            period
            date
            minimumDate
            maximumDate
            displayOrder
        }
        loginTimeCharacteristic {
            pk
            times
            displayOrder
        }
        productCharacteristic {
            pk
            productsSku
            transactionTypes
            displayOrder
        }
        earningCampaignCharacteristic {
          pk
          behavioralType
          catalogType
          campaign {
              pk
              name
          }
          campaignCategory {
              pk
              name
          }
          numberOfParticipateTimes
          viewCampaignType
          period
          date
          minimumDate
          maximumDate
          numberOfViewCampaignTimes
          stayPeriodType
          stayPeriod
          displayOrder
        }
        couponCampaignCharacteristic {
          pk
          behavioralType
          catalogType
          campaign {
              pk
              name
          }
          campaignCategory {
              pk
              name
          }
          numberOfParticipateTimes
          viewCampaignType
          period
          date
          minimumDate
          maximumDate
          numberOfViewCampaignTimes
          stayPeriodType
          stayPeriod
          displayOrder
        }
        viewCampaignCharacteristic {
          pk
          behavioralType
          catalogType
          campaign {
              pk
              name
          }
          campaignCategory {
              pk
              name
          }
          numberOfParticipateTimes
          viewCampaignType
          period
          date
          minimumDate
          maximumDate
          numberOfViewCampaignTimes
          stayPeriodType
          stayPeriod
          displayOrder
        }
        couponCharacteristic {
          pk
          behavioralType
          couponSet {
              pk
              name
          }
          numberOfBehavioralTimes
          period
          date
          minimumDate
          maximumDate
          displayOrder
        }
        bindingShopifyCharacteristic {
          pk
          bound
          displayOrder
        }
        recencyFrequencyMonetaryScoreCharacteristic {
          pk
          displayOrder
          scoreDefinition
        }
        productCategoryCharacteristic {
          pk
          productCategories(first: 100) {
            edges {
              node {
                pk
                name
              }
            }
          }
          productSubcategories(first: 100) {
            edges {
              node {
                pk
                name
              }
            }
          }
          transactionTypes
          displayOrder
        }
        discountLoverCharacteristic {
          pk
          period
          date
          minimumDate
          maximumDate
          inXNumberOfDays
          times
          displayOrder
          type
          lookup
          minimumTimes
          maximumTimes
        }
        customerAdditionalDataCharacteristic {
          pk
          lookup
          fieldName
          textValue
          numberValue
          minimumValue
          maximumValue
          displayOrder
        }
        transactionAdditionalDataCharacteristic {
          pk
          lookup
          fieldName
          textValue
          numberValue
          minimumValue
          maximumValue
          displayOrder
        }
        purchasedItemAdditionalDataCharacteristic {
          pk
          lookup
          fieldName
          textValue
          numberValue
          minimumValue
          maximumValue
          displayOrder
        }
        accountStatusCharacteristic {
          pk
          displayOrder
          accountStatus
        }
        segmentMessageTrackingCharacteristic {
          pk
          displayOrder
          trackingType
          message {
            pk
            name
          }
        }
        segmentPurchaseInStoreCharacteristic {
          pk
          displayOrder
          period
          date
          minimumDate
          maximumDate
          inXNumberOfDays
          storeType
          stores {
            edges {
              node {
                pk
                name
                visibleInFrontEnd
              }
            }
          }
          type
          lookup
          times
          minimumTimes
          maximumTimes
        }
        segmentEarnedPointsInLifetimeCharacteristic {
          pk
          displayOrder
          lookup
          points
          minimumPoints
          maximumPoints
        }
        segmentPointsToExpireCharacteristic {
          pk
          displayOrder
          dateType
          number
        }
        segmentPpReachTargetCharacteristic {
          pk
          displayOrder
          lookup
          target
          numberType
          progressionPoints
          minimumProgressionPoints
          maximumProgressionPoints
        }
      }
  }
}
`;

export const getSegmentList = (afterCursor = '', search) => {
  let order = '-pk';
  let extraSearch = ''
  if (search.moreSearch?.sort) {
    order = search.moreSearch?.sort;
  }
  if (search.moreSearch?.defaultRecencyfrequencySegment != null || search.moreSearch?.defaultRecencyfrequencySegment !== undefined) {
    extraSearch += `recencyfrequencymonetaryscoreDefaultSegment: ${search.moreSearch?.defaultRecencyfrequencySegment}`
  }
  //name: "${search}",
  //   orderBy: "${order}",
  console.log('@@255: ', search.type, search.isSelectorLoad);
  const query = `
{
  segments(
    first: ${search.pageSize ? search.pageSize : 20},
    after: "${afterCursor}",
    nameIcontains: "${search.search || ''}",
    orderBy: "${order}",
    dateOfBirthRange: ${JSON.stringify(search.dateOfBirthRange) || "[]"},
    ${extraSearch}
  ) {
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        pk
        name
        description
        totalCustomers
        defaultSegment
        scoreDefinitionType
      }
    }
  }
}

  `;
  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneSegment = (id) => {
  //   orderBy: "${order}",
  const query = `
    {
      segment(id: "${id}") {
        id
        pk
        name
        description
        totalCustomers
        defaultSegment
        customers {
          edges {
              node {
                  pk
                  firstName
              }
          }
        }
        lastUpdateCustomersDate
        ${SEGMENT_CONTENT}
      }
    }
    `;
  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createOrUpdateSegment = (value) => {
  const createUpdateHeader = value.id ? 'Update' : 'Create';

  const query = `
  mutation ${createUpdateHeader}Segment($input: ${createUpdateHeader}SegmentInput!) {
    ${createUpdateHeader.toLowerCase()}Segment(input: $input) {
      node {
        pk
        id
        name
        ${SEGMENT_CONTENT}
      }
      errors {
        field
        messages
      }
    }
  }
  `;

  const variables = {
    input: value,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateSegment = (value) => {
  const query = `
  mutation UpdateSegment($input: UpdateSegmentInput!) {
    updateSegment(input: $input) {
      node {
        id
        pk
        name
        ${SEGMENT_CONTENT}
      }
      errors {
        field
        messages
      }
    }
  }
  `;

  const variables = {
    input: value,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteSegments = (ids) => {
  const query = `mutation DeleteSegments($input: DeleteSegmentsInput!) {
    deleteSegments(input: $input) {
      success
    }
  }`;

  const variables = {
    input: {
      ids: ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
