import { EarningRuleTypeKey } from 'config/CustomEnums';
import { BaseHelper } from 'services/BaseGraphQLAPIHelper';

import { getInFilterValue } from 'utils';

export const getCouponTemplateList = (afterCursor, search) => {
  let order = '-pk';
  if (search.rank === false || search.rank === 'false') {
    order = 'pk';
  }

  let searchString = `, orderBy: "${order}"`;
  if (search?.sort) {
    searchString = `, orderBy: "${search?.sort}"`;
  }
  if (search.all || search.isAll) {
    searchString += `, first: ${search.pageSize || 100}`;
  } else {
    searchString += `, first: 20`;
  }
  if (search.coupon_medium) {
    searchString += `, mediumTypeIn: ${getInFilterValue(search.coupon_medium)}`;
  }
  if (search.out_of_stock) {
    searchString += `, outOfStock: ${search.out_of_stock}`;
  }
  if (search.status) {
    searchString += `, isActive: ${search.status}`;
  }
  if (search.expired) {
    searchString += `, isExpired: ${search.expired}`;
  }
  if (search.coupon_type) {
    searchString += `, couponTemplateTypeIn: ${getInFilterValue(
      search.coupon_type,
    )}`;
  }
  if (search.redemption_method) {
    searchString += `, redemptionMethodIn: ${getInFilterValue(
      search.redemption_method,
    )}`;
  }
  if (search.expired_date) {
    switch (search.expired_date) {
      case 'absolute':
        if (search.absolute) {
          const expiredDateRange = search.absolute.split(',');
          searchString += `, absoluteGte: "${expiredDateRange[0]}", absoluteLte: "${expiredDateRange[1]}"`;
        }
        break;
      case 'relativeDay':
        if (search.relativeDay) {
          searchString += `, relativeDay: ${search.relativeDay}`;
        }
        break;
      default:
        searchString += `, allTime: true`;
        break;
    }
  }

  if (search.search || search.searchKey || search.filterName) {
    searchString += `, nameIcontains: "${
      search.search || search.searchKey || search.filterName
    }"`;
  }

  const query = `{
    couponTemplates(after: "${afterCursor}"${searchString}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          name
          shouldUseInStore
          isForcedInactive
          instructionSectionTitle
          instructionSectionContent
          detailSectionTitle
          detailSectionContent
          coverPhoto
          detailPhoto1
          detailPhoto2
          detailPhoto3
          detailPhoto4
          applyCoverPhotoToAllLanguage
          applyDetailPhotosToAllLanguage
          lastModifiedDate
          creationDate
          linkedCampaignsName
          absoluteEffectiveDate
          absoluteExpiryDate
          status
          stock
          onlineStock
          offlineStock
          totalNumberOfGeneratedCoupons
          totalNumberOfGeneratedOnlineCoupons
          totalNumberOfGeneratedOfflineCoupons
          totalNumberOfAcquiredCoupons
          totalNumberOfUsedCoupons
          validPeriodType
          numberOfDaysToExpireAfterAcquisition
          offlineFormats
        }
      }
    }
  }
  `;
  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCouponSet = (couponSetID) => {
  const getEarningRuleQueryString= () => {
    const earningRuleNotLinkEarningCampaignQuery = [
      EarningRuleTypeKey.GENERAL_PURCHASE,
      EarningRuleTypeKey.BIRTHDAY,
      EarningRuleTypeKey.NEW_MEMBER,
      'fillingFormType',
      EarningRuleTypeKey.LEVEL_UPGRADE,
    ]?.map(item=> `
    ${item}TypeEarningRules {
      edges {
        node {
          pk
          id
          name
        }
      }
    }  
    `)
    const earningRuleLinkEarningCampaignQuery = [
      EarningRuleTypeKey.MEMBER_REFERRAL,
      EarningRuleTypeKey.QR_CODE_SCANNING,
      EarningRuleTypeKey.GPS_CHECK_IN,
    ]?.map(item => `
    ${item}TypeEarningRules {
      edges {
        node {
          pk
          id
          name
          campaigns {
            edges {
              node {
                pk
                id
                name
              }
            }
          }
        }
      }
    }  
    `)
    const queryList = [...earningRuleNotLinkEarningCampaignQuery, ...earningRuleLinkEarningCampaignQuery]
    return queryList.join()
  }
  const query = `{
    couponTemplate(id: "${couponSetID}") {
      id
      pk
      name
      shortDescription
      lastModifiedDate
      creationDate
      instructionSectionTitle
      instructionSectionContent
      detailSectionTitle
      detailSectionContent
      validPeriodType
      absoluteEffectiveDate
      absoluteExpiryDate
      numberOfDaysToExpireAfterAcquisition
      isForcedInactive
      blackoutPeriod {
        edges{
          node {
            pk
            startDate
            endDate
          }
        }
      }
      blackoutWeekday
      translations {
        edges {
          node {
            language
            name
            shortDescription
            id
            pk
            instructionSectionTitle
            instructionSectionContent
            detailSectionTitle
            detailSectionContent
            coverPhoto
            detailPhoto1
            detailPhoto2
            detailPhoto3
            detailPhoto4
          }
        }
      }
      brand {
        id
        name
        pk
        translations {
          edges {
            node {
              language
              name
              description
              id
            }
          }
        }
        stores {
          edges {
            node {
              id
              pk
              name
              address
              description
              visibleInFrontEnd
              translations {
                edges {
                  node {
                    language
                    name
                    address
                    description
                  }
                }
              }
            }
          }
        }
      }
      applicableStores {
        edges {
          node {
            id
            pk
            name
            address
            description
            isOnlineStore
            visibleInFrontEnd
            translations {
              edges {
                node {
                  language
                  name
                  address
                  description
                }
              }
            }
          }
        }
      }
      shouldUseInStore
      coverPhoto
      detailPhoto1
      detailPhoto2
      detailPhoto3
      detailPhoto4
      applyCoverPhotoToAllLanguage
      applyDetailPhotosToAllLanguage
      # computed fields
      totalNumberOfGeneratedCoupons
      totalNumberOfGeneratedOnlineCoupons
      totalNumberOfGeneratedOfflineCoupons
      totalNumberOfAcquiredCoupons
      totalNumberOfUsedCoupons
      stock
      onlineStock
      offlineStock
      linkedCampaignsName
      status
      mediumType
      isDifferentCodeForOnlineOffline
      onlineCouponTemplateType
      onlineRedemptionMethod
      onlineFormats
      onlineCouponCodeType
      onlineGenericCode
      offlineFormats
      offlineBarcodeFormat {
        id
        pk
        name
      }
      offlineRedemptionMethod
      offlineCouponTemplateType
      offlineCouponCodeType
      offlineGenericCode
      offlineShowCopiableString
      showOnlineOfflineInput
      offlineShouldImportByFile
      onlineShouldImportByFile
      campaigns {
        edges {
          node {
            id
            pk
            name
            type
          }
        }
      }
      stampRewards{
        edges{
            node{
              id
              pk
              linkedStampCampaign{
                  id
                  pk
                  name
              }
            }
        }
      }
      ${getEarningRuleQueryString()}
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createCouponTemplate = (couponTemplateInfo) => {
  const query = `mutation CreateCouponTemplate($input: CreateCouponTemplateInput!) {
      createCouponTemplate(input: $input) {
        node {
          id
          pk
          name
          mediumType
          coverPhoto
          brand {
            name
          }
          offlineCouponTemplateType
          offlineCouponCodeType
          offlineRedemptionMethod
          offlineFormats
          offlineBarcodeFormat {
            pk
          }
          applicableStores {
            edges {
              node {
                id
                pk
                name
                isOnlineStore
                visibleInFrontEnd
                address
                description
              }
            }
          }
          shouldUseInStore
          offlineShowCopiableString
          isDifferentCodeForOnlineOffline
          showOnlineOfflineInput
          offlineShouldImportByFile
          onlineShouldImportByFile
        }
        errors {
          field
          messages
        }
      }
    }`;
  const variables = {
    input: {
      name: 'campaign',
      ...couponTemplateInfo,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const generateCoupon = (payload) => {
  const query = `mutation GenerateCoupons($input: GenerateCouponsInput!) {
      generateCoupons(input: $input) {
        success
      }
    }`;
  const variables = {
    input: {
      ...payload,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getSingleCouponListWithTemplate = (
  templateID,
  afterCursor = null,
  rank = null,
  filterKey = null,
  virtualRealTypeIn = ['REAL', 'NORMAL'],
) => {
  let order = '-pk';
  if (rank === false || rank === 'false') {
    order = 'pk';
  }
  const query = `{
    couponTemplate(id: "${templateID}") {
      coupons(first: 20, orderBy: "${order}", after: "${afterCursor}",
      virtualRealTypeIn: ${getInFilterValue(virtualRealTypeIn)},
      serialNumberIcontains: "${filterKey || ''}") {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            pk
            serialNumber
            creationDate
            lastModifiedDate
            dateOfPurchase
            dateOfEarning
            dateOfGrant
            isUsed
            isExpired
            isActive
            isForcedInactive
            isEffective
            effectiveDate
            expiryDate
            referenceId
            owner {
              id
              pk
              firstName
              lastName
            }
            store {
              id
              pk
              name
              staffCode
            }
          }
        }
      }
    }
  }`;
  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getAllSingleCouponWithTemplate = (templateID) => {
  const query = `{
    couponTemplate(id: "${templateID}") {
      coupons {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            pk
            dateOfGrant
            serialNumber
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getSingleCoupon = (id) => {
  const query = `
  {
    coupon(id: "${id}") {
      id
      pk
      template {
        name
        offlineFormats
        brand {
          name
        }
        offlineBarcodeFormat {
          id
          pk
          name
        }
        offlineRedemptionMethod
      }
      isForcedInactive
      store {
        name
        visibleInFrontEnd
      }
      owner {
        pk
        firstName
        lastName
        membershipId
      }
      dateOfPurchase
      dateOfEarning
      dateOfGrant
      serialNumber
      code
      isUsed
      isExpired
      isActive
      effectiveDate
      expiryDate
      creationDate
      lastModifiedDate
      referenceId
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deactiveCoupons = (ids) => {
  const query = `
  mutation DeactivateCoupons($input: DeactivateCouponsInput!) {
    deactivateCoupons(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteSingleCoupon = (couponPK) => {
  const query = `
  mutation DeleteCoupon($input: DeleteCouponInput!) {
    deleteCoupon(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      id: couponPK,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteSingleCoupons = (ids) => {
  const query = `
  mutation DeleteCoupons($input: DeleteCouponsInput!) {
    deleteCoupons(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids: ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deactiveSingleCoupon = (couponPK) => {
  const query = `
  mutation DeactivateCoupon($input: DeactivateCouponInput!) {
    deactivateCoupon(input: $input) {
      node {
        id
        template {
          name
        }
      }
    }
  }`;
  const variables = {
    input: {
      id: couponPK,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const activeSingleCoupon = (couponPK) => {
  const query = `
  mutation ActivateCoupon($input: ActivateCouponInput!) {
    activateCoupon(input: $input) {
      node {
        id
        template {
          name
        }
      }
    }
  }`;
  const variables = {
    input: {
      id: couponPK,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const activeCoupons = (ids) => {
  const query = `
  mutation ActivateCoupons($input: ActivateCouponsInput!) {
    activateCoupons(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids: ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const grantCoupon = (couponPK, customerPK) => {
  const query = `mutation GrantCoupon($input: GrantCouponInput!) {
    grantCoupon(input: $input) {
      node {
        id
        pk
        isForcedInactive
        owner {
          firstName
          lastName
        }
        dateOfPurchase
        dateOfEarning
        dateOfGrant
        serialNumber
        isUsed
        isExpired
        isActive
        effectiveDate
        expiryDate
      }
    }
  }`;
  const variables = {
    input: {
      id: couponPK,
      customer: customerPK,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const reclaimCoupon = (id) => {
  const query = `
  mutation ReclaimCoupon($input: ReclaimCouponInput!) {
    reclaimCoupon(input: $input) {
      node {
        id
        pk
        template {
          name
        }
        isForcedInactive
        store {
          name
        }
        owner {
          firstName
          lastName
        }
        dateOfPurchase
        dateOfEarning
        dateOfGrant
        serialNumber
        isUsed
        isExpired
        isActive
        effectiveDate
        expiryDate
      }
    }
  }`;
  const variables = {
    input: {
      id,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteCouponSet = (couponSetPK) => {
  const query = `
  mutation DeleteCouponTemplate($input: DeleteCouponTemplateInput!) {
    deleteCouponTemplate(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      id: couponSetPK,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteCouponSets = (ids) => {
  const query = `
  mutation DeleteCouponTemplates($input: DeleteCouponTemplatesInput!) {
    deleteCouponTemplates(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const duplicateCouponSet = (couponSetPK) => {
  const query = `
   mutation CopyCouponTemplate($input: CopyCouponTemplateInput!) {
     copyCouponTemplate(input: $input) {
       success
     }
   }`;
  const variables = {
    input: {
      id: couponSetPK,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateCouponSetActiveStatus = (
  couponSetPK,
  isForcedInactive = false,
) => {
  const query = `
  mutation UpdateCouponTemplate($input: UpdateCouponTemplateInput!) {
    updateCouponTemplate(input: $input) {
      node {
        id
        name
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: {
      id: couponSetPK,
      isForcedInactive: isForcedInactive,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateCouponSet = (couponSet) => {
  const query = `
  mutation UpdateCouponTemplate($input: UpdateCouponTemplateInput!) {
    updateCouponTemplate(input: $input) {
      node {
        pk
        id
        name
        brand {
          name
        }
        offlineCouponTemplateType
        offlineCouponCodeType
        offlineRedemptionMethod
        offlineFormats
        offlineBarcodeFormat {
          pk
        }
        applicableStores {
          edges {
            node {
              id
              pk
              name
              isOnlineStore
              visibleInFrontEnd
              address
            }
          }
        }
        shouldUseInStore
        offlineShowCopiableString
        mediumType
        isDifferentCodeForOnlineOffline
        showOnlineOfflineInput
        offlineShouldImportByFile
        onlineShouldImportByFile
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: {
      ...couponSet,
    },
  };
  console.log('@656', variables);
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getAllCouponSet = (ssoUid) => {
  const query = `
  {
    coupons(customerSsoUid: "${ssoUid}", customerName: "", isExpired: false, isUsed: false orderBy: "-pk") {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          template {
            name
          }
          isForcedInactive
          store {
            name
            staffCode
          }
          owner {
            id
            pk
            firstName
            lastName
          }
          dateOfPurchase
          dateOfEarning
          dateOfGrant
          serialNumber
          isUsed
          isExpired
          isActive
          effectiveDate
          expiryDate
        }
      }
    }
  }

  `;

  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getBarcodeFormats = (search) => {
  const searchName = search || '';
  const query = `{
    barcodeFormats(nameIcontains:"${searchName}") {
      edges {
        node {
          id
          pk
          name
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCustomerDetailCouponList = ({
  afterCursor = null,
  customerId = null,
  type = null,
  search = null,
}) => {
  const query = `
    {
      coupons(first: 20, after: "${afterCursor}", orderBy: "-date_of_acquisition,expiry_date,-pk",
      customerId: ${customerId},
      customerDetailCouponType: "${type}",
      couponTemplateName: "${search}") {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            pk
            virtualRealType
            dateOfPurchase
            dateOfEarning
            dateOfGrant
            isUsed
            isExpired
            isActive
            isForcedInactive
            isEffective
            effectiveDate
            expiryDate
            template {
              pk
              name
              mediumType
              brand {
                name
                stores {
                  edges {
                    node {
                      pk
                      name
                      isOnlineStore
                      visibleInFrontEnd
                    }
                  }
                }
              }
              shouldUseInStore
              applicableStores {
                edges {
                    node {
                        pk
                        name
                        isOnlineStore
                        visibleInFrontEnd
                    }
                }
              }
              coverPhoto
              validPeriodType
              absoluteEffectiveDate
              absoluteExpiryDate
              numberOfDaysToExpireAfterAcquisition
            }
            virtualCoupon {
              pk
            }
            store {
              pk
              name
              visibleInFrontEnd
            }
          }
        }
      }
    }`;
  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const selectStore = ({
  id,
  customerId,
  storeId,
}) => {
  const query = `
    mutation SelectStore($input: SelectStoreInput!) {
      selectStore(input: $input) {
        success
      }
    }
  `;
  const variables = {
    input: {
      id,
      customer: customerId,
      store: storeId
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const useCoupon = ({
  id,
  customerId,
  storeId,
}) => {
  const query = `
    mutation UseCoupon($input: UseCouponInput!) {
      useCoupon(input: $input) {
        success
      }
    }
  `;
  const variables = {
    input: {
      id,
      customer: customerId,
      store: storeId
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
