import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { ArrayStringData } from '../../../utils';
import { useSelector } from 'react-redux';
import { CampaignType, MessageBackendChannel, MessageChannel } from '../../../config/CustomEnums';

function Filter({
  backAction = () => {},
  displayIn = null,
 }) {
  const location = useLocation();

  const { brandList, checkedBrandList, customerGroupList, segmentList } = useSelector(
    (state) => ({
      brandList: state.brand.brandList,
      checkedBrandList: state.brand.checkedList,
      customerGroupList: state.customerGroup.groupList,
      segmentList: state.segments.segmentList,
    }),
  );

  const parsedSearch = queryString.parse(location.search);
  const searchType = parsedSearch['type'] || '';
  const searchPublishStatus = parsedSearch['status'] || '';
  const searchExpiredStatus = parsedSearch['expired'] || '';
  const searchMessageChannel = parsedSearch['message_channel'] || '';
  const searchBrand = parsedSearch['brand'] || '';
  const searchActivePeriod = parsedSearch['active_period'] || '';
  const searchVisablePeriod = parsedSearch['visable_period'] || '';

  const searchTargetCustomer = parsedSearch['target_customer'] || '';
  const searchGroups = parsedSearch['groupsIn'] || '';
  const searchSegments = parsedSearch['segmentsIn'] || '';
  // const searchLevels = parsedSearch['levelsIn'] || '';

  const [type, setType] = useState(searchType);
  const [status, setStatus] = useState(
    ArrayStringData(searchPublishStatus)
  );
  const [expired, setExpired] = useState(searchExpiredStatus);
  const [messageChannel, setMessageChannel] = useState(
    ArrayStringData(searchMessageChannel),
  );
  const [brand, setBrand] = useState([]);
  const [activePeriod, setActivePeriod] = useState(searchActivePeriod);
  const [visablePeriod, setVisablePeriod] = useState(searchVisablePeriod);
  const [targetCustomer, setTargetCustomer] = useState(searchTargetCustomer);
  const [groups, setGroups] = useState(ArrayStringData(searchGroups));
  const [segments, setSegments] = useState(ArrayStringData(searchSegments));
  // const [levels, setLevels] = useState(ArrayStringData(searchLevels));

  useEffect(()=>{
    if ( searchBrand && checkedBrandList ) {
      setBrand(...brand, checkedBrandList)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedBrandList])

  const content = [
    ...(displayIn === 'STAMP_LIST' ?
    [] : [{
      title: 'Type',
      data: [
        { pk: CampaignType.couponsCampaign, name: 'Coupon' },
        { pk: CampaignType.earningRulesCampaign, name: 'Earning' },
        { pk: CampaignType.generalMessageCampaign, name: 'General Message' },
      ],
      value: type,
      setValue: setType,
      component: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    }]),
    {
      title: 'Message channel',
      data: [
        {
          name: MessageChannel.all,
          pk: MessageBackendChannel.all,
        },
        {
          name: MessageChannel.allWhatsapp,
          pk: MessageBackendChannel.allWhatsapp,
        },
        {
          name: MessageChannel.push,
          pk: MessageBackendChannel.push,
        },
        {
          name: MessageChannel.email,
          pk: MessageBackendChannel.email,
        },
        {
          name: MessageChannel.sms,
          pk: MessageBackendChannel.sms,
        },
        {
          name: MessageChannel.whatsapp,
          pk: MessageBackendChannel.whatsapp,
        },
        {
          name: MessageChannel.inbox,
          pk: MessageBackendChannel.inbox,
        },
      ],
      value: messageChannel,
      setValue: setMessageChannel,
      component: FilterTypeEnum.choice,
    },
    { 
      filterTitle: 'Brand',
      data: brandList,
      value: brand,
      setValue: setBrand,
      placeholder: 'Search by brand name',
      loadMoreAction: 'brand/getCurrentPageBrands',
      filterAction: 'brand/getCurrentPageBrands',
      customItem: (item) => `[ID: ${item?.pk}] ${item?.name}`,
      defaultFilter: {
        isSimpleNode: true,
        noLoading: true,
      },
      component: FilterTypeEnum.multipleSelectorDropdown,
    },
    {
      title: 'Expired',
      data: [
        { name: 'Unexpired', pk: 'false' },
        { name: 'Expired', pk: 'true' },
      ],
      value: expired,
      setValue: setExpired,
      component: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Status',
      data: [
        { name: 'Draft', pk: 'DRAFT'},
        { name: 'Pending For Approval', pk: 'PENDING'},
        { name: 'Published', pk: 'PUBLISHED' },
      ],
      value: status,
      setValue: setStatus,
      component: FilterTypeEnum.choice,
      className: 'fit-content-height-area'
    },
    {
      title: 'Target customer',
      data: [
        {
          name: 'Customer list',
          pk: 'groupsIn',
          subComponment: {
            data: customerGroupList,
            value: groups,
            setValue: setGroups,
            component: FilterTypeEnum.choice,
            className: 'filter-list-items-sub-area',
          },
        },
        {
          name: 'Segment',
          pk: 'segmentsIn',
          subComponment: {
            data: segmentList,
            value: segments,
            setValue: setSegments,
            component: FilterTypeEnum.choice,
            className: 'filter-list-items-sub-area',
          },
        },
      ],
      value: targetCustomer,
      setValue: setTargetCustomer,
      component: FilterTypeEnum.singleChoiceTargetCustomer,
    },
    {
      title: 'Active period',
      value: activePeriod,
      setValue: setActivePeriod,
      component: FilterTypeEnum.dateRange,
    },
    {
      title: 'Visible period',
      data: [
        { name: 'Inbox', pk: 'inbox' },
        { name: 'Push', pk: 'push' },
      ],
      value: visablePeriod,
      setValue: setVisablePeriod,
      component: FilterTypeEnum.dateRange,
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;
