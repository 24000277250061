import {
  APIStatus,
  LanguageConfig,
  SESSION_KEYS,
} from 'config/CustomEnums';
import { loading } from 'models/LoadingUtil';

import { createOrUpdateProductCategory, createOrUpdateProductSubcategory, getOneProductCategory, getOneProductSubcategory } from 'services/ProductCategoryApiHelper';

import {
  convertPKToId,
  getObjectFromSessionStorage,
  removeFromSessionStorage,
  saveToSessionStorage,
} from 'utils';

export const sessionDataKey = {
  categoryObjectKey: SESSION_KEYS.CREATE_PRODUCT_CATEGORY_SESSION_KEY,
  categoryStepEndKey: SESSION_KEYS.CREATE_PRODUCT_CATEGORY_STEP_END_SESSION_KEY,
  categoryOriginalData: SESSION_KEYS.CREATE_PRODUCT_CATEGORY_ORIGINAL_DATA_SESSION_KEY,

  subcategoryObjectKey: SESSION_KEYS.CREATE_PRODUCT_SUBCATEGORY_SESSION_KEY,
  subcategoryStepEndKey: SESSION_KEYS.CREATE_PRODUCT_SUBCATEGORY_STEP_END_SESSION_KEY,
  subcategoryOriginalData: SESSION_KEYS.CREATE_PRODUCT_SUBCATEGORY_ORIGINAL_DATA_SESSION_KEY,
};


const getProductCategoryInitState = () => ({
  pk: null,
  id: null,
  name: null,
})
const getProductSubcategoryInitState = () => ({
  pk: null,
  id: null,
  name: null,
})

const getInitialState = () => {
  return {
    productCategory: getProductCategoryInitState(),
    productSubcategory: getProductSubcategoryInitState(),
    errorFields: [],
    currentStep: 0,
    languageTag: LanguageConfig.english,
    createStatus: APIStatus.none,
    hasUpdatedDefaultValues: false,
    formHasSubmitted: false,
  };
};

const parseProductCategoryTranslations = (data) => {
  let parsedTranslations = {};
  parsedTranslations[LanguageConfig.english] = {
    name: data.name,
  }
  data.translations.edges.forEach((item) => {
    const translationData = item.node;
    let language = translationData.language;
    if (language === 'ZH_HANS') {
      language = LanguageConfig.simplifiedChinese;
    } else if (language === 'ZH_HANT') {
      language = LanguageConfig.traditionalChinese;
    }
    parsedTranslations[language] = {
      name: translationData.name,
      id: translationData.id,
      pk: translationData.pk,
    };
  });
  return parsedTranslations;
};

const parseProductCategoryDetail = (data) => {
  const linkedPhysicalStoreProductCategories = data.linkedPhysicalStoreProductCategories?.edges?.map((value) => ({
    pk: value?.node?.pk,
    name: value?.node?.name,
  }))
  const linkedPhysicalStoreProductSubcategories = data.linkedPhysicalStoreProductSubcategories?.edges?.map((value) => ({
    pk: value?.node?.pk,
    name: value?.node?.name,
  }))
  const productSubcategories = data.productSubcategories?.edges?.map((value) => ({
    pk: value?.node?.pk,
    name: value?.node?.name,
  }))
  const linkedOnlineStoreCollections = (JSON.parse(data.linkedOnlineStoreCollections || '') || [])?.map(item=>({
    label: item?.title,
    value: {
      pk: item?.id,
      title: item?.title,
    }
  }))
  const linkedOnlineStoreTags = data.linkedOnlineStoreTags?.map(
    item => ({
      label: item,
      value: {
        pk: item, name: item,
      }
    }))
  const translations = parseProductCategoryTranslations(data)
  const selectedCategoriesAndSubSategories = [
    ...linkedPhysicalStoreProductCategories?.map(item => ({
      ...item,
      realPk: item.pk,
      pk: `physical store product category_${item.pk}`,
      groupKey: {
        pk: "Physical store product category",
        name: "Physical store product category",
      }
    })),
    ...linkedPhysicalStoreProductSubcategories?.map(item => ({
      ...item,
      realPk: item.pk,
      pk: `physical store product subcategory_${item.pk}`,
      groupKey: {
        pk: "Physical store product subcategory",
        name: "Physical store product subcategory",
      }
    })),
  ]
  return {
    ...data,
    productSubcategories,
    linkedPhysicalStoreProductCategories,
    linkedPhysicalStoreProductSubcategories,
    linkedOnlineStoreCollections,
    linkedOnlineStoreTags,
    selectedCategoriesAndSubSategories,
    translations,
  }
}

const parseProductSubcategoryDetail = (data) => {
  const linkedPhysicalStoreProductSubcategories = data.linkedPhysicalStoreProductSubcategories?.edges?.map((value) => ({
    pk: value?.node?.pk,
    name: value?.node?.name,
  }))
  const productCategories = data.productCategories?.edges?.map((value) => ({
    pk: value?.node?.pk,
    name: value?.node?.name,
  }))
  const linkedOnlineStoreTags = data.linkedOnlineStoreTags?.map(
    item => ({
      label: item,
      value: {
        pk: item, name: item,
      }
    }))
  const translations = parseProductCategoryTranslations(data)
  return {
    ...data,
    productCategories,
    linkedPhysicalStoreProductSubcategories,
    linkedOnlineStoreTags,
    translations,
  }
}

const parseProductCategoryInputBody = (data) => {
  let linkedPhysicalStoreProductCategories = []
  let linkedPhysicalStoreProductSubcategories = []
  data?.selectedCategoriesAndSubSategories && data.selectedCategoriesAndSubSategories.forEach(
    (value)=> {
      if (value?.groupKey?.name === "Physical store product category") {
        linkedPhysicalStoreProductCategories.push(value?.realPk)
      } else if (
        value?.groupKey?.name === "Physical store product subcategory"
      ) {
        linkedPhysicalStoreProductSubcategories.push(value?.realPk)
      }
    }
  )
  const linkedOnlineStoreTagPks = data?.linkedOnlineStoreTags?.map(item => item?.value?.pk) || []
  const linkedOnlineStoreCollections = data?.linkedOnlineStoreCollections?.map(item => ({
    id: item?.value?.pk,
    title: item?.value?.title,
  }))
  return {
    name: data.translations?.[LanguageConfig.english]?.name || data.name || "",
    displayOrder: data?.displayOrder || null,
    productSubcategories: data?.productSubcategories?.map(item=>item?.pk),
    linkedPhysicalStoreProductCategories,
    linkedPhysicalStoreProductSubcategories,
    linkedOnlineStoreCollections: JSON.stringify(linkedOnlineStoreCollections),
    linkedOnlineStoreTags: linkedOnlineStoreTagPks
  }
}

const parseProductSubcategoryInputBody = (data) => {
  const linkedOnlineStoreTagPks = data?.linkedOnlineStoreTags?.map(item => item?.value?.pk) || []
  return {
    name: data.translations?.[LanguageConfig.english]?.name || data.name || "",
    displayOrder: data?.displayOrder || null,
    productCategories: data?.productCategories?.map(item => item?.pk),
    linkedPhysicalStoreProductSubcategories: data?.linkedPhysicalStoreProductSubcategories?.map(item => item?.pk),
    linkedOnlineStoreTags: linkedOnlineStoreTagPks
  }
}

export default {
  namespace: 'createProductCategory',
  state: getInitialState(),

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },

    loadProductCategoryFromCookie(state, { payload }) {
      const category = getObjectFromSessionStorage(sessionDataKey.categoryObjectKey);
      if (!category) {
        return {
          ...state,
        };
      }
      saveToSessionStorage(sessionDataKey.categoryOriginalData, category);
      saveToSessionStorage(sessionDataKey.categoryObjectKey, category);
      return {
        ...state,
        productCategory: category,
        hasUpdatedDefaultValues: true,
      };
    },

    saveOrRemoveProductCategoryFromCookie(state, { payload }) {
      if (payload.save) {
        if (payload.values) {
          saveToSessionStorage(sessionDataKey.categoryOriginalData, payload.values);
        }
      } else {
        removeFromSessionStorage(sessionDataKey.categoryOriginalData);
        removeFromSessionStorage(sessionDataKey.categoryObjectKey);
      }
      saveToSessionStorage(sessionDataKey.categoryStepEndKey, true);
      return {
        ...state,
      };
    },

    loadProductSubcategoryFromCookie(state, { payload }) {
      const subcategory = getObjectFromSessionStorage(sessionDataKey.subcategoryObjectKey);
      if (!subcategory) {
        return {
          ...state,
        };
      }
      saveToSessionStorage(sessionDataKey.subcategoryOriginalData, subcategory);
      saveToSessionStorage(sessionDataKey.subcategoryObjectKey, subcategory);
      return {
        ...state,
        productSubcategory: subcategory,
        hasUpdatedDefaultValues: true,
      };
    },

    saveOrRemoveProductSubcategoryFromCookie(state, { payload }) {
      if (payload.save) {
        if (payload.values) {
          saveToSessionStorage(sessionDataKey.subcategoryOriginalData, payload.values);
        }
      } else {
        removeFromSessionStorage(sessionDataKey.subcategoryOriginalData);
        removeFromSessionStorage(sessionDataKey.subcategoryObjectKey);
      }
      saveToSessionStorage(sessionDataKey.subcategoryStepEndKey, true);
      return {
        ...state,
      };
    },

    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },

  effects: {
    getOneProductCategory: [
      function* ({ payload }, { put }) {
        const { id: pk } = payload
        const id = convertPKToId('ProductCategoryNode', pk);
        const serviceArgs = [getOneProductCategory, id];
        function* onSuccess(data) {
          const productCategory = data?.productCategory
          const parsedData = parseProductCategoryDetail(productCategory)
          yield put({
            type: 'updateState',
            payload: {
              productCategory: parsedData,
              hasUpdatedDefaultValues: true,
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    getOneProductSubcategory: [
      function* ({ payload }, { put }) {
        const { id: pk } = payload
        const id = convertPKToId('ProductSubcategoryNode', pk);
        const serviceArgs = [getOneProductSubcategory, id];
        function* onSuccess(data) {
          const productSubcategory = data?.productSubcategory
          const parsedData = parseProductSubcategoryDetail(productSubcategory)
          yield put({
            type: 'updateState',
            payload: {
              productSubcategory: parsedData,
              hasUpdatedDefaultValues: true,
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    createOrUpdateProductCategory: [
      function* ({ payload }, { select, put }) {
        const { values, id } = payload
        const inputBody = parseProductCategoryInputBody(values);
        if (id) {
          inputBody.id = id
        }
        const serviceArgs = [createOrUpdateProductCategory, inputBody];
        saveToSessionStorage(sessionDataKey.categoryStepEndKey, true);
        function* onSuccess() {
          removeFromSessionStorage(sessionDataKey.categoryObjectKey);
          yield put({
            type: 'updateState',
            payload: {
              formHasSubmitted: true,
            }
          });
          const afterActions = payload.afterActions || (() => { });
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    createOrUpdateProductSubcategory: [
      function* ({ payload }, { select, put }) {
        const { values, id } = payload
        const inputBody = parseProductSubcategoryInputBody(values);
        if (id) {
          inputBody.id = id
        }
        const serviceArgs = [createOrUpdateProductSubcategory, inputBody];
        saveToSessionStorage(sessionDataKey.subcategoryStepEndKey, true);
        function* onSuccess() {
          removeFromSessionStorage(sessionDataKey.subcategoryObjectKey);
          yield put({
            type: 'updateState',
            payload: {
              formHasSubmitted: true,
            }
          });
          const afterActions = payload.afterActions || (() => { });
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    updateProductCategoryDisplayOrder: [
      function* ({ payload }, { select, put }) {
        const { displayOrder, id } = payload
        const inputBody = {
          id,
          displayOrder
        }
        const serviceArgs = [createOrUpdateProductCategory, inputBody];
        function* onSuccess() {
          const afterActions = payload.afterActions || (() => { });
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    updateProductSubcategoryDisplayOrder: [
      function* ({ payload }, { select, put }) {
        const { displayOrder, id } = payload
        const inputBody = {
          id,
          displayOrder
        }
        const serviceArgs = [createOrUpdateProductSubcategory, inputBody];
        function* onSuccess() {
          const afterActions = payload.afterActions || (() => { });
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
