import { apiWithResponseHandle, loading } from './LoadingUtil';
import { convertNumberToCursor, convertCursorToNumber } from '../utils';
import {
  deleteProductCategories,
  deleteProductSubcategories,
  duplicateProductCategory,
  duplicateProductSubcategory,
  getAllOnlineStoreCollections,
  getAllOnlineStoreTags,
  getAllPhysicalStoreCategory,
  getAllPhysicalStoreSubcategory,
  getPagedPhysicalStoreCategories,
  getPagedPhysicalStoreSubcategories,
  getPagedProductCategories,
  getPagedProductSubcategories,
  getSimpleProductCategoriesAndSubcategories
} from 'services/ProductCategoryApiHelper';
import { LanguageConfig } from 'config/CustomEnums';


const getInitialState = () => ({
  categoryListDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Product category', fieldName: 'name' },
    { displayName: 'Display order', fieldName: 'displayOrder', orderField: "displayOrder" },
    { displayName: 'Assigned product subcategory', fieldName: 'displayProductSubcategories' },
    { displayName: `Linked physical store's product category`, fieldName: 'displayLinkedPhysicalStoreProductCategories' },
    { displayName: `Linked physical store's product subcategory`, fieldName: 'displayLinkedPhysicalStoreProductSubcategories' },
    { displayName: `Linked online store's product category`, fieldName: 'displayLinkedOnlineStoreCollections' },
    { displayName: `Linked online store's product subcategory`, fieldName: 'displayLinkedOnlineStoreTags' },
  ],
  subcategoryListDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Product subcategory', fieldName: 'name' },
    { displayName: 'Display order', fieldName: 'displayOrder', orderField: "displayOrder" },
    { displayName: `Assigned product category`, fieldName: 'displayProductCategories' },
    { displayName: `Linked physical store's product subcategory`, fieldName: 'displayLinkedPhysicalStoreProductSubcategories' },
    { displayName: `Linked online store's product subcategory`, fieldName: 'displayLinkedOnlineStoreTags' },
  ],
  productCategoryList: [],
  productSubcategoryList: [],
  physicalStoreProductCategories: [],
  physicalStoreProductSubcategories: [],
  onlineStoreProductCategories: [],
  onlineStoreProductSubcategories: [],
  totalCount: 0,
  totalPage: 0,
  pageInfo: {
    startCursor: 0,
    endCursor: 0,
  },
  checkedList: [],
});

const parseProductCategoryTranslations = (translations) => {
  let parsedTranslations = {};
  translations.forEach((item) => {
    const translationData = item.node;
    let language = translationData.language;
    if (language === 'ZH_HANS') {
      language = LanguageConfig.simplifiedChinese;
    } else if (language === 'ZH_HANT') {
      language = LanguageConfig.traditionalChinese;
    }
    parsedTranslations[language] = {
      name: translationData.name,
      id: translationData.id,
      pk: translationData.pk,
    };
  });
  return parsedTranslations;
}

const parseProductCategoryList = (item) => {
  const linkedPhysicalStoreProductCategories = item?.linkedPhysicalStoreProductCategories?.edges?.map((value) => ({
    pk: value?.node?.pk,
    name: value?.node?.name,
  }))
  const linkedPhysicalStoreProductSubcategories = item?.linkedPhysicalStoreProductSubcategories?.edges?.map((value) => ({
    pk: value?.node?.pk,
    name: value?.node?.name,
  }))
  const productSubcategories = item?.productSubcategories?.edges?.map((value) => ({
    pk: value?.node?.pk,
    name: value?.node?.name,
  }))
  const linkedOnlineStoreCollections = JSON.parse(item?.linkedOnlineStoreCollections || "[]") || []
  const displayLinkedOnlineStoreCollections = linkedOnlineStoreCollections?.map(item => item?.title)?.join(",") || "-"
  const translations =
    item?.translations?.edges?.length > 0
      ? parseProductCategoryTranslations(item.translations.edges)
      : {};
  const data = {
    ...item,
    pk: item.pk,
    displayOrder: item?.displayOrder || '-',
    linkedOnlineStoreCollections,
    displayLinkedOnlineStoreCollections,
    linkedOnlineStoreTags: item?.linkedOnlineStoreTags,
    displayLinkedOnlineStoreTags: item?.linkedOnlineStoreTags?.join(',') || "-",
    linkedPhysicalStoreProductCategories,
    displayLinkedPhysicalStoreProductCategories: linkedPhysicalStoreProductCategories?.map(value => value?.name).join(",") || "-",
    linkedPhysicalStoreProductSubcategories,
    displayLinkedPhysicalStoreProductSubcategories: linkedPhysicalStoreProductSubcategories?.map(value => value?.name).join(",") || "-",
    productSubcategories,
    displayProductSubcategories: productSubcategories?.map(value => value?.name).join(",") || "-",
    translations,
  }
  return data
}

const parseProductSubcategoryList = (item) => {
  const linkedPhysicalStoreProductSubcategories = item.linkedPhysicalStoreProductSubcategories?.edges?.map((value) => ({
    pk: value?.node?.pk,
    name: value?.node?.name,
  }))
  const productCategories = item.productCategories?.edges?.map((value) => ({
    pk: value?.node?.pk,
    name: value?.node?.name,
  }))
  const translations =
    item.translations?.edges?.length > 0
      ? parseProductCategoryTranslations(item.translations.edges)
      : {};
  const data = {
    ...item,
    pk: item.pk,
    displayOrder: item?.displayOrder || '-',
    linkedOnlineStoreTags: item.linkedOnlineStoreTags,
    displayLinkedOnlineStoreTags: item.linkedOnlineStoreTags?.join(',') || "-",
    linkedPhysicalStoreProductSubcategories,
    displayLinkedPhysicalStoreProductSubcategories: linkedPhysicalStoreProductSubcategories?.map(value => value?.name).join(",") || "-",
    productCategories,
    displayProductCategories: productCategories?.map(value => value?.name).join(",") || "-",
    translations,
  }
  return data
}

export default {
  namespace: 'productCategoryList',
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clearData(state, { payload }) {
      return {
        ...state,
        ...getInitialState(),
      };
    },
  },
  effects: {
    getPagedPhysicalStoreCategories: [
      function* ({ payload }, { put }) {
        const { search, page } = payload
        const afterCursor = page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const serviceArgs = [getPagedPhysicalStoreCategories, afterCursor, search];
        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: {

            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getPagedPhysicalStoreSubcategories: [
      function* ({ payload }, { put }) {
        const { search, page } = payload
        const afterCursor = page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const serviceArgs = [getPagedPhysicalStoreSubcategories, afterCursor, search];
        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: {

            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getPagedProductCategories: [
      function* ({ payload }, { put }) {
        const {
          search,
          rank,
          page,
          sort,
        } = payload
        const afterCursor = page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const serviceArgs = [getPagedProductCategories, afterCursor, search, rank, sort];
        function* onSuccess(data) {
          const productCategories = data?.productCategories
          const pageInfo = productCategories?.pageInfo;
          const totalCount = productCategories?.totalCount;

          const parsedData = productCategories?.edges?.map((item) => parseProductCategoryList(item?.node))

          const startCursor = convertCursorToNumber(pageInfo.startCursor);
          const endCursor = convertCursorToNumber(pageInfo.endCursor);

          yield put({
            type: 'updateState',
            payload: {
              productCategoryList: parsedData,
              totalCount: totalCount,
              totalPage: Math.ceil(totalCount / 20),
              pageInfo: {
                startCursor: startCursor + 1,
                endCursor: endCursor + 1,
              },
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getPagedProductSubcategories: [
      function* ({ payload }, { put }) {
        const { search, page, rank, sort } = payload
        const afterCursor = page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const serviceArgs = [getPagedProductSubcategories, afterCursor, search, rank, sort];
        function* onSuccess(data) {
          const subproductCategories = data?.productSubcategories
          const pageInfo = subproductCategories?.pageInfo;
          const totalCount = subproductCategories?.totalCount;

          const parsedData = subproductCategories?.edges?.map((item) => parseProductSubcategoryList(item?.node))
          const startCursor = convertCursorToNumber(pageInfo.startCursor);
          const endCursor = convertCursorToNumber(pageInfo.endCursor);

          yield put({
            type: 'updateState',
            payload: {
              productSubcategoryList: parsedData,
              totalCount: totalCount,
              totalPage: Math.ceil(totalCount / 20),
              pageInfo: {
                startCursor: startCursor + 1,
                endCursor: endCursor + 1,
              },
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getAllPhysicalStoreCategory: [
      function* ({ payload }, { put }) {
        const serviceArgs = [getAllPhysicalStoreCategory, payload];

        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: {
              physicalStoreProductCategories: data.physicalStoreCategories.edges.map(
                (item) => item.node,
              ),
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getAllPhysicalStoreSubcategory: [
      function* ({ payload }, { put }) {
        const serviceArgs = [getAllPhysicalStoreSubcategory, payload];

        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: {
              physicalStoreProductSubcategories: data.physicalStoreSubcategories.edges.map(
                (item) => item.node,
              ),
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getAllOnlineStoreCollections: [
      function* ({ payload }, { put }) {
        const serviceArgs = [getAllOnlineStoreCollections, payload];

        function* onSuccess(data) {
          const values = JSON.parse(data.getOnlineStoreAllCollections.collections)
          yield put({
            type: 'updateState',
            payload: {
              onlineStoreProductCategories: values.map((item) => ({ pk: item.id, title: item.title })),
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getAllOnlineStoreTags: [
      function* ({ payload }, { put }) {
        const serviceArgs = [getAllOnlineStoreTags, payload];

        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: {
              onlineStoreProductSubcategories: data.getOnlineStoreAllProductTags.productTags.map((item) => ({ pk: item, name: item })),
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    duplicateProductCategory: [
      function* ({ payload }, { select, put }) {
        const serviceArgs = [duplicateProductCategory, payload.data.pk];
        const afterAction = payload.afterAction || (() => { });
        function* onSuccess() {
          yield afterAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    duplicateProductSubcategory: [
      function* ({ payload }, { select, put }) {
        const serviceArgs = [duplicateProductSubcategory, payload.data.pk];
        const afterAction = payload.afterAction || (() => { });
        function* onSuccess() {
          yield afterAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    deleteProductCategories: [
      function* ({ payload }, { put, select }) {
        const { checkedList } = yield select((state) => ({
          checkedList: state.productCategoryList.checkedList,
        }));

        let ids = [];
        checkedList.forEach((item) => {
          ids.push(item.pk);
        });

        const serviceArgs = [
          deleteProductCategories,
          {
            ids: ids,
          },
        ];
        function* onSuccess() {
          const afterActions = payload.afterAction || (() => { });
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    deleteProductSubcategories: [
      function* ({ payload }, { put, select }) {
        const { checkedList } = yield select((state) => ({
          checkedList: state.productCategoryList.checkedList,
        }));

        let ids = [];
        checkedList.forEach((item) => {
          ids.push(item.pk);
        });

        const serviceArgs = [
          deleteProductSubcategories,
          {
            ids: ids,
          },
        ];
        function* onSuccess() {
          const afterActions = payload.afterAction || (() => { });
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getSimpleProductCategoriesAndSubcategories: [
      function* ({ payload }, { put }) {
        const serviceArgs = [getSimpleProductCategoriesAndSubcategories];

        function* onSuccess(data) {
          const productCategories = data?.productCategories

          const parsedCategories = productCategories?.edges?.map((item) => parseProductCategoryList(item?.node))
          const productSubcategories = data?.productSubcategories


          const parsedSubcategories = productSubcategories?.edges?.map((item) => parseProductCategoryList(item?.node))

          yield put({
            type: 'updateState',
            payload: {
              productCategoryList: parsedCategories,
              productSubcategoryList: parsedSubcategories,
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ]
  },
};
