import React, { useEffect } from 'react';
import 'components/campaign/campaignCreation/CreateCampaignStepTwo.scss';
import { useDispatch, connect } from 'react-redux';
import { CampaignType } from 'config/CustomEnums';
import ContentSections from 'components/base/ContentSections';
import ActivePeriodSection from 'components/base/period/ActivePeriodSection';
import CouponCampaignSection from 'components/campaign/campaignCreation/CouponCampaignSection';
import PropertiesGeneralSection from 'components/campaign/campaignCreation/PropertiesGeneralSection';
import VisiblePeriodSection from 'components/campaign/campaignCreation/VisiblePeriodSection';
import { ContinueWithBackButtons } from 'components/base/BottomStepComponent';
import { useFormContext } from 'react-hook-form';
import { validate } from 'containers/engagement/campaign/CreateCampaignValidate';
import { scrollToFirstError } from 'components/campaign/campaignCreation/CreateCampaignHandleError';

function CreateStampCampaignStepTwo() {
  const dispatch = useDispatch();
  const { getValues, watch, setError, clearErrors, formState } = useFormContext();
  const errors = formState.errors;
  const campaignType = watch('campaignType');

  useEffect(() => {
    scrollToFirstError(
      errors,
      [
        "customerGroup",
        "segments",
        "activeEndDate",
        "visibleEndDate",
      ]
    )
  }, [errors])

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 1,
      isBack,
    });
    dispatch({
      type: 'createCampaign/stepChange',
      payload: { isBack: isBack, step: 1, isValid: isValid, isStamp: true },
    });
  };

  const sections = [
    <PropertiesGeneralSection />,
    <ActivePeriodSection />,
    <VisiblePeriodSection />,
    campaignType === CampaignType.couponsCampaign ? (
      <CouponCampaignSection />
    ) : null,
  ];

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <ContinueWithBackButtons
        continueAction={() => {
          stepChangeAction(false);
        }}
        backAction={() => {
          stepChangeAction(true);
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({});

export default connect(mapPropsToState)(CreateStampCampaignStepTwo);
