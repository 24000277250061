import {
  PermissionDisplayOrder,
  PermissionGroupMap,
  PermissionNameMap,
} from 'config/PermissionAndGroup';
import { BaseHelper } from 'services/BaseGraphQLAPIHelper';

import { capitalizeFirstLetter } from 'utils/StringUtil';

export const getAllPermissions = (afterCursor) => {
  const query = `{
    permissions(first: 100, after: "${afterCursor}") {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          name
          codename
          adminGroupName
        }
      }
    }
  }`;

  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

const getPermissionName = (codename, originName) => {
  if (PermissionNameMap?.hasOwnProperty(codename)) {
    return PermissionNameMap[codename];
  }
  return originName;
};

const getPermissionGroupName = (codename, adminGroupName) => {
  if (PermissionGroupMap?.hasOwnProperty(codename)) {
    return PermissionGroupMap[codename];
  }
  return `${adminGroupName}s`;
};

function compareFn(a, b) {
  return PermissionDisplayOrder.indexOf(a.codename) - PermissionDisplayOrder.indexOf(b.codename);
}

export const parsePermissionList = (permissions) => {
  const data =
    permissions?.edges.map((item) => {
      const { node } = item;
      const { adminGroupName, codename, name } = node;
      const permissionName = getPermissionName(codename, name);
      const permissionGroupName = getPermissionGroupName(
        codename,
        adminGroupName,
      );
      return {
        id: node.id,
        pk: node.pk,
        realPk: node.pk,
        name: permissionName,
        codename,
        groupKey: {
          pk: permissionGroupName,
          name: capitalizeFirstLetter(permissionGroupName),
        },
      };
    }) || [];
  return data.sort(compareFn);
};
