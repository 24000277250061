import {
  LanguageConfig,
} from '../../../config/CustomEnums';

const CreateCategoryError = {
  name: {
    message: 'Please provide a name.',
  },
  displayOrder: {
    validate: {
      message: 'Please provide a valid display order',
    }
  },
  assignCategory: {
    require: {
      message: "Please provide product categories"
    }
  }
};

const productCategoryFirstStepValidate = ({ getValues, setError }) => {
  const errorList = [];

  const nameField = `translations.${LanguageConfig.english}.name`;
  const displayOrderField = `displayOrder`
  const [
    name,
    displayOrder,
  ] = getValues([
    nameField,
    displayOrderField,
  ]);
  if (!name) {
    errorList.push({
      name: nameField,
      message: CreateCategoryError.name.message,
    });
  }
  if ((displayOrder && displayOrder < 1) || displayOrder == '0') {
    errorList.push({
      name: displayOrderField,
      message: CreateCategoryError.displayOrder.validate.message,
    });
  }
  errorList.forEach((error) => {
    setError(
      error.name,
      {
        type: 'require',
        message: error.message,
      }
    );
  });
  return !errorList.length;
}
const productSubcategoryFirstStepValidate = ({ getValues, setError }) => {
  const errorList = [];

  const nameField = `translations.${LanguageConfig.english}.name`;
  const displayOrderField = `displayOrder`
  const assignCategoryField = `productCategories`

  const [
    name,
    displayOrder,
    assignCategory,
  ] = getValues([
    nameField,
    displayOrderField,
    assignCategoryField,
  ]);
  if (!name) {
    errorList.push({
      name: nameField,
      message: CreateCategoryError.name.message,
    });
  }
  if ((displayOrder && displayOrder < 1) || displayOrder == '0') {
    errorList.push({
      name: displayOrderField,
      message: CreateCategoryError.displayOrder.validate.message,
    });
  }
  if (!assignCategory || assignCategory?.length < 1) {
    errorList.push({
      name: assignCategoryField,
      message: CreateCategoryError.assignCategory.require.message,
    });
  }
  errorList.forEach((error) => {
    setError(
      error.name,
      {
        type: 'require',
        message: error.message,
      }
    );
  });
  return !errorList.length;
}
export function productCategoryValidate({ getValues, setError, clearErrors, step }) {
  clearErrors();
  switch (step) {
    default:
    case 0:
      const validStepOne = productCategoryFirstStepValidate({ getValues, setError });
      return validStepOne;
  }
}

export function productSubcategoryValidate({ getValues, setError, clearErrors, step }) {
  clearErrors();
  switch (step) {
    default:
    case 0:
      const validStepOne = productSubcategoryFirstStepValidate({ getValues, setError });
      return validStepOne;
  }
}
