import React, { useEffect, useState } from 'react';
import { Image, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import queryString from 'query-string';

import BaseMultipleSelectorV2 from 'components/base/BaseMultipleSelectorV2';
import { OnlyContinueButton } from 'components/base/BottomStepComponent';
import ContentSections from 'components/base/ContentSections';
import CustomRadios from 'components/base/CustomRadios';
import { ReactHookFormErrorMessage } from 'components/base/ErrorFieldMessage';
import ItemTypeSet, {
  MIN_WIDTH,
  SPACE_BETWEEN,
} from 'components/base/ItemTypeSet';
import Loading from 'components/base/Loading';
import BasePrompt from 'components/base/prompt/BasePrompt';
import { ContinueCreate } from 'components/campaign/CampaignUrlConfig';
import CouponSelectDropdown from 'components/campaign/campaignCreation/CouponSelectDropdown';
import {
  CampaignTypesConfig,
  getCampaignTypeSelectPromptConfig,
} from 'components/campaign/campaignCreation/CreateCampaignStepOneConfigs';
import EarningRulesSelectDropdown from 'components/campaign/campaignCreation/EarningRulesSelectDropdown';
import { CustomTitleLabel } from 'components/earning/CustomBaseComponments';
import { APIStatus, CampaignType, EarningRuleType } from 'config/CustomEnums';
import { PermissionCodes } from 'config/PermissionCodes';
import { validate } from 'containers/engagement/campaign/CreateCampaignValidate';
import {
  GENERAL_TYPE,
  isShowCouponRelated,
  isShowEarningRuleRelated,
  publishChannelList,
} from 'models/CreateCampaignModel';

import { getScrollbarWidth, useMainContentWidth } from 'utils/ScreenUtil';

import addImage from 'assets/images/addx1.png';

import './CreateCampaignStepOne.scss';

function CreateCampaignStepOne({
  status,
  promptShowed,
  isPublished,
  linkedCoupon,
  campaign,
}) {
  const mainContentWidth = useMainContentWidth();
  const fullContainerWith = mainContentWidth - 80 - 60 - getScrollbarWidth();

  const [showCreateNewLinkPrompt, setShowCreateNewLinkPrompt] = useState(false);
  const [refreshDate, setRefreshDate] = useState(new Date());
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    getValues,
    watch,
    setValue,
    control,
    formState,
    clearErrors,
    setError,
  } = useFormContext();
  const { errors } = formState;

  const earningRuleField = 'linkedEarningRules';
  const couponField = 'linkedCoupon';

  const watchCampaignType = watch('campaignType');
  const watchCoupon = watch(couponField) || {};
  const watchEarningRules = watch(earningRuleField);
  const watchHideInAnyChannel = watch('hideInAnyChannel');
  const watchPublishChannel = watch('publishChannel');
  const isCouponRelated = isShowCouponRelated(watchCampaignType);
  const isEarningRuleRelated = isShowEarningRuleRelated(watchCampaignType);
  const isWebhookRelated =
    watchEarningRules?.type === EarningRuleType.fillingForm;

  const promptconfig = getCampaignTypeSelectPromptConfig(watchCampaignType);
  const search = queryString.parse(history.location.search);

  const prefillEarningRuleSettingToGMCampaign = ({
    earningRule,
    campaignType,
  }) => {
    if (campaignType !== CampaignType.generalMessageCampaign) {
      return;
    }

    if (earningRule?.startDate && !earningRule?.endDate) {
      setValue('isAlwaysActivePeriod', true, { shouldDirty: true });
    }
    earningRule?.startDate &&
      setValue('activeStartDate', new Date(earningRule?.startDate), {
        shouldDirty: true,
      });
    earningRule?.endDate &&
      setValue('activeEndDate', new Date(earningRule?.endDate), {
        shouldDirty: true,
      });

    earningRule?.isExclusive &&
      setValue('isExclusive', true, { shouldDirty: true });
    if (earningRule?.targetedCustomerGroups?.edges?.length > 0) {
      setValue('generalType', GENERAL_TYPE.customerGroup, {
        shouldDirty: true,
      });
      const selectedCustomerGroup =
        earningRule?.targetedCustomerGroups?.edges.map((item) => item.node);
      setValue('customerGroup', selectedCustomerGroup, { shouldDirty: true });
    }
    if (earningRule?.targetedSegments?.edges?.length > 0) {
      setValue('generalType', GENERAL_TYPE.segment, { shouldDirty: true });
      const selectedSegment = earningRule?.targetedSegments?.edges.map(
        (item) => item.node,
      );
      setValue('segments', selectedSegment, { shouldDirty: true });
    }
  };

  useEffect(() => {
    if (history.location.search && !watchCampaignType) {
      const type = search.type;
      const id = search.id;
      const actionName =
        type === CampaignType.couponsCampaign
          ? 'getAndLinkCouponDetail'
          : 'getAndLinkEarningRuleDetail';
      console.log('@90');
      dispatch({
        type: `createCampaign/${actionName}`,
        payload: {
          couponId: id,
          earningRuleId: id,
          afterAction: (data) => {
            console.log('@93', data);
            setValue('campaignType', type);
            if (data.linkedCoupon) {
              const coupon = data.linkedCoupon;
              setValue(couponField, coupon);
              linkCouponChanged(coupon);
            } else {
              prefillEarningRuleSettingToGMCampaign({
                earningRule: data.linkedEarningRules,
                campaignType: type,
              });
              const earningRule = data.linkedEarningRules;
              if (type === CampaignType.earningRulesCampaign) {
                setValue(earningRuleField, earningRule);
              };
              linkEarningRuleChanged(earningRule);
            }
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchLinked = ({ actionName, idKey, idValue, dataKey }) => {
    dispatch({
      type: actionName,
      payload: {
        [idKey]: idValue,
        afterAction: (data) => {
          console.log('@@111: ', data);
          const sourceTranslations = { ...getValues().translations };
          (Object.keys(data[dataKey]?.translations) || []).forEach((lang) => {
            const translation = data[dataKey]?.translations[lang];

            (Object.keys(translation) || []).forEach((key) => {
              if (sourceTranslations[lang] && key in sourceTranslations[lang]) {
                setValue(`translations.${lang}.${key}`, translation[key], {
                  shouldDirty: true,
                });
              } else if (
                !sourceTranslations[lang] &&
                ['id', 'pk'].indexOf(key) < 0
              ) {
                setValue(`translations.${lang}.${key}`, translation[key], {
                  shouldDirty: true,
                });
              }
            });
          });
        },
      },
    });
  };

  useEffect(() => {
    if (isCouponRelated) {
      setValue(earningRuleField, {}, { shouldDirty: true });
      linkEarningRuleChanged({});
    }
    if (isEarningRuleRelated) {
      setValue(couponField, {}, { shouldDirty: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchCampaignType]);

  const linkCouponChanged = (coupon) => {
    if (coupon?.pk) {
      fetchLinked({
        actionName: 'createCampaign/getAndLinkCouponDetail',
        idKey: 'couponId',
        idValue: coupon.pk,
        dataKey: couponField,
      });
    }
  };

  const linkEarningRuleChanged = (earningRule) => {
    if (watchHideInAnyChannel && !isWebhookRelated) {
      setValue('hideInAnyChannel', false, { shouldDirty: true });
    }
    if (earningRule?.pk) {
      fetchLinked({
        actionName: 'createCampaign/getAndLinkEarningRuleDetail',
        idKey: 'earningRuleId',
        idValue: earningRule.pk,
        dataKey: earningRuleField,
      });
    }
  };

  const couponLinkSet = (
    <Controller
      control={control}
      name="linkedCoupon"
      render={() => (
        <>
          <div className="step-link-coupon-area-select">
            <CouponSelectDropdown
              title={'Link to coupon set'}
              description={
                'Please link to an existing coupon set. After you linked to an existing coupon set, the system will auto-fill in the content of the coupon set in the next step.'
              }
              defaultValue={{
                value: watchCoupon,
                label: watchCoupon?.pk
                  ? `[ID: ${watchCoupon?.pk}] ${watchCoupon?.name}`
                  : watchCoupon?.name,
              }}
              setValue={(item) => {
                console.log('couponChange:', item);
                const coupon = item || {};
                setValue(couponField, coupon, { shouldDirty: true });
                linkCouponChanged(coupon);
              }}
              // addButtonAction={() => setShowCreateNewLinkPrompt(true)}
              addButtonInfo={{
                requires: PermissionCodes.changeCouponTemplate,
                children: <Image src={addImage} />,
                action: () => setShowCreateNewLinkPrompt(true),
                customClass: 'earning-rule-add-new-link-button',
              }}
              refreshDate={refreshDate}
            />
          </div>
          <ReactHookFormErrorMessage errors={errors} id="linkedCoupon" />
        </>
      )}
    />
  );

  const earningRuleLinkSet = (
    <Controller
      control={control}
      name="linkedEarningRules"
      render={() => (
        <>
          <div className="step-link-coupon-area-select">
            <EarningRulesSelectDropdown
              title={'Link to earning rule set'}
              description={
                'Please link to an existing earning rule set. After you linked to an existing earning rule set, the system will auto-fill in the content of the earning rule set in the next step.'
              }
              defaultValue={{
                value: watchEarningRules,
                label: watchEarningRules?.pk
                  ? `[ID: ${watchEarningRules?.pk}] ${watchEarningRules?.name}`
                  : watchEarningRules?.name,
              }}
              defaultFilter={{
                otherSearch: {
                  typeIn: [
                    EarningRuleType.qrCodeScanning,
                    EarningRuleType.gpsCheckIn,
                    EarningRuleType.memberReferral,
                  ],
                },
              }}
              setValue={(item) => {
                console.log('earning rule Change:', item);
                const earningRule = item || {};
                setValue(earningRuleField, earningRule, { shouldDirty: true });
                linkEarningRuleChanged(earningRule);
              }}
              // addButtonAction={() => setShowCreateNewLinkPrompt(true)}
              addButtonInfo={{
                requires: PermissionCodes.changeEarningRule,
                children: <Image src={addImage} />,
                action: () => setShowCreateNewLinkPrompt(true),
                customClass: 'earning-rule-add-new-link-button',
              }}
              refreshDate={refreshDate}
            />
          </div>
          <ReactHookFormErrorMessage errors={errors} id="linkedEarningRules" />
        </>
      )}
    />
  );

  const publishChannelArea = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <label className="create-section-title">publish channel</label>
      <label className="section-short-description">
        select the channel to be published.
      </label>
      <CustomTitleLabel title="Select publish channel" />
      {isWebhookRelated ? (
        <Row className="featured-exclusive-row">
          <CustomRadios
            disable={false}
            options={[
              { label: 'Hide in any channel', value: true },
              { label: 'Show in specific channel', value: false },
            ]}
            default={watchHideInAnyChannel}
            onChange={(value) => {
              setValue('hideInAnyChannel', value, { shouldDirty: true });
            }}
          />
        </Row>
      ) : null}
      {watchHideInAnyChannel ? null : (
        <BaseMultipleSelectorV2
          data={{
            sourceData: publishChannelList,
            targetData: watchPublishChannel,
            targetChange: (value) => {
              setValue('publishChannel', value, { shouldDirty: true });
            },
          }}
          custom={{
            customTitle: <></>,
          }}
          hideTopSpace={true}
          size={['shorter']}
        />
      )}
    </div>
  );

  const campaignTypeSelectArea = (
    <Controller
      control={control}
      name="campaignType"
      render={() => (
        <div
          className="step-type-area campaign-step-one-bottom"
          style={{
            justifyContent:
              fullContainerWith < (MIN_WIDTH + SPACE_BETWEEN) * 2
                ? 'center'
                : 'flex-start',
          }}
        >
          {CampaignTypesConfig.map((typeItem) => (
            <ItemTypeSet
              key={`${typeItem.id}-${typeItem.description}`}
              item={typeItem}
              selected={watchCampaignType}
              disable={isPublished}
              onClick={(id) => {
                if (id === watchCampaignType) {
                  return;
                }
                clearErrors();
                setValue('campaignType', id, { shouldDirty: true });
                dispatch({
                  type: 'createCampaign/typeChange',
                  payload: { campaignType: id },
                });
              }}
            />
          ))}
        </div>
      )}
    />
  );

  const sections = [
    <>
      {status === APIStatus.calling ? (
        <Loading />
      ) : (
        <div>
          <label className="step-one-title">Campaign Type</label>
          {campaignTypeSelectArea}
          {isCouponRelated ? couponLinkSet : null}
          {isEarningRuleRelated ? earningRuleLinkSet : null}
          <BasePrompt
            show={showCreateNewLinkPrompt}
            closeAction={() => setShowCreateNewLinkPrompt(false)}
            leftButton={{
              text: promptconfig.refreshButton,
              action: () => {
                setRefreshDate(new Date());
                setShowCreateNewLinkPrompt(false);
              },
            }}
            rightButton={{
              text: promptconfig.button,
              action: () => {
                const pathname =
                  watchCampaignType === CampaignType.couponsCampaign
                    ? '/coupons/create'
                    : '/earns/create';
                window.open(pathname, '_blank');
              },
            }}
            title={promptconfig.title}
            description={promptconfig.message}
          />
        </div>
      )}
    </>,
    isEarningRuleRelated || isCouponRelated ? publishChannelArea : null,
  ];

  const goToNextStep = (assignRemain) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 0,
      isBack: false,
    });
    console.log('isValid:', isValid, errors);
    setValue('assignRemain', assignRemain);
    console.log('@357 values', getValues);
    dispatch({
      type: 'createCampaign/stepChange',
      payload: {
        step: 0,
        isValid,
      },
    });
  };

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <OnlyContinueButton
        continueAction={async () => {
          goToNextStep(true);
        }}
        disabledContinue={
          !watchCampaignType ||
          (watchCampaignType === CampaignType.couponsCampaign &&
            (!watchCoupon?.pk ||
              (!watchHideInAnyChannel && !watchPublishChannel))) ||
          (watchCampaignType === CampaignType.earningRulesCampaign &&
            (!watchEarningRules?.pk ||
              (!watchHideInAnyChannel && !watchPublishChannel)))
        }
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  campaignType: state.createCampaign.campaign.campaignType,
  linkedCoupon: state.createCampaign.campaign.linkedCoupon,
  linkedEarningRules: state.createCampaign.campaign.linkedEarningRules,
  status: state.createCampaign.createStatus,
  promptShowed: state.createCampaign.promptShowed,
  isPublished: state.createCampaign.campaign.isPublished,
  campaign: state.createCampaign.campaign,
});

export default connect(mapPropsToState)(CreateCampaignStepOne);
