import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { ArrayStringData } from '../../../utils';

function Filter({
  subCategoryList,
  brandList,
  checkedBrandList,
  backAction = () => {}
}) {
  const location = useLocation();

  const parsedSearch = queryString.parse(location.search);
  const searchStoreType = parsedSearch['store_type'] || '';
  const searchVisibleInFrontEnd = parsedSearch['visible_in_front_end'] || '';
  const searchSubcategory = parsedSearch['subcategory'] || '';
  const searchBrand = parsedSearch['brand'] || '';

  const [storeType, setStoreType] = useState(searchStoreType);
  const [visibleInFrontEnd, setVisibleInFrontEnd] = useState(searchVisibleInFrontEnd);
  const [subcategory, setSubcategory] = useState(
    ArrayStringData(searchSubcategory)
  );
  const [brand, setBrand] = useState([]);

  useEffect(()=>{
    if ( searchBrand && checkedBrandList ) {
      setBrand(...brand, checkedBrandList)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedBrandList])

  // useEffect(() => {
  //   dispatch(createAction('storeCategoryList/getAllStoreSubcategoryList')({}));
  // }, [dispatch]);

  const content = [
    {
      title: 'Store type',
      data: [
        { name: 'Offline', pk: 'offline' },
        { name: 'Online', pk: 'online' },
      ],
      value: storeType,
      setValue: setStoreType,
      component: FilterTypeEnum.singleChoiceSegment,
    },
    {
      title: 'Visible in front end',
      data: [
        { name: 'Showing', pk: 'true' },
        { name: 'Hidden', pk: 'false' },
      ],
      value: visibleInFrontEnd,
      setValue: setVisibleInFrontEnd,
      component: FilterTypeEnum.singleChoiceSegment,
    },
    {
      title: 'Subcategory',
      data: subCategoryList,
      value: subcategory,
      setValue: setSubcategory,
      component: FilterTypeEnum.choice,
    },
    { 
      filterTitle: 'Brand',
      data: brandList,
      value: brand,
      setValue: setBrand,
      placeholder: 'Search by brand name',
      loadMoreAction: 'brand/getCurrentPageBrands',
      filterAction: 'brand/getCurrentPageBrands',
      customItem: (item) => `[ID: ${item?.pk}] ${item?.name}`,
      defaultFilter: {
        isSimpleNode: true,
        noLoading: true,
      },
      component: FilterTypeEnum.multipleSelectorDropdown,
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

const mapPropsToState = (state) => ({
  subCategoryList: state.storeCategoryList.subCategoryList,
  brandList: state.brand.brandList,
  checkedBrandList: state.brand.checkedList,
});

export default connect(mapPropsToState)(Filter);
