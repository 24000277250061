import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import queryString from 'query-string';

import CustomBreadcrumb from 'components/base/CustomBreadcrumb';
import CustomListComponent from 'components/base/CustomListComponent';
import AuthButton from 'components/base/AuthButton';
import BaseTabListContainer from 'containers/base/BaseTabListContainer';
import BaseListContainer from 'containers/base/BaseListContainer';
import { PermissionCodes } from 'config/PermissionCodes';
import { NavBarNames } from 'config/NavBarNameList';

import { createAction } from 'utils';


function LevelDiscountList({
  totalCount,
  pageInfo,
  totalPage,
  displayFields,
  pagedList,
  checkedList,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  console.log('displayFields', displayFields);
  console.log('pagedList', pagedList);
  console.log('totalPage', totalPage);

  const actionList = [
  ];

  const tabs = [
    {
      name: 'List',
      content: (
        <BaseTabListContainer
          hideTab={true}
          groupActions={actionList}
          pageInfo={pageInfo}
          totalCount={totalCount || 0}
          listContent={
            <BaseListContainer
              fields={displayFields}
              model={'levelDiscount'}
              permissionGroup={PermissionCodes.levelDiscount}
              dataList={pagedList}
              totalPage={totalPage}
              deleteInfo={{
                data: [],
                title: NavBarNames.levelDiscount,
                relatedName: '',
                onComfirm: {},
                relatedSections: [],
              }}
              actions={[
                'Edit',
                'Detail',
              ]}
            />
          }
        />
      ),
    },
  ];

  const [showDeleteView, setShowDeleteView] = useState(false);

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    const searchKey = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);
    console.log(searchKey);
    dispatch(
      createAction('levelDiscount/getList')({
        ...searchDict,
        rank: rank,
        filterName: searchKey,
        page: page,
      }),
    );
  }, [dispatch, location]);

  const buttons = [
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: 'Define what benefits are eligible to the customers in each level.',
          title: NavBarNames.levelDiscount,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={true}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  totalCount: state.levelDiscount?.totalCount,
  pageInfo: state.levelDiscount?.pageInfo,
  totalPage: state.levelDiscount?.totalPage,
  displayFields: state.levelDiscount?.listDisplayFields,
  pagedList: state.levelDiscount?.pagedList,
  checkedList: state.levelDiscount?.checkedList,
})

export default connect(mapPropsToState)(LevelDiscountList)