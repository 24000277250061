import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import MembershipInfoCard from 'components/customer/MembershipInfoCard';
import EditCustomerAdditionalDataModal from 'components/customer/additionalData/EditCustomerAdditionalDataModal';
import { TitleWithButton } from 'components/customerDetail/utils';
import { AdditionalDataFieldType } from 'config/CustomEnums';
import { PermissionCodes } from 'config/PermissionCodes';

import CustomerEdit from 'assets/images/customer_edit.svg';

const mapPropsToState = (state) => ({
  userCustomData: state.customerList.customer?.userCustomData,
  sectionsConfig: state.customerList.sectionsConfig,
});

const CustomerAdditionalData = ({ userCustomData = {}, sectionsConfig }) => {
  console.log('sectionsConfig', sectionsConfig);
  const dispatch = useDispatch();
  const [showAdditionalDataModal, setShowAdditionalDataModal] = useState(false);

  useEffect(() => {
    dispatch({
      type: 'config/getAdditionalConfig',
    });
  }, []);

  let inDictFields = [];
  for (const section of sectionsConfig) {
    const newFields = section?.fields?.map((item) => item.fieldName);
    inDictFields = inDictFields.concat(newFields);
  }
  const otherFields = [];
  for (const [key, value] of Object.entries(userCustomData)) {
    if (inDictFields.includes(key)) {
      continue;
    }
    otherFields.push(key);
  }

  return (
    <>
      <TitleWithButton
        containerExtraClassName="customer-detail-section-title-container"
        title="CUSTOMER ADDITIONAL DATA"
        titleExtraClassName="customer-detail-section-title"
        firstButtonImage={CustomerEdit}
        firstButtonContent="Edit"
        firstButtonOnClick={() => setShowAdditionalDataModal(true)}
        firstButtonRequires={PermissionCodes.changeCustomer}
      />
      {sectionsConfig?.map((section) => {
        const sortedFields = section?.fields?.map((item) => {
          if (
            [
              AdditionalDataFieldType.Toggle,
              AdditionalDataFieldType.Checkbox,
            ].includes(item.fieldType)
          ) {
            return [
              {
                title: item.displayTitle,
                field: item.fieldName,
                isBoolean: true,
              },
            ];
          }
          return [
            {
              title: item.displayTitle,
              field: item.fieldName,
            },
          ];
        });
        return (
          <MembershipInfoCard
            key={`${section.name}`}
            title={section.name}
            data={userCustomData}
            fields={sortedFields}
          />
        );
      })}
      {otherFields?.length > 0 ? (
        <MembershipInfoCard
          title={'Other fields'}
          data={userCustomData}
          fields={otherFields?.map((key) => [{ title: key, field: key }])}
        />
      ) : null}
      <EditCustomerAdditionalDataModal
        otherFields={otherFields}
        show={showAdditionalDataModal}
        setShow={setShowAdditionalDataModal}
      />
    </>
  );
};

export default connect(mapPropsToState)(CustomerAdditionalData);
