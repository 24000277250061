import React from 'react';

import config from 'config';
import queryString from 'query-string';

import { URLFragments } from 'components/campaign/CampaignUrlConfig';
import {
  bulkRewardQueryKey,
  couponRecordQueryKey,
  pointRecordQueryKey,
  transactionQueryKey,
} from 'components/pointRecord/RecordsUrlConfig';
import { NavBarNames } from 'config/NavBarNameList';
import { PermissionCodes } from 'config/PermissionCodes';
import AdminGroupList from 'containers/admin/adminGroups/AdminGroupList';
import AdminList from 'containers/admin/admins/AdminList';
import MessageCreditRecords from 'containers/admin/admins/MessageCreditRecords';
import CustomerList from 'containers/customers/customer/CustomerList';
import CustomerGroupList from 'containers/customers/customerGroup/CustomerGroupList';
import SegmentList from 'containers/customers/segments/SegmentList';
import Dashboard from 'containers/dashboard/Dashboard';
import RFMDashboard from 'containers/dashboard/RFMDashboard';
import SuperSetDashboard from 'containers/dashboard/SuperSetDashboard';
import CampaignList from 'containers/engagement/campaign/CampaignList';
import CampaignCategory from 'containers/engagement/campaignCategory/CampaignCategory';
import CouponList from 'containers/engagement/coupon/CouponList';
import EarningRuleList from 'containers/engagement/earningRule/EarningRuleList';
import LanguageList from 'containers/engagement/language/LanguageList';
import LevelDiscountList from 'containers/engagement/level/LevelDiscountList';
import MessageList from 'containers/engagement/message/MessageList';
import StampCampaignList from 'containers/engagement/stampCampaign/StampCampaignList';
import GiftCardList from 'containers/giftcard/GiftCardList';
import GiftCardRecordList from 'containers/giftcard/GiftCardRecordList';
import BannerList from 'containers/homeManage/banners/BannerList';
import FeaturedCampaignList from 'containers/homeManage/featuredCampaigns/FeaturedCampaignList';
import BrandList from 'containers/merchants/brands/BrandList';
import ProductCategoryList from 'containers/merchants/productCategory/ProductCategoryList';
import StoreCategory from 'containers/merchants/storeCategory/StoreCategory';
import StoreCodeList from 'containers/merchants/stores/StoreCodeList';
import StoreList from 'containers/merchants/stores/StoreList';
import BulkRewardRecordList from 'containers/record/bulkRewardRecords/BulkRewardRecordList';
import CouponTransaction from 'containers/record/couponRecords/CouponTransaction';
import PointTransactionList from 'containers/record/pointRecords/PointTransactionList';
import TransactionList from 'containers/record/records/TransactionList';
import StampRecordList from 'containers/record/stampRecords/StampRecordList';
import WebviewList from 'containers/webview/WebviewList';

const dashboard = {
  name: NavBarNames.dashboard,
  list: [
    {
      path: '/dashboard',
      location: {
        pathname: '/dashboard',
      },
      exact: true,
      name: NavBarNames.dashboard,
      codename: PermissionCodes.viewDashboard,
      main: () => <Dashboard />,
    },
    {
      path: '/demographic_overview',
      location: {
        pathname: '/demographic_overview',
      },
      exact: true,
      name: NavBarNames.dashboardDemographic,
      codename: PermissionCodes.viewDashboard,
      main: () => (
        <SuperSetDashboard embedID={config.dashboardDemographicEmbedID} />
      ),
    },
    {
      path: '/sales_dashboard',
      location: {
        pathname: '/sales_dashboard',
      },
      exact: true,
      name: NavBarNames.dashboardSales,
      codename: PermissionCodes.viewDashboard,
      main: () => <SuperSetDashboard embedID={config.dashboardSalesEmbedID} />,
    },
    {
      path: '/earn_and_burn',
      location: {
        pathname: '/earn_and_burn',
      },
      exact: true,
      name: NavBarNames.dashboardCampaignAndRedemption,
      codename: PermissionCodes.viewDashboard,
      main: () => (
        <SuperSetDashboard embedID={config.dashboardCampaignEmbedID} />
      ),
    },
    {
      path: '/rfm',
      location: {
        pathname: '/rfm',
      },
      exact: true,
      name: NavBarNames.rfm,
      codename: PermissionCodes.viewDashboard,
      main: () => <RFMDashboard />,
    },
  ],
};

const admin = {
  name: 'administrators',
  displayName: 'Users & Permissions',
  list: [
    {
      path: '/administrator_groups',
      location: {
        pathname: '/administrator_groups',
      },
      exact: true,
      name: NavBarNames.adminGroup,
      codename: PermissionCodes.administratorgroup,
      main: () => <AdminGroupList />,
    },
    {
      path: '/administrators',
      location: {
        pathname: '/administrators',
      },
      exact: true,
      name: NavBarNames.admin,
      codename: PermissionCodes.administrator,
      main: () => <AdminList />,
    },
    {
      path: '/message_credit_records',
      location: {
        pathname: '/message_credit_records',
      },
      exact: true,
      name: NavBarNames.messageCreditRecords,
      codename: PermissionCodes.message,
      main: () => <MessageCreditRecords />,
    },
  ],
};

const customers = {
  name: 'user',
  list: [
    {
      path: '/customers',
      location: {
        pathname: '/customers',
      },
      exact: true,
      name: NavBarNames.customer,
      codename: PermissionCodes.customer,
      overviewCodename: PermissionCodes.viewDashboard,
      main: () => <CustomerList />,
    },
    {
      path: '/segments',
      location: {
        pathname: '/segments',
      },
      exact: true,
      name: NavBarNames.segments,
      codename: PermissionCodes.segment,
      main: () => <SegmentList />,
    },
    {
      path: '/customer_group',
      location: {
        pathname: '/customer_group',
        search: queryString.stringify({
          page: 1,
          rank: true,
          search: '',
        }),
      },
      exact: true,
      name: NavBarNames.customerGroup,
      codename: PermissionCodes.customergroup,
      main: () => <CustomerGroupList />,
    },
  ],
};

const engagement = {
  name: 'Engagement',
  list: [
    {
      path: '/campaigns',
      location: {
        pathname: '/campaigns',
      },
      exact: true,
      name: NavBarNames.campaigns,
      codename: PermissionCodes.campaign,
      overviewCodename: PermissionCodes.viewDashboard,
      main: () => <CampaignList />,
    },
    {
      path: '/stamp_campaigns',
      location: {
        pathname: '/stamp_campaigns',
      },
      exact: true,
      name: NavBarNames.stampCampaigns,
      codename: PermissionCodes.campaign,
      overviewCodename: PermissionCodes.viewDashboard,
      main: () => <StampCampaignList />,
    },
    {
      path: '/campaign_category',
      location: {
        pathname: '/campaign_category',
      },
      exact: true,
      name: NavBarNames.campaignCategory,
      codename: PermissionCodes.campaigncategory,
      main: () => <CampaignCategory />,
    },
    {
      path: '/messages',
      location: {
        pathname: '/messages',
        search: queryString.stringify({
          page: 1,
          rank: true,
          search: '',
        }),
      },
      exact: true,
      name: NavBarNames.message,
      codename: PermissionCodes.message,
      main: () => <MessageList />,
    },
    {
      path: '/earns',
      location: {
        pathname: '/earns',
      },
      exact: true,
      name: NavBarNames.earnRules,
      codename: PermissionCodes.earningrule,
      main: () => <EarningRuleList />,
    },
    {
      path: '/coupons',
      location: {
        pathname: '/coupons',
      },
      exact: true,
      name: NavBarNames.coupons,
      codename: PermissionCodes.coupontemplate,
      overviewCodename: PermissionCodes.viewDashboard,
      main: () => <CouponList />,
    },
    {
      path: '/gift_cards',
      location: {
        pathname: '/gift_cards',
      },
      exact: true,
      name: NavBarNames.giftCards,
      codename: PermissionCodes.giftcard,
      main: () => <GiftCardList />,
    },
    {
      path: '/languages',
      location: {
        pathname: '/languages',
      },
      exact: true,
      name: NavBarNames.langauge,
      codename: PermissionCodes.language,
      main: () => <LanguageList />,
    },
  ],
};

const merchants = {
  name: 'Merchants',
  list: [
    {
      path: '/brands',
      location: {
        pathname: '/brands',
        search: queryString.stringify({
          page: 1,
          rank: true,
          search: '',
        }),
      },
      exact: true,
      name: NavBarNames.brands,
      codename: PermissionCodes.brand,
      main: () => <BrandList />,
    },
    {
      path: '/stores',
      location: {
        pathname: '/stores',
      },
      exact: true,
      name: NavBarNames.stores,
      codename: PermissionCodes.store,
      main: () => <StoreList />,
    },
    {
      path: '/store_category',
      location: {
        pathname: '/store_category',
      },
      exact: true,
      name: NavBarNames.storeCategory,
      codename: PermissionCodes.storecategory,
      main: () => <StoreCategory />,
    },
    {
      path: '/store_codes',
      location: {
        pathname: '/store_codes',
      },
      exact: true,
      name: NavBarNames.storeCodes,
      codename: PermissionCodes.store,
      main: () => <StoreCodeList />,
    },
    {
      path: '/product_categories',
      location: {
        pathname: '/product_categories',
      },
      exact: true,
      name: NavBarNames.productCategory,
      codename: PermissionCodes.productCategory,
      main: () => <ProductCategoryList />,
    },
  ],
};

const others = {
  name: 'Others',
  list: [
    {
      path: '/webview',
      location: {
        pathname: '/webview',
      },
      exact: true,
      name: NavBarNames.webview,
      main: () => <WebviewList />,
    },
    {
      path: '/level_benefits',
      location: {
        pathname: '/level_benefits',
      },
      exact: true,
      name: NavBarNames.levelDiscount,
      codename: PermissionCodes.levelDiscount,
      main: () => <LevelDiscountList />,
    },
  ],
};

const records = {
  name: 'Record',
  list: [
    {
      path: '/transactions',
      location: {
        pathname: '/transactions',
        search: queryString.stringify({
          [transactionQueryKey.searchKey]: '',
          [transactionQueryKey.page]: 1,
          [transactionQueryKey.rank]: true,
          [transactionQueryKey.tab]: 'all',
        }),
      },
      exact: true,
      name: NavBarNames.transactions,
      codename: PermissionCodes.transaction,
      main: () => <TransactionList />,
    },
    {
      path: '/bulk_rewards',
      location: {
        pathname: '/bulk_rewards',
        search: queryString.stringify({
          [bulkRewardQueryKey.searchKey]: '',
          [bulkRewardQueryKey.page]: 1,
          [bulkRewardQueryKey.rank]: true,
          [bulkRewardQueryKey.tab]: 'all',
        }),
      },
      exact: true,
      name: NavBarNames.bulkRewards,
      codename: PermissionCodes.viewBulkReward,
      main: () => <BulkRewardRecordList />,
    },
    {
      path: '/stamp_records',
      location: {
        pathname: '/stamp_records',
        // search: queryString.stringify({
        //   [stampRecordQueryKey.searchKey]: '',
        //   [stampRecordQueryKey.page]: 1,
        //   [stampRecordQueryKey.rank]: true,
        //   [stampRecordQueryKey.tab]: 'all',
        // }),
      },
      exact: true,
      name: NavBarNames.stampRecords,
      codename: PermissionCodes.viewStampRecord,
      main: () => <StampRecordList />,
    },
    {
      path: '/gift_card_records',
      location: {
        pathname: '/gift_card_records',
      },
      exact: true,
      name: NavBarNames.giftCardRecords,
      codename: PermissionCodes.giftcardRecord,
      main: () => <GiftCardRecordList />,
    },
    {
      path: '/point_records',
      location: {
        pathname: '/point_records',
        search: queryString.stringify({
          [pointRecordQueryKey.searchKey]: '',
          [pointRecordQueryKey.page]: 1,
          [pointRecordQueryKey.rank]: true,
          // [pointRecordQueryKey.tab]: 'all',
        }),
      },
      exact: true,
      name: NavBarNames.pointRecords,
      codename: PermissionCodes.pointtransaction,
      main: () => <PointTransactionList />,
    },
    {
      path: '/coupon_records',
      location: {
        pathname: '/coupon_records',
        search: queryString.stringify({
          [couponRecordQueryKey.searchKey]: '',
          [couponRecordQueryKey.page]: 1,
          [couponRecordQueryKey.rank]: true,
          [couponRecordQueryKey.tab]: 'all',
        }),
      },
      exact: true,
      name: NavBarNames.couponRecords,
      codename: PermissionCodes.coupontransaction,
      main: () => <CouponTransaction />,
    },
  ],
};

const homemanager = {
  name: 'Manage Home page',
  list: [
    {
      path: '/banners',
      location: {
        pathname: '/banners',
      },
      exact: true,
      name: NavBarNames.banners,
      codename: PermissionCodes.banners,
      main: () => <BannerList />,
    },
    {
      path: '/home_campaigns',
      location: {
        pathname: '/home_campaigns',
      },
      exact: true,
      name: NavBarNames.featuredCampaign,
      codename: PermissionCodes.campaign,
      main: () => <FeaturedCampaignList />,
    },
  ],
};

export const tabList = [
  dashboard,
  customers,
  engagement,
  merchants,
  others,
  records,
  admin,
  homemanager,
];
