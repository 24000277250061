import React, { useEffect, useState } from 'react';
import { Image, Modal } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';

import PromptButton, { BUTTON_TYPES } from 'components/base/prompt/BaseButtons';
import OtherFieldSection from 'components/customer/additionalData/ProfileOtherFieldSection';
import Section from 'components/customer/additionalData/ProfileSection';
import { PermissionCodes } from 'config/PermissionCodes';

import CloseIcon from 'assets/images/prompt_close_icon.svg';

import './EditCustomerAdditionalDataModal.scss';

const mapPropsToState = (state) => ({
  userCustomData: state.customerList.customer?.userCustomData,
  sectionsConfig: state.customerList.sectionsConfig,
  customer: state.customerList.customer,
  errors: state.customerList.errorFields,
});

const EditCustomerAdditionalDataModal = ({
  userCustomData = {},
  sectionsConfig,
  customer,
  errors,
  otherFields,
  show,
  setShow,
}) => {
  const dispatch = useDispatch();

  const [newUserCustomData, setNewUserCustomData] = useState(userCustomData);
  useEffect(() => {
    setNewUserCustomData(userCustomData);
  }, [userCustomData]);

  useEffect(() => {
    dispatch({
      type: 'config/getAdditionalConfig',
    });
  }, []);

  const onClose = () => {
    setShow(false);
  };

  const saveAction = () => {
    dispatch({
      type: 'customerList/updateCustomerAdditionalData',
      payload: {
        id: customer.pk,
        data: newUserCustomData,
        successCallback: () => {
          dispatch({
            type: 'customerList/getOneCustomer',
            payload: {
              id: customer.pk,
            },
          });
          setShow(false);
        },
        failureCallback: () => {},
      },
    });
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Body className={`base-prompt-container`}>
        <Image
          src={CloseIcon}
          className="base-prompt-close-button"
          onClick={onClose}
        />

        <label className="customer-detail-modal-title">
          Edit customer additional data
        </label>

        <div className="customer-additional-data">
          {sectionsConfig?.map((section) => (
            <Section
              key={`${section?.name}`}
              sectionConfig={section}
              newUserCustomData={newUserCustomData}
              setNewUserCustomData={setNewUserCustomData}
            />
          ))}
          <OtherFieldSection
            otherFields={otherFields}
            newUserCustomData={newUserCustomData}
            setNewUserCustomData={setNewUserCustomData}
          />
        </div>

        <div className="base-prompt-buttons">
          <PromptButton
            title="Cancel"
            action={onClose}
            type={BUTTON_TYPES.secondary}
          />
          <PromptButton
            title="Confirm"
            action={saveAction}
            requires={PermissionCodes.changeCustomer}
            type={BUTTON_TYPES.main}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default connect(mapPropsToState)(EditCustomerAdditionalDataModal);
