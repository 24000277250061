import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';

import BaseMultipleSelectorV2 from 'components/base/BaseMultipleSelectorV2';
import CustomTitleWithInput from 'components/base/CustomTitleWithInput';
import { ReactHookFormErrorMessage } from 'components/base/ErrorFieldMessage';
import { AdminGroupErrorHandleFields } from 'containers/admin/adminGroups/AdminGroupErrorHandleFields';
import BaseEditSection from 'containers/base/BaseEditSection';

function CreateAdminGroupStepOneGeneralSection({ allPermissions }) {
  const { watch, setValue, control, formState } = useFormContext();
  const dispatch = useDispatch();
  const { errors } = formState;
  const watchName = watch('name');
  const watchPermissions = watch('permissions') || [];

  useEffect(() => {
    dispatch({
      type: 'admin/getAllPermissions',
      payload: {},
    });
    return () => {
      dispatch({
        type: 'admin/clearState',
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BaseEditSection
        title="General"
        fields={[
          <Controller
            control={control}
            name="name"
            rules={{ required: AdminGroupErrorHandleFields.name.required }}
            render={() => (
              <CustomTitleWithInput
                title={'Name'}
                defaultValue={watchName}
                setValue={(value) => {
                  setValue('name', value, {
                    shouldDirty: true,
                  });
                }}
              />
            )}
          />,
          <ReactHookFormErrorMessage errors={errors} id="name" />,
          <Controller
            control={control}
            name="permissions"
            render={() => (
              <BaseMultipleSelectorV2
                title={'Assign permissions(optional)'}
                tips={
                  'The word “Edit” in below permission items covered create, edit, delete, duplicate permission; while for edit campaign and edit message permissions, they only covered delete and duplicate, create and edit has separate permissions.'
                }
                namespace="admin"
                groupKey="groupKey"
                data={{
                  sourceData: allPermissions,
                  targetData: watchPermissions,
                  targetChange: (value) => {
                    console.log('targetChange @65', value);
                    setValue('permissions', value, {
                      shouldDirty: true,
                    });
                  },
                }}
              />
            )}
          />,
          <ReactHookFormErrorMessage errors={errors} id="permissions" />,
        ]}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  allPermissions: state.admin.allPermissionList, // state.admin.cmsAllPermissions,
});

export default connect(mapPropsToState)(CreateAdminGroupStepOneGeneralSection);
