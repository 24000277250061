import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createAction } from 'utils';

import CustomSwitchButton from 'components/base/CustomSwitchButton';
import CustomTitleWithInput from 'components/base/CustomTitleWithInput';
import { hasError } from 'components/base/ErrorFieldMessage';
import ContentSections from 'components/base/ContentSections';
import { SaveAndBackButtons } from 'components/base/BottomStepComponent';
import { listItem } from 'components/banners/DisplayItems';
import { CustomTitleLabel } from 'components/earning/CustomBaseComponments';

import './CreateLevelDiscountContent.scss';

function LevelDiscountContent() {
  const {
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const discountNumberFieldName = "discountNumber"
  const isFreeDeliveryOnShopifyFieldName = "freeDeliveryOnShopfify"

  const watchSourceName = watch('sourceName');
  const watchDiscount = watch(discountNumberFieldName);
  const watchIsFreeDeliveryOnShopify = watch(isFreeDeliveryOnShopifyFieldName);

  return (
    <>
      <label className="create-section-title">General</label>

      {listItem(
        'Name',
        watchSourceName,
      )}

      <CustomTitleWithInput
        title={'Discount'}
        defaultValue={watchDiscount}
        setValue={(value) => {
          setValue(discountNumberFieldName, value, { shouldDirty: true, });
        }}
        error={{
          id: discountNumberFieldName,
          error: hasError(errors, discountNumberFieldName),
          message: 'Please provide required/valid quantity.',
        }}
        customClass={'level-discount-input'}
        unit={"%"}
        type={'number'}
        extra={{
          min: 1,
          max: 100,
        }}
      />
      <CustomTitleLabel title="Shopify - free delivery" />
      <CustomSwitchButton
        defaultChecked={watchIsFreeDeliveryOnShopify || false}
        onChange={(isChecked) => {
          setValue(isFreeDeliveryOnShopifyFieldName, isChecked, { shouldDirty: true });
        }}
      />
    </>
  );
}

export default function CreateLevelDiscountContent() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { getValues, setError, clearErrors } = useFormContext();
  const validate = async () => {
    clearErrors(['discountNumber']);
    const [discountNumber] = getValues(['discountNumber']);
    let isError = false;

    if (
      !discountNumber
      || Number(discountNumber) <= 0
      || Number(discountNumber) > 100
    ) {
      isError = true;
      setError(
        'discountNumber',
        {
          type: 'require',
          message: 'Please provide required/valid quantity.',
        },
        { shouldFocus: true },
      );
    }
    return !isError;
  };

  return (
    <>
      <ContentSections sections={[<LevelDiscountContent />]} hidePreview={true} />
      <SaveAndBackButtons
        saveText={'Save'}
        backAction={() => history.goBack()}
        saveAction={async () => {
          const isValid = await validate();
          if (isValid) {
            dispatch(createAction('levelDiscount/createOrUpdate')(getValues()));
          }
        }}
      />
    </>
  );
}
