import { getInFilterValue } from '../utils';
import { BaseHelper } from './BaseGraphQLAPIHelper';

const STORE_NODE = `
id
pk
name
isOnlineStore
visibleInFrontEnd
displayPriority
photo
address
phoneNumberCountryCode
phoneNumberSubscriberNumber
codeDisplayImage
codeDownloadImage
latitude
longitude
emailAddress
description
staffCode
subcategories(first: 50) {
  edges {
    node {
      pk
      category {
        name
        displayPriority
      }
      name
      displayPriority
    }
  }
}
district {
  region {
      pk
      name
  }
  name
}
brand {
  pk
  name
}
translations {
  edges {
    node {
      language
      name
      description
      address
    }
  }
}
`

const SIMPLE_STORE_NODE = `
id
pk
name
isOnlineStore
visibleInFrontEnd
translations {
  edges {
    node {
      language
      name
    }
  }
}
`

const LIST_NODE = `
id
pk
name
photo
displayPriority
brand {
  pk
  name
}
phoneNumberCountryCode
phoneNumberSubscriberNumber
emailAddress
isOnlineStore
visibleInFrontEnd
subcategories(first: 50) {
  edges {
    node {
      pk
      name
      displayPriority
    }
  }
}
`

export const getStores = () => {
  const query = `{
        stores(first: 100) {
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          edges {
            cursor
            node {
              id
              pk
              name
              photo
              address
              translations(first: 3) {
                edges {
                  node {
                    language
                    name
                  }
                }
              }
            }
          }
        }
      }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getStoresOfBrand = (brandID, afterCursor) => {
  const query = `{
    brand(id: "${brandID}", after: "${afterCursor}") {
      stores(first: 20) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            pk
            name
            photo
            address
            translations {
              edges {
                node {
                  language
                  name
                }
              }
            }
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getPagedStores = (afterCursor, search = {}) => {
  // const order = rank ? '-pk' : 'pk';
  console.log('@@73: ', search);
  let searchString = '';
  if (search.search) {
    searchString += `, name: "${search.search}"`;
  }
  if (search.subcategory) {
    searchString += `, subcategoriesIn: ${getInFilterValue(search.subcategory)}`;
  }
  if (search.brand) {
    searchString += `, brandIn: ${getInFilterValue(search.brand)}`;
  }
  if (search?.isOnlineStore || search?.store_type === "online") {
    searchString += ", isOnlineStore: true";
  } else if (
    (search?.isOnlineStore !== undefined && !search?.isOnlineStore)
    || search?.store_type === "offline"
  ) {
    searchString += ", isOnlineStore: false";
  }

  if (search?.visible_in_front_end !== undefined) {
    searchString += `, visibleInFrontEnd: ${search.visible_in_front_end}`;
  }

  let orderBy = 'displayPriority';
  if (search?.sort) {
    orderBy = search.sort;
  }
  let pageSize = 20;
  if (search?.pageSize) {
    pageSize = search.pageSize;
  }
  const storeNode = search?.isListNode ? LIST_NODE : search?.isSimpleNode ? SIMPLE_STORE_NODE : STORE_NODE;
  const query = `{
    stores(first: ${pageSize}, after: "${afterCursor}", orderBy: "${orderBy}"${searchString}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ${storeNode}
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneStore = (id) => {
  const query = `{
    store(id: "${id}") {
      id
      pk
      name
      displayPriority
      photo
      address
      phoneNumberCountryCode
      phoneNumberSubscriberNumber
      codeDisplayImage
      codeDownloadImage
      latitude
      longitude
      emailAddress
      description
      staffCode
      isOnlineStore
      visibleInFrontEnd
      thirdPartyStoreCode
      subcategories(first: 50) {
          edges {
              node {
                  pk
                  id
                  category {
                      name
                      displayPriority
                  }
                  name
                  displayPriority
              }
          }
      }
      district {
          region {
              pk
              name
          }
          name
          pk
      }
      brand {
          pk
          name
      }
      translations {
          edges {
          node {
              pk
              language
              name
              description
              address
          }
          }
      }
    }
  }
  `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteStores = (ids) => {
  const query = `mutation DeleteStores($input: DeleteStoresInput!) {
    deleteStores(input: $input) {
      success
    }
  }
  `;
  const variables = { input: { ids: ids } };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createOrUpdateStore = (values) => {
  const action = values.id ? 'Update' : 'Create';
  let query = `mutation ${action}Store($input: ${action}StoreInput!) {
    ${action.toLowerCase()}Store(input: $input) {
      success
      node {
          id
          pk
          name
          displayPriority
          photo
          address
          phoneNumberCountryCode
          phoneNumberSubscriberNumber
          latitude
          longitude
          emailAddress
          description
          staffCode
          isOnlineStore
          visibleInFrontEnd
          subcategories {
              edges {
                  node {
                      pk
                      category {
                          name
                          displayPriority
                      }
                      name
                      displayPriority
                  }
              }
          }
          district {
              region {
                  pk
                  name
              }
              name
          }
          brand {
              pk
          }
          translations {
            edges {
              node {
                pk
                language
                name
                description
                address
              }
            }
          }
      }
      errors {
        field
        messages
      }
    }
  }

  `;

  const variables = { input: values };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getDistricts = (afterCursor, search) => {
  let filter = '';

  if (search?.search) {
    filter += `, nameIcontains: "${search.search}"`;
  }
  const query = `{
    districts(first: 50, after: "${afterCursor}", orderBy: "pk"${filter}) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          region {
              pk
              name
          }
          name
          translations {
            edges {
              node {
                language
                name
                pk
              }
            }
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getDownloadStoreCodesZip = (values) => {
  const query = `mutation DownloadStores($input: DownloadStoreCodesInput!) {
    downloadStores(input: $input) {
      success
      zipLink
    }
  }`;
  const variables = { input: values };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
