import {
  ApprovalStatus,
  LanguageConfig,
  MessageBackendChannel,
  MessageChannel,
  MessageTag,
  MessageWhatsappTemplateType,
} from 'config/CustomEnums';
import { loading } from 'models/LoadingUtil';
import {
  MESSAGE_PAGE_SIZE,
  deleteMessages,
  duplicateMessage,
  getMessage,
  getMessageCredit,
  getMessageTemplate,
  getMessages,
  getWhatsappMessageTemplates,
} from 'services/MessageApiHelper';

import {
  convertCursorToNumber,
  convertNumberToCursor,
  formatNumberWithCommas,
} from 'utils';
import { TimeFormater, formatDate } from 'utils/TimeFormatUtil';

const getInitialState = () => ({
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    {
      displayName: 'Name',
      fieldName: 'name',
      linked: true,
      orderField: 'name',
    },
    { displayName: 'Message channel', fieldName: 'displayChannels' },
    {
      displayName: 'Related campaign',
      fieldName: 'relatedCampaignName',
      orderField: 'campaign',
    },
    {
      displayName: 'Push open rate (opened/sent)',
      fieldName: 'displaypushOpenRate',
    },
    {
      displayName: 'Push click rate (clicked/opened)',
      fieldName: 'displaypushClickRate',
    },
    {
      displayName: 'Email open rate (opened/sent)',
      fieldName: 'displayemailOpenRate',
    },
    {
      displayName: 'Email click rate (clicked/opened)',
      fieldName: 'displayemailClickRate',
    },
    {
      displayName: 'SMS click rate (clicked/sent)',
      fieldName: 'displaysmsClickRate',
    },
    {
      displayName: 'Whatsapp click rate (clicked/sent)',
      fieldName: 'displaywhatsappClickRate',
    },
    {
      displayName: 'Inbox open rate (opened/sent)',
      fieldName: 'displayinboxOpenRate',
    },
    {
      displayName: 'Inbox click rate (clicked/opened)',
      fieldName: 'displayinboxClickRate',
    },
    { displayName: 'Target customers', fieldName: 'displayTargetCustomer' },
    {
      displayName: 'Delivery time',
      fieldName: 'deliveryDate',
      orderField: 'scheduledDate',
    },
    { displayName: 'Status', fieldName: 'status' },
  ],
  currentPageMessageList: [],
  allMessageList: [],
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  currentLastCursor: '',
  currentPage: 0,
  messageTemplateList: [],
  totalPage: 0,
  totalCount: 0,
  checkedList: [],
  message: {},
  errorFields: [],
});

export const parseChannel = (channelName) => {
  if (channelName === MessageBackendChannel.inbox) {
    return MessageChannel.inbox;
  }
  if (channelName === MessageBackendChannel.whatsapp) {
    return MessageChannel.whatsapp;
  }
  if (channelName === MessageBackendChannel.sms) {
    return MessageChannel.sms;
  }
  if (channelName === MessageBackendChannel.email) {
    return MessageChannel.email;
  }
  if (channelName === MessageBackendChannel.push) {
    return MessageChannel.push;
  }
  if (channelName === MessageBackendChannel.allWhatsapp) {
    return MessageChannel.allWhatsapp;
  }
  if (channelName === MessageBackendChannel.all) {
    return MessageChannel.all;
  }
};

const parseWhatsappTemplateList = (list) => {
  return list
    .map((item) => {
      const node = item.node;
      const template = node.template;
      return {
        id: node.id,
        name: node.name,
        bodyText: template.body,
        bodyParameterCount: template.parameterCount,
        footerText: template.footer,
        headerFormat: template.header.format,
        headerText: template.header.text,
      };
    })
    .filter(
      (item) =>
        item.headerFormat !== MessageWhatsappTemplateType.DOCUMENT &&
        item.headerFormat !== MessageWhatsappTemplateType.VIDEO,
    );
};

const parseMessageTranslations = (translations) => {
  let parsedTranslations = {};
  translations.forEach((item) => {
    const translationData = item.node;
    let language = translationData.language;
    if (language === 'ZH_HANS') {
      language = LanguageConfig.simplifiedChinese;
    } else if (language === 'ZH_HANT') {
      language = LanguageConfig.traditionalChinese;
    }
    parsedTranslations[language] = {
      name: translationData.title,
      content: translationData.content,
      photo: translationData.photo,
      id: translationData.id,
      pk: translationData.pk,
    };
  });
  return parsedTranslations;
};

const parseMessageStatus = (sendStatus, approvalStatus) => {
  switch (sendStatus) {
    case 'STATE_COMPLETED':
      return MessageTag.sent;
    case 'STATE_INITIAL':
      if (approvalStatus === ApprovalStatus.draft) {
        return MessageTag.draft;
      } else if (approvalStatus === ApprovalStatus.pending) {
        return MessageTag.pendingForApproval;
      }
      return MessageTag.scheduled;
    case 'STATE_SENDING':
      return MessageTag.sending;
    case 'STATE_ERROR':
      return MessageTag.error;
    default:
      break;
  }
};

const parseMessageRate = (message, messageStatus, channelList) => {
  const isPending =
    [MessageTag.sending, MessageTag.scheduled].indexOf(messageStatus) !== -1;
  const displyChannel = channelList?.map((item) => item?.toLowerCase()) || [];

  const isDisplayChannelRate = (channel) => {
    if (
      channelList?.indexOf(MessageChannel.all) !== -1 ||
      displyChannel.indexOf(channel) !== -1
    ) {
      return true;
    }
    return false;
  };

  const getDisplayRate = (channel, countData, totalCountData, rateData) => {
    if (!isDisplayChannelRate(channel)) {
      return '-';
    }
    if (isPending) {
      return 'Pending';
    }
    if (totalCountData) {
      return `${rateData}(${countData}/${totalCountData})`;
    }
    return '-';
  };
  let res = {};
  ['push', 'email', 'sms', 'whatsapp', 'inbox'].forEach((channel) => {
    res[`${channel}ClickCount`] = message?.[`${channel}ClickCount`];
    res[`${channel}ClickTotalCount`] = message?.[`${channel}ClickTotalCount`];
    res[`${channel}ClikRate`] =
      message?.[`${channel}ClickTotalCount`] !== 0
        ? `${parseInt(
          100 *
          (message?.[`${channel}ClickCount`] /
            message?.[`${channel}ClickTotalCount`]),
        )}%`
        : '0%';
    res[`display${channel}ClickRate`] = getDisplayRate(
      channel,
      res[`${channel}ClickCount`],
      res[`${channel}ClickTotalCount`],
      res[`${channel}ClikRate`],
    );
    if (!['sms', 'whatsapp'].includes(channel)) {
      res[`${channel}OpenCount`] = message?.[`${channel}OpenCount`];
      res[`${channel}OpenTotalCount`] = message?.[`${channel}OpenTotalCount`];
      res[`${channel}OpenRate`] =
        message?.[`${channel}OpenTotalCount`] !== 0
          ? `${parseInt(
            100 *
            (message?.[`${channel}OpenCount`] /
              message?.[`${channel}OpenTotalCount`]),
          )}%`
          : '0%';
      res[`display${channel}OpenRate`] = getDisplayRate(
        channel,
        res[`${channel}OpenCount`],
        res[`${channel}OpenTotalCount`],
        res[`${channel}OpenRate`],
      );
    }
  });
  return res;
};

const parseListMessage = (data) => {
  const parsedChannelList = data.channels.edges?.map((item) =>
    parseChannel(item.node.pk),
  );
  const customerGroupList = data.targetedCustomerGroups?.edges?.map(
    (item) => item.node.name,
  );
  const segmentsNameList = data.targetedSegments?.edges?.map(
    (item) => item.node?.name,
  );
  const translations =
    data.translations?.edges?.length > 0
      ? parseMessageTranslations(data.translations.edges)
      : {};
  return {
    pk: data.pk,
    id: data.id,
    name: data.name || '-',
    content: data.content,
    photo: data.photo,
    channels: parsedChannelList,
    displayChannels: parsedChannelList.toString(),
    relatedCampaign: {
      pk: data.campaign?.pk,
      name: data.campaign?.name,
    },
    relatedCampaignName: data.campaign?.pk
      ? `[ID: ${data.campaign?.pk}] ${data.campaign?.name}`
      : '-',
    targetCustomerGroup: data.targetedCustomerGroups?.edges.map(
      (item) => item.node,
    ),
    displayTargetCustomer: customerGroupList.toString(),
    targetedSegments: data.targetedSegments?.edges.map((item) => item.node),
    displayTargetedSegments:
      segmentsNameList?.length > 0 ? segmentsNameList.toString() : null,
    scheduledDate: data.scheduledDate,
    deliveryDate: formatDate(
      data.scheduledDate,
      TimeFormater.dayMonthYearWeekTimeA,
    ),
    translations: translations,
    creditSpent: data.realCreditSpent,
    displayCreditSpent:
      data?.sendingState === 'STATE_INITIAL'
        ? 'Pending to sent'
        : formatNumberWithCommas(data.realCreditSpent),
    status: parseMessageStatus(data.sendingState, data.approvalStatus),
    ...parseMessageRate(
      data,
      parseMessageStatus(data.sendingState, data.approvalStatus),
      parsedChannelList,
    ),
  };
};

const parseImageUrl = (imageUrl) => {
  if (imageUrl !== null && imageUrl !== '') {
    return {
      type: 'TYPE_URL',
      value: imageUrl,
    };
  }
  return null;
};

const getDisplayMessageCredit = (credit, status) => {
  return status === 'STATE_INITIAL'
    ? 'Pending to sent'
    : formatNumberWithCommas(credit)
}

const parseDetailMessage = (data) => {
  const parsedChannelList = data.channels.edges?.map((item) =>
    parseChannel(item.node.pk),
  );
  const translations = {
    [LanguageConfig.english]: {
      name: data.title,
      content: data.content,
      photo: parseImageUrl(data.photo),
      emailTitle: data.emailTitle,
      emailContent: data.emailContent,
      smsContent: data.smsContent,

      whatsappTemplate: JSON.parse(data.whatsappTemplateJson || '{}'),
      whatsappTemplateName: data.whatsappTemplateName,
      whatsappImage: parseImageUrl(data.whatsappImage),
      whatsappHeader: data.whatsappHeaderArgs,
      whatsappBody: data.whatsappBodyArgs,
    },
  };
  const edges = data.translations?.edges || [];
  edges.forEach((translation) => {
    const {
      language,
      title,
      content,
      photo,
      emailTitle,
      emailContent,
      smsContent,
      pk,
      id,
      whatsappTemplateJson,
      whatsappTemplateName,
      whatsappImage,
      whatsappHeaderArgs,
      whatsappBodyArgs,
    } = translation.node;
    translations[language] = {
      id: id,
      pk: pk,
      name: title,
      content: content,
      photo: parseImageUrl(photo),
      emailTitle: emailTitle,
      emailContent: emailContent,
      smsContent: smsContent,

      whatsappTemplate: JSON.parse(whatsappTemplateJson || '{}'),
      whatsappTemplateName: whatsappTemplateName,
      whatsappImage: parseImageUrl(whatsappImage),
      whatsappHeader: whatsappHeaderArgs,
      whatsappBody: whatsappBodyArgs,
    };
  });
  console.log('translations', translations);
  return {
    pk: data.pk,
    id: data.id,
    name: data.name || '',
    isDirectMarketing: !!data.isDirectMarketing,
    channels: parsedChannelList,
    displayChannels: parsedChannelList.toString(),
    displayTemplateName: data.messageTemplate?.name || '-',
    displayIsStartWithTemplate: data.isStartWithTemplate ? 'Yes' : 'No',
    isStartWithTemplate: data.isStartWithTemplate,
    messageTemplate: data.messageTemplate ?
      {
        label: data.messageTemplate?.name,
        value: data.messageTemplate,
      } : {},
    relatedCampaign: {
      pk: data.campaign?.pk,
      name: data.campaign?.name,
      isExclusive: data.campaign?.isExclusive,
    },
    sendToAll: data?.sendToAll || false,
    targetCustomerGroup: data.targetedCustomerGroups?.edges.map(
      (item) => item.node,
    ),
    targetedSegments: data.targetedSegments?.edges.map((item) => item.node),
    scheduledDate: data.scheduledDate,
    translations,
    status: parseMessageStatus(data.sendingState, data.approvalStatus),
    approvalStatus: data.approvalStatus,
    creditSpent: data.realCreditSpent,
    displayCreditSpent: getDisplayMessageCredit(data?.realCreditSpent, data?.sendingState),
    ...parseMessageRate(
      data,
      parseMessageStatus(data.sendingState, data.approvalStatus),
      parsedChannelList,
    ),
    recipientsFollowCampaign: data.recipientsFollowCampaign,
    whatsappCreditSpent: data?.whatsappCreditSpent,
    smsCreditSpent: data?.smsCreditSpent,
    emailCreditSpent: data?.emailCreditSpent,
    displayWhatsappCreditSpent: getDisplayMessageCredit(data?.whatsappCreditSpent, data?.sendingState),
    displaySmsCreditSpent: getDisplayMessageCredit(data?.smsCreditSpent, data?.sendingState),
    displayEmailCreditSpent: getDisplayMessageCredit(data?.emailCreditSpent, data?.sendingState),
  };
};

const parseMessageTemplate = (template) => {
  const data = template?.node;
  const parsedChannelList = data.channels.edges?.map((item) =>
    parseChannel(item.node.pk),
  );

  const translations = {
    [LanguageConfig.english]: {
      title: data.title,
      content: data.content,
      photo: parseImageUrl(data.photo),
      emailTitle: data.emailTitle,
      emailContent: data.emailContent,
      smsContent: data.smsContent,

      whatsappTemplate: JSON.parse(data.whatsappTemplateJson || '{}'),
      whatsappTemplateName: data.whatsappTemplateName,
      whatsappImage: parseImageUrl(data.whatsappImage),
      whatsappHeader: data.whatsappHeaderArgs,
      whatsappBody: data.whatsappBodyArgs,
    },
  };

  const translationEdges = data.translations?.edges || [];
  translationEdges.forEach((translation) => {
    const {
      language,
      title,
      content,
      photo,
      emailTitle,
      emailContent,
      smsContent,
      pk,
      id,
      whatsappTemplateJson,
      whatsappTemplateName,
      whatsappImage,
      whatsappHeaderArgs,
      whatsappBodyArgs,
    } = translation.node;
    translations[language] = {
      id: id,
      pk: pk,
      title: title,
      content: content,
      photo: parseImageUrl(photo),
      emailTitle: emailTitle,
      emailContent: emailContent,
      smsContent: smsContent,

      whatsappTemplate: JSON.parse(whatsappTemplateJson || '{}'),
      whatsappTemplateName: whatsappTemplateName,
      whatsappImage: parseImageUrl(whatsappImage),
      whatsappHeader: whatsappHeaderArgs,
      whatsappBody: whatsappBodyArgs,
    };
  });

  return {
    pk: data.pk,
    id: data.id,
    name: data.name,
    channels: parsedChannelList,
    content: data.content,
    emailTitle: data.emailTitle,
    emailContent: data.emailContent,
    photo: data.photo,
    relatedCampaign: {
      pk: data.campaign?.pk,
      name: data.campaign?.name,
    },
    smsContent: data.smsContent,
    title: data.title,
    translations: translations,
  };
};

export default {
  namespace: 'messageList',
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    updateAllMessageList(state, { payload }) {
      return {
        ...state,
        allMessageList: payload?.page > 1
          ? [...state.allMessageList, ...payload?.currentPageMessageList]
          : payload?.currentPageMessageList,
      };
    },
    clearData(state, { payload }) {
      return {
        ...state,
        ...getInitialState(),
      };
    },
  },
  effects: {
    getCurrentPageMessages: [
      function* ({ payload }, { put }) {
        const { page, rank, searchKey, isAll } = payload;
        let afterCursor = '';
        if (page > 1) {
          afterCursor = convertNumberToCursor(
            (page - 1) * MESSAGE_PAGE_SIZE - 1,
          );
        }
        const serviceArgs = [
          getMessages,
          afterCursor,
          rank,
          searchKey,
          payload,
        ];
        function* onSuccess(data) {
          const pageInfo = data.messages.pageInfo;
          const totalCount = data.messages.totalCount;
          const currentLastCursor = pageInfo.endCursor;
          const messageData = data.messages.edges;
          const messageList = messageData.map((item) =>
            parseListMessage(item.node),
          );
          if (isAll) {
            yield put({
              type: 'updateAllMessageList',
              payload: {
                page,
                currentPageMessageList: messageList,
              }
            });
          } else {
            yield put({
              type: 'updateState',
              payload: {
                currentPageMessageList: messageList,
                pageInfo: {
                  startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                  endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
                },
                totalCount,
                currentLastCursor,
                totalPage: Math.ceil(totalCount / MESSAGE_PAGE_SIZE),
              },
            });
          };
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getMessage: [
      function* ({ payload }, { put }) {
        const { messagePK } = payload;
        const messageID = btoa(`MessageNode:${messagePK}`);
        const serviceArgs = [getMessage, messageID];
        function* onSuccess(data) {
          const messageData = data.message;
          const message = parseDetailMessage(messageData);
          yield put({
            type: 'updateState',
            payload: {
              message: message,
            },
          });
          const afterAction = payload.afterAction || (() => { });
          yield afterAction(message);
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getMessageCredit: [
      function* ({ payload }, { put, select }) {
        const { startDate, endDate, successAction } = payload;

        const serviceArgs = [getMessageCredit, startDate, endDate];
        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: {
              realCreditTotalSpent: data?.messages?.creditTotalSpent,
              whatsappCreditTotalSpent: data?.messages?.whatsappCreditSpent,
              smsCreditTotalSpent: data?.messages?.smsCreditSpent,
              emailCreditTotalSpent: data?.messages?.emailCreditSpent,

            },
          });
          successAction && successAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getMessageTemplate: [
      function* ({ payload }, { put, select }) {
        const { search: nameIcontains } = payload;
        const serviceArgs = [getMessageTemplate, nameIcontains];
        function* onSuccess(data) {
          const parsedMessageTemplate = data.messageTemplates?.edges?.map(
            (data) => parseMessageTemplate(data),
          );
          yield put({
            type: 'updateState',
            payload: {
              messageTemplateList: parsedMessageTemplate,
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    delete: [
      function* ({ payload }, { put, select }) {
        const { checkedList } = yield select((state) => ({
          checkedList: state.messageList.checkedList,
        }));

        let pks = [];
        checkedList.forEach((item) => {
          pks.push(item.pk);
        });

        const serviceArgs = [deleteMessages, pks];
        function* onSuccess() {
          const afterActions = payload.afterAction;
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    duplicate: [
      function* ({ payload }, { put }) {
        const serviceArgs = [duplicateMessage, payload.data.pk];
        function* onSuccess() {
          const afterActions = payload.afterAction || (() => { });
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getWhatsappMessageTemplateList: [
      function* ({ payload }, { put }) {
        const serviceArgs = [getWhatsappMessageTemplates];
        function* onSuccess(response) {
          console.log('getWhatsappMessageTemplateList@onSuccess', response);
          const whatsappMessageTemplates = response.mpowerWhatsappConfigs;
          const whatsappTemplates = parseWhatsappTemplateList(
            whatsappMessageTemplates.edges,
          );
          if (whatsappTemplates?.length > 0) {
            yield put({
              type: 'updateState',
              payload: {
                whatsappTemplates,
              },
            });
          }
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
