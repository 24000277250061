
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Image } from 'react-bootstrap';

import { getHashKeyString } from 'utils';

import LineImage from '../../assets/images/line.png';
import DotImage from '../../assets/images/dot.png';
import '../../containers/customers/customer/CustomerOverviewDetail.scss';
import ContentSections from 'components/base/ContentSections';
import MembershipInfoCard from 'components/customer/MembershipInfoCard';
import AuthButton from 'components/base/AuthButton';
import { CUSTOMER_DETAIL_TAB_NAME } from 'config/CustomEnums';


const CustomerPointsAndLevelList = ({
  customer,
  activityLogs,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const hash = getHashKeyString(location.hash);
  const params = useParams();
  const customerId = params.id;

  const [seeAll, setSeeAll] = useState(false);

  useEffect(() => {
    if (hash !== CUSTOMER_DETAIL_TAB_NAME.pointAndLevel) {
      return;
    };

    dispatch({
      type: 'customerList/getOneCustomer',
      payload: { id: customerId },
    });

    dispatch({
      type: 'customerList/getCustomerActivityLog',
      payload: {
        customerId: customerId,
      },
    });
  }, [dispatch, location]);

  return (
    <>
      <div className="scroll-container-common">
        <ContentSections
          sections={[
            <>
              <MembershipInfoCard
                title={'POINTS AND LEVEL'}
                data={customer}
                fields={[
                  [
                    { title: 'Total lifetime points used', field: 'totalLifetimePointsUsed' },
                    { title: 'Total lifetime points expired', field: 'totalLifetimePointsExpired' }
                  ],
                ]}
              />

              <div className='customer-detail-points-and-levels-separate-line' />

              {
                activityLogs?.length <= 0 ? (
                  <label className="custom-record-normal-font custom-timeline-time-color list-new-line">
                    -
                  </label>
                ) : (
                  <div className="custom-record-table">
                    {activityLogs.map((item, index) => {
                      if (!seeAll && index > 4) {
                        return null
                      };
                      return (
                        <div className="custom-timeline-area">
                          <div className="custom-timeline-icon ">
                            <Image src={DotImage} />
                            {index === activityLogs.length - 1 ? null : (
                              <Image src={LineImage} className="custom-timeline-line" />
                            )}
                          </div>
                          <div className="d-flex flex-column">
                            <label className="custom-record-blod-font custom-timeline-font-color">
                              {item.text}
                            </label>
                            <label className="custom-record-normal-font custom-timeline-time-color">
                              {item.updateTime}
                            </label>
                          </div>
                        </div>
                      );
                    })}
                    {
                      !seeAll && <AuthButton
                        title='see all'
                        customClass={'btn-back-button-common customer-detail-points-and-levels-see-all-button'}
                        action={() => setSeeAll(true)}
                      />
                    }
                  </div>
                )
              }
            </>
          ]}
          hidePreview={true}
          sectionStyle="detail-record-section-item"
        />
      </div>
    </>
  );
}

const mapPropsToState = (state) => ({
  customer: state.customerList.customer,
  activityLogs: state.customerList.activityLogs,
});

export default connect(mapPropsToState)(CustomerPointsAndLevelList);
