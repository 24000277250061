import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { ArrayStringData } from '../../../utils';
import BaseFilter, { FilterTypeEnum } from '../../../components/base/BaseFilter';
import { useSelector } from 'react-redux';

function Filter({ backAction = () => {} }) {
  const location = useLocation();
  const parsedSearch = queryString.parse(location.search);
  const searchEventType = parsedSearch['event_type'] || '';
  const searchRecordType = parsedSearch['record_type'] || '';
  const searchQuantity = parsedSearch['quantity'] || '';
  const searchCreateDate = parsedSearch['create_date'] || '';
  const [eventType, setEventType] = useState(searchEventType);
  const [recordType, setRecordType] = useState(
    ArrayStringData(searchRecordType),
  );
  const [quantity, setQuantity] = useState(() => {
    const values = ArrayStringData(searchQuantity);
    if (values.length > 0) return values;
    return [0, 0];
  });
  const [createDate, setCreateDate] = useState(searchCreateDate);
  const { maxQuantityValue } = useSelector((state) => ({
    maxQuantityValue: state.stampRecord.maxQuantityValue,
  }));

  const content = [
    {
      title: 'Event type',
      dislpayName: 'Event type',
      data: [
        { pk: 'ADD_STAMP', name: 'Add Stamp' },
        { pk: 'REMOVE_STAMP', name: 'Remove Stamp' },
      ],
      value: eventType,
      setValue: setEventType,
      component: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Record type',
      displayName: 'Record type',
      data: [
        { name: 'Customer earned', pk: 'CUSTOMER_EARN' },
        { name: 'Admin remove', pk: 'ADMIN_REMOVE' },
        { name: 'Admin add', pk: 'ADMIN_ADD' },
        { name: 'Stamp expired', pk: 'STAMP_EXPIRED' },
      ],
      value: recordType,
      setValue: setRecordType,
      component: FilterTypeEnum.choice,
    },
    {
      title: 'Create date',
      displayName: 'Create date',
      value: createDate,
      setValue: setCreateDate,
      component: FilterTypeEnum.dateRange,
    },
    {
      title: 'Quantity',
      displayName: 'Quantity',
      data: [0, maxQuantityValue],
      value: quantity,
      setValue: setQuantity,
      component: FilterTypeEnum.slider,
    },
  ];

  return (
    <BaseFilter
    content={content}
    backAction={backAction}
    />
  )
}

export default Filter;
