import React from 'react';

import { Select } from 'antd';

import CustomTitleWithInput from 'components/base/CustomTitleWithInput';
import {
  ReactHookFormErrorMessage,
  errorMessage,
  hasError,
} from 'components/base/ErrorFieldMessage';

const ProfileDropdownField = ({
  fieldName,
  displayTitle,
  mandatory,
  choices,
  defaultValue,
  setValue,
}) => {
  const { Option } = Select;
  const onChange = (v) => setValue(v);
  return (
    <>
      <CustomTitleWithInput
        title={displayTitle}
        per={'Key'}
        type={'text'}
        defaultValue={fieldName}
        disabled
        buttonNewLine
      />
      <div className="inline-field">
        <label className="help-message margin-right-10-percent">
          {'Value'}
        </label>
        <Select
          className="custom-select-container"
          style={{ width: '690px' }}
          defaultValue={defaultValue}
          onChange={onChange}
        >
          {choices?.map((choice) => (
            <Option key={`choice-${choice.value}`} value={choice.value}>
              {choice.label}
            </Option>
          ))}
        </Select>
      </div>
    </>
  );
};

export default ProfileDropdownField;
