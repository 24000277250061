import { BaseHelper } from './BaseGraphQLAPIHelper';

const MESSAGE_CREDIT_RECORDS_NODE = `{
  id
  pk
  message {
    pk
    name
    admin {
      id
      pk
      username
    }
    creationDate
    realCreditSpent
  }
  creationDate
}`;

const getCreditFilter = (search, param) => {
  if (!param || !search) {
    return ""
  }
  let searchString = ""
  const range = search?.[param]?.split(',');

  const camelCaseParam = param.split("_").map((word, index) => {
    if (index === 0) {
      return word.toLowerCase();
    }
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }).join('');
  console.log("getCreditFilter:", camelCaseParam)
  if (range?.[0]) {
    searchString += `, ${camelCaseParam}Gte: ${range[0]}`;
  }
  if (range?.[1]) {
    searchString += `,${camelCaseParam}Lte: ${range[1]}`;
  }
  return searchString
}

export const getMessageCreditRecords = (afterCursor, search) => {
  let searchString = `, orderBy: "${search.reverse ? '-pk' : 'pk'}"`;
  if (search?.sort) {
    searchString = `, orderBy: "${search.sort}"`;
  }
  if (search.search) {
    searchString += `, nameIcontains: "${search.search}"`;
  }
  if (search?.create_at) {
    const createDate = search.create_at.split(',');
    searchString += `, creationDateGte: "${createDate[0]}", creationDateLte: "${createDate[1]}"`;
  }
  searchString += getCreditFilter(search, "credit_consumed")
  searchString += getCreditFilter(search, "whatsapp_credit_consumed")
  searchString += getCreditFilter(search, "sms_credit_consumed")
  searchString += getCreditFilter(search, "email_credit_consumed")

  const query = `{
    messageCreditRecords(first: 20, after:"${afterCursor}"${searchString}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node ${MESSAGE_CREDIT_RECORDS_NODE}
      }
    }
  }
  `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
}

export const getMessageCreditRecord = (id) => {
  const query = `{
    messageCreditRecord(id: "${id}") ${MESSAGE_CREDIT_RECORDS_NODE}
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
}
