import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import './SegmentList.scss';
import BaseListContainer from '../../base/BaseListContainer';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import DeletePrompt from '../../../components/base/DeletePrompt';
import AuthButton from '../../../components/base/AuthButton';
import { NavBarNames } from '../../../config/NavBarNameList';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';
import UpdateRecencyFrequencyMonetaryScoreSetting from './UpdateRecencyFrequencyMonetaryScoreSetting';


function SegmentList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const [showScoreSetting, setShowScoreSetting] = useState(false);

  const {
    listFields,
    dataList,
    pageInfo,
    totalPage,
    totalCount,
    checkedList,
  } = useSelector((state) => ({
    listFields: state.segments.listDisplayFields,
    dataList: state.segments.segmentList,
    pageInfo: state.segments.pageInfo,
    totalPage: state.segments.totalPage,
    totalCount: state.segments.totalCount,
    checkedList: state.segments.checkedList,
  }));

  const buttons = [
    <AuthButton
      title={'Edit RFM Score Setting'}
      className="btn-back-button-common btn-download"
      action={() => {
        setShowScoreSetting(true);
      }}
      requires={PermissionCodes.changeSegment}
    />,
    <AuthButton
      title={'Create Segment'}
      className="btn-further"
      action={() => {
        // dispatch({ type: 'earningRuleList/clearData' });
        history.push({
          pathname: '/segments/create/',
        });
      }}
      requires={PermissionCodes.changeSegment}
    ></AuthButton>,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        // setShowPrompt(true);
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.deleteSegment,
    },
  ];

  const tabs = [
    // {
    //   name: 'Overview',
    //   content: <div>Overview</div>,
    // },
    {
      name: 'List of Segments',
      content: (
        <BaseTabListContainer
          showLastUpdateTime
          hideTab={true}
          tabs={[]}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          listContent={
            <BaseListContainer
              fields={listFields}
              dataList={dataList}
              totalPage={totalPage}
              model={'segments'}
              hasOtherActions={false}
              permissionGroup={PermissionCodes.segment}
              deleteInfo={{
                data: [],
                title: 'segments',
                relatedName: '',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.SEGMENT,
              }}
            />
          }
        />
      ),
    },
  ];

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [activeTab, setActiveTab] = useState(tabs[0].name);

  useEffect(() => {
    dispatch({ type: 'segments/clearData' });
  }, [dispatch]);

  useEffect(() => {
    const queryString = require('query-string');
    if (queryString) {
      const searchDict = queryString.parse(location.search);
      const searchKey = queryString.parse(location.search)['search'];
      const stringRank = queryString.parse(location.search)['rank'];
      const stringPage = queryString.parse(location.search)['page'] || 1;
      const rank = stringRank === 'true';
      const page = parseInt(stringPage);
      dispatch({
        type: 'segments/getSegmentsList',
        payload: {
          page,
          reverse: rank,
          type: '',
          search: searchKey,
          moreSearch: { ...searchDict },
        }
      });
    }
  }, [dispatch, location]);

  return (
    <>
      <CustomListComponent
        caution={{
          detail:
            'Dynamic Segment is a system-managed customer list which will be auto-updated. In every update, customers who are in certain conditions will be added to the list; in other words, customers who no longer meet the conditions will be removed.',
          title: NavBarNames.segments,
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={false}
        onTabChange={(key) => {
          setActiveTab(key);
          //   let search = '';
          //   if (key !== 'Overview') {
          //     // search = queryString.stringify({
          //     //   [URLFilterKeys.type]: CampaignType.allTypes,
          //     //   [URLFilterKeys.page]: 1,
          //     //   [URLFilterKeys.searchKey]: '',
          //     //   [URLFilterKeys.rank]: true,
          //     // });
          //   }
          //   history.push({ pathname: '/customer', hash: key, search: search });
        }}
      />
      <DeletePrompt
        data={checkedList}
        title={'segments'}
        relatedName={''}
        relatedSections={DELETE_RELATED_SECTIONS.SEGMENT}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: 'segments/delete',
            payload: {
              afterAction: () => {
                history.push(location);
              },
            }
          });
        }}
      />
      <UpdateRecencyFrequencyMonetaryScoreSetting 
        show={showScoreSetting}
        setShow={setShowScoreSetting}
      />
    </>
  );
}

export default SegmentList;
