import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';

import { DropDownWithDefaultSelect } from 'components/base/ActionsDropdown';
import { hasReactHookFormError } from 'components/base/ErrorFieldMessage';
import { CustomTitleWithDropDown } from 'components/customer/CustomTitleWithDropDown';
import { CustomTitleLabel } from 'components/earning/CustomBaseComponments';
import { MESSAGE_TRACKING_TYPE_DISPLAY } from 'config/CustomEnums';

const MessageTrackingSection = ({
  messages,
  title,
  groupName,
  fieldBaseName
}) => {
  const {
    watch,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();
  const trackingTypeFieldName = `${fieldBaseName}.trackingType`;
  const trackingType = watch(trackingTypeFieldName);
  const messageFieldName = `${fieldBaseName}.message`;
  const defaultCampaignFilter = { isAll: true };

  const dropDownOptions = (values, setAction) => {
    return values.map((value) => ({
      name: value,
      action: () => {
        setAction(value);
      },
    }));
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'messageList/getCurrentPageMessages',
      payload: {
        ...defaultCampaignFilter,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <CustomTitleLabel title={title} />
      <div className="d-flex flex-column">
        <div style={{ display: 'flex' }}>
          <Controller
            name={trackingTypeFieldName}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <DropDownWithDefaultSelect
                actions={dropDownOptions(
                  [
                    MESSAGE_TRACKING_TYPE_DISPLAY.OPEN,
                    MESSAGE_TRACKING_TYPE_DISPLAY.NOT_OPEN,
                    MESSAGE_TRACKING_TYPE_DISPLAY.CLICK,
                    MESSAGE_TRACKING_TYPE_DISPLAY.NOT_CLICK,
                  ],
                  (value) => {
                    setValue(field.name, value, {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  },
                )}
                defaultValue={trackingType}
                className={
                  hasReactHookFormError(errors, field.name) ? 'error-field' : ''
                }
              />
            )}
          />

          <label className="content-campaign-related-to margin-right-10-percent">
            related to
          </label>

          <Controller
            name={messageFieldName}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomTitleWithDropDown
                setValue={(value) => {
                  const { label, value: choiceValue } = value;
                  if (choiceValue) {
                    const { pk, name } = choiceValue;
                    setValue(
                      field.name,
                      { value: { pk, name }, label },
                      { shouldDirty: true, shouldValidate: true },
                    );
                  } else {
                    setValue(field.name, null, {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }
                }}
                source={messages}
                labelContainPk
                defaultValue={{
                  value: field.value?.value,
                  label: field.value?.value?.pk
                    ? `[ID: ${field.value?.value?.pk}] ${field.value?.value?.name}`
                    : field.value?.value?.name,
                }}
                loadMoreAction={'messageList/getCurrentPageMessages'}
                filterAction={'messageList/getCurrentPageMessages'}
                defaultFilter={defaultCampaignFilter}
                customClass={
                  hasReactHookFormError(errors, field.name)
                    ? 'error-field'
                    : ''
                }
              />
            )}
          />
        </div>
        {
          [
            MESSAGE_TRACKING_TYPE_DISPLAY.OPEN,
            MESSAGE_TRACKING_TYPE_DISPLAY.NOT_OPEN,
          ].includes(trackingType)
            ? <label className="tips-message">
              Please noticed that the open status is not trackable for SMS message
            </label>
            : null
        }
      </div>
    </div>
  );
}

const mapPropsToState = (state) => ({
  messages: state.messageList.allMessageList,
})

export default connect(mapPropsToState)(MessageTrackingSection);
