import React from 'react';
import { Button } from 'react-bootstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { SaveAndBackWithOutTempButtons } from 'components/base/BottomStepComponent';

import ContentItemSection from './ContentItemSection';
import moment from 'moment';

export default function ContentSection() {
  const { control, getValues, trigger } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'conditionGroups',
  });
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <>
      {fields
        ? fields?.map(
            (field, index) => (
              <ContentItemSection
                key={field.pk || field.temporaryPk}
                index={index}
                removeConditionGroups={remove}
              />
            )
          )
        : null}
      <Button
        onClick={() => {
          append({ conditions: [], temporaryPk: moment().toISOString() });
        }}
        className="btn-back-button-common add-sub-category-btn"
      >
        + Add Condition Group
      </Button>

      <SaveAndBackWithOutTempButtons
        saveText={'Save'}
        saveAction={async (event) => {
          const result = await trigger(undefined, { shouldFocus: true });
          if (result) {
            dispatch({
              type: 'segments/createOrUpdateSegment',
              payload: getValues(),
            });
          }
          console.log('@eric', result, getValues());
          // setOnCheck(true);
          // handleSave(event);
        }}
        backAction={() => history.goBack()}
      />
    </>
  );
}
