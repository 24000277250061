import { ImageDomain } from 'config/CustomEnums';
import { BaseHelper } from 'services/BaseGraphQLAPIHelper';

import { getInFilterValue, getListInFilterValue } from 'utils';

export const CUSTOMER_TEMPLATE_FILE_PATH = `${ImageDomain}import_customer_template.csv`;

const CUSTOMER_CONTENT = `
id
pk
ssoUid
firstName
lastName
nickname
membershipId
pointAccount {
  id
  pk
  totalPointsEarned
  balance
  totalPointsUsed
  totalPointsExpired
  currentLevel {
    pk
    id
    levelName
  }
  currentLevelRenewDatetime
  currentLevelExpiredInXDays
}
mobilePhoneNumberCountryCode
mobilePhoneNumberSubscriberNumber
emailAddress
isAssignedAsTestingCustomer
gender {
  label
  value
}
dateOfBirth
address
company
signUpChannel
referrer {
  id
}
referredByCampaign {
  id
}
isForcedInactive
isActivated
status
preferredMessageLanguage
creationDate
lastModifiedDate
hasAgreedDirectMarketing
directMarketingViaEmail
groups {
  edges {
    node {
      id
      name
    }
  }
}
segments {
  edges {
    node {
      id
      name
      conditionGroups {
        edges {
            node {
                levelCharacteristic {
                  id
                  levels(first: 2) {
                      edges {
                          node {
                              id
                              levelName
                              translations(first: 2) {
                                  edges {
                                      node {
                                          levelName
                                          levelBenefitUrl
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
            }
        }
      }
    }
  }
}
isDeleted
isReset
`;

const LIST_CONTENT = `
id
pk
firstName
lastName
nickname
membershipId
mobilePhoneNumberCountryCode
mobilePhoneNumberSubscriberNumber
emailAddress
pointAccount {
  id
  pk
  currentLevel {
    pk
    id
    levelName
  }
}
segments {
  edges {
    node {
      id
      name
    }
  }
}
isAssignedAsTestingCustomer
isDeleted
isForcedInactive
isActivated
status
acquisitionChannel
userCustomData
`;
const DROPDOWN_LIST_CONTENT = `
  pk
  id
  firstName
  lastName
  nickname
  membershipId
`;

export const getCustomerGroups = ({
  pageItemCount = 100,
  afterCursor,
  moreSearch,
}) => {
  let searchString = '';
  if (moreSearch?.showInCustomerProfile) {
    searchString += ' , showInCustomerProfile: true';
  }

  const query = `{
    customerGroups(first: ${pageItemCount}, after:"${afterCursor}"${searchString}) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          name
        }
      }
    }
  }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getAllCustomers = (afterCursor, search = {}, moreSearch) => {
  let searchValue = '';
  if (search && Object.keys(search).length > 0) {
    searchValue = Object.keys(search)
      .map((key) => `${key}: ${search[key]}`)
      .join(', ');
  }
  let orderBy = 'pk';
  if (moreSearch?.sort) {
    orderBy = moreSearch.sort;
  }
  let pageItemCount = 100;
  if (moreSearch?.pageItemCount) {
    pageItemCount = moreSearch.pageItemCount;
  }
  const query = `{
    customers(first: ${pageItemCount}, after:"${afterCursor}", orderBy:"${orderBy}", ${searchValue}) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          ssoUid
          firstName
          lastName
          nickname
          membershipId
          isDeleted
          pointAccount {
            id
            pk
            totalPointsEarned
            balance
            totalPointsUsed
            totalPointsExpired
            currentLevel {
              pk
              id
              levelName
            }
            currentLevelRenewDatetime
            currentLevelExpiredInXDays
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCustomersByPage = (
  afterCursor,
  rank = 'true',
  search,
  groups,
  segments,
  levels,
  age,
  genders,
  status,
  startDate,
  endDate,
  birthMonth,
  sort,
  payload,
) => {
  // firstName_Icontains: "", groups_Name: "", gender_In: "", dateOfBirth_Month: "", mobilePhoneNumberCountryCode_In: "852,853,86", ageBetween:"0,2",signUpAnniversaryBetween: "0,2", level: "Level 2"

  const variables = {
    fieldsOrderBy: 'display_priority',
    customersOrderBy: rank ? '-pk' : 'pk',
    customersAfter: afterCursor,
  };
  if (sort) {
    variables.customersOrderBy = sort;
  }

  if (search) {
    variables.nameOrMobileOrEmail = search;
  }
  if (groups) {
    variables.groupsIn = getListInFilterValue(groups);
  }
  if (segments) {
    variables.segmentsIn = getListInFilterValue(segments);
  }
  if (levels) {
    variables.levelIn = getListInFilterValue(levels);
  }
  if (age) {
    variables.ageRange = getListInFilterValue(age);
  }
  if (genders) {
    variables.genderIn = getListInFilterValue(genders);
  }
  if (status) {
    variables.statusIn = getListInFilterValue(status);
  }
  if (startDate) {
    variables.dateOfBirthGte = startDate;
  }
  if (endDate) {
    variables.dateOfBirthLte = endDate;
  }
  if (birthMonth) {
    variables.birthMonth = birthMonth;
  }
  if (payload?.create_date) {
    const createDatePeriod = payload.create_date.split(',');
    variables.creationDateGte = createDatePeriod[0];
    variables.creationDateLte = createDatePeriod[1];
  }
  if (payload?.acquisition_channel) {
    variables.acquisitionChannel = payload.acquisition_channel;
  }
  if (payload?.additional_data) {
    console.log('additional_data', payload.additional_data);
    variables.additionalData = payload.additional_data;
  }
  if (payload?.isAssignedAsTestingCustomer) {
    variables.isAssignedAsTestingCustomer = payload.isAssignedAsTestingCustomer;
  }

  const customerNode = payload?.isListNode
    ? LIST_CONTENT
    : payload?.isDropdownNode
    ? DROPDOWN_LIST_CONTENT
    : CUSTOMER_CONTENT;

  // console.log('@@104: ', payload?.isListNode, payload?.isDropdownNode);
  const query = `query(
    $fieldsOrderBy: String!,
    $customersOrderBy: String!,
    $customersAfter: String!,
    $nameOrMobileOrEmail: String,
    $groupsIn: [String!],
    $segmentsIn: [String!],
    $levelIn: [String!],
    $ageRange: [String!],
    $genderIn: [String!],
    $statusIn: [String!],
    $dateOfBirthGte: Date,
    $dateOfBirthLte: Date,
    $birthMonth: String,
    $acquisitionChannel: String,
    $additionalData: String,
    $creationDateGte: Date,
    $creationDateLte: Date,
    $isAssignedAsTestingCustomer: Boolean,
  ) {
    customerAdditionalDataFields(orderBy: $fieldsOrderBy) {
      edges {
        cursor
        node {
          displayPriority
          fieldName
        }
      }
    }
    customers(
      first: 20,
      after: $customersAfter,
      orderBy: $customersOrderBy,
      nameOrMobileOrEmail: $nameOrMobileOrEmail,
      groupsIn: $groupsIn,
      segmentsIn: $segmentsIn,
      levelIn: $levelIn,
      ageRange: $ageRange,
      genderIn: $genderIn,
      statusIn: $statusIn,
      dateOfBirthGte: $dateOfBirthGte,
      dateOfBirthLte: $dateOfBirthLte,
      birthMonth: $birthMonth,
      acquisitionChannel: $acquisitionChannel,
      additionalData: $additionalData,
      creationDateGte: $creationDateGte,
      creationDateLte: $creationDateLte,
      isAssignedAsTestingCustomer: $isAssignedAsTestingCustomer,
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ${customerNode}
        }
      }
    }
  }`;
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCustomerReportsByPage = (afterCursor, search = {}) => {
  let searchString = `, orderBy: "${search.rank ? '-pk' : 'pk'}"`;
  if (search?.sort) {
    searchString = `, orderBy: "${search?.sort}"`;
  }
  if (search?.searchKey) {
    searchString += `, reportName: "${search.searchKey}"`;
  }
  if (search?.reportType) {
    searchString += `, reportTypeIn: ${getInFilterValue(search.reportType)}`;
  }
  if (search?.timeRange) {
    const timeRange = search.timeRange.split(',');
    searchString += `, timeRangeGte: "${timeRange[0]}", timeRangeLte: "${timeRange[1]}"`;
  }

  const query = `
  {
    customerReports(first: 20,
      after: "${afterCursor}"
      ${searchString}
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          reportName
          reportFileUrl
          reportType
          startTime
          endTime
          creationDate
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteCustomers = (ids) => {
  const query = `
    mutation DeleteCustomers($input: DeleteCustomersInput!) {
      deleteCustomers(input: $input) {
        success
        deleteFail
      }
    }
  `;
  const variables = {
    input: {
      ids: ids,
    },
  };

  // console.log('@@338: ', query);

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateCustomer = (values) => {
  const query = `
  mutation UpdateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      node {
        id
        pk
        firstName
        lastName
        nickname
        membershipId
        ssoUid
        mobilePhoneNumberCountryCode
        mobilePhoneNumberSubscriberNumber
        emailAddress
        gender {
          label
          value
        }
        dateOfBirth
        address
        company
        signUpChannel
        referrer {
          id
        }
        referredByCampaign {
          id
        }
        isForcedInactive
        preferredMessageLanguage
        creationDate
        lastModifiedDate
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: values,
  };

  // console.log('@@338: ', query);

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneCustomer = (id) => {
  const query = `query {
    customerAdditionalDataSections(orderBy: "displayPriority", first: 100) {
      edges {
        node {
          id
          pk
          name
          displayPriority
          fields(orderBy: "displayPriority,fieldName", first: 100) {
            totalCount
            edges {
              node {
                displayPriority
                isMandatory
                customerEditable
                fieldName
                fieldType
                displayTitle
                tips
                choices(orderBy: "displayPriority,fieldValue", first: 100) {
                  totalCount
                  edges {
                    node {
                      fieldValue
                      displayLabel
                      displayPriority
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    customer(id: "${id}") {
      id
      pk
      firstName
      lastName
      nickname
      ssoUid
      signUpMethod
      membershipId
      mobilePhoneNumberCountryCode
      mobilePhoneNumberSubscriberNumber
      emailAddress
      isAssignedAsTestingCustomer
      isDeleted
      gender {
        label
        value
      }
      dateOfBirth
      address
      company
      signUpChannel
      referrer {
        id
        pk
        firstName
        lastName
        membershipId
      }
      referredByCampaign {
        id
        pk
        name
      }
      referredByCampaigns {
        pk
        name
      }
      isForcedInactive
      isActivated
      status
      preferredMessageLanguage
      creationDate
      lastModifiedDate
      userCustomData
      pointAccount {
        id
        pk
        totalPointsEarned
        balance
        totalPointsUsed
        totalPointsExpired
        currentLevel {
          pk
          id
          levelName
        }
        currentLevelRenewDatetime
        currentLevelExpiredInXDays
        pointsToUpgrade
        pointsToRetain
      }
      totalSpending
      averageOrderValue
      totalNumberOfOrders
      daysFromLastOrder
      hasAgreedDirectMarketing
      directMarketingViaEmail
      userCustomData
      acquisitionChannel
      signUpByMerchant {
        pk
        username
      }
      remarks (
        orderBy: "-creation_date"
      ) {
        edges {
          node {
            id
            pk
            remark
            isCreator
            creator {
              id
              pk
              firstName
              lastName
              username
            }
            creationDate
          }
        }
      }
      groups {
        edges {
          node {
            id
            pk
            name
            showInCustomerProfile
          }
        }
      }
      segments {
        edges {
          node {
            id
            pk
            name
            conditionGroups {
              edges {
                node {
                  levelCharacteristic {
                    id
                    levels(first: 2) {
                      edges {
                        node {
                          id
                          levelName
                          translations(first: 2) {
                            edges {
                              node {
                                levelName
                                levelBenefitUrl
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      coupons(orderBy: "template,-pk", first: 100, customerDetailCouponType: "AVAILABLE") {
        edges {
          node {
            id
            pk
            template {
              id
              pk
              name
            }
            status
            usageMedium
            virtualRealType
          }
        }
      }
      totalNumberOfCouponsAcquired
      totalNumberOfCouponsUsed
      totalNumberOfCouponsExpired
      editLevelRenewalDate
    }
  }
  `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneCustomerBehavioralData = (id) => {
  const query = `query {
    customer(id: "${id}") {
      id
      pk
      averageTransactionValue
      lastPurchaseDate
      totalTransactionValue
      countTransaction
      countTransactionInPhysicalStore
      countTransactionInOnlineStore
      topCategoryList {
        id
        pk
        name
      }
      topSkuList
      topStoreList {
        id
        pk
        name
      }
    }
  }
  `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCustomerActivityLog = ({ ssoUid, customerId }) => {
  let filters = '';
  if (ssoUid) {
    filters = `, customerSsoUid: "${ssoUid}"`;
  }
  if (customerId) {
    filters = `, customerId: "${customerId}"`;
  }
  // activityTypeIn: "CUSTOMER_POINTS_EARNED,CUSTOMER_POINTS_USED,CUSTOMER_POINTS_EXPIRED,CUSTOMER_LEVEL_CHANGED,CUSTOMER_LEVEL_RETAINED"
  const query = `{
    customerActivityLogs(first: 50${filters}, orderBy: "-pk") {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          activityType
          customerSsoUid
          customerPointAndLevelLogTitle
          logContentId
          logContentType {
            appLabel
            model
          }
          creationDate
        }
      }
    }
  }
  `;

  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const activeCustomer = (values) => {
  const query = `
  mutation ActivateCustomer($input: ActivateCustomerInput!) {
    activateCustomer(input: $input) {
      node {
        id
        pk
        isForcedInactive
      }
    }
  }
  `;
  const variables = {
    input: values,
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deactiveCustomer = (values) => {
  console.log(values);
  const query = `
  mutation DeactivateCustomer($input: DeactivateCustomerInput!) {
    deactivateCustomer(input: $input) {
      node {
        id
        pk
        isForcedInactive
      }
    }
  }
  `;
  const variables = {
    input: values,
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getPagedCustomerAdditionalDataFields = (afterCursor, search) => {
  let searchString = `, orderBy: "display_priority"`;

  if (search) {
    searchString += `, fieldNameIcontains: "${search}"`;
  }
  const query = `{
    customerAdditionalDataFields(
      first: 20,
      after: "${afterCursor}"${searchString}
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          fieldName
          displayPriority
          creationDate
          lastModifiedDate
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createCustomerRemark = (inputBody) => {
  const query = `
  mutation CreateCustomerRemark($input: CreateCustomerRemarkInput!) {
    createCustomerRemark(input: $input) {
      node {
        id
        pk
        remark
        creator {
          id
          pk
          firstName
          lastName
          username
        }
        creationDate
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteCustomerRemark = (id) => {
  const query = `
  mutation DeleteCustomerRemark($input: DeleteCustomerRemarkInput!) {
    deleteCustomerRemark(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: {
      id: id,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const sendMessageToResetPassword = (ssoUid, messageTarget) => {
  const query = `
  mutation SendResetCustomerPasswordMessage($input: SendResetCustomerPasswordMessageInput!) {
    sendResetCustomerPasswordMessage(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: {
      ssoUid,
      target: messageTarget,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
}
