import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import queryString from 'query-string';

import AuthButton from 'components/base/AuthButton';
import CustomBreadcrumb from 'components/base/CustomBreadcrumb';
import CustomListComponent from 'components/base/CustomListComponent';
import DeletePrompt from 'components/base/DeletePrompt';
import { DELETE_RELATED_SECTIONS, EarningRuleType } from 'config/CustomEnums';
import { NavBarNames } from 'config/NavBarNameList';
import { PermissionCodes } from 'config/PermissionCodes';
import BaseListContainer from 'containers/base/BaseListContainer';
import BaseTabListContainer from 'containers/base/BaseTabListContainer';

import Filter from './Filter';

import { createAction } from 'utils';

import './EarningRuleList.scss';

function EarningRuleList({
  listFields,
  dataList,
  pageInfo,
  totalPage,
  totalCount,
  checkedList,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const buttons = [
    <AuthButton
      title="Create Earning Rules"
      action={() => {
        dispatch({ type: 'earningRuleList/clearData' });
        history.push({
          pathname: '/earns/create',
        });
      }}
      requires={PermissionCodes.addEarningRule}
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.deleteEarningRule,
    },
  ];

  const tabs = [
    {
      name: 'List Of Earnning Rules',
      content: (
        <BaseTabListContainer
          hideTab={false}
          maxTabWidth={670}
          tabs={[
            { key: EarningRuleType.allTypes, name: 'All Type' },
            { key: EarningRuleType.qrCodeScanning, name: 'QR Code' },
            { key: EarningRuleType.gpsCheckIn, name: 'GPS' },
            { key: EarningRuleType.memberReferral, name: 'Referral' },
            { key: EarningRuleType.generalPurchase, name: 'Purchase' },
            { key: EarningRuleType.newMember, name: 'New Member' },
            { key: EarningRuleType.birthday, name: 'Birthday' },
            { key: EarningRuleType.fillingForm, name: 'Webhook' },
            { key: EarningRuleType.levelUpgrade, name: 'Level Upgrade' },
          ]}
          groupActions={groupActions}
          // defaultTabKey={levels}
          pageInfo={pageInfo}
          totalCount={totalCount}
          filter={{ hasFilter: true, component: Filter }}
          listContent={
            <BaseListContainer
              fields={listFields}
              dataList={dataList}
              totalPage={totalPage}
              model={'earningRuleList'}
              permissionGroup={PermissionCodes.earningrule}
              deleteInfo={{
                data: [],
                title: 'earning rules',
                relatedName: 'campaigns',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.EARNING_RULE,
              }}
              actions={['Edit', 'Detail', 'Delete']}
            />
          }
        />
      ),
    },
  ];

  useEffect(() => {
    const parsedSearch = queryString.parse(location.search);
    const type = parsedSearch['type'] || '';
    const searchKey = parsedSearch['search'] || '';
    const stringRank = parsedSearch['rank'] || 'true';
    const stringPage = parsedSearch['page'] || 1;
    const targetCustomer = parsedSearch['target_customer'] || '';
    const groupsIn = parsedSearch['groupsIn'] || '';
    const segmentsIn = parsedSearch['segmentsIn'] || '';
    const levelsIn = parsedSearch['levelsIn'] || '';
    const rewardType = parsedSearch['reward_type'] || '';
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);

    dispatch({
      type: 'earningRuleList/getEarningRuleListWithTypes',
      payload: {
        ...parsedSearch,
        page,
        reverse: rank,
        search: searchKey,
        otherSearch: {
          rewardType,
          targetCustomer,
          groupsIn,
          segmentsIn,
          levelsIn,
        },
        type: type, //filters.levels.join(','),
      },
    });
    dispatch(
      createAction('customerGroup/getGroupList')({ isSimpleList: true }),
    );
    dispatch(
      createAction('segments/getSegmentsList')({
        type: 'all',
        isSelectorLoad: true,
      }),
    );
    dispatch({ type: 'levels/getLevelList' });
  }, [dispatch, location]);

  return (
    <>
      <CustomListComponent
        caution={{
          detail:
            'Campaign can link this rules to let customer earn points or coupons',
          title: NavBarNames.earnRules,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={true}
      />
      <DeletePrompt
        data={checkedList}
        title={'earning rules'}
        relatedName={'campaigns'}
        relatedSections={DELETE_RELATED_SECTIONS.EARNING_RULE}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: 'earningRuleList/delete',
            payload: {
              afterAction: () => {
                history.push(location);
              },
            },
          });
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  listFields: state.earningRuleList.listDisplayFields,
  dataList: state.earningRuleList.earningRuleTypeList,
  pageInfo: state.earningRuleList.pageInfo,
  totalPage: state.earningRuleList.totalPage,
  totalCount: state.earningRuleList.totalCount,
  checkedList: state.earningRuleList.checkedList,
});

export default connect(mapPropsToState)(EarningRuleList);
