import { defaultStep, getNewStepConfig } from './StepBarUtil';
import { TRANSACTION_RECORD_TYPE } from '../containers/record/records/TransactionList';
import {
  createTransaction,
  updateTransaction,
} from '../services/TransactionRecordsAPIHelper';
import { loading } from './LoadingUtil';
import {
  getFileNameFromUrl,
  saveToSessionStorage,
  removeFromSessionStorage,
  getObjectFromSessionStorage,
} from '../utils';
import { DISCOUNT_TYPE, REFUND_TYPE } from 'config/CustomEnums';

export const sessionDataKey = {
  objectKey: 'createTransaction',
  stepEndKey: 'createTransactionStepEnd',
  origionalData: 'createTransactionOriginalData',
};

export const CreateTransactionError = {
  customer: {
    name: 'customer',
    message: 'Please provide a customer.',
  },
  transactionDate: {
    name: 'transactionDate',
    message: 'Please provide a transaction date.',
  },
  store: {
    name: 'stote', //optional when type is POS
    message: 'Please provide a store.',
  },
  posInvoiceId: {
    name: 'posInvoiceID',
    message: 'Please provide a invoice id.',
  },
  invoiceId: {
    name: 'invoiceID',
    message: 'Please provide a invoice id.',
  },
  offlineEventType: {
    name: 'offlineEventType',
    message: 'Please provide a offline event type.',
  },
  staffName: {
    name: 'staffName',
    message: 'Please provide a staff name.',
  },
  totalValue: {
    name: 'totalValue',
    message: 'Please provide a eligible total value.',
  },
  totalValueFormat: {
    name: 'totalValueFormat',
    message: 'Please enter eligible total value in digits.',
  },
  paidAmount: {
    name: 'paidAmount',
    message: 'Please provide a paid amount value.',
  },
  paidAmountFormat: {
    name: 'paidAmountFormat',
    message: 'Please enter paid amount value in digits.',
  },
  purchasedItemName: {
    name: 'purchasedItemName',
    message: 'Please provide a product name.',
  },
  purchasedItemQuantity: {
    name: 'purchasedItemQuanitity',
    message: 'Please provide a quantity.',
  },
  purchasedItemQuantityFormat: {
    name: 'purchasedItemQuantityFormat',
    message: 'Please enter quantity in digits.',
  },
  purchasedItemValue: {
    name: 'purchasedItemValue',
    message: 'Please provide a final price.',
  },
  purchasedItemValueFormat: {
    name: 'purchasedItemValueFormat',
    message: 'Please enter value in digits.',
  },
  receiptImage: {
    name: 'receiptImage',
    message: 'Please provide a receipt image.',
  },
  creditCardSlipImage: {
    name: 'creditCardSlipImage',
    message: 'Please provide a credit card slip image.',
  },
  refundAmount: {
    name: 'refundAmount',
    message: 'Please provide a refund amount.',
  },
  additionalDataTitle: {
    name: "additionalDataTitle",
    message: 'Please provide a title.',
  },
  discountValue: {
    name: "discountValue",
    message: 'Please provide a value.',
  },
  discountType: {
    name: "discountType",
    message: 'Please provide a type.',
  }
};

function getTransactionInit() {
  return {
    pk: null,
    id: null,
    ssoUid: null,
    name: null,
    transactionType: null,
    transactionDisplayType: null,
    storeName: null,
    creationDate: null,
    transactionDate: new Date(),
    purchasedDate: null,
    transactionDetailDisplayDate: null,
    transactionDetailCreationDate: null,
    totalValue: null,
    onlineEventType: null,
    offlineEventType: null,
    displayOfflineEventType: null,
    staffName: null,
    shippingFee: null,
    otherCharge: null,
    remarks: null,
    posInvoiceId: null,
    invoiceId: null,
    receiptImage: null,
    creditCardSlipImage: null,
    purchasedItems: [
      {
        productName: null,
        sku: null,
        category: null,
        brand: null,
        quantity: null,
        value: null,
      },
    ],
    customer: {},
    store: {},
  };
}

function getInitState() {
  const stepNameList = ['Type', 'Content', 'Purchase Detail'];
  return {
    transaction: getTransactionInit(),
    errorFields: [],
    stepConfig: defaultStep(stepNameList),
    currentStep: 0,
    selectedType: null,
    transactionStatusChanged: false,
    formHasSubmitted: false,
    hasUpdatedDefaultValues: false,
  };
}

function parsePhotoUrlForAPI(image) {
  let imageUrl = image;
  if (image && image.value) {
    imageUrl = image.value;
  }
  return getFileNameFromUrl(imageUrl);
}

function parseCreateTransactionInputBody(data) {
  const receiptImage = data.receiptImage;
  const creditCardSlipImage = data.creditCardSlipImage;
  const transactionDisplayType = data.transactionDisplayType;
  const discountList = [];
  (data?.discounts || []).forEach((item) => {
    if (item.value && item.type) {
      discountList.push({
        value: item.value,
        code: item?.code,
        valueType: item.valueType,
        type: item.type,
        ...(item?.pk ? { id: item.pk } : {})
      })
    }
  });
  const additionalData = {}
  data?.additionalData?.length > 0 && data.additionalData.forEach((item) => {
    if (item?.title) {
      additionalData[item.title] = item.value === null ? "" : item.value
    }
  })
  let inputBody = {
    date: data.transactionDate,
    // date: formatDate(data.transactionDate, TimeFormater.dayMonthYearWeekTimeA),
    offlineEventType: data.offlineEventType,
    staffName: data.staffName,
    customer: data.customer?.pk,
    totalValue: data.totalValue,
    paidAmount: data.paidAmount || null,
    discountAmount: data?.discountAmount || null,
    store: data.store.storePK,
    remarks: data.remarks,
    extraFields: JSON.stringify(additionalData),
    pointToCash: data?.pointToCash || null,
    shippingFee: data?.shippingFee || null,
    otherCharge: data?.otherCharge || null,
    discounts: discountList
  };
  if (transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS) {
    inputBody = {
      ...inputBody,
      posInvoiceId: data.posInvoiceId,
      refundedInvoiceId: data.refundedInvoiceId,
    };
    if (data.offlineEventType === "PAID") {
      inputBody["purchasedDate"] = data.purchasedDate;
    };
    if (data.offlineEventType === "PARTIALLY_REFUNDED") {
      inputBody["refund"] = data.refundAmount;
    } else if (data.offlineEventType === "REFUNDED") {
      inputBody["refund"] = data.refundType === REFUND_TYPE.CUSTOM_QUANTITY ? data.refundAmount : null;
      inputBody["refundType"] = data.refundType;
    } else {
      inputBody["refund"] = null;
      inputBody["refundType"] = null;
    }
  } else if (transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM) {
    inputBody = {
      ...inputBody,
      invoiceId: data.invoiceId,
      receiptImage: parsePhotoUrlForAPI(receiptImage),
      creditCardSlipImage: parsePhotoUrlForAPI(creditCardSlipImage),
    };
  }
  console.log('@@288: ', data, inputBody);
  return inputBody;
}

function parsePurchasedItemInputBody(data, offlineEventType) {
  const discountList = [];
  (data?.discounts || []).forEach((item) => {
    if (item.value && item.type) {
      discountList.push({
        type: item?.type || DISCOUNT_TYPE.ITEM,
        value: item.value,
        code: item?.code,
        valueType: item.valueType,
        ...(item?.pk ? { id: item.pk } : {})
      })
    }
  });
  const additionalData = {}
  data.additionalData?.length> 0 &&data.additionalData.forEach((item)=>{
    if (item?.title) {
      additionalData[item.title] = item.value === null ? "" : item.value
    }
  })
  const inputBody = {
    name: data.productName,
    sku: data.sku,
    category: data.category,
    subcategory: data.subcategory,
    brand: data.brand,
    quantity: data.quantity,
    originalPrice: data.originalPrice || null,
    value: data.value,
    extraFields: JSON.stringify(additionalData),
    discounts: discountList,
  };
  if (offlineEventType === "PARTIALLY_REFUNDED") {
    inputBody["status"] = data?.isRefunded ? "REFUNDED" : "UNFULFILLED"
  }
  return inputBody;
}

export default {
  namespace: 'createTransaction',
  state: getInitState(),

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    updateTransactionState(state, { payload }) {
      const transaction = { ...state.transaction, ...payload };
      console.log('@@433: ', transaction);
      saveToSessionStorage(sessionDataKey.objectKey, transaction);
      return {
        ...state,
        transaction: transaction,
      };
    },
    loadTransactionFromCookie(state, { payload }) {
      const transaction = getObjectFromSessionStorage(sessionDataKey.objectKey);
      if (!transaction) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        transaction: transaction,
        hasUpdatedDefaultValues: true,
      };
    },
    saveOrRemoveTransactionFromCookie(state, { payload }) {
      if (payload.save) {
        if (payload.values) {
          saveToSessionStorage(sessionDataKey.objectKey, payload.values);
        }
      } else {
        removeFromSessionStorage(sessionDataKey.objectKey);
      }
      saveToSessionStorage(sessionDataKey.stepEndKey, true);
      return {
        ...state,
      };
    },
    addPurchasedItem(state, { payload }) {
      return {
        ...state,
        transaction: {
          ...state.transaction,
          purchasedItems: [...state.transaction.purchasedItems, { ...payload }],
        },
      };
    },
    updatePruchasedItems(state, { payload }) {
      const { index, data } = payload;
      return {
        ...state,
        transaction: {
          ...state.transaction,
          purchasedItems: [
            ...state.transaction.purchasedItems.slice(0, index),
            {
              ...state.transaction.purchasedItems[index],
              ...data,
            },
            ...state.transaction.purchasedItems.slice(index + 1),
          ],
        },
      };
    },
    stepChange(state, { payload }) {
      const isBack = payload.isBack;
      let step = payload.step;
      const isValid = payload.isValid;
      const stepConfig = getNewStepConfig(
        step,
        state.stepConfig,
        !isValid,
        isBack,
      );
      if (isValid) {
        step = isBack ? step - 1 : step + 1;
      }
      return {
        ...state,
        stepConfig,
        currentStep: step,
      };
    },
    clearData(state, { payload }) {
      return { ...state, ...getInitState() };
    },
  },

  effects: {
    *setFieldToSession({ payload }, { select }) {
      const oldTransaction = yield select(
        (state) => state.createTransaction.transaction,
      );
      const transaction = { ...oldTransaction, ...payload };

      saveToSessionStorage(sessionDataKey.objectKey, transaction);
    },
    createTransaction: [
      function* ({ payload }, { call, select, put }) {
        const { values } = payload;
        const transactionDisplayType = values.transactionDisplayType;
        const data = parseCreateTransactionInputBody(values);
        if (transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_ONLINE) {
          data.transactionType = 'ONLINE';
        } else if (transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS) {
          data.transactionType = 'OFFLINE_POS';
        } else if (transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM) {
          data.transactionType = 'OFFLINE_REWARD_CLAIM';
        }
        if (values.transactionDisplayType === TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS) {
          const purchasedItems = values.purchasedItems;
          data.purchasedItemsInput = purchasedItems.map((item) => {
            return parsePurchasedItemInputBody(item, values?.offlineEventType);
          });
        }

        const serviceArgs = [createTransaction, data];

        saveToSessionStorage(sessionDataKey.stepEndKey, true);

        function* onSuccess(data) {
          const transactionData = data.createTransaction.node;
          yield put({
            type: 'updateTransactionState',
            payload: {
              pk: transactionData?.pk,
              id: transactionData?.id,
            }
          });
          yield put({
            type: 'updateState',
            payload: {
              transactionStatusChanged: true,
              formHasSubmitted: true,
            },
          });
          removeFromSessionStorage(sessionDataKey.objectKey);
        }

        function* onFailed(data) {
          console.log('@@512: ', data);
          yield put({
            type: 'updateState',
            payload: {
              formHasSubmitted: true,
            },
          });
        }

        function onArgumentsError(data) {
          console.log('@@512: ', data);
        }
        yield loading(serviceArgs, onSuccess, onFailed, onArgumentsError);
      },
      { type: 'takeLatest' },
    ],

    updateTransaction: [
      function* ({ payload }, { put, select }) {
        const { values } = payload;
        let parsedInputBody = parseCreateTransactionInputBody(values);
        parsedInputBody.id = values.pk;

        if (values.transactionDisplayType ===TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS) {
          const purchasedItems = values.purchasedItems;
          parsedInputBody.purchasedItemsInput = purchasedItems.map((item) => {
            const inputBody = parsePurchasedItemInputBody(item, values?.offlineEventType);
            if (item.pk) {
              inputBody.id = item.pk;
            }
            return inputBody;
          });
        }

        saveToSessionStorage(sessionDataKey.stepEndKey, true);
        const serviceArgs = [updateTransaction, parsedInputBody];
        function* onSuccess() {
          removeFromSessionStorage(sessionDataKey.objectKey);
          yield put({
            type: 'updateState',
            payload: {
              transactionStatusChanged: true,
              formHasSubmitted: true,
            },
          });
        }
        function* onFailed(data) {
          console.log('@@512: ', data);
          yield put({
            type: 'updateState',
            payload: {
              formHasSubmitted: true,
            },
          });
        }
        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
  },
};
