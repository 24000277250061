import React, { useState } from 'react';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import { DropDownWithDefaultSelect } from '../base/ActionsDropdown';
import { Controller, useFormContext } from 'react-hook-form';
import { hasReactHookFormError } from '../base/ErrorFieldMessage';

const ExtraSuffixLabel = ({
  extraSuffix
}) => (
  <label className="help-message margin-left-10-percent margin-right-10-percent">
    {extraSuffix}
  </label>
);

export default function LookupWithMutipleNumber({
  fieldBaseName,
  title,
  groupName,
  extraSuffix,
  notRequired = false,
}) {
  const { setValue, watch, control, register, formState: { errors } } = useFormContext();
  const lookupFieldName = `${fieldBaseName}.lookup`;
  const minFieldName = `${fieldBaseName}.minNum`; 
  const maxFieldName = `${fieldBaseName}.maxNum`; 
  const lookup = watch(lookupFieldName) || 'Please select';

  const getInputNumberCount = (lookup) => {
    if (lookup === 'Please select') {
      return 0;
    }
    let showInputCount = 1;
    if (lookup.toLowerCase().indexOf('empty') >= 0) {
      showInputCount = 0;
    } else if (lookup.toLowerCase() === 'between') {
      showInputCount = 2;
    }

    return showInputCount;
  };

  const [showInputNumber, setShowInputNumber] = useState(
    getInputNumberCount(lookup),
  );

  const lookups =
    groupName === 'signUpAnniversaryCharacteristic'
      ? ['Equal to', 'Not equal to', 'Between', 'Less than', 'Greater than']
      : [
          'Equal to',
          'Not equal to',
          'Is empty',
          'Is not empty',
          'Between',
          'Less than',
          'Greater than',
        ];

  const dropDownOptions = lookups.map((lookupItem) => ({
    name: lookupItem,
    action: () => {
      setValue(lookupFieldName, lookupItem, { shouldDirty: true, shouldValidate: true });
      setShowInputNumber(getInputNumberCount(lookupItem));
    },
  }));

  return (
    <div>
      <CustomTitleLabel title={title} />

      <div style={{ display: 'flex' }}>
        <Controller
          control={control}
          name={lookupFieldName}
          rules={{ required: !notRequired }}
          render={({ field }) => (
              <DropDownWithDefaultSelect
                actions={dropDownOptions}
                defaultValue={lookup}
                className={hasReactHookFormError(errors, field.name) ? 'error-field' : ''}
              />
          )}
        />

        <input
          type="number"
          className={`custom-number-input-short ${hasReactHookFormError(errors, minFieldName) ? 'error-field' : ''}`}
          {...register(minFieldName, { required: showInputNumber >= 1 })}
          hidden={showInputNumber < 1}
        />
        {
          extraSuffix && showInputNumber >= 1 && (
            <ExtraSuffixLabel extraSuffix={extraSuffix} />
          )
        }
        <label
          className="help-message margin-left-10-percent margin-right-10-percent"
          hidden={showInputNumber < 2}
        >
          and
        </label>
        <input
          type="number"
          className={`custom-number-input-short ${hasReactHookFormError(errors, maxFieldName) ? 'error-field' : ''}`}
          {...register(maxFieldName, { required: showInputNumber >= 2 })}
          hidden={showInputNumber < 2}
        />
        {
          extraSuffix && showInputNumber >= 2 && (
            <ExtraSuffixLabel extraSuffix={extraSuffix} />
          )
        }
      </div>
    </div>
  ); 
}