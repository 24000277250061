import React from 'react';
import InputWithMutipleDropDown from './InputWithMutipleDropDown';
import TransactionTypeMutipleSelector from './TransactionTypeMutipleSelector';
import { PERIOD_TYPE_DISPLAY } from 'config/CustomEnums';

const LastPurchaseSection = ({
  title,
  groupName,
  fieldBaseName
}) => {
  return (
    <div className="d-flex flex-column">
      <InputWithMutipleDropDown
        fieldBaseName={fieldBaseName}
        title={title}
        rangeOptions={[
          PERIOD_TYPE_DISPLAY.IN_X_NUMBER_OF_DAYS,
          PERIOD_TYPE_DISPLAY.WITHIN,
          PERIOD_TYPE_DISPLAY.EQUAL_TO,
          PERIOD_TYPE_DISPLAY.BETWEEN,
          PERIOD_TYPE_DISPLAY.GREATER_THAN,
          PERIOD_TYPE_DISPLAY.LESS_THAN,
        ]}
        hasPeriodDropdown
      />
      <TransactionTypeMutipleSelector fieldBaseName={fieldBaseName} />
    </div>
  );
}

export default LastPurchaseSection;
