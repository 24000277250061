import React, { useState } from 'react';
import { connect } from 'react-redux';

import { CustomStatusTagFromText } from 'components/base/CustomStatusTag';
import CustomTips from 'components/base/CustomTips';
import Loading from 'components/base/Loading';
import { CUSTOMER_DETAIL_TAB_NAME, SIGN_UP_METHOD } from 'config/CustomEnums';
import { PermissionCodes } from 'config/PermissionCodes';

import AddRemarkModal from './AddRemarkModal';
import AllRemarkModal, { SingleRemark } from './AllRemarkModal';
import DeleteRemarkModal from './DeleteRemarkModal';
import EditCustomerGroupModal from './EditCustomerGroupModal';
import EditPersonalInformationModal from './EditPersonalInformationModal';
import PersonalInformationFields from './PersonalInformationFields';
import UpdatePointsModal from './UpdatePointsModal';
import { TitleWithButton } from './utils';

import './PersonalInformation.scss';

import CustomerEdit from 'assets/images/customer_edit.svg';

const PointStatus = ({ value, content }) => {
  return (
    <div className="customer-detail-point-status-container">
      <label className="customer-detail-point-status-value">
        {value || '-'}
      </label>
      <label className="customer-detail-point-status-content">{content}</label>
    </div>
  );
};

const TopItemList = ({ title, columnName, dataList }) => {
  return (
    <div className="customer-detail-top-item-list-container">
      <label className="customer-detail-point-status-value">{title}</label>
      <div className="customer-detail-item-list-content">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Ranking</th>
              <th scope="col">{columnName}</th>
            </tr>
          </thead>
          <tbody>
            {dataList?.map((item, index) => (
              <tr key={`top-item-${index}`}>
                <th scope="row">{index + 1}</th>
                <td>{item}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const CustomerInformationBehavioralData = ({ customerBehavioralData }) => {
  if (customerBehavioralData?.customerProfileBehavioralDataLoading) {
    return (
      <>
        <div className="customer-detail-edit-title-with-button">
          <label className="create-section-label customer-detail-edit-title">
            Behavioral data
          </label>
        </div>
        <div className="customer-detail-points-container gray-box">
          <Loading />
        </div>
      </>
    );
  }
  return (
    <>
      <div className="customer-detail-edit-title-with-button">
        <label className="create-section-label customer-detail-edit-title">
          Behavioral data
        </label>
      </div>
      <div className="customer-detail-points-container gray-box">
        <PointStatus
          value={customerBehavioralData?.displayAverageTransactionValue}
          content="Average transaction value ($)"
        />
        <PointStatus
          value={customerBehavioralData?.displayLastPurchaseDate}
          content="Last purchase"
        />
        <PointStatus
          value={customerBehavioralData?.displayTotalTransactionValue}
          content="Total transaction value ($)"
        />
      </div>
      <div className="customer-detail-points-container gray-box">
        <PointStatus
          value={customerBehavioralData?.displayCountTransaction}
          content="No. of transaction"
        />
        <PointStatus
          value={customerBehavioralData?.displayCountTransactionInPhysicalStore}
          content="No. of transaction in physical store"
        />
        <PointStatus
          value={customerBehavioralData?.displayCountTransactionInOnlineStore}
          content="No. of transaction in online store"
        />
      </div>
      <div className="customer-detail-points-container gray-box">
        <TopItemList
          title="Top 3 Product categories"
          columnName="Product category"
          dataList={customerBehavioralData?.displayTopCategoryList}
        />
        <TopItemList
          title="Top 3 SKU"
          columnName="SKU"
          dataList={customerBehavioralData?.displayTopSkuList}
        />
        <TopItemList
          title="Top 3 Stores"
          columnName="Store"
          dataList={customerBehavioralData?.displayTopStoreList}
        />
      </div>
    </>
  );
};

const SingleTag = ({ value }) => {
  return <label className="customer-detail-single-tag">{value}</label>;
};

const PersonalInformation = ({
  customer,
  customerBehavioralData,
  setActiveTab,
}) => {
  const [showPersonalInformationMore, setShowPersonalInformationMore] =
    useState(false);
  const [showEditPersonalInformation, setShowEditPersonalInformation] =
    useState(false);
  const [showUpdatePoints, setShowUpdatePoints] = useState(false);
  const [showCustomerGroup, setShowCustomerGroup] = useState(false);
  const [showAddRemark, setShowAddRemark] = useState(false);
  const [showAllRemark, setShowAllRemark] = useState(false);
  const [showDeleteRemark, setShowDeleteRemark] = useState(false);
  const [deleteRemarkId, setDeleteRemarkId] = useState(null);

  let personalInfoFields = [
    SIGN_UP_METHOD.MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS,
    SIGN_UP_METHOD.MOBILE_PHONE_NUMBER,
    SIGN_UP_METHOD.EMAIL_ADDRESS,
  ].includes(customer.signUpMethod)
    ? [[{ title: 'Login Method & Contact:', field: 'displayLoginMethod', displayBlock: true }]]
    : [];

  if (showPersonalInformationMore) {
    personalInfoFields = [
      ...personalInfoFields,
      [
        { title: 'First name:', field: 'firstName' },
        { title: 'Last name:', field: 'lastName' },
        { title: 'Preferred name (optional):', field: 'nickname' },
      ],
      [
        { title: 'Gender (optional):', field: 'displayGender' },
        { title: 'Birth month and year:', field: 'displayDateOfBirth' },
        {
          title: 'Preferred message language:',
          field: 'preferredMessageLanguageDisplay',
        },
      ],
      [
        { title: 'Accept direct marketing:', field: 'acceptDirectMarketing' },
        {
          title: 'Accept direct marketing via email:',
          field: 'acceptDirectMarketingViaEmail',
        },
        { title: 'Address (optional):', field: 'address' },
      ],
      [{ title: 'Assign as test customer:', field: 'assignToTest' }],
    ];
  }

  return (
    <>
      <label className="create-section-title">{`MEMBER ID: ${
        customer.membershipId || '-'
      }`}</label>

      <TitleWithButton
        title="Personal information"
        firstButtonImage={CustomerEdit}
        firstButtonContent="Edit"
        firstButtonOnClick={() => setShowEditPersonalInformation(true)}
        firstButtonRequires={PermissionCodes.changeCustomer}
      />

      <div className="customer-detail-tips-container">
        <CustomTips
          detail={`Level Privilege

          ${customer.levelPrivilege}
        `}
          popoverClassName="customer-detail-tips"
        >
          {CustomStatusTagFromText(customer.status, 'customer-detail-status')}
          {customer.level ? (
            <label className={`custom-tag-label customer-detail-level`}>
              {customer.level}
            </label>
          ) : null}
        </CustomTips>
      </div>

      <label className="customer-detail-description">
        {customer.displayJoinedTime}
      </label>

      <label className="customer-detail-description">
        {customer.displayLevelRenewalDate}
      </label>

      <PersonalInformationFields
        customer={customer}
        fields={personalInfoFields}
      />

      <button
        type="button"
        className="custom-upload-btn customer-detail-show-more-button"
        onClick={() => {
          setShowPersonalInformationMore((showMore) => !showMore);
        }}
      >
        {`show ${showPersonalInformationMore ? 'less' : 'more'}`}
      </button>

      <div className="customer-detail-separate" />

      <TitleWithButton
        title="Points & Progression points"
        firstButtonImage={CustomerEdit}
        firstButtonContent="Edit"
        firstButtonOnClick={() => setShowUpdatePoints(true)}
        firstButtonRequires={PermissionCodes.changeCustomer}
        secondButtonContent="View records"
        secondButtonOnClick={() =>
          setActiveTab(CUSTOMER_DETAIL_TAB_NAME.pointAndLevel)
        }
      />

      <div className="customer-detail-points-container">
        <PointStatus
          value={customer.pointsBalanceWithComma}
          content="Points balance"
        />
        <PointStatus
          value={customer.pointsToUpgradeWithComma}
          content="Progress points to upgrade to next level"
        />
        <PointStatus
          value={customer.pointsToRetainWithComma}
          content="Progress points left to retain in current level"
        />
      </div>

      <div className="customer-detail-separate" />

      <CustomerInformationBehavioralData
        customerBehavioralData={customerBehavioralData}
      />

      <div className="customer-detail-separate" />

      <label className="create-section-label">Segments</label>

      {customer.displaySegmentList?.length > 0 ? (
        <div className="customer-detail-tag-container">
          {customer.displaySegmentList?.map((tag) => (
            <SingleTag key={`tag-${tag}`} value={tag} />
          ))}
        </div>
      ) : (
        <div className="customer-detail-tag-container">
          {'-'}
        </div>
      )}

      <div className="customer-detail-separate" />

      <TitleWithButton
        title="Customer tag (Customer list)"
        firstButtonImage={CustomerEdit}
        firstButtonContent="Edit"
        firstButtonOnClick={() => setShowCustomerGroup(true)}
        firstButtonRequires={PermissionCodes.changeCustomer}
      />

      {customer.displayGroupList?.length > 0 ? (
        <div className="customer-detail-tag-container">
          {customer.displayGroupList?.map((tag) => (
            <SingleTag key={`tag-${tag}`} value={tag} />
          ))}
        </div>
      ) : (
        <div className="customer-detail-tag-container">
          {'-'}
        </div>
      )}

      <div className="customer-detail-separate" />

      <TitleWithButton
        title="Remark"
        firstButtonContent="+ Add remark"
        firstButtonOnClick={() => setShowAddRemark(true)}
        firstButtonRequires={PermissionCodes.changeCustomer}
        secondButtonContent="See all"
        secondButtonOnClick={() => setShowAllRemark(true)}
      />

      {customer.displayRemarks?.length > 0 ? (
        <>
          {customer.displayRemarks?.map((item) => (
            <SingleRemark
              remark={item}
              setShowDeleteRemark={setShowDeleteRemark}
              setDeleteRemarkId={setDeleteRemarkId}
            />
          ))}
        </>
      ) : (
        '-'
      )}

      <EditPersonalInformationModal
        show={showEditPersonalInformation}
        setShow={setShowEditPersonalInformation}
      />

      <UpdatePointsModal
        show={showUpdatePoints}
        setShow={setShowUpdatePoints}
      />

      <EditCustomerGroupModal
        show={showCustomerGroup}
        setShow={setShowCustomerGroup}
      />

      <AllRemarkModal
        show={showAllRemark}
        setShow={setShowAllRemark}
        setShowDeleteRemark={setShowDeleteRemark}
        setDeleteRemarkId={setDeleteRemarkId}
      />

      <DeleteRemarkModal
        show={showDeleteRemark}
        setShow={setShowDeleteRemark}
        deleteRemarkId={deleteRemarkId}
        setDeleteRemarkId={setDeleteRemarkId}
      />

      <AddRemarkModal show={showAddRemark} setShow={setShowAddRemark} />
    </>
  );
};

const mapPropsToState = (state) => ({
  customer: state.customerList.customer,
  customerBehavioralData: state.customerList.customerBehavioralData,
});

export default connect(mapPropsToState)(PersonalInformation);
