import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Image } from 'react-bootstrap';
import { DropDownWithDefaultSelect } from 'components/base/ActionsDropdown';
import { hasReactHookFormError } from 'components/base/ErrorFieldMessage';
import { CustomTitleLabel } from 'components/earning/CustomBaseComponments';
import { TRANSACTION_TYPE_DISPLAY, PERIOD_TYPE_DISPLAY } from 'config/CustomEnums';
import InputWithMutipleDropDown from './InputWithMutipleDropDown';
import LookupWithMutipleNumber from './LookupWithMutipleNumber';
import BranchLine from 'assets/images/branchLinex3.png';

const DiscountLoverSection = ({
  title,
  groupName,
  fieldBaseName
}) => {
  const {
    watch,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();
  const typeFieldName = `${fieldBaseName}.type`;
  const type = watch(typeFieldName);

  const dropDownOptions = (values, setAction) => {
    return values.map((value) => ({
      name: value,
      action: () => {
        setAction(value);
      },
    }));
  };

  return (
    <div className="d-flex flex-column">
      <InputWithMutipleDropDown
        fieldBaseName={fieldBaseName}
        title={title}
        rangeOptions={[
          PERIOD_TYPE_DISPLAY.ALL_THE_TIME,
          PERIOD_TYPE_DISPLAY.IN_X_NUMBER_OF_DAYS,
          PERIOD_TYPE_DISPLAY.WITHIN,
          PERIOD_TYPE_DISPLAY.EQUAL_TO,
          PERIOD_TYPE_DISPLAY.BETWEEN,
          PERIOD_TYPE_DISPLAY.GREATER_THAN,
          PERIOD_TYPE_DISPLAY.LESS_THAN,
        ]}
        hasPeriodDropdown
      />
      <CustomTitleLabel title="Amount of transaction with discount (optional)" />
      <div className="d-flex">
        <Controller
          name={typeFieldName}
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <DropDownWithDefaultSelect
              actions={dropDownOptions(
                [
                  TRANSACTION_TYPE_DISPLAY.NUMBER,
                  TRANSACTION_TYPE_DISPLAY.PERCENTAGE,
                ],
                (value) => {
                  setValue(field.name, value, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                },
              )}
              defaultValue={type}
              className={
                hasReactHookFormError(errors, field.name) ? 'error-field' : ''
              }
            />
          )}
        />

        <label className="content-campaign-related-to margin-right-10-percent">
          of transaction with discount
        </label>
      </div>

      <div className="d-flex">
        <Image src={BranchLine} className="content-campaign-branchLine" />
        <div className="segment-with-line-margin">
          <LookupWithMutipleNumber
            fieldBaseName={fieldBaseName}
            groupName={groupName}
            title={null}
            extraSuffix={type === TRANSACTION_TYPE_DISPLAY.PERCENTAGE ? '%' : null}
            notRequired
          />
        </div>
      </div>
    </div>
  );
}

export default DiscountLoverSection;
