import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';

import { ContinueWithBackButtons } from 'components/base/BottomStepComponent';
import ContentSections from 'components/base/ContentSections';
import {
  defaultPreviewImage,
  getPreviewLayoutImages,
  getTipsImages,
} from 'components/campaign/campaignCreation/CampaignStepTwoPreviewConfig';
import DetailSection from 'components/campaign/campaignCreation/DetailSection';
import GeneralSection from 'components/campaign/campaignCreation/GeneralSection';
import InstructionSection from 'components/campaign/campaignCreation/InstructionSection';
import KeyVisualSection from 'components/campaign/campaignCreation/KeyVisualSection';
import { CampaignType } from 'config/CustomEnums';
import { validate } from 'containers/engagement/campaign/CreateCampaignValidate';

import './CreateCampaignStepTwo.scss';
import { scrollToFirstError } from './CreateCampaignHandleError';

function CreateCampaignStepTwo({ languages }) {
  const { getValues, watch, formState, clearErrors, setError } =
    useFormContext();
  const errors = formState.errors;
  // const fieldError = Object.keys(errors || []).length;
  const errorLanguage = Object.keys(errors?.translations || [])?.[0];
  // eslint-disable-next-line no-unused-vars
  const [onCheck, setOnCheck] = useState({
    value: false,
    isBack: false,
  });
  const watchCampaignType = watch('campaignType');
  const watchHideInAnyChannel = watch('hideInAnyChannel');
  const dispatch = useDispatch();
  const defaultImage = defaultPreviewImage(watchCampaignType);
  // const isCouponRelated = isShowCouponRelated(watchCampaignType);

  const [activeSection, setActiveSection] = useState();
  const [fieldError, setFieldError] = useState(Object.keys(errors || []).length);
  const inputWithImageConfig = getPreviewLayoutImages(watchCampaignType);
  const tipsImageConfig = getTipsImages(watchCampaignType);

  useEffect(() => {
    setFieldError(Object.keys(errors || []).length);
    scrollToFirstError(
      errors,
      [
        "translations.en.generalName",
        "translations.en.coverPhoto",
        "translations.en.squareCoverPhoto",
      ]
    )
  }, [errors]);

  const getTabs = (language, setActiveSection) => {
    return [
      <GeneralSection onFocus={setActiveSection} language={language} />,
      ...(watchHideInAnyChannel
        ? []
        : [
            <KeyVisualSection onFocus={setActiveSection} language={language} />,
            <InstructionSection
              focusChange={setActiveSection}
              language={language}
              titleTips={tipsImageConfig.instructionTitle}
              contentTips={tipsImageConfig.instructionContent}
            />
            ,
            <DetailSection
              focusChange={setActiveSection}
              language={language}
              onSubmit={onCheck.value}
              titleTips={tipsImageConfig.detailsTitle}
              contentTips={tipsImageConfig.detailsContent}
            />,
          ]),
    ];
  };

  const stepChangeAction = (isBack) => {
    setFieldError(0);
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 1,
      isBack,
      languages: languages?.map(item=>item.code),
    });
    dispatch({
      type: 'createCampaign/stepChange',
      payload: { isBack: isBack, step: 1, isValid },
    });
  };

  return (
    <>
      <ContentSections
        fieldsError={fieldError}
        errorLanguage={errorLanguage}
        languageTabContent={{
          containers: languages?.map((language) => {
            return {
              container: getTabs(language.code, setActiveSection),
              key: language.code,
              title: language.sourceName,
            };
          }),
        }}
        inputWithImageConfig={
          watchHideInAnyChannel ? null : inputWithImageConfig
        }
        defaultImage={watchHideInAnyChannel ? null : defaultImage}
        activeSection={activeSection}
      />
      <ContinueWithBackButtons
        continueAction={() => {
          stepChangeAction(false);
        }}
        backAction={() => {
          stepChangeAction(true);
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  languages: state.language.allList,
});

export default connect(mapPropsToState)(CreateCampaignStepTwo);
