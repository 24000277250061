import React, { useEffect, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import queryString from 'query-string';

import CustomBreadcrumb from 'components/base/CustomBreadcrumb';
import Loading from 'components/base/Loading';
import BaseForm from 'components/base/v2/BaseForm';
import { SavedStatus } from 'config/CustomEnums';
import CreateAdminStepOne from 'containers/admin/admins/CreateAdminStepOne';
import {
  removeDataFromSessionStorage,
  saveDataToSessionStorage,
} from 'models/AdminModel';

import { createAction } from 'utils';

function CreateAdmin({
  admin,
  formHasSubmitted,
  hasUpdatedDefaultValues,
  saved,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;

  const search = queryString.parse(history.location.search);
  const pageTitle = useMemo(() => {
    if (!id) {
      const type = search?.type;
      if (type === '1') {
        return "Create Merchant App Users";
      }
      return "Create CMS Admin Users";
    }
    return admin.username;
  }, [search?.type, id, admin.username]);

  useEffect(() => {
    if (
      saved === SavedStatus.savedWithSuccess &&
      history.location.pathname.includes('edit')
    ) {
      history.push('/administrators');
    }
  }, [saved, history]);

  useEffect(() => {
    if (id) {
      dispatch(createAction('admin/getOneAdmin')({ id }));
    } else {
      dispatch({ type: 'admin/loadDataFromCookieSession' });
    }
  }, [dispatch, id]);

  if (id && !admin?.id) {
    return <Loading customClass={'common-full-height'} />;
  }
  const stepSet = [<CreateAdminStepOne />];
  return (
    <BaseForm
      defaultValues={admin}
      formHasSubmitted={formHasSubmitted}
      hasUpdatedDefaultValues={hasUpdatedDefaultValues}
      tempSave={(save, getValues) => {
        if (save) {
          saveDataToSessionStorage(getValues());
        } else {
          removeDataFromSessionStorage();
        }
      }}
      showFinishPop={saved === SavedStatus.savedWithSuccess}
      nextStepConfig={{
        title: 'Successfully Created!',
        description: 'Administrators is successfully created.',
        steps: null,
        onHide: () => {},
        buttons: [
          {
            text: 'Back to administrators list',
            action: () =>
              history.push({
                pathname: '/administrators',
              }),
          },
        ],
      }}
      content={stepSet}
      breadcrumb={
        <CustomBreadcrumb
          name={pageTitle}
        />
      }
      currentStep={0}
      caution={{
        detail: '',
        title: pageTitle,
        model: 'admin',
      }}
    />
  );
}

const mapPropsToState = (state) => ({
  admin: state.admin.oneAdmin || {},
  saved: state.admin.saved,
  hasUpdatedDefaultValues: state.admin.hasUpdatedDefaultValues,
  formHasSubmitted: state.admin.formHasSubmitted,
});

export default connect(mapPropsToState)(CreateAdmin);
