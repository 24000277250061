import React from 'react';
import { Image } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { listMultiItem } from 'components/banners/DisplayItems';
import { getCampaignTypeTitle } from 'components/campaign/campaignCreation/CreateCampaignStepOneConfigs';
import { CampaignType, EarningRuleRewardType, EarningRuleTypeKey, WEEKDAY } from 'config/CustomEnums';
import { GENERAL_TYPE } from 'models/CreateCampaignModel';

import { capitalizeFirstLetter } from 'utils/StringUtil';
import { getReadablePeriod } from 'utils/TimeFormatUtil';

import CheckOnIcon from 'assets/images/check_on.svg';

import './ProfileSection.scss';

function ProfileSection({ data }) {
  const { getValues } = useFormContext() || {};
  const campaign = data || getValues() || {};

  const getListStrings = (list) => {
    if (!list || list?.length === 0) {
      return null;
    }
    return list?.map((item) => item.name).toString();
  };

  const profileField = ({
    field, 
    value, 
    showShortDescription = false, 
    link = "", 
    linkText="View detail"
  }) => {
    return (
      <>
        <label className="create-section-label create-section-label-bottom-space">
          {field}
        </label>
        <div>
          <label className="create-section-field-input profile-section-label-bottom">
            {value || '-'}
          </label>
          {link && <Link className="profile-section-link" to={link}>{linkText}</Link>}
        </div>
        {showShortDescription ? (
          <div className="display-over-all-limit-container">
            <Image src={CheckOnIcon} className="display-over-all-limit-icon" />
            <label className="display-over-all-limit">
              Display the overall limit in the campaign.
            </label>
          </div>
        ) : null}
      </>
    );
  };

  const earningCampaignEarningRuleSection =()=> {
    const earningRuleType = EarningRuleTypeKey?.[campaign.linkedEarningRules?.type]
    const earningRuleRewardType = campaign.linkedEarningRules?.[`${earningRuleType || ''}TypeRewardType`]
    
    let rewardQuantity;
    if (earningRuleRewardType === EarningRuleRewardType.coupons) {
      rewardQuantity = campaign.linkedEarningRules?.[`${earningRuleType || ''}TypeCouponRewardTypeQuantity`]
    } else if (earningRuleRewardType === EarningRuleRewardType.points){
      rewardQuantity = campaign.linkedEarningRules?.[`${earningRuleType || ''}TypePointsRewardTypePoints`]
    }
    
    return <>
      {profileField({
        field: 'Link to earning rule set',
        ...campaign.linkedEarningRules?.pk ? {
          value: `[ID: ${campaign.linkedEarningRules.pk}] ${campaign.linkedEarningRules?.name}`,
          link: `/earns/${campaign.linkedEarningRules.pk}/`,
          linkText: 'View earning rule'
        } : {
          value: campaign.linkedEarningRules?.name || "-"
        }
      })}
      {profileField({
        field: 'Reward type',
        value: capitalizeFirstLetter(
          earningRuleRewardType?.toLowerCase() || '-'
        ),
      })}
      {earningRuleRewardType === EarningRuleRewardType.coupons &&
        profileField({
          field: 'Link to coupon set',
          ...campaign.linkedEarningRules?.[`${earningRuleType || ''}TypeCouponRewardTypeCouponTemplate`]?.pk ? {
            value: `[ID: ${campaign.linkedEarningRules?.[`${earningRuleType || ''}TypeCouponRewardTypeCouponTemplate`].pk}]`
              + ` ${campaign.linkedEarningRules?.[`${earningRuleType || ''}TypeCouponRewardTypeCouponTemplate`]?.name}`,
            link: `/coupons/${campaign.linkedEarningRules?.[`${earningRuleType || ''}TypeCouponRewardTypeCouponTemplate`].pk}/`,
            linkText: 'Link to coupon set'
          } : {
            value: campaign.linkedEarningRules?.[`${earningRuleType || ''}TypeCouponRewardTypeCouponTemplate`]?.name || '-'
          }
        })
      }
      {profileField({
        field: 'Quantity',
        value: rewardQuantity || '-',
      })}
      {earningRuleRewardType === EarningRuleRewardType.points && 
        profileField({
          field: 'Progression points',
          value: campaign.linkedEarningRules?.[`${earningRuleType || ''}TypePointsRewardTypeTpe`] || '-',
        })
      }
    </>
  }

  return (
    <>
      <label className="create-section-title">PROPERTIES</label>
      {profileField({
        field: 'Campaign type',
        value: getCampaignTypeTitle(campaign.campaignType),
      })}
      {campaign.campaignType === CampaignType.couponsCampaign &&
        profileField({
          field: 'Link to coupon set',
          ...campaign.linkedCoupon?.pk ? {
            value: `[ID: ${campaign.linkedCoupon.pk}] ${campaign.linkedCoupon?.name}`,
            link: `/coupons/${campaign.linkedCoupon.pk}/`,
            linkText: "View coupon set"
          } : {
            value: campaign.linkedCoupon?.name || "-"
          }
        })
      }
      {campaign.campaignType === CampaignType.earningRulesCampaign && earningCampaignEarningRuleSection()}
      {campaign.hideInAnyChannel ? (
        profileField({
          field: 'Active period',
          value: getReadablePeriod(
            campaign.activeStartDate,
            campaign.activeEndDate,
            campaign.isAlwaysActivePeriod,
          ),
        })
      ) : (
        <>
          {profileField({
            field: 'Campaign category',
            value: getListStrings(campaign.categories),
          })}
          {profileField({
            field: 'Target customer groups',
            value: getListStrings(
              campaign.generalType === GENERAL_TYPE.customerGroup
                ? campaign.customerGroup
                : [],
            ),
         } )}
          {profileField({
            field: 'Segments',
            value: getListStrings(
              campaign.generalType === GENERAL_TYPE.segment
                ? campaign.segments
                : [],
            ),
          })}
          {profileField({
            field: 'Active period',
            value: getReadablePeriod(
              campaign.activeStartDate,
              campaign.activeEndDate,
              campaign.isAlwaysActivePeriod,
            ),
          })}
          {listMultiItem(
            'Blackout period (by period)',
            campaign.activePeriodValues?.map((item) =>
              getReadablePeriod(item.startDate, item.endDate, false),
            ),
          )}
          {listMultiItem(
            'Blackout period (by weekday)',
            campaign.activeWeekday?.map((item) => WEEKDAY[item]),
          )}
          {profileField({
            field: 'Visible period',
            value: getReadablePeriod(
              campaign.visibleStartDate,
              campaign.visibleEndDate,
              campaign.isAlwaysVisiblePeriod,
            ),
          })}
          {profileField({ field: 'Related brand', value: campaign.linkedBrand?.name })}
          {campaign.campaignType === CampaignType.couponsCampaign && (
            <>
              {profileField({
                field: 'Per campaign coupon acquiring limit',
                value: campaign?.linkedCoupon?.totalNumberOfGeneratedCoupons === null
                  ? '-'
                  : `${campaign?.linkedCoupon?.totalNumberOfGeneratedCoupons} number of coupons`,
                showShortDescription: campaign.displayOverLimit,
              })}
              {profileField({
                field: 'Per head coupon acquiring limit during the campaign active period',
                value: campaign.perHeadLimit === null
                  ? '-'
                  : `${campaign.perHeadLimit} number of coupons`,
              })}
              {profileField({
                field: 'Required points',
                value: `${campaign.requiredPoints} points`,
              })}
            </>
          )}
        </>
      )}
    </>
  );
}

export default ProfileSection;
