import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { withResizeDetector } from 'react-resize-detector';

import { contentEnum } from 'models/SegmentModel';

import BaseMultipleSelectorV2 from '../base/BaseMultipleSelectorV2';
import { hasReactHookFormError } from '../base/ErrorFieldMessage';
import { createAction } from 'utils';

function ProductCategoryMultipleSelector({ width, fieldBaseName }) {
  const {
    control,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useFormContext();
  const dispatch = useDispatch();
  const selectedFieldName = `${fieldBaseName}.selectedCategoriesAndSubCategories`;
  const selectedValues = watch(selectedFieldName);

  const { productCategory, productSubcategory } = useSelector((state) => ({
    productCategory: state.productCategoryList.productCategoryList,
    productSubcategory: state.productCategoryList.productSubcategoryList,
  }));


  useEffect(() => {
    dispatch(createAction('productCategoryList/getSimpleProductCategoriesAndSubcategories')());
    return () => {
      dispatch(createAction('productCategoryList/clearData')());
    }
  }, []);

  let sourceData = []
  productCategory?.length > 0 && productCategory.forEach(item => {
    sourceData.push({
      pk: `product category_${item?.pk}`,
      name: item?.name,
      realPk: item?.pk,
      groupKey: {
        pk: 'product category',
        name: 'Product category',
      },
    })
  })
  productSubcategory?.length > 0 && productSubcategory.forEach(item => {
    sourceData.push({
      pk: `product subcategory_${item?.pk}`,
      name: item?.name,
      realPk: item?.pk,
      groupKey: {
        pk: 'product subcategory',
        name: 'Product subcategory',
      },
    })
  })

  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Controller
          control={control}
          name={selectedFieldName}
          rules={{ required: true }}
          shouldUnregister
          render={({ field }) => (
            <div style={width < 972 ? { width: '100%' } : { flex: 1 }}>
              <BaseMultipleSelectorV2
                title={contentEnum.productCategory}
                data={{
                  sourceData: sourceData,
                  targetData: selectedValues || [],
                  targetChange: (options) => {
                    setValue(field.name, options, { shouldDirty: true });
                  },
                }}
                groupKey={'groupKey'}
                sourceReminderText={'Please select item(s)'}
                amidReminderText={'Please select item(s)'}
                error={{
                  error: hasReactHookFormError(errors, field.name),
                  message: 'Please select at least one item',
                }}
                canNoChildren={true}
              />
            </div>
          )}
        />
      </div>
    </div>
  );
}

export default withResizeDetector(ProductCategoryMultipleSelector);
