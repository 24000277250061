import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import queryString from 'query-string';

import AuthButton from 'components/base/AuthButton';
import CustomBreadcrumb from 'components/base/CustomBreadcrumb';
import CustomListComponent from 'components/base/CustomListComponent';
import DeletePrompt from 'components/base/DeletePrompt';
import ExportRangeModal from 'components/base/ExportRangeModal';
import ListButtonsGroup from 'components/base/ListButtonsGroup';
import { NavBarNames } from 'config/NavBarNameList';
import { PermissionCodes } from 'config/PermissionCodes';
import BaseListContainer from 'containers/base/BaseListContainer';
import BaseTabListContainer from 'containers/base/BaseTabListContainer';
import { ImportResource } from 'models/DownloadImportModel';

import { createAction } from 'utils';

import Filter from './GiftCardRecordFilter';

const mapPropsToState = (state) => ({
  totalCount: state.giftCardRecord?.totalCount,
  pageInfo: state.giftCardRecord?.pageInfo,
  totalPage: state.giftCardRecord?.totalPage,
  displayFields: state.giftCardRecord?.listDisplayFields,
  pagedList: state.giftCardRecord?.pagedList,
  checkedList: state.giftCardRecord?.checkedList,
});

function GiftCardRecordList({
  totalCount,
  pageInfo,
  totalPage,
  displayFields,
  pagedList,
  checkedList,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const tabs = [
    {
      name: 'List',
      content: (
        <BaseTabListContainer
          hideTab={true}
          pageInfo={pageInfo}
          totalCount={totalCount || 0}
          searchPlaceholder={'Search by Gift card code'}
          filter={{
            hasFilter: true,
            component: Filter,
          }}
          listContent={
            <BaseListContainer
              fields={displayFields}
              model={'giftCardRecord'}
              permissionGroup={PermissionCodes.giftcardRecord}
              dataList={pagedList}
              totalPage={totalPage}
              deleteInfo={{
                data: [],
                title: 'Gift card records',
                relatedName: '',
                relatedSections: [],
              }}
              actions={['Detail',]}
            />
          }
        />
      ),
    },
  ];

  const [showDeleteView, setShowDeleteView] = useState(false);
  const [isExportAll, setIsExportAll] = useState(true);
  const [showExportModal, setShowExportModal] = useState(false);

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    const searchKey = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);
    console.log(searchKey);
    dispatch(
      createAction('giftCardRecord/getList')({
        ...searchDict,
        rank: rank,
        filterName: searchKey,
        page: page,
      }),
    );
  }, [dispatch, location]);

  const exportAction = () => {
    if (isExportAll) {
      dispatch(
        createAction('downloadAndImport/createDownloadTask')({
          from: ImportResource.giftCardRecord,
        }),
      );
      return
    }
    const parsedSearch = queryString.parse(location.search);
    const code = parsedSearch['search'] || null;
    const createDatePeriod = parsedSearch?.create_date?.split(',');
    const idIn = (checkedList || []).map((item) => item.pk);
    const actionIn = parsedSearch?.status?.split(',');
    const pointsExpiryDatePeriod = parsedSearch?.points_expiry_date?.split(',');

    dispatch(
      createAction('downloadAndImport/createDownloadTask')({
        from: ImportResource.giftCardRecord,
        related: {
          creationDateGte: createDatePeriod?.[0],
          creationDateLte: createDatePeriod?.[1],
          codeIcontains: code,
          idIn,
          actionIn,
          expiryDateGte: pointsExpiryDatePeriod?.[0],
          expiryDateLte: pointsExpiryDatePeriod?.[1],
        },
      }),
    );
  }

  const buttons = [
    <ListButtonsGroup
      primaryButton={
        <AuthButton
          title="Export"
          action={() => {
            setShowExportModal(true)
          }}
          requires={PermissionCodes.viewGiftCardRecord}
        />
      }
    />,
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '...',
          title: NavBarNames.giftCardRecords,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={true}
      />
      <ExportRangeModal
        show={showExportModal}
        title={`Export Gift Card Records`}
        closeAction={() => {
          setShowExportModal(false)
        }}
        rightButton={{
          text: 'Export',
          action: () => {
            exportAction()
            setShowExportModal(false)
          },
          requires: PermissionCodes.addExportjob,
        }}
        isExportAll={isExportAll}
        setIsExportAll={setIsExportAll}
      />
      <DeletePrompt
        data={checkedList}
        show={showDeleteView}
        title={'Gift card records'}
        relatedName=""
        relatedSections={[]}
        onClose={() => setShowDeleteView(false)}
        onConfirm={() => {
          setShowDeleteView(false);
          dispatch(
            createAction('giftCardRecord/delete')({
              afterAction: () => history.push(location),
            }),
          );
        }}
      />
    </>
  );
}

export default connect(mapPropsToState)(GiftCardRecordList);
