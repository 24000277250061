import React from 'react';

import CustomTitleLabelWithEditorBar from 'components/base/CustomTitleLabelWithEditorBar';
import ProfileDatetimeField from 'components/customer/additionalData/ProfileDatetimeField';
import ProfileDropdownField from 'components/customer/additionalData/ProfileDropdownField';
import ProfileSelectionField from 'components/customer/additionalData/ProfileSelectionField';
import ProfileTextField from 'components/customer/additionalData/ProfileTextField';
import ProfileToggleField from 'components/customer/additionalData/ProfileToggleField';
import { AdditionalDataFieldType } from 'config/CustomEnums';

const ProfileSection = ({
  sectionConfig,
  newUserCustomData,
  setNewUserCustomData,
}) => {
  const updateCustomData = (v, fieldName) => {
    const value = {
      ...newUserCustomData,
    };
    value[fieldName] = v;
    setNewUserCustomData(value);
  };
  return (
    <>
      <CustomTitleLabelWithEditorBar title={sectionConfig.name} />
      {sectionConfig?.fields?.map((field) => {
        const fieldName = field.fieldName;
        const defaultValue = newUserCustomData?.[fieldName];

        if (field.fieldType === AdditionalDataFieldType.Datetime) {
          return (
            <ProfileDatetimeField
              key={fieldName}
              fieldName={fieldName}
              displayTitle={field.displayTitle}
              mandatory={field.mandatory}
              defaultValue={defaultValue}
              setValue={(v) => updateCustomData(v, fieldName)}
            />
          );
        }
        if (
          field.fieldType === AdditionalDataFieldType.Toggle ||
          field.fieldType === AdditionalDataFieldType.Checkbox
        ) {
          return (
            <ProfileToggleField
              key={fieldName}
              fieldName={fieldName}
              displayTitle={field.displayTitle}
              mandatory={field.mandatory}
              defaultValue={defaultValue}
              setValue={(v) => updateCustomData(v, fieldName)}
            />
          );
        }
        if (
          field.fieldType === AdditionalDataFieldType.Dropdown ||
          field.fieldType === AdditionalDataFieldType.SingleChoices
        ) {
          return (
            <ProfileDropdownField
              key={fieldName}
              fieldName={fieldName}
              displayTitle={field.displayTitle}
              mandatory={field.mandatory}
              choices={field.choices}
              defaultValue={defaultValue}
              setValue={(v) => updateCustomData(v, fieldName)}
            />
          );
        }
        if (field.fieldType === AdditionalDataFieldType.MultiChoices) {
          return (
            <ProfileSelectionField
              key={fieldName}
              fieldName={fieldName}
              displayTitle={field.displayTitle}
              mandatory={field.mandatory}
              choices={field.choices}
              defaultValue={defaultValue}
              setValue={(v) => updateCustomData(v, fieldName)}
            />
          );
        }
        return (
          <ProfileTextField
            key={fieldName}
            fieldName={fieldName}
            displayTitle={field.displayTitle}
            mandatory={field.mandatory}
            isNumeral={field.fieldType === AdditionalDataFieldType.Number}
            defaultValue={defaultValue}
            setValue={(v) => updateCustomData(v, fieldName)}
          />
        );
      })}
    </>
  );
};

export default ProfileSection;
