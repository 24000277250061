import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import queryString from 'query-string';

import BaseFilter, { FilterTypeEnum } from 'components/base/BaseFilter';
import {
  EarningRuleLinkedCampaign,
  EarningRuleRewardType,
  EarningRuleType,
} from 'config/CustomEnums';

import { ArrayStringData } from 'utils';

function Filter({
  customerGroupList,
  segmentList,
  levelList,
  backAction = () => {}
}) {
  const location = useLocation();

  const parsedSearch = queryString.parse(location.search.slice(1));
  const searchType = parsedSearch['type'] || '';
  const searchRewardType = parsedSearch['reward_type'] || '';
  const searchCampaignType = parsedSearch['linked_campaign_type'] || '';
  const searchTargetCustomer = parsedSearch['target_customer'] || '';
  const searchGroups = parsedSearch['groupsIn'] || '';
  const searchSegments = parsedSearch['segmentsIn'] || '';
  const searchLevels = parsedSearch['levelsIn'] || '';

  const [type, setType] = useState(searchType);
  const [rewardType, setRewardType] = useState(
    ArrayStringData(searchRewardType),
  );
  const [linkedCampaignType, setLinkedCampaignType] = useState(
    ArrayStringData(searchCampaignType),
  );
  const [targetCustomer, setTargetCustomer] = useState(searchTargetCustomer);
  const [groups, setGroups] = useState(ArrayStringData(searchGroups));
  const [segments, setSegments] = useState(ArrayStringData(searchSegments));
  const [levels, setLevels] = useState(ArrayStringData(searchLevels));

  const content = [
    {
      title: 'Type',
      data: [
        { pk: EarningRuleType.qrCodeScanning, name: 'QR Code' },
        { pk: EarningRuleType.gpsCheckIn, name: 'GPS' },
        { pk: EarningRuleType.memberReferral, name: 'Referral' },
        { pk: EarningRuleType.generalPurchase, name: 'Purchase' },
        { pk: EarningRuleType.newMember, name: 'New Member' },
        { pk: EarningRuleType.birthday, name: 'Birthday' },
        { pk: EarningRuleType.fillingForm, name: 'Webhook' },
        { pk: EarningRuleType.levelUpgrade, name: 'Level Upgrade' },
      ],
      value: type,
      setValue: setType,
      component: FilterTypeEnum.singleChoice,
    },
    {
      title: 'Reward type',
      data: [
        { name: 'Coupons', pk: EarningRuleRewardType.coupons },
        { name: 'Points', pk: EarningRuleRewardType.points },
        { name: 'Stamps', pk: EarningRuleRewardType.stamps },
      ],
      value: rewardType,
      setValue: setRewardType,
      component: FilterTypeEnum.choice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Linked campaign type',
      data: [
        {
          name: 'Stamp campaign',
          pk: EarningRuleLinkedCampaign.stampCampaign,
        },
        {
          name: 'Earning campaign',
          pk: EarningRuleLinkedCampaign.earningCampaign,
        },
      ],
      value: linkedCampaignType,
      setValue: setLinkedCampaignType,
      component: FilterTypeEnum.choice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Target customer',
      data: [
        {
          name: 'Customer list',
          pk: 'groupsIn',
          subComponment: {
            data: customerGroupList,
            value: groups,
            setValue: setGroups,
            component: FilterTypeEnum.choice,
            className: 'filter-list-items-sub-area',
          },
        },
        {
          name: 'Segment',
          pk: 'segmentsIn',
          subComponment: {
            data: segmentList,
            value: segments,
            setValue: setSegments,
            component: FilterTypeEnum.choice,
            className: 'filter-list-items-sub-area',
          },
        },
        {
          name: 'Level',
          pk: 'levelsIn',
          subComponment: {
            data: levelList,
            value: levels,
            setValue: setLevels,
            component: FilterTypeEnum.choice,
            className: 'filter-list-items-sub-area',
          },
        },
      ],
      value: targetCustomer,
      setValue: setTargetCustomer,
      component: FilterTypeEnum.singleChoiceTargetCustomer,
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

const mapPropsToState = (state) => ({
  customerGroupList: state.customerGroup.groupList,
  segmentList: state.segments.segmentList,
  levelList: state.levels.levelList,
});

export default connect(mapPropsToState)(Filter);
