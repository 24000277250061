import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';

import i18n from 'I18n';

import BaseMultipleSelectorV2 from 'components/base/BaseMultipleSelectorV2';
import { errorMessage, hasError } from 'components/base/ErrorFieldMessage';

function TaskSection({ language, earningRuleList, onFocus = () => {} }) {
  const { setValue, watch, formState } = useFormContext();
  const errors = formState.errors;
  const watchLinkedEarningRules = watch('linkedEarningRules') || [];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'earningRuleList/getAllList',
      payload: {
        isAll: true,
        ...config.defaultFilter,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const config = {
    title: 'Link to earning rules',
    namespace: 'earningRuleList',
    data: earningRuleList,
    selectedList: watchLinkedEarningRules,
    setValue: (value) => {
      setValue('linkedEarningRules', value, { shouldDirty: true });
    },
    errorIdKey: 'linkedEarningRules',
    defaultFilter: { linkedCampaignType: 'STAMP_CAMPAIGN' },
  };

  return (
    <>
      <label className="create-section-title">
        {i18n.t('task', { local: language })}
      </label>
      <span id={config.errorIdKey}/>
      <BaseMultipleSelectorV2
        title={config.title}
        namespace={config.namespace}
        custom={{
          customItem: (item) => `[ID: ${item.pk}] ${item.name}`,
        }}
        data={{
          sourceData: config.data,
          targetData: config.selectedList,
          targetChange: (value) => {
            config.setValue(value);
          },
        }}
        error={{
          error: hasError(errors, config.errorIdKey),
          id: config.errorIdKey,
          message: errorMessage(errors, config.errorIdKey),
        }}
        sortable={false}
        onFocusFunc={() => onFocus('tasks')}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  languages: state.language.allList,
  earningRuleList: state.earningRuleList.notPagedAllList,
});
export default connect(mapPropsToState)(TaskSection);
