import React from 'react';

import CustomSwitchButton from 'components/base/CustomSwitchButton';
import CustomTitleWithInput from 'components/base/CustomTitleWithInput';
import {
  ReactHookFormErrorMessage,
  errorMessage,
  hasError,
} from 'components/base/ErrorFieldMessage';

const ProfileToggleField = ({
  fieldName,
  displayTitle,
  mandatory,
  defaultValue,
  setValue,
}) => {
  const onChange = (v) => setValue(v);
  return (
    <>
      <CustomTitleWithInput
        title={displayTitle}
        per={'Key'}
        type={'text'}
        defaultValue={fieldName}
        disabled
        buttonNewLine
      />
      <div className="inline-field">
        <label className="help-message margin-right-10-percent">
          {'Value'}
        </label>
        <CustomSwitchButton
          defaultChecked={!!defaultValue}
          onChange={onChange}
        />
      </div>
    </>
  );
};

export default ProfileToggleField;
