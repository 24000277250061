import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import '../campaign/campaignCreation/PropertiesGeneralSection.scss';
import './RewardSection.scss';
import CustomSwitchButton from '../base/CustomSwitchButton';
import BasePrompt from '../base/prompt/BasePrompt';
import {
  CustomTitleLabel,
} from './CustomBaseComponments';
import { createAction } from '../../utils';
import { PermissionCodes } from '../../config/PermissionCodes';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import InputFieldControl from '../base/InputFieldControl';
import BaseMultipleSelectorV2 from 'components/base/BaseMultipleSelectorV2';
// const options = (source) => {
//   if (!source) {
//     return [];
//   }
//   return source.map((item) => {
//     return {
//       name: item.storeName,
//       value: item.storePK,
//     };
//   });
// };

function GeneralPurchaseSection({
  storeList,
  productSkus
}) {
  const { watch, setValue } = useFormContext();

  const minSpending = watch('minSpending')
  const maxSpending = watch('maxSpending')
  const selectedStores = watch('selectedStores')
  const specialSkus = watch('specialSkus')
  const eligibleDays = watch('eligibleDays')
  const isExcludeDeliveryCost = watch('isExcludeDeliveryCost') || false
  const isExcludeOtherCharges = watch('isExcludeOtherCharges') || false
  const isInBirthMonth = watch('isInBirthMonth') || false

  const camma = ',';
  const inputSkus = watch("inputSkus")

  const dispatch = useDispatch();
  const [showCreateStore, setShowCreateStore] = useState(false);
  const [storePage, setStorePage] = useState(1);
  const [oldSelectedStores, setOldSelectedStores] = useState([]);
  const [refreshDate, setRefreshDate] = useState(new Date());
  const history = useHistory();

  useEffect(() => {
    dispatch(createAction('storeModel/getPagedStoreList')({page:storePage,isSelectorLoad:true}));
    dispatch(createAction('shopify/getProductList')());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setStorePage(prevStorePage => prevStorePage + 1)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, refreshDate]);

  useEffect(()=>{
    if (oldSelectedStores?.length < 1 && selectedStores?.length > 0) {
      setOldSelectedStores(selectedStores)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedStores])

  return (
    <>
      <label className="create-section-title">EARNING REQUIREMENTS</label>
      <InputFieldControl
        name={'minSpending'}
        title={'Minimum spending'}
        value={minSpending}
        setValue={(value) => {
          setValue('minSpending', value, { shouldDirty: true });
        }}
        type='number'
        per='HK$'
        className='coupon-campaign-section-input'
      />
      <InputFieldControl
        name={'maxSpending'}
        title={'Maximum spending (optional)'}
        value={maxSpending}
        setValue={(value) => {
          setValue('maxSpending', value, { shouldDirty: true });
        }}
        type='number'
        per='HK$'
        className='coupon-campaign-section-input'
      />

      <CustomTitleLabel title="Exclude delivery cost (for online purchase)" />
      <CustomSwitchButton
        defaultChecked={isExcludeDeliveryCost}
        onChange={(isChecked) => {
          setValue('isExcludeDeliveryCost', isChecked, { shouldDirty: true });
        }}
      />

      <CustomTitleLabel title="Exclude other charges (for online purchase)" />
      <CustomSwitchButton
        defaultChecked={isExcludeOtherCharges}
        onChange={(isChecked) => {
          setValue('isExcludeOtherCharges', isChecked, { shouldDirty: true });
        }}
      />

      <InputFieldControl
        name={'eligibleDays'}
        title={'Accept receipt from X days after purchase(optional)'}
        value={eligibleDays}
        setValue={(value) => {
          setValue('eligibleDays', value, { shouldDirty: true });
        }}
        type='number'
        unit='days'
        className='coupon-campaign-section-input'
      />
      <BaseMultipleSelectorV2 
        title={'Limited to store (optional)'}
        namespace='storeModel'
        data={{
          sourceData: storeList?.concat(oldSelectedStores),
          targetData: selectedStores,
          targetChange: (value) => {
            setValue('selectedStores', value, {shouldDirty: true})
          },
        }}
        custom={{
          customItem: item => item.displayStoreName
        }}
        addButton={{
          title: 'Add Store',
          action: () => {
            setShowCreateStore(true);
          },
          requires: PermissionCodes.addStore,
        }}
        loadMoreAction={() => {
          console.log("loadmore in")
            dispatch({
              type:'storeModel/getPagedStoreList',
              payload: {
                isSelectorLoad: true,
                page: storePage
              }
            })
            setStorePage(prevStorePage => prevStorePage + 1)
          }
        }
        requires={PermissionCodes.addStore}
      />
      <BaseMultipleSelectorV2 
        title={'SKU (optional)'}
        data={{
          sourceData: productSkus,
          targetData: specialSkus,
          targetChange: (value) => {
            setValue('specialSkus', value, {shouldDirty: true})
          },
        }}
        // get product list more not use, just for further updates
        // loadMoreAction={() => {
        //   console.log("@@GeneralPurchaseSection product skus load more") 
        //   dispatch({ type:'shopify/getProductListMore' })
        // }}
      />
      <div>
        <label className="tips-message">Please enter the SKU in below box, separate by comma. e.g. sku001, sku002</label>
        <textarea
          onChange={({ target }) => {
            const currentSkus = target.value.trim().split(camma);
            setValue("inputSkus", currentSkus, { shouldDirty: true })
          }}
          className="custom-textarea-input-box custom-textarea-input-font"
          onFocus={() => {}}
          value={inputSkus?.join(camma)}
        />
      </div>

      <CustomTitleLabel title="In birth-month" />
      <CustomSwitchButton
        defaultChecked={isInBirthMonth}
        onChange={(isChecked) => {
          setValue('isInBirthMonth', isChecked, { shouldDirty: true });
        }}
      />
      <label className="tips-message">
        {"If turned on, only the customers, who have birthday in current month, can fulfil this requirement to earn the rewards"}
      </label>
      <BasePrompt
        show={showCreateStore}
        closeAction={() => setShowCreateStore(false)}
        leftButton={{
          text: 'Done and refresh',
          action: () => {
            setRefreshDate(new Date());
            setStorePage(1);
            setShowCreateStore(false);
          },
        }}
        rightButton={{
          text: 'create store',
          action: () => {
            window.open('/stores/create', '_blank');
          }
        }}
        title={'Go to create store?'}
        description={'Go to create store'}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  storeList: state.storeModel.storeList,
  productSkus: state.shopify.productSkus,
})

export default connect(mapPropsToState)(GeneralPurchaseSection);
