import React from 'react';

import moment from 'moment';

import CustomDateTimeSelect from 'components/base/CustomDateTimeSelect';
import CustomTitleWithInput from 'components/base/CustomTitleWithInput';
import {
  ReactHookFormErrorMessage,
  errorMessage,
  hasError,
} from 'components/base/ErrorFieldMessage';

const ProfileDatetimeField = ({
  fieldName,
  displayTitle,
  mandatory,
  defaultValue,
  setValue,
}) => {
  const onTimeChange = (v) => {
    const dateString = moment(v).format('YYYY-MM-DDTHH:mm:ss');
    setValue(dateString);
  };
  return (
    <>
      <CustomTitleWithInput
        title={displayTitle}
        per={'Key'}
        type={'text'}
        defaultValue={fieldName}
        disabled
        buttonNewLine
      />
      <div className="inline-field">
        <label className="help-message margin-right-10-percent">
          {'Value'}
        </label>
      </div>
      <CustomDateTimeSelect
        defaultTime={defaultValue ? new Date(defaultValue) : new Date()}
        error={false}
        onTimeChange={onTimeChange}
      />
    </>
  );
};

export default ProfileDatetimeField;
