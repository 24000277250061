import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomBreadcrumb from 'components/base/CustomBreadcrumb';
import CustomListComponent from 'components/base/CustomListComponent';
import { createAction } from 'utils';
import ContentSections from 'components/base/ContentSections';
import { APIStatus } from 'config/CustomEnums';
import Loading from 'components/base/Loading';
import AuthButton from 'components/base/AuthButton';
import { PermissionCodes } from 'config/PermissionCodes';
import MembershipInfoCard from 'components/customer/MembershipInfoCard';

function LevelDiscountDetail({
  levelDiscount,
  status,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const tabs = [
    {
      name: 'Detail',
      content: (
        <ContentSections
          sections={
            status === APIStatus.calling
              ? [<Loading />]
              : [
                  <MembershipInfoCard
                    title="information"
                    data={levelDiscount}
                    fields={[
                      [{ title: 'Name', field: 'sourceName' }],
                      [{ title: 'Discount', field: 'displayDiscount' }],
                      [{ title: 'Shopify - free delivery', field: 'displayShopifyFreeDelivery' }],
                    ]}
                  />,
                ]
          }
          hidePreview
        />
      ),
    },
  ];

  const buttons = [
    <AuthButton
      title="Edit"
      action={() => {
        dispatch({ type: 'levelDiscount/clearData' });
        history.push({
          pathname: 'edit/',
        });
      }}
      requires={PermissionCodes.changeLanguage}
    />,
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  useEffect(() => {
    if (params.id) {
      dispatch(
        createAction('levelDiscount/getOneDetail')({
          id: params.id,
          view: true,
        }),
      );
    }
    return () => {
      dispatch({ type: 'levelDiscount/clearData' });
    };
  }, [dispatch, params.id]);

  return (
    <div className="campaign-category-detail">
      <CustomListComponent
        caution={{
          detail: '',
          title: levelDiscount.sourceName,
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb name={levelDiscount.sourceName} />}
        buttons={buttons}
        tabs={tabs}
        onTabChange={(key) => {
          setActiveTab(key);
        }}
      />
    </div>
  );
}

const mapPropsToState = (state) => ({
  levelDiscount: state.levelDiscount.detail,
  status: state.loading.status,
})

export default connect(mapPropsToState)(LevelDiscountDetail)