import React, { useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import { DropDownWithDefaultSelect } from '../base/ActionsDropdown';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomErrorMessage, hasError, hasReactHookFormError } from '../base/ErrorFieldMessage';
import { PERIOD_TYPE_DISPLAY } from 'config/CustomEnums';
import CustomDateTimeSelect from 'components/base/CustomDateTimeSelect';
import './InputWithMutipleDropDown.scss';
import BranchLine from 'assets/images/branchLinex3.png';

const defaultRangeOptions = [
  PERIOD_TYPE_DISPLAY.ALL_THE_TIME,
  PERIOD_TYPE_DISPLAY.WITHIN,
  PERIOD_TYPE_DISPLAY.EQUAL_TO,
  PERIOD_TYPE_DISPLAY.BETWEEN,
  PERIOD_TYPE_DISPLAY.GREATER_THAN,
  PERIOD_TYPE_DISPLAY.LESS_THAN,
];

export default function InputWithMutipleDropDown({
  title,
  fieldBaseName,
  hasNumber,
  rangeOptions,
  hasPeriodDropdown,
}) {
  const { setValue, setError, clearErrors, control, watch, register, formState: { errors } } = useFormContext();
  const fixedRangeOptions = rangeOptions || defaultRangeOptions;

  const numberFieldName = `${fieldBaseName}.number`;
  const periodRangeFieldName = `${fieldBaseName}.periodRange`;
  const periodDaysFieldName = `${fieldBaseName}.periodDays`;
  const periodMinDateFieldName = `${fieldBaseName}.minDate`;
  const periodMaxDateFieldName = `${fieldBaseName}.maxDate`;
  const periodNumberOfDaysFieldName = `${fieldBaseName}.numberOfDays`;

  const periodRange = watch(periodRangeFieldName) || 'Please select';
  const periodDays = watch(periodDaysFieldName) || 'Please select';
  const periodMinDate = watch(periodMinDateFieldName);
  const periodMaxDate = watch(periodMaxDateFieldName);
  const periodNumberOfDays = watch(periodNumberOfDaysFieldName);

  const showPeriodDays = periodRange === PERIOD_TYPE_DISPLAY.WITHIN;
  const showPeriodMinDate = [
    PERIOD_TYPE_DISPLAY.EQUAL_TO,
    PERIOD_TYPE_DISPLAY.BETWEEN,
    PERIOD_TYPE_DISPLAY.GREATER_THAN,
    PERIOD_TYPE_DISPLAY.LESS_THAN,
  ].includes(periodRange);
  const showPeriodMaxDate = periodRange === PERIOD_TYPE_DISPLAY.BETWEEN;

  const rangePeriodOptions = ['1 day', '7 days', '1 month', '3 months', '6 months', '1 year'];

  const dropDownOptions = (values, fieldName) => {
    return values.map((value) => ({
      name: value,
      action: () => {
        setValue(fieldName, value, { shouldDirty: true, shouldValidate: true });
      },
    }));
  };

  const checkValidDate = () => {
    clearErrors(periodMaxDateFieldName);
    if (periodRange === PERIOD_TYPE_DISPLAY.BETWEEN) {
      if (
        periodMinDate
        && periodMaxDate
        && new Date(periodMaxDate) <= new Date(periodMinDate)
      ) {
        setError(periodMaxDateFieldName, {
          type: 'manual',
          message: "The end date and time cannot be on or before the start date and time.",
        });
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    checkValidDate();
  }, [periodMinDate, periodMaxDate])

  return (
    <div>
      {title ? <CustomTitleLabel title={title} /> : null}
      <div style={{ display: 'flex' }}>
        {hasNumber ? (
          <input
            type="number"
            className={`custom-number-input-long ${hasReactHookFormError(errors, numberFieldName) ? 'error-field' : ''}`}
            {...register(numberFieldName, { required: hasNumber })}
          />
        ) : null}

        <Controller
          control={control}
          rules={{ required: fixedRangeOptions.length !== 1 }}
          name={periodRangeFieldName}
          render={({ field }) => (
            <DropDownWithDefaultSelect
              actions={dropDownOptions(fixedRangeOptions, periodRangeFieldName)}
              defaultValue={periodRange}
              className={hasReactHookFormError(errors, field.name) ? 'error-field' : ''}
            />)
          }
        />

        {showPeriodDays && hasPeriodDropdown ? (
          <Controller
            control={control}
            name={periodDaysFieldName}
            rules={{ required: showPeriodDays && hasPeriodDropdown }}
            render={({ field }) => (
              <DropDownWithDefaultSelect
                actions={dropDownOptions(rangePeriodOptions, periodDaysFieldName)}
                defaultValue={periodDays}
                className={hasReactHookFormError(errors, field.name) ? 'error-field' : ''}
              />)
            }
          />
        ) : null}
      </div>

      {
        periodRange === PERIOD_TYPE_DISPLAY.IN_X_NUMBER_OF_DAYS
          ? (
            <div className="d-flex">
              <Image src={BranchLine} className="content-campaign-branchLine" />
              <div className='period-date-with-line-margin'>
                <div className="d-flex">
                  <label className="content-campaign-related-to margin-right-10-percent">
                    in
                  </label>
                  <input
                    type="number"
                    min="0"
                    className={`custom-number-input-long ${hasReactHookFormError(errors, periodNumberOfDaysFieldName) ? 'error-field' : ''
                      }`}
                    {...register(periodNumberOfDaysFieldName, { required: 'This field is required.' })}
                  />
                  <label className="content-campaign-related-to margin-right-10-percent">
                    number of days
                  </label>
                </div>
                <CustomErrorMessage name={periodNumberOfDaysFieldName} />
              </div>
            </div>
          )
          : null
      }

      {
        showPeriodMinDate && (
          <div className="d-flex">
            <Image src={BranchLine} className="content-campaign-branchLine" />
            <div className='period-date-with-line-margin'>
              <Controller
                control={control}
                name={periodMinDateFieldName}
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomDateTimeSelect
                    defaultTime={periodMinDate ? new Date(periodMinDate) : null}
                    error={null}
                    onTimeChange={(date) => {
                      setValue(periodMinDateFieldName, date, { shouldDirty: true });
                    }}
                  />
                )}
              />
              {
                showPeriodMaxDate && (
                  <>
                    <label className='content-campaign-related-to period-date-margin'>to</label>
                    <div className='period-date-margin'>
                      <Controller
                        control={control}
                        name={periodMaxDateFieldName}
                        rules={{ required: true, validate: value => { return checkValidDate() } }}
                        render={({ field }) => (
                          <CustomDateTimeSelect
                            defaultTime={periodMaxDate ? new Date(periodMaxDate) : null}
                            error={hasError(errors, periodMaxDateFieldName)}
                            onTimeChange={(date) => {
                              setValue(periodMaxDateFieldName, date, { shouldDirty: true });
                            }}
                          />
                        )}
                      />
                    </div>
                  </>
                )
              }
            </div>
          </div>
        )
      }
    </div>
  );
}