import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Modal, Image } from 'react-bootstrap';
import CloseIcon from '../../assets/images/prompt_close_icon.svg';
import PromptButton, { BUTTON_TYPES } from 'components/base/prompt/BaseButtons';
import { PermissionCodes } from 'config/PermissionCodes';
import CustomTitleWithInput from 'components/base/CustomTitleWithInput';
import ErrorFieldMessage, { hasErrorFromList } from 'components/base/ErrorFieldMessage';
import CustomRadioWithTitleAndRelated from '../customer/CustomRadioWithTitleAndRelated';
import CustomTitleWithSwitch from 'components/base/CustomTitleWithSwitch';
import { EditCustomerHandleError } from 'components/customer/EditCustomerHandleError';
import { SIGN_UP_METHOD } from 'config/CustomEnums';
import { CustomTitleLabel } from 'components/earning/CustomBaseComponments';
import { DropDownWithDefaultSelect } from 'components/base/ActionsDropdown';
import './EditPersonalInformationModal.scss';
import CustomDateTimeSelect from 'components/base/CustomDateTimeSelect';
import { formatDate } from 'utils/TimeFormatUtil';
import { EmailSection, MobileSection } from './ContactSection';


const EditPersonalInformationModal = ({
  customer,
  errors,
  levelList,
  show,
  setShow,
}) => {
  const dispatch = useDispatch();

  const customerLevel = `${customer.level} (Current)`;

  const [isForcedInactive, setIsForcedInactive] = useState(customer.status === "Deactive");
  const [countryCode, setCountryCode] = useState(customer.mobilePhoneNumberCountryCode);
  const [subscriberNumber, setSubscriberNumber] = useState(customer.mobilePhoneNumberSubscriberNumber);
  const [email, setEmail] = useState(customer.email);
  const [firstName, setFirstName] = useState(customer.firstName);
  const [lastName, setLastName] = useState(customer.lastName);
  const [nickName, setNickName] = useState(customer.nickname);
  const [gender, setGender] = useState(customer.gender);
  const [address, setAddress] = useState(customer.address);
  const [level, setLevel] = useState(customerLevel);
  const [showLevelRenewalDate, setShowLevelRenewalDate] = useState(false);
  const [levelRenewalDate, setLevelRenewalDate] = useState(customer.currentLevelRenewDatetime);
  const [notifyCustomer, setNotifyCustomer] = useState(false);
  const [isTestUser, setIsTestUser] = useState(customer.isAssignedAsTestingCustomer);

  const isCurrentLevel = level === customerLevel;

  const defaultRenewalDate = customer.editLevelRenewalDate;
  let defaultRenewalDateFormat = formatDate(customer.currentLevelRenewDatetime);
  if (defaultRenewalDate) {
    defaultRenewalDateFormat = formatDate(defaultRenewalDate);
  };

  const dropDownOptions = (values, setAction) => {
    return values.map((value) => {
      const valueName = value?.name === customer.level ? customerLevel : value?.name;
      return {
        name: valueName,
        action: () => {
          setAction(valueName);
        },
      }
    });
  };

  const onClose = () => {
    setShow(false);
  }


  const saveAction = () => {
    dispatch({
      type: 'customerList/checkCustomerProfileValid',
      payload: {
        data: {
          ...customer,
          isForcedInactive,
          mobilePhoneNumberCountryCode: countryCode,
          mobilePhoneNumberSubscriberNumber: subscriberNumber,
          emailAddress: email,
          firstName,
          lastName,
          nickname: nickName,
          gender,
          address,
          forceLevel: isCurrentLevel ? null : levelList.find(item => item.name === level)?.pk,
          forceLevelRenewalDate: levelRenewalDate,
          forceLevelNotifyCustomer: notifyCustomer,
          isAssignedAsTestingCustomer: isTestUser,
        },
        customer: customer,
        refreshCustomer: true,
      },
    });
  };

  useEffect(() => {
    if (show) {
      dispatch({
        type: 'customerList/updateState',
        payload: {
          errorsList: [],
        }
      })

      setIsForcedInactive(customer.status === "Deactive");
      setCountryCode(customer.mobilePhoneNumberCountryCode);
      setSubscriberNumber(customer.mobilePhoneNumberSubscriberNumber);
      setEmail(customer.email);
      setFirstName(customer.firstName);
      setLastName(customer.lastName);
      setNickName(customer.nickname);
      setGender(customer.gender);
      setAddress(customer.address);
      setLevel(customerLevel);
      setLevelRenewalDate(customer.currentLevelRenewDatetime);
      setNotifyCustomer(false);
      setIsTestUser(customer.isAssignedAsTestingCustomer);
    }
  }, [show])

  useEffect(() => {
    if (defaultRenewalDate) {
      setLevelRenewalDate(defaultRenewalDate);
    };
    if (!isCurrentLevel) {
      setShowLevelRenewalDate(true);
    };
  }, [level, showLevelRenewalDate]);

  return (
    <Modal
      show={show}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop='static'
    >
      <Modal.Body className={`base-prompt-container customer-detail-prompt`}>
        <Image
          src={CloseIcon}
          className="base-prompt-close-button customer-detail-prompt-close-button"
          onClick={onClose}
        />

        <label className='customer-detail-modal-title'>Edit personal information</label>

        <div className='customer-detail-scroll-list'>
          <CustomTitleWithSwitch
            title={'Is forced inactive'}
            customTitleClass={'customer-detail-prompt-field-title'}
            defaultValue={isForcedInactive}
            setValue={(value) => {
              setIsForcedInactive(value);
            }}
          />

          {
            customer.signUpMethod === SIGN_UP_METHOD.EMAIL_ADDRESS
              ? <>
                <EmailSection
                  signUpMethod={customer.signUpMethod}
                  email={email}
                  setEmail={setEmail}
                  errors={errors}
                />
                <MobileSection
                  signUpMethod={customer.signUpMethod}
                  countryCode={countryCode}
                  setCountryCode={setCountryCode}
                  subscriberNumber={subscriberNumber}
                  setSubscriberNumber={setSubscriberNumber}
                  errors={errors}
                />
              </>
              : <>
                <MobileSection
                  signUpMethod={customer.signUpMethod}
                  countryCode={countryCode}
                  setCountryCode={setCountryCode}
                  subscriberNumber={subscriberNumber}
                  setSubscriberNumber={setSubscriberNumber}
                  errors={errors}
                />
                <EmailSection
                  signUpMethod={customer.signUpMethod}
                  email={email}
                  setEmail={setEmail}
                  errors={errors}
                />
              </>
          }

          <CustomTitleWithInput
            title={'First name'}
            customTitleClass='customer-detail-prompt-field-title'
            defaultValue={firstName}
            setValue={(value) => {
              setFirstName(value);
            }}
            error={{
              id: EditCustomerHandleError.firstName.name,
              error: hasErrorFromList(errors, EditCustomerHandleError.firstName.name),
              message: EditCustomerHandleError.firstName.message,
            }}
          />

          <CustomTitleWithInput
            title={'Last name'}
            customTitleClass='customer-detail-prompt-field-title'
            defaultValue={lastName}
            setValue={(value) => {
              setLastName(value);
            }}
            error={{
              id: EditCustomerHandleError.lastName.name,
              error: hasErrorFromList(errors, EditCustomerHandleError.lastName.name),
              message: EditCustomerHandleError.lastName.message,
            }}
          />

          <CustomTitleWithInput
            title={'Preferred name (optional)'}
            customTitleClass='customer-detail-prompt-field-title'
            defaultValue={nickName}
            setValue={(value) => {
              setNickName(value);
            }}
          />

          <CustomRadioWithTitleAndRelated
            title="Gender (optional)"
            customTitleClass='customer-detail-prompt-field-title'
            options={[
              { label: 'Male', value: 'MALE' },
              { label: 'Female', value: 'FEMALE' },
              { label: 'Not disclosed', value: 'NOT_DISCLOSED' },
            ]}
            defaultValue={gender}
            setValue={(value) => {
              setGender(value);
            }}
          />

          <CustomTitleWithInput
            title={'Address (optional)'}
            customTitleClass='customer-detail-prompt-field-title'
            defaultValue={address}
            useDefaultValue={false}
            setValue={(value) => {
              setAddress(value);
            }}
          />

          <CustomTitleLabel title="Update current level" />
          <label className="section-caution-description">
            {"Once updated the level, a new level renewal window will be started using the selected level as the start.\nThe new window start time will be the confirmation of this edit."}
          </label>

          <DropDownWithDefaultSelect
            actions={dropDownOptions(
              levelList,
              (value) => {
                setLevel(value);
                setShowLevelRenewalDate(false);
              },
            )}
            defaultValue={level}
          />

          {
            showLevelRenewalDate
              ? <div>
                <CustomTitleLabel title="New level renewal date" />
                <label className="section-caution-description">
                  {`By default, the new level renewal date will be ${defaultRenewalDateFormat}. Current level renewal date: ${customer.levelRenewalDate}.`}
                </label>
                <CustomDateTimeSelect
                  defaultTime={levelRenewalDate ? new Date(levelRenewalDate) : new Date()}
                  error={hasErrorFromList(errors, EditCustomerHandleError.levelRenewalDate.name)}
                  onTimeChange={(date) => {
                    setLevelRenewalDate(date);
                  }}
                  noNeedTime
                />
                {hasErrorFromList(errors, EditCustomerHandleError.levelRenewalDate.name) ? (
                  <ErrorFieldMessage
                    id={EditCustomerHandleError.levelRenewalDate.name}
                    error
                    message={EditCustomerHandleError.levelRenewalDate.message}
                  />
                ) : null}
              </div>
              : null
          }

          <CustomTitleWithSwitch
            title={'Notify customer'}
            customTitleClass='customer-detail-prompt-field-title'
            defaultValue={notifyCustomer}
            setValue={(value) => {
              setNotifyCustomer(value);
            }}
          />

          <CustomTitleWithSwitch
            title={'Assign as test customer'}
            customTitleClass='customer-detail-prompt-field-title'
            defaultValue={isTestUser}
            setValue={(value) => {
              setIsTestUser(value);
            }}
          />
        </div>

        <div className="base-prompt-buttons customer-detail-prompt-buttons">
          <PromptButton
            title='Cancel'
            action={onClose}
            type={BUTTON_TYPES.secondary}
          />
          <PromptButton
            title='Confirm'
            action={saveAction}
            requires={PermissionCodes.changeCustomer}
            type={BUTTON_TYPES.main}
          />
        </div>

      </Modal.Body>
    </Modal>
  );
}

const mapPropsToState = (state) => ({
  customer: state.customerList.customer,
  errors: state.customerList.errorsList,
  levelList: state.levels.levelList || [],
});

export default connect(mapPropsToState)(EditPersonalInformationModal);
