import { ImageDomain } from 'config/CustomEnums';
import { BaseHelper } from 'services/BaseGraphQLAPIHelper';

import { getInFilterValue } from 'utils';

export const TRANSACTION_TEMPLATE_FILE_PATH = `${ImageDomain}import_transaction_template.csv`;

export const getCouponTransactions = (afterCursor = null, search = {}) => {
  console.log('@@4: ', search);
  let searchString = `, orderBy: "${search.rank ? '-pk' : 'pk'}"`;
  if (search?.sort) {
    searchString = `, orderBy: "${search?.sort}"`;
  }
  if (search.searchKey) {
    searchString += `, customerName: "${search.searchKey}"`;
  }
  if (search.ssoUid) {
    searchString += `, customerSsoUid: "${search.ssoUid}"`;
  }
  if (search.record_type) {
    searchString += `, typeIn: ${getInFilterValue(search.record_type)}`;
  }
  if (search.coupon_medium) {
     searchString += `, mediumTypeIn: ${getInFilterValue(search.coupon_medium)}`;
  }

  if (search.expired_date) {
    switch (search.expired_date) {
      case 'absolute':
        if (search.absolute) {
          const expiredDateRange = search.absolute.split(',');
          searchString += `, absoluteGte: "${expiredDateRange[0]}", absoluteLte: "${expiredDateRange[1]}"`;
        }
        break;
      case 'relativeDay':
        if (search.relativeDay) {
          searchString += `, relativeDay: ${search.relativeDay}`;
        }
        break;
      default:
        searchString += `, allTime: true`;
        break;
    }
  }
  if (search?.create_date) {
    const createDatePeriod = search.create_date.split(',');
    searchString += `, creationDateGte: "${createDatePeriod[0]}", creationDateLte: "${createDatePeriod[1]}"`;
  }
  if (search?.campaigns) {
    searchString += `, targetCampaignIds: ${getInFilterValue(search.campaigns)} `;
  }

  const query = `{
        couponTransactions(
          first: 20,
          after: "${afterCursor}"${searchString}
        ) {
          totalCount
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          edges {
            cursor
            node {
              id
              pk
              type
              pointsSpent
              creationDate
              customer {
                pk
                ssoUid
                firstName
                lastName
                nickname
                membershipId
                isReset
              }
              campaign {
                pk
                name
                type
                earningCampaignTypeEarningRule {
                    pk
                    name
                  }
              }
              coupon {
                id
                pk
                effectiveDate
                expiryDate
                serialNumber
                referenceId
                template {
                    name
                    validPeriodType
                    absoluteEffectiveDate
                    absoluteExpiryDate
                    numberOfDaysToExpireAfterAcquisition
                  }
              }
              administrator {
                pk
                username
              }
            }
          }
        }
      }`;
  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCouponTransaction = (couponTransactionID) => {
  const query = `
  {
    couponTransaction(id: "${couponTransactionID}") {
      id
      pk
      type
      pointsSpent
      creationDate
      usedAt
      customer {
        pk
        ssoUid
        firstName
        lastName
        nickname
        membershipId
        isReset
      }
      campaign {
        pk
        name
        type
        earningCampaignTypeEarningRule {
          pk
          name
        }
      }
      earningRule {
        pk
        name
      }
      coupon {
        id
        pk
        referenceId
        effectiveDate
        expiryDate
        template {
          name
          validPeriodType
          absoluteEffectiveDate
          absoluteExpiryDate
          numberOfDaysToExpireAfterAcquisition
        }
      }
      administrator {
        pk
        username
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getPointTransactionRecords = (afterCursor = '', search = {}) => {
  // let searchString = `, orderBy: "${search.rank ? '-pk' : 'pk'}"`;
  let searchString = `, orderBy: "-pk"`;
  if (search?.sort) {
    searchString = `, orderBy: "${search?.sort}"`;
  }
  if (search.type) {
    searchString += `, pointTypeIn: ${getInFilterValue(search.type)}`;
  }
  if (search.searchKey) {
    searchString += `, customerName: "${search.searchKey}"`;
  }
  if (search.record_type) {
    searchString += `, transactionTypeIn: ${getInFilterValue(search.record_type)}`;
  }
  if (search.value) {
    const valueRange = search.value.split(',');
    searchString += `, valueGte: ${valueRange[0]}, valueLte: ${valueRange[1]}`;
  }
  if (search?.create_date) {
    const createDate = search.create_date.split(',');
    searchString += `, creationDateGte: "${createDate[0]}", creationDateLte: "${createDate[1]}"`;
  }
  if (search?.gift_card_code) {
    const giftCardCode = search.gift_card_code;
    searchString += `, codeContains: "${giftCardCode}"`;
  }
  if (search?.point_source) {
    searchString += `, pointSourceIn: ${getInFilterValue(search.point_source)}`;
  }

  const query = `
  {
    pointTransactions(
      first: 20,
      after: "${afterCursor}",
      hideBeVoidRecords: false${searchString}
    ) {
      totalCount
      maxPointValue
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          eventIdentifier
          customer {
            pk
            ssoUid
            firstName
            lastName
            nickname
            membershipId
            isReset
          }
          pointType
          value
          tpe
          transactionType
          remarks
          creationDate
          usedType
          usedRemark
          usedDate
          adminVoidTransaction {
            pk
            eventIdentifier
            transactionType
          }
          giftCardCodes
          giftCardPoints
          loyaltyPoints
          pointSource
          expireAtDate
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOnePointTransactionRecord = (pointRecordID) => {
  const query = `
  {
    pointTransaction(id: "${pointRecordID}") {
      id
      pk
      eventIdentifier
      customer {
        pk
        membershipId
        ssoUid
        firstName
        lastName
        nickname
        isReset
      }
      pointType
      value
      transactionType
      remarks
      creationDate
      usedType
      usedRemark
      usedDate
      tpe
      adminVoidTransaction {
        pk
        eventIdentifier
        transactionType
      }
      giftCardCodes
      giftCardPoints
      loyaltyPoints
      pointSource
      expireAtDate
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCouponTransactionReports = (afterCursor = null, search = {}) => {
  let searchString = `, orderBy: "${search.rank ? '-pk' : 'pk'}"`;
  if (search?.sort) {
    searchString = `, orderBy: "${search?.sort}"`;
  }
  if (search?.searchKey) {
    searchString += `, reportName: "${search.searchKey}"`;
  }
  if (search?.reportType) {
    searchString += `, reportTypeIn: ${getInFilterValue(search.reportType)}`;
  }
  if (search?.timeRange) {
    const timeRange = search.timeRange.split(',');
    searchString += `, timeRangeGte: "${timeRange[0]}", timeRangeLte: "${timeRange[1]}"`;
  }
  const query = `
  {
    couponReports(first: 20,
      after: "${afterCursor}"
      ${searchString}
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          reportName
          reportFileUrl
          reportType
          startTime
          endTime
          creationDate
        }
      }
    }
  }`
  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getPointTransactionReports = (afterCursor = '', search = {}) => {
  let searchString = `, orderBy: "${search.rank ? '-pk' : 'pk'}"`;
  if (search?.sort) {
    searchString = `, orderBy: "${search?.sort}"`;
  }
  if (search?.searchKey) {
    searchString += `, reportName: "${search.searchKey}"`;
  }
  if (search?.reportType) {
    searchString += `, reportTypeIn: ${getInFilterValue(search.reportType)}`;
  }
  if (search?.timeRange) {
    const timeRange = search.timeRange.split(',');
    searchString += `, timeRangeGte: "${timeRange[0]}", timeRangeLte: "${timeRange[1]}"`;
  }

  const query = `
  {
    pointReports(first: 20,
      after: "${afterCursor}"
      ${searchString}
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          reportName
          reportFileUrl
          reportType
          startTime
          endTime
          creationDate
        }
      }
    }
  }`
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createPointAddTypeRecord = (inputBody) => {
  const query = `
  mutation AddPoints($input: AddPointsInput!) {
    addPoints(input: $input) {
      success
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createPointRemoveTypeRecord = (inputBody) => {
  const query = `
  mutation RemovePoints($input: RemovePointsInput!) {
    removePoints(input: $input) {
      success
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getTransactions = (afterCursor = '', search) => {
  console.log('@@261: ', search);
  let searchString = `, orderBy: "${search.rank ? '-pk' : 'pk'}"`;
  if (search?.sort) {
    searchString = `, orderBy: "${search?.sort}"`;
  }
  if (search.searchKey) {
    searchString += `, customerName: "${search.searchKey}"`;
  }
  if (search.ssoUid) {
    searchString += `, customerSsoUid: "${search.ssoUid}"`;
  }
  if (search.transaction_type) {
    searchString += `, transactionTypeIn: ${getInFilterValue(search.transaction_type)}`;
  }
  if (search.offline_event_type) {
    searchString += `, offlineEventTypeIn: ${getInFilterValue(search.offline_event_type)}`;
  }
  if (search.online_event_type) {
    searchString += `, onlineEventTypeIn: ${getInFilterValue(search.online_event_type)}`;
  }
  if (search?.gift_card_code) {
    const giftCardCode = search.gift_card_code;
    searchString += `, codeIcontains: "${giftCardCode}"`;
  }
  if (search.transaction_date) {
    const transactionDatePeriod = search?.transaction_date?.split(',');
    searchString += `, dateGte: "${transactionDatePeriod[0]}", dateLte: "${transactionDatePeriod[1]}"`;
  }
  if (search?.create_date) {
    const createDatePeriod = search.create_date.split(',');
    searchString += `, creationDateGte: "${createDatePeriod[0]}", creationDateLte: "${createDatePeriod[1]}"`;
  }
  if (search.purchased_date) {
    const purchasedDatePeriod = search?.purchased_date?.split(',');
    searchString += `, purchasedDateGte: "${purchasedDatePeriod[0]}", purchasedDateLte: "${purchasedDatePeriod[1]}"`;
  }

  const query = `
  {
    transactions(
      first: 20,
      after: "${afterCursor}"${searchString}
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          date
          purchasedDate
          transactionType
          customer {
            pk
            ssoUid
            firstName
            lastName
            nickname
            membershipId
            isReset
          }
          store {
            pk
            name
            id
            visibleInFrontEnd
            brand {
              pk
              name
            }
          }
          onlineEventType
          offlineEventType
          creationDate
          invoiceId
          paidAmount
          totalValue
          virtualGiftCards {
            edges {
              node {
                id
                pk
                code
              }
            }
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getTransaction = (id) => {
  const query = `
  {
    transaction(id: "${id}") {
      id
      pk
      date
      purchasedDate
      transactionType
      creationDate
      customer {
        pk
        membershipId
        ssoUid
        firstName
        lastName
        nickname
        isReset
      }
      store {
        pk
        id
        name
        visibleInFrontEnd
      }
      staffName
      onlineEventType
      offlineEventType
      shippingFee
      otherCharge
      paidAmount
      totalValue
      discountAmount
      pointToCash
      remarks
      shopifyOrderId
      shopifyOrderPayload
      posInvoiceId
      invoiceId
      refundedInvoiceId
      receiptImage
      creditCardSlipImage
      purchasedItems {
        edges {
          node {
            id
            pk
            name
            sku
            category
            subcategory
            shopifyCollections,
            shopifyTags
            brand
            quantity
            originalPrice
            value
            extraFields
            status
            discounts {
              edges {
                node {
                  id
                  pk
                  type
                  code
                  value
                  valueType
                }
              }
            }
          }
        }
      }
      discounts {
        edges {
          node {
            id
            pk
            type
            code
            value
            valueType
          }
        }
      }
      refundType
      refund
      extraFields
      virtualGiftCards {
        edges {
          node {
            id
            pk
            code
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getTransactionAdditionalDataFields = (afterCursor = '', search) => {
  let searchString = `, orderBy: "pk"`;

  if (search) {
    searchString += `, fieldNameIcontains: "${search}"`;
  }
  const query = `
  {
    transactionAdditionalDataFields(
    first: 20,
    after: "${afterCursor}"${searchString}
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          fieldName
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
}

export const getPurchasedItemAdditionalDataFields = (afterCursor = '', search) => {
  let searchString = `, orderBy: "pk"`;

  if (search) {
    searchString += `, fieldNameIcontains: "${search}"`;
  }
  const query = `
  {
    purchasedItemAdditionalDataFields(
    first: 20,
    after: "${afterCursor}"${searchString}
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          fieldName
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
}

export const approveTransaction = (id) => {
  const query = `
  mutation ApproveTransaction($input: ApproveTransactionInput!) {
    approveTransaction(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      id: id,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const rejectTransaction = (id) => {
  const query = `
  mutation RejectTransaction($input: RejectTransactionInput!) {
    rejectTransaction(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      id: id,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const recallTransaction = (id) => {
  const query = `
  mutation RecallTransaction($input: RecallTransactionInput!) {
    recallTransaction(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      id: id,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteTransactions = (ids) => {
  const query = `
  mutation DeleteTransactions($input: DeleteTransactionsInput!) {
    deleteTransactions(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids: ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createTransaction = (inputBody) => {
  const query = `
  mutation CreateTransaction($input: CreateTransactionInput!) {
    createTransaction(input: $input) {
      node {
        id
        pk
        date
        purchasedDate
        transactionType
        customer {
                  id
              }
        store {
                  id
              }
        staffName
        shippingFee
        otherCharge
        paidAmount
        totalValue
        remarks
        posInvoiceId
        invoiceId
        receiptImage
        creditCardSlipImage
        offlineEventType
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateTransaction = (inputBody) => {
  const query = `
  mutation UpdateTransaction($input: UpdateTransactionInput!) {
    updateTransaction(input: $input) {
      node {
        id
        pk
        date
        purchasedDate
        transactionType
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
