import { POINTS_EXPIRY_DATE_VALUE } from 'config/CustomEnums';
import { BaseHelper } from 'services/BaseGraphQLAPIHelper';

import { getInFilterValue } from 'utils';

export const getGiftCardList = (afterCursor, moreSearch) => {
  let filter = '';
  if (moreSearch.filterName || moreSearch.search) {
    filter = `, codeIcontains:"${moreSearch.filterName || moreSearch.search}"`;
  }
  if (moreSearch?.type) {
    filter += `, type: "${moreSearch.type}"`;
  }
  if (moreSearch?.status) {
    filter += `, statusIn: ${getInFilterValue(moreSearch.status)}`;
  }
  if (moreSearch?.expiry_date) {
    filter += `, activePeriodType: "${moreSearch.expiry_date}"`;
  }
  if (moreSearch?.create_date) {
    const createDatePeriod = moreSearch.create_date.split(',');
    filter += `, creationDateGte: "${createDatePeriod[0]}", creationDateLte: "${createDatePeriod[1]}"`;
  }

  let orderBy = moreSearch.rank ? '-creation_date' : 'creation_date';
  if (moreSearch?.sort) {
    orderBy = moreSearch.sort;
  }

  const query = `query {
    giftCards(
      first: ${moreSearch?.isAll ? 100 : 20},
      after:"${afterCursor}",
      orderBy:"${orderBy}"${filter}
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          code
          type
          cashValue
          status
          usedAt
          expireAt
          creationDate

          activePeriodType
          endDate
          expireDays
          shortLink

          virtualConfigVariant {
            id
            pk
            sku
            cashValue
            price
            configId
            activePeriodType
            endDate
            expireDays
          }
          isBuyForSelf
          sender {
            id
            pk
            firstName
            lastName
            emailAddress
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getGiftCard = (id) => {
  const query = `{
    giftCard(id: "${id}") {
      id
      pk
      code
      type
      cashValue
      status
      usedAt
      expireAt
      creationDate

      activePeriodType
      endDate
      expireDays
      shortLink

      virtualConfigVariant {
        id
        pk
        sku
        cashValue
        price
        configId
        activePeriodType
        endDate
        expireDays
      }
      isBuyForSelf
      senderContact
      sender {
        id
        pk
        firstName
        lastName
        emailAddress
        membershipId
        mobilePhoneNumberCountryCode
        mobilePhoneNumberSubscriberNumber
      }
      senderContact
      recipientContact
      emailSubject
      emailContent
      recipientMobile
      smsContent
      recipient {
        id
        pk
        firstName
        lastName
        emailAddress
        membershipId
        mobilePhoneNumberCountryCode
        mobilePhoneNumberSubscriberNumber
      }
      sendMethod
      sendDate
      sendState
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const deleteGiftCards = (ids) => {
  const query = `mutation DeleteGiftCards($input: DeleteGiftCardsInput!) {
    deleteGiftCards(input: $input) {
      success
    }
  }
  `;
  const variables = { input: { ids: ids } };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateGiftCardRecipient = ({ code, sendMethod, contact }) => {
  const query = `
  mutation UpdateGiftCardRecipient($input: UpdateGiftCardRecipientInput!) {
    updateGiftCardRecipient(input: $input) {
      success
    }
  }
  `;

  const variables = {
    input: {
      code,
      sendMethod,
      contact,
    },
  };
  console.log('variables', variables);
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createPhysicalGiftCard = (values) => {
  const query = `
  mutation CreatePhysicalGiftCard(
    $input: CreatePhysicalGiftCardInput!
  ) {
    createPhysicalGiftCard(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: values,
  };
  console.log('variables', variables);
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getGiftCardRecordList = (afterCursor, moreSearch) => {
  console.log('getGiftCardRecordList');
  let filter = '';
  if (moreSearch.filterName || moreSearch.search) {
    filter = `, codeIcontains:"${moreSearch.filterName || moreSearch.search}"`;
  }
  if (moreSearch?.type) {
    filter += `, type: "${moreSearch.type}"`;
  }
  if (moreSearch?.status) {
    filter += `, actionIn: ${getInFilterValue(moreSearch.status)}`;
  }
  if (moreSearch?.points_expiry_date) {
    const expiryDatePeriod = moreSearch.points_expiry_date.split(',');
    filter += `, expiryDateGte: "${expiryDatePeriod[0]}", expiryDateLte: "${expiryDatePeriod[1]}"`;
  }
  if (moreSearch?.create_date) {
    const createDatePeriod = moreSearch.create_date.split(',');
    filter += `, creationDateGte: "${createDatePeriod[0]}", creationDateLte: "${createDatePeriod[1]}"`;
  }

  let orderBy = moreSearch.rank ? '-creation_date' : 'creation_date';
  if (moreSearch?.sort) {
    orderBy = moreSearch.sort;
  }

  const query = `query {
    giftCardRecords(
      first: ${moreSearch?.isAll ? 100 : 20},
      after:"${afterCursor}",
      orderBy:"${orderBy}"${filter}
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          action
          creationDate
          giftCard {
            id
            pk
            code
            type
            cashValue
            status
            usedAt
            expireAt
            sender {
              id
              pk
              firstName
              lastName
              emailAddress
            }
            virtualConfigVariant {
              id
              pk
              sku
              cashValue
              price
              configId
              activePeriodType
              endDate
              expireDays
            }
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getGiftCardRecord = (id) => {
  const query = `{
    giftCardRecord(id: "${id}") {
      id
      pk
      action
      creationDate
      giftCard {
        id
        pk
        code
        shortLink
        type
        cashValue
        status
        usedAt
        expireAt
        sendMethod
        sender {
          id
          pk
          firstName
          lastName
          emailAddress
        }
        senderContact
        recipientContact
        recipientMobile
        recipient {
          id
          pk
          membershipId
        }
        virtualConfigVariant {
          id
          pk
          sku
          cashValue
          price
          configId
          activePeriodType
          endDate
          expireDays
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getGiftCardVirtualConfigVariants = (afterCursor, moreSearch) => {
  const query = `query {
    virtualGiftCardVariants(
      first: 100,
      orderBy: "cash_value"
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          configId
          cashValue
          price
          activePeriodType
          endDate
          expireDays
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const updateVirtualGiftCardVariants = (values) => {
  const query = `
  mutation UpdateVirtualGiftCardVariants(
    $input: UpdateVirtualGiftCardVariantsInput!
  ) {
    updateVirtualGiftCardVariants(input: $input) {
      success
  		errors {
  			field
  			messages
  		}
    }
  }
  `;
  const variables = {
    input: values,
  };
  console.log('variables', variables);
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export function parseGiftCardType(type) {
  switch (type) {
    case 'VIRTUAL':
      return 'Virtual';
    case 'PHYSICAL':
      return 'Physical';
    default:
      return '-';
  }
}

export function parseGiftCardStatus(status, type) {
  console.log('status, type', status, type);
  if (status === 'INIT') {
    if (type === 'VIRTUAL') {
      return 'Customer online purchased';
    } else {
      return 'Generated';
    }
  }
  if (status === 'USED') {
    return 'Customer used';
  }
  if (status === 'ADMIN_EDITED') {
    return 'Admin edited';
  }
  if (status === 'REFUNDED') {
    return 'Refunded';
  }
  return '-';
}

export function parseGiftCardAction(action) {
  switch (action) {
    case 'GENERATED':
      return 'Generated';
    case 'PURCHASED':
      return 'Customer online purchased';
    case 'ADMIN_EDITED':
      return 'Admin edited';
    case 'USED':
      return 'Customer used';
    case 'REFUNDED':
      return 'Refunded';
    default:
      return '-';
  }
}

export const getValidateVariantErrors = ({
  cashValue,
  price,
  activePeriodType,
  endDate,
  expireDays,
}) => {
  const errors = [];
  if (!cashValue && !price) {
    return errors;
  }
  if (!cashValue || parseInt(cashValue) <= 0) {
    errors.push('cashValue');
  }
  if (!price || parseFloat(price) <= 0.0) {
    errors.push('price');
  }
  if (activePeriodType === POINTS_EXPIRY_DATE_VALUE.DAYS_SINCE_ACQUIRE) {
    if (isNaN(Number(expireDays)) || Number(expireDays) < 0) {
      errors.push('expireDays');
    }
  }
  return errors;
};
