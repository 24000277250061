
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { listItem } from 'components/banners/DisplayItems';
import AuthButton from 'components/base/AuthButton';
import ContentSections from 'components/base/ContentSections';
import CustomBreadcrumb from 'components/base/CustomBreadcrumb';
import CustomListComponent from 'components/base/CustomListComponent';
import Loading from 'components/base/Loading';
import { CustomTitleLabel } from 'components/earning/CustomBaseComponments';
import { APIStatus, LanguageConfig } from 'config/CustomEnums';
import { PermissionCodes } from 'config/PermissionCodes';

import { createAction } from 'utils';

const mapPropsToState = (state) => ({
  status: state.loading.status,
  productSubcategory: state.createProductCategory.productSubcategory,
  languages: state.language.allList,
})

const ProductSubcategoryDetail = ({
  productSubcategory,
  status,
  languages,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (params.id) {
      dispatch(createAction('language/getList')({ isSelectorLoad: true }));
      dispatch(
        createAction('createProductCategory/getOneProductSubcategory')({ id: params.id })
      );
    }
  }, [dispatch, params.id]);

  const topRightButtons = [
    <AuthButton
      title="Edit"
      action={() => history.push('edit/')}
      requires={PermissionCodes.changeProductCategory}
    />
  ];


  const renderTabForLanguage = (language) => {
    const translatedName = productSubcategory?.translations?.[language]?.name || productSubcategory.name
    const displayOrder = productSubcategory?.displayOrder || "-"
    return [
      <>
        {listItem('Name(Order)', translatedName)}
        {listItem('Display order', displayOrder)}
        <CustomTitleLabel title={'Assigned product category (Order)'} />
        {
          productSubcategory?.productCategories?.length > 0
            ? productSubcategory?.productCategories?.map((data) => <li>{data?.name}</li>)
            : "-"
        }
        <CustomTitleLabel title={`Linked physical store's product subcategory`} />
        {
          productSubcategory?.linkedPhysicalStoreProductSubcategories?.length > 0
            ? productSubcategory?.linkedPhysicalStoreProductSubcategories?.map((data) => <li>{data?.name}</li>)
            : "-"
        }
        <CustomTitleLabel title={`Linked online store's product subcategory`} />
        {
          productSubcategory?.linkedOnlineStoreTags?.length > 0
            ? productSubcategory?.linkedOnlineStoreTags?.map((data) => <li>{data?.label}</li>)
            : "-"
        }
      </>
    ]
  }

  const tabs = [
    {
      name: 'Detail',
      content:
        status === APIStatus.calling ? (
          <Loading />
        ) : (
          <div className="scroll-container-common">
            <ContentSections
              hidePreview
              languageTabContent={{
                containers: languages?.map((item) => ({
                  container: [renderTabForLanguage(item.code)],
                  key: item.code,
                  title: item.sourceName,
                })),
              }}
              activeSection={LanguageConfig.english}
            />
          </div>
        ),
    },
  ]

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '',
          title: productSubcategory?.name || "",
        }}
        breadcrumb={
          <CustomBreadcrumb name={productSubcategory?.name || ""} />
        }
        hideTab={false}
        tabs={tabs}
        buttons={topRightButtons}
      />
    </>
  );
}
export default connect(mapPropsToState)(ProductSubcategoryDetail);