import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import ExportRangeModal from 'components/base/ExportRangeModal';
import { ActionsDropdownForItem } from '../../../components/base/ActionsDropdown';
import AuthButton from '../../../components/base/AuthButton';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import ListButtonsGroup from '../../../components/base/ListButtonsGroup';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { ImportResource } from '../../../models/DownloadImportModel';
import BaseListContainer from '../../base/BaseListContainer';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import Filter from './Filter';

function StampRecordList({
  listDisplayFields,
  pagedList,
  totalCount,
  pageInfo,
  totalPage,
  checkedList,
}) {
  const [isExportAll, setIsExportAll] = useState(true);
  const [showExportModal, setShowExportModal] = useState(false);

  const queryString = require('query-string');
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const searchDict = queryString.parse(location.search);
  const currentPage = searchDict?.page || 1;
  const searchKey = searchDict?.search;

  const fetchData = () => {
    dispatch({
      type: 'stampRecord/getCurrentPageStampRecords',
      payload: {
        page: currentPage,
        searchKey,
        ...searchDict,
      },
    });
  };
  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const exportCSVAction = () => {
    const stampRecordListIn = (checkedList || []).map(item => item.pk);

    dispatch({
      type: 'downloadAndImport/createDownloadTask',
      payload: {
        from: ImportResource.stampRecord,
        ...isExportAll ? {} : {
          related: {
            eventTypeIn: searchDict?.event_type?.split(','),
            recordTypeIn: searchDict?.record_type?.split(','),
            stampQuantityGte: searchDict?.quantity?.split(',')?.[0],
            stampQuantityLte: searchDict?.quantity?.split(',')?.[1],
            creationDateGte: searchDict?.create_date?.split(',')?.[0],
            creationDateLte: searchDict?.create_date?.split(',')?.[1],
            customerNameIcontains: searchDict?.search || null,
            stampRecordListIn: stampRecordListIn,
          }
        }
      },
    });
  };

  const buttons = [
    <ListButtonsGroup
      hideExtraButtonWidth={600}
      hideAllButtonWidth={530}
      primaryButton={[
        <AuthButton
          title={'Export'}
          action={() => setShowExportModal(true)}
          requires={PermissionCodes.addExportjob}
        />,
      ]}
      primaryPopContent={[
        {
          requires: PermissionCodes.addExportjob,
          action: () => setShowExportModal(true),
          content: 'Export',
        },
      ]}
    />,
  ];
  const typeTabs = [
    { key: 'all', name: 'ALL' },
    { key: 'ADD_STAMP', name: 'Add Stamp' },
    { key: 'REMOVE_STAMP', name: 'Remove Stamp' },
  ];
  const tabs = [
    {
      name: '',
      content: (
        <BaseTabListContainer
          hideTab={false}
          maxTabWidth={300}
          tabs={typeTabs}
          tabSearchKey={"event_type"}
          groupActions={[]}
          pageInfo={pageInfo}
          totalCount={totalCount}
          searchMoreStyle={{ width: '350px' }}
          filter={{
            hasFilter: true,
            component: Filter,
          }}
          listContent={
            <BaseListContainer
              fields={listDisplayFields}
              dataList={pagedList}
              totalPage={totalPage ? totalPage : 0}
              deleteInfo={{
                data: [],
                title: '',
                relatedName: '',
                onComfirm: {},
              }}
              model={'stampRecord'}
              hideActions={false}
              customClassName="coupon-record-table"
              useCustomCommonActions
              customCommonActions={(item) => {
                const actions = [
                  {
                    name: 'View Detail',
                    action: () => {
                      return history.push(`${location.pathname}/${item.pk}/`);
                    },
                    requires: PermissionCodes.viewStampRecord,
                  },
                ];
                return (
                  <ActionsDropdownForItem
                    object={item}
                    actions={actions}
                    actionTitle={'View Detail'}
                  />
                );
              }}
            />
          }
        />
      ),
    },
  ];

  return (<>
    <CustomListComponent
      caution={{
        title: 'Stamp records',
      }}
      buttons={buttons}
      breadcrumb={<CustomBreadcrumb />}
      hideTab={true}
      tabs={tabs}
    />
    <ExportRangeModal
      show={showExportModal}
      title={`Export Stamp Records`}
      closeAction={() => {
        setShowExportModal(false)
      }}
      rightButton={{
        text: 'Export',
        action: () => {
          exportCSVAction()
          setShowExportModal(false)
        },
        requires: PermissionCodes.addExportjob,
      }}
      isExportAll={isExportAll}
      setIsExportAll={setIsExportAll}
    />
  </>

  );
}

const mapPropsToState = (state) => ({
  listDisplayFields: state.stampRecord.listDisplayFields,
  pagedList: state.stampRecord.pagedList,
  totalCount: state.stampRecord.totalCount,
  pageInfo: state.stampRecord.pageInfo,
  totalPage: state.stampRecord.totalPage,
  checkedList: state.stampRecord.checkedList,
});

export default connect(mapPropsToState)(StampRecordList);
