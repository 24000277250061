import React from 'react';
import { useFormContext } from 'react-hook-form';
import { connect } from 'react-redux';

import { SectionTopTitle } from 'components/base/CommonComponent';
import InputFieldControl from 'components/base/InputFieldControl';
import { ADMINISTRATOR_TYPES, EMAIL_REG } from 'config/CustomEnums';
import { AdminErrorHandleFields } from 'containers/admin/admins/AdminErrorHandleFields';

function PersonalInfoSections({ adminType, }) {
  const { watch, setValue } = useFormContext();

  const watchAdminType = adminType;
  const watchEmailAddress = watch('email');
  const watchFirstName = watch('firstName');
  const watchLastName = watch('lastName');

  const requireEmail = watchAdminType === ADMINISTRATOR_TYPES.CMS_ADMINISTRATOR;

  const firstName = (
    <InputFieldControl
      name={'firstName'}
      title={'First name (optional)'}
      rules={{}}
      value={watchFirstName}
      setValue={(value) => {
        setValue('firstName', value, { shouldDirty: true });
      }}
    />
  );
  const lastName = (
    <InputFieldControl
      name={'lastName'}
      title={'Last name (optional)'}
      rules={{}}
      value={watchLastName}
      setValue={(value) => {
        setValue('lastName', value, { shouldDirty: true });
      }}
    />
  );

  const emailAddress = (
    <InputFieldControl
      name={'email'}
      title={`Email address ${requireEmail ? '' : '(optional)'}`}
      tips={
        'Only admin with email address provided could receive reminder email for coupon low-stock.'
      }
      rules={{
        validate: {
          validEmail: (watchEmailAddress) => {
            if (requireEmail && !watchEmailAddress) {
              return AdminErrorHandleFields.required.email;
            }
            if (!watchEmailAddress) {
              return true;
            }
            if (EMAIL_REG.test(watchEmailAddress)) {
              return true;
            }
            return AdminErrorHandleFields.others.email.valid;
          },
        },
      }}
      value={watchEmailAddress}
      setValue={(value) => {
        setValue('email', value, { shouldDirty: true });
      }}
    />
  );

  return (
    <>
      <SectionTopTitle title="Personal Information" />
      {firstName}
      {lastName}
      {emailAddress}
    </>
  );
}

export default connect()(PersonalInfoSections);
