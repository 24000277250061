import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Modal, Image } from 'react-bootstrap';

import PromptButton, { BUTTON_TYPES } from 'components/base/prompt/BaseButtons';
import CustomRadioWithTitleAndRelated from 'components/customer/CustomRadioWithTitleAndRelated';
import { SIGN_UP_METHOD } from 'config/CustomEnums';
import { PermissionCodes } from 'config/PermissionCodes';
import CloseIcon from '../../assets/images/prompt_close_icon.svg';
import { createAction } from 'utils';


const mapPropsToState = (state) => ({
  customer: state.customerList.customer,
});

const accountType = {
  mobile: "mobile",
  email: "email",
}

const ResetPasswordModal = ({
  customer,
  show,
  setShow,
}) => {
  const dispatch = useDispatch();
  const customerLoginMethod = customer?.signUpMethod;
  
  const [selectedAccount, setSelectedAccount] = useState();

  const selectorOptions = [
    ...[
      SIGN_UP_METHOD.MOBILE_PHONE_NUMBER,
      SIGN_UP_METHOD.MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS
    ].includes(customerLoginMethod)
      ? [{ label: customer?.mobileNumber, value: accountType.mobile }]
      : [],
    ...[
      SIGN_UP_METHOD.EMAIL_ADDRESS,
      SIGN_UP_METHOD.MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS
    ].includes(customerLoginMethod)
      ? [{ label: customer?.email, value: accountType.email }]
      : [],
  ]

  useEffect(()=>{
    if (customerLoginMethod === SIGN_UP_METHOD.EMAIL_ADDRESS) {
      setSelectedAccount(accountType.email)
      return
    }
    setSelectedAccount(accountType.mobile)
  },[customerLoginMethod])


  const closeModal = () => {
    setShow(false);
  };

  const comfirmAction = () => {
    dispatch(createAction('customerList/sendMessageToResetPassword')({
      messageTarget: selectedAccount,
      ssoUid: customer?.ssoUid,
      afterAction: closeModal
    }))
  };

  return (
    <Modal
      show={show}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop='static'
    >
      <Modal.Body className={`base-prompt-container customer-detail-prompt`}>
        <Image
          src={CloseIcon}
          className="base-prompt-close-button customer-detail-prompt-close-button"
          onClick={closeModal}
        />
        <label className='customer-detail-modal-title'>{'Reset password'}</label>
        <dev className='customer-detail-reset-password-text'>
          {'Once confirmed, a password reset message will be sent to the customer\'s account.'}
        </dev>
        <CustomRadioWithTitleAndRelated
          title="Select the account to send to"
          options={selectorOptions}
          defaultValue={selectedAccount}
          setValue={(value) => {
            setSelectedAccount(value);
          }}
        />
        <div className="base-prompt-buttons customer-detail-prompt-buttons">
          <PromptButton
            title='Cancel'
            action={closeModal}
            type={BUTTON_TYPES.secondary}
          />
          <PromptButton
            title='Confirm to send'
            action={comfirmAction}
            requires={PermissionCodes.changeCustomer}
            type={BUTTON_TYPES.main}
          />
        </div>

      </Modal.Body>
    </Modal>
  );
}

export default connect(mapPropsToState)(ResetPasswordModal);
