import React from 'react';
import CustomTitleWithInput from 'components/base/CustomTitleWithInput';
import ErrorFieldMessage, { hasErrorFromList, ReactHookFormErrorMessage } from 'components/base/ErrorFieldMessage';
import { EditCustomerHandleError } from 'components/customer/EditCustomerHandleError';
import { CountryCodeOptions, SIGN_UP_METHOD } from 'config/CustomEnums';
import './ContactSection.scss';
import { CustomTitleWithDropDownAndInput } from 'components/customer/CustomTitleWithDropDown';


export const MobileSection = ({
  signUpMethod,
  countryCode,
  setCountryCode,
  subscriberNumber,
  setSubscriberNumber,
  errors = [],
  formErrors = [],
}) => {
  const title = signUpMethod === SIGN_UP_METHOD.EMAIL_ADDRESS
    ? "Second Login Method & Contact (optional)"
    : "Login Method & Contact";
  const defaultCountryCode = countryCode
    ? { label: `+${countryCode}`, value: countryCode, pk: countryCode }
    : { label: null, value: null, pk: null }

  return (
    <>
      <CustomTitleWithDropDownAndInput
        title={title}
        description="Ensure that the customer identity and contact information have been verified before updating"
        source={CountryCodeOptions}
        defaultValue={defaultCountryCode}
        defaultInputValue={subscriberNumber}
        needFilter={false}
        inputCustomClass="customer-detail-edit-mobile-input"
        unit=""
        setValue={(value) => {
          setCountryCode(value?.value);
        }}
        setInputValue={(value) => {
          setSubscriberNumber(value);
        }}
      />
      <ErrorFieldMessage
        id={EditCustomerHandleError.mobilePhoneNumberCountryCode.name}
        error={hasErrorFromList(errors, EditCustomerHandleError.mobilePhoneNumberCountryCode.name)}
        message={EditCustomerHandleError.mobilePhoneNumberCountryCode.message}
      />
      <ErrorFieldMessage
        id={EditCustomerHandleError.mobilePhoneNumberSubscriberNumber.name}
        error={hasErrorFromList(errors, EditCustomerHandleError.mobilePhoneNumberSubscriberNumber.name)}
        message={errors?.find(item => item.name === EditCustomerHandleError.mobilePhoneNumberSubscriberNumber.name)?.message}
      />
      <ErrorFieldMessage
        id={EditCustomerHandleError.adminEditMobile.name}
        error={hasErrorFromList(errors, EditCustomerHandleError.adminEditMobile.name)}
        message={errors?.find(item => item.name === EditCustomerHandleError.adminEditMobile.name)?.message}
      />
      <ReactHookFormErrorMessage errors={formErrors} id={EditCustomerHandleError.mobilePhoneNumberCountryCode.name} />
      <ReactHookFormErrorMessage errors={formErrors} id={EditCustomerHandleError.mobilePhoneNumberSubscriberNumber.name} />
    </>
  )
}


export const EmailSection = ({
  signUpMethod,
  email,
  setEmail,
  errors = [],
  formErrors = [],
}) => {
  let title = "Second Login Method & Contact (optional)";
  if (signUpMethod === SIGN_UP_METHOD.EMAIL_ADDRESS) {
    title = "Login Method & Contact";
  }
  else if (signUpMethod === SIGN_UP_METHOD.MOBILE_PHONE_NUMBER_AND_EMAIL_ADDRESS) {
    title = "Second Login Method & Contact";
  }

  return (
    <>
      <CustomTitleWithInput
        title={title}
        tips="Ensure that the customer identity and contact information have been verified before updating"
        customTitleClass='customer-detail-prompt-field-title'
        defaultValue={email}
        setValue={(value) => {
          setEmail(value);
        }}
        error={{
          id: EditCustomerHandleError.emailAddress.name,
          error: hasErrorFromList(errors, EditCustomerHandleError.emailAddress.name),
          message: EditCustomerHandleError.emailAddress.message
        }}
      />
      <ErrorFieldMessage
        id={EditCustomerHandleError.adminEditEmail.name}
        error={hasErrorFromList(errors, EditCustomerHandleError.adminEditEmail.name)}
        message={errors?.find(item => item.name === EditCustomerHandleError.adminEditEmail.name)?.message}
      />
      <ReactHookFormErrorMessage errors={formErrors} id={EditCustomerHandleError.emailAddress.name} />
    </>
  )
};
