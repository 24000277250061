import React, { useState } from "react";
import { Collapse } from 'react-bootstrap';

import { ReactComponent as ArrowDown } from 'assets/images/import-dropdown-not-show-arrow.svg';
import { ReactComponent as ArrowUp } from 'assets/images/import-dropdown-show-arrow.svg';
import AuthButton from "./AuthButton";

const AuthButtonWithDropdown = ({
  className = "",
  permissionRequires = "",
  title = "",
  options = [],
}) => {
  const [showDropdown, setShowDropdown] = useState(false)

  return (
    <AuthButton
      className={`${className} btn-back-button-common import-button-with-dropdown`}
      action={() => setShowDropdown(!showDropdown)}
      requires={permissionRequires}
    >
      <div>{title}</div>
      {showDropdown ? <ArrowUp className="arrow-svg" /> : <ArrowDown className="arrow-svg" />}
      <Collapse in={showDropdown}>
        <div className="import-customer-dropdown">
          {options?.map(item =>
            <div
              onClick={item?.action || (() => { })}
            >
              {item?.title || ""}
            </div>
          )}
        </div>
      </Collapse>
    </AuthButton>
  );
}

export default AuthButtonWithDropdown;