import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BaseMultipleSelectorV2 from 'components/base/BaseMultipleSelectorV2';
import CustomTitleWithInput from 'components/base/CustomTitleWithInput';
import BasePrompt from 'components/base/prompt/BasePrompt';
import { CheckStatus, SavedStatus } from 'config/CustomEnums';
import { AdminGroupErrorHandleFields } from 'containers/admin/adminGroups/AdminGroupErrorHandleFields';

import { createAction } from 'utils';

import './AddNewAdminGroupPrompt.scss';

function AddNewAdminGroupPrompt(props) {
  const onClose = props.onClose || (() => {});
  const onConfirm = props.onConfirm || (() => {});
  const dispatch = useDispatch();
  const { errorFields, checked, saved, allPermissions, oneAdminGroup } =
    useSelector((state) => ({
      errorFields: state.adminGroup.errorFields,
      checked: state.adminGroup.checked,
      saved: state.adminGroup.saved,
      allPermissions: state.admin.allPermissionList, //state.admin.cmsAllPermissions
      oneAdminGroup: state.adminGroup.oneAdminGroup,
    }));
  const [name, setName] = useState();
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    if (checked === CheckStatus.checkedWithSuccess) {
      dispatch(
        createAction('adminGroup/updateOrCreate')({
          data: { name, permissions },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, checked, name, permissions]);

  useEffect(() => {
    if (saved === SavedStatus.savedWithSuccess) {
      console.log('@@45: ', oneAdminGroup);
      onConfirm(oneAdminGroup);
      setName('');
      dispatch({
        type: 'adminGroup/updateState',
        payload: {
          saved: SavedStatus.init,
          checked: CheckStatus.initOrNotChecked,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onConfirm, saved]);

  const otherBody = () => {
    return (
      <>
        <div className="new-admin-other-body">
          <CustomTitleWithInput
            title={'Name'}
            defaultValue={name}
            setValue={setName}
            error={{
              id: 'name',
              error: errorFields?.fields?.includes('name'),
              message: errorFields?.messages?.map((item) => {
                if (item.field === 'name') {
                  return AdminGroupErrorHandleFields[item.field][
                    item.errorType
                  ];
                }
                return undefined;
              }),
            }}
          />
          <BaseMultipleSelectorV2
            title={'Assign permissions(optional)'}
            tips={
              'The word “Edit” in below permission items covered create, edit, delete, duplicate permission; while for edit campaign and edit message permissions, they only covered delete and duplicate, create and edit has separate permissions.'
            }
            size={['shorter']}
            data={{
              sourceData: allPermissions,
              targetData: permissions,
              targetChange: setPermissions,
            }}
          />
        </div>
      </>
    );
  };

  return (
    <BasePrompt
      show={props.show}
      closeAction={() => {
        onClose();
        setPermissions([]);
      }}
      rightButton={{
        text: 'Save',
        action: () =>
          dispatch({
            type: 'adminGroup/checkValsValid',
            payload: { name, permissions },
          }),
      }}
      title={'Add Permission Groups'}
      description={
        'Created here will be updated to Permission Groups synchronously'
      }
      otherBody={otherBody}
    />
  );
}

export default AddNewAdminGroupPrompt;
