import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import AuthButton from 'components/base/AuthButton';
import ContentSections from 'components/base/ContentSections';
import CustomBreadcrumb from 'components/base/CustomBreadcrumb';
import CustomListComponent from 'components/base/CustomListComponent';

import { PermissionCodes } from 'config/PermissionCodes';

import './CustomerOverviewDetail.scss';
import PersonalInformation from 'components/customerDetail/PersonalInformation';
import PurchaseItems from 'components/customerDetail/PurchaseItems';
import CouponSection from 'components/customerDetail/CouponSection';
import MembershipSection from 'components/customerDetail/MembershipSection';
import CustomerAdditionalData from 'components/customer/additionalData/CustomerAdditionalData';
import Loading from 'components/base/Loading';
import CustomerEarningRuleList from 'components/customerDetail/CustomerEarningRuleList';
import { getHashKeyString } from 'utils';
import CustomerRewardList from 'components/customerDetail/CustomerRewardList';
import CustomerExclusiveCampaignList from 'components/customerDetail/CustomerExclusiveCampaignList';
import CustomerStampCampaignList from 'components/customerDetail/CustomerStampCampaignList';
import CustomerPointsAndLevelList from 'components/customerDetail/CustomerPointsAndLevelList';
import { CUSTOMER_DETAIL_TAB_NAME } from 'config/CustomEnums';


function CustomerDetail({
  customer,
  customerProfileLoading,
}) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const id = params.id;

  const activeTab = getHashKeyString(location.hash) || CUSTOMER_DETAIL_TAB_NAME.profile;

  const setActiveTab = (key) => {
    history.push({ pathname: location.pathname, hash: key, search: "" });
  }

  const tabs = [
    {
      name: CUSTOMER_DETAIL_TAB_NAME.profile,
      content: (
        <div className="scroll-container-common">
          <ContentSections
            sections={
              customerProfileLoading
                ? [<Loading />]
                : [
                  <PersonalInformation
                    setActiveTab={setActiveTab}
                  />,
                  <PurchaseItems />,
                  <CouponSection
                    setActiveTab={setActiveTab}
                  />,
                  <MembershipSection />,
                  <CustomerAdditionalData />,
                ]}
            hidePreview={true}
          />
        </div>
      )
    },
    {
      name: CUSTOMER_DETAIL_TAB_NAME.exclusiveCampaign,
      content: (
        <CustomerExclusiveCampaignList />
      )
    },
    {
      name: CUSTOMER_DETAIL_TAB_NAME.stampCampaign,
      content: (
        <CustomerStampCampaignList />
      )
    },
    {
      name: CUSTOMER_DETAIL_TAB_NAME.earningRules,
      content: (
        <CustomerEarningRuleList />
      )
    },
    {
      name: CUSTOMER_DETAIL_TAB_NAME.coupons,
      content: (
        <CustomerRewardList />
      )
    },
    {
      name: CUSTOMER_DETAIL_TAB_NAME.pointAndLevel,
      content: (
        <CustomerPointsAndLevelList />
      )
    }
  ];

  const buttons = [
    customer.isDeleted ? null : (
      <AuthButton
        title="Edit"
        action={() => {
          dispatch({ type: 'customerList/clearData' });
          history.push({
            pathname: 'edit/',
          });
        }}
        requires={PermissionCodes.changeCustomer}
      />
    ),
  ];

  useEffect(() => {
    dispatch({ type: 'customerList/clearData' });
    dispatch({ type: 'couponTransactions/clearData' });
    dispatch({ type: 'transactions/clearData' });
    dispatch({ type: 'earningRuleList/clearData' });
    dispatch({ type: 'singleCoupon/clearData'});
    dispatch({ type: 'campaignList/clearData' });
    dispatch({
      type: 'customerList/getAllCustomerGroups',
      payload: {},
    })
    dispatch({ type: 'levels/getLevelList' });
  }, [dispatch]);

  useEffect(() => {
    dispatch({
      type: 'customerList/getOneCustomer',
      payload: { id },
    });
    dispatch({
      type: 'customerList/getOneCustomerBehavioralData',
      payload: { id },
    });
  }, [dispatch, id]);

  return (
    <div className="customer">
      <CustomListComponent
        caution={{
          detail: '',
          title: customer.name,
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb name={customer.name} />}
        buttons={buttons}
        tabs={tabs}
        onTabChange={(key) => {
          if (!customer?.pk) {
            return;
          };
          setActiveTab(key);
        }}
      />
    </div>
  );
}

const mapPropsToState = (state) => ({
  customer: state.customerList.customer,
  customerProfileLoading: state.customerList.customerProfileLoading,
});

export default connect(mapPropsToState)(CustomerDetail);
