import {
  getStampRecord,
  getStampRecords,
} from '../services/StampRecordsApiHelper';
import {
  convertCursorToNumber,
  convertNumberToCursor,
  createAction,
} from '../utils';
import { getDisplayDate } from '../utils/TimeFormatUtil';
import { createModel } from './BaseModel';
import { loading } from './LoadingUtil';

const eventType = {
  ADD_STAMP: 'Add Stamp',
  REMOVE_STAMP: 'Remove Stamp',
};
const recordType = {
  CUSTOMER_EARN: 'Customer Earn',
  ADMIN_REMOVE: 'Admin Remove',
  ADMIN_ADD: 'Admin Add',
  STAMP_EXPIRED: 'Stamp Expired',
};

function getInitState() {
  return {
    listDisplayFields: [
      { displayName: 'ID', fieldName: 'pk', linked: true },
      {
        displayName: 'Name (preferred name)',
        fieldName: 'name',
        linked: false,
        orderField: 'ownerFirstName',
      },
      {
        displayName: 'Membership ID',
        fieldName: 'membershipId',
        orderField: 'membershipId',
        linked: true,
      },
      { displayName: 'Event Type', fieldName: 'displayEventType' },
      {
        displayName: 'Quantity',
        fieldName: 'quantity',
        orderField: 'quantity',
      },
      { displayName: 'Remark', fieldName: 'remark' },
      {
        displayName: 'Create at',
        fieldName: 'creationDate',
        orderField: 'creationDate',
      },
    ],
    maxQuantityValue: 0,
  };
}

function parseStampRecord(item) {
  const name = item.owner?.nickname
    ? item.owner.nickname
    : `${item.owner.firstName} ${item.owner.lastName}`;
  return {
    pk: item.pk,
    id: item.id,
    name: name,
    membershipId: item.owner?.membershipId,
    customerId: item.owner?.pk,
    customerIsReset: item.owner?.isReset,
    recordType: item.recordType,
    displayRecordType: recordType[item.recordType],
    eventType: item.eventType,
    displayEventType: eventType[item.eventType],
    quantity: item.stampQuantity,
    remark: item.remark,
    creationDate: getDisplayDate(item.creationDate),
  };
}

export default createModel({
  namespace: 'stampRecord',
  states: getInitState(),
  params: {
    objectKey: 'stampRecords',
    pkNode: 'StampRecordNode',
    detailAPI: getStampRecord,
    parseDetail: (data) => parseStampRecord(data?.stampRecord),
    initState: getInitState(),
  },
  reducers: {},
  effects: {
    getCurrentPageStampRecords: [
      function* ({ payload }, { call, put }) {
        const { page } = payload;
        let afterCursor = undefined;
        if (page > 1) {
          afterCursor = convertNumberToCursor((page - 1) * 20 - 1);
        }
        const serviceArgs = [getStampRecords, afterCursor, payload];
        function* onSuccess(data) {
          const stampRecordData = data.stampRecords?.edges;
          const pageInfo = data.stampRecords?.pageInfo;
          const totalCount = data.stampRecords.totalCount;
          const startCursor = convertCursorToNumber(pageInfo.startCursor);
          const endCursor = convertCursorToNumber(pageInfo.endCursor);
          const stampRecordDataList = stampRecordData.map((item) =>
            parseStampRecord(item.node),
          );
          yield put(
            createAction('updateState')({
              pagedList: stampRecordDataList,
              pageInfo: {
                startCursor: isNaN(startCursor) ? 0 : startCursor + 1,
                endCursor: isNaN(endCursor) ? 0 : endCursor + 1,
              },
              totalCount,
              totalPage: Math.ceil(totalCount / 20),
              maxQuantityValue: data.stampRecords.maxStampQuantity,
            }),
          );
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
});
