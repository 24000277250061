import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import queryString from 'query-string';

import { ActionsDropdownForItem } from 'components/base/ActionsDropdown';
import AuthButton from 'components/base/AuthButton';
import CustomBreadcrumb from 'components/base/CustomBreadcrumb';
import CustomListComponent from 'components/base/CustomListComponent';
import DeletePrompt from 'components/base/DeletePrompt';
import ListButtonsGroup from 'components/base/ListButtonsGroup';
import BasePrompt from 'components/base/prompt/BasePrompt';
import CustomerOverview from 'components/customer/CustomerOverview';
import Filter from 'components/customer/Filter';
import DateRangeCompare from 'components/dashboard/DateRangeCompare';
import DateRangeSelection from 'components/dashboard/DateRangeSelection';
import { APIStatus, DELETE_RELATED_SECTIONS, StatusTag } from 'config/CustomEnums';
import { NavBarNames } from 'config/NavBarNameList';
import { PermissionCodes } from 'config/PermissionCodes';
import BaseListContainer from 'containers/base/BaseListContainer';
import BaseTabListContainer from 'containers/base/BaseTabListContainer';
import { ImportResource } from 'models/DownloadImportModel';

import { createAction, getHashKeyString } from 'utils';

import './CustomerList.scss';
import CustomFileUploader from 'components/base/CustomFileUploader';
import CustomSwitchButton from 'components/base/CustomSwitchButton';
import { CustomTitleLabel } from 'components/earning/CustomBaseComponments';
import CustomTitleWithInput from 'components/base/CustomTitleWithInput';
import AuthButtonWithDropdown from 'components/base/AuthButtonWithDropdown';
import { CUSTOMER_TEMPLATE_FILE_PATH } from 'services/CustomerAPIHelper';
import ExportRangeModal from 'components/base/ExportRangeModal';

const TAB_OVERVIEW = 'Overview';
const TAB_LIST_OF_CUSTOMER = 'List of Customers';
const TAB_REPORTS = 'Reports';

function CustomerList({
  listFields,
  dataList,
  pageInfo,
  totalPage,
  totalCount,
  checkedList,
  importedFile,
  importedFileName,
  importStatus,
  isSuperuser,
  userPermissions,
  hasDashboardPermission,
  importError,
  reportListDisplayFields,
  customerReportList,
}) {
  const exportResource = {
    customers: "Customers",
    reports: "Reports"
  }
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [exportPrompt, setExportPrompt] = useState({
    show: false,
    exportResource: ''
  });
  const [isExportAll, setIsExportAll] = useState(true);
  const [lastImportFailedType, setLastImportFailedType] = useState();
  const [showImportModal, setShowImportModal] = useState(false);
  const [isImportPreActiveCustomer, setIsImportPreActiveCustomer] = useState(false);
  const [showCreateCustomerGroup, setShowCreateCustomerGroup] = useState(false);
  const [newCustomerGroupName, setNewCustomerGroupName] = useState('');
  const [newCustomerGroupDescription, setNewCustomerGroupDescription] = useState('');

  const activeTab = getHashKeyString(location.hash) || (hasDashboardPermission ? TAB_OVERVIEW : TAB_LIST_OF_CUSTOMER);

  const handleImportClick = ({ isImportNewCustomer = false }) => {
    setIsImportPreActiveCustomer(isImportNewCustomer)
    setShowImportModal(true)
  };

  const closeImportModal = () => {
    setShowImportModal(false)
    setShowCreateCustomerGroup(false)
    setNewCustomerGroupDescription('')
    setNewCustomerGroupName('')
    setIsImportPreActiveCustomer(false)
    dispatch(
      createAction('uploadFiles/updateState')({
        importedFile: '',
        importedFileName: '',
      }),
    );
  }

  const handleImportCustomer = () => {
    dispatch(createAction('downloadAndImport/importFile')({
      from: isImportPreActiveCustomer ? ImportResource.customerPreActive : ImportResource.customer,
      file: importedFile,
      related: showCreateCustomerGroup
        ? {
          customerGroupName: newCustomerGroupName,
          customerGroupDescription: newCustomerGroupDescription
        }
        : {},
      successAction: () => {
        closeImportModal()
      },
      failedAction: () => {
        setLastImportFailedType(isImportPreActiveCustomer ? ImportResource.customerPreActive : ImportResource.customer)
        closeImportModal()
      }
    }));
  };

  const handleExportTempalte = ()=>{
    var eleLink = document.createElement('a');
    eleLink.href = CUSTOMER_TEMPLATE_FILE_PATH;
    eleLink.style.display = 'none';
    document.body.appendChild(eleLink);
    eleLink.click();
    document.body.removeChild(eleLink);
  }
  const exportCustomersAction = () => {
    const parsedSearch = queryString.parse(location.search);
    const createDatePeriod = parsedSearch?.create_date?.split(',');
    const customerListIn = (checkedList || []).map((item) => item.pk);

    dispatch({
      type: 'downloadAndImport/createDownloadTask',
      payload: {
        from: ImportResource.customer,
        ...(activeTab === TAB_LIST_OF_CUSTOMER && !isExportAll ? {
          related: {
            groupsIn: parsedSearch?.customer_groups?.split(','),
            segmentsIn: parsedSearch?.segments?.split(','),
            levelsIn: parsedSearch?.levels?.split(','),
            ageRange: parsedSearch?.age?.split(','),
            genderIn: parsedSearch?.gender?.split(','),
            dateOfBirthGte: parsedSearch?.birthday_start_date,
            dateOfBirthLte: parsedSearch?.birthday_end_date,
            creationDateGte: createDatePeriod?.[0],
            creationDateLte: createDatePeriod?.[1],
            nameOrMobileOrEmail: parsedSearch?.search,
            customerListIn: customerListIn,
            statusIn: parsedSearch?.status?.split(','),
            acquisitionChannel: parsedSearch?.acquisition_channel,
            additionalData: parsedSearch?.additional_data,
            birthMonth: parsedSearch?.birth_month?.split(','),
          }
        } : {})
      },
    });
  };

  const exportReportAction = () => {
    const searchDict = queryString.parse(location.search);
    const reportType = searchDict['report_type'];
    const timeRange = searchDict['time_range']?.split(',');
    const nameIcontains = searchDict['search'];
    dispatch(
      createAction('downloadAndImport/createDownloadTask')({
        from: ImportResource.customerReport,
        ...(isExportAll || activeTab !== TAB_REPORTS
          ? {}
          : {
            related: {
              nameIcontains,
              reportType,
              timeRangeGte: timeRange?.[0],
              timeRangeLte: timeRange?.[1],
            },
          }),
      }),
    );
  };
  const buttons = [
    <ListButtonsGroup
      hideExtraButtonWidth={500}
      extraButtons={[
        <AuthButton
          customClass="btn-back-button-common btn-download"
          title={'Download Import & Batch Update Form'}
          action={handleExportTempalte}
          requires={PermissionCodes.addExportjob}
        />,
        <AuthButtonWithDropdown
          className='btn-download'
          permissionRequires={PermissionCodes.addExportjob}
          title={'Export'}
          options={[
            {
              title: exportResource.customers,
              action: () => {
                setExportPrompt({
                  show: true,
                  exportResource: exportResource.customers,
                });
              }
            },
            {
              title: exportResource.reports,
              action: () => {
                setExportPrompt({
                  show: true,
                  exportResource: exportResource.reports,
                });
              }
            },
          ]}
        />,
        <AuthButton
          customClass="btn-back-button-common btn-download"
          title="Batch Update"
          action={() => {
            handleImportClick({
              isImportNewCustomer: false,
            });
          }}
          requires={PermissionCodes.addImportjob}
        />,
        <AuthButton
          customClass="btn-further"
          title="Import New Customers"
          action={() => {
            handleImportClick({
              isImportNewCustomer: true,
            });
          }}
          requires={PermissionCodes.addImportjob}
        />,
      ]}
      extraPopContent={[
        {
          requires: PermissionCodes.addExportjob,
          action: handleExportTempalte,
          content: `Download Import & Batch Update Form`,
        },
        {
          requires: PermissionCodes.addExportjob,
          action: () => {
            setExportPrompt({
              show: true,
              exportResource: exportResource.customers,
            });
          },
          content: `Export ${exportResource.customers}`,
        },
        {
          requires: PermissionCodes.addExportjob,
          action: () => {
            setExportPrompt({
              show: true,
              exportResource: exportResource.reports,
            });
          },
          content: `Export ${exportResource.reports}`,
        },
        {
          requires: PermissionCodes.addImportjob,
          action: () => {
            handleImportClick({
              isImportNewCustomer: false,
            });
          },
          content: 'Batch Update',
        },
        {
          requires: PermissionCodes.addImportjob,
          action: () => {
            handleImportClick({
              isImportNewCustomer: true,
            });
          },
          content: 'Import New Customers',
        },
      ]}
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.deleteCustomer,
    },
  ];

  const reportTypeTabs = [
    { key: 'all', name: 'All Types' },
    { key: 'WEEKLY', name: 'Weekly' },
    { key: 'MONTHLY', name: 'Monthly' },
  ];

  const tabs = [
    hasDashboardPermission ? {
      name: TAB_OVERVIEW,
      content: <CustomerOverview />,
    } : {},
    ...(isSuperuser ||
      userPermissions?.filter(
        (val) => val.split('_')[1] === PermissionCodes.customer,
      ).length > 0
      ? [{
        name: TAB_LIST_OF_CUSTOMER,
        content: (
          <BaseTabListContainer
            hideTab={true}
            tabs={[]}
            groupActions={groupActions}
            pageInfo={pageInfo}
            totalCount={totalCount}
            searchPlaceholder={'Search by name, membership ID, mobile number or email'}
            filter={{
              hasFilter: true,
              component: Filter,
            }}
            listContent={
              <BaseListContainer
                fields={listFields}
                dataList={dataList}
                totalPage={totalPage}
                model={'customerList'}
                permissionGroup={PermissionCodes.customer}
                deleteInfo={{
                  data: [],
                  title: 'customers',
                  relatedName: '',
                  relatedSections: DELETE_RELATED_SECTIONS.CUSTOMER,
                  onComfirm: {},
                }}
                hasOtherActions={true}
                customActions={{
                  deactive: (item) => {
                    console.log('@@157: ', item);
                    dispatch({
                      type: 'customerList/updateCustomerActive',
                      payload: {
                        data: {
                          isForcedInactive: item.status === StatusTag.deactive,
                          id: item.pk,
                        },
                        afterAction: () => history.push(location),
                      }
                    });
                  },
                }}
              />
            }
          />
        ),
      },
      {
        name: TAB_REPORTS,
        content: (
          <BaseTabListContainer
            tabs={reportTypeTabs}
            tabSearchKey={'report_type'}
            pageInfo={pageInfo}
            totalCount={totalCount}
            searchPlaceholder={'Search by report name'}
            filter={{
              hasFilter: true,
              component: Filter,
            }}
            listContent={
              <BaseListContainer
                fields={reportListDisplayFields}
                dataList={customerReportList}
                totalPage={totalPage}
                model={'customerList'}
                permissionGroup={PermissionCodes.customer}
                deleteInfo={{
                  data: [],
                  title: '',
                  relatedName: '',
                  onComfirm: {},
                }}
                useCustomCommonActions={true}
                customCommonActions={(item) => {
                  const actions = [
                    {
                      name: 'Download',
                      requires: PermissionCodes.addExportjob,
                      href: item?.reportFileUrl
                    },
                  ];
                  return (
                    <ActionsDropdownForItem object={item} actions={actions} />
                  );
                }}
              />
            }
          />
        ),
      }]
      : []),
  ];

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  const importModalBody = () => {
    return (
      <div>
        <div className="d-flex flex-column">
          <CustomTitleLabel title={'Selected file'} />
          {importedFileName &&
            <div className='uploaded-file-name'>{importedFileName}</div>}
          <CustomFileUploader
            title={importedFile ? 'Re-upload file' : 'Upload file'}
            accept='.csv'
            showFileName={false}
            buttonClass={'btn-back-button-common upload-customer-btn'}
          />
        </div>
        {importedFile &&
          <div className="d-flex flex-column">
            <CustomTitleLabel title={'Create a customer list for further action'} />
            <div className='tips-message'>
              {'Please be expected that the import and the customer list creation process will \
              take some time depends on the customers amount and the current tasks in the system task. \
              The customer list, by default, will not be shown as “customer tag” in customer profile.'}
            </div>
            <CustomSwitchButton
              onChange={(open) => {
                setShowCreateCustomerGroup(open)
              }}
              defaultChecked={showCreateCustomerGroup}
            />
          </div>
        }
        {showCreateCustomerGroup &&
          <>
            <div>
              <CustomTitleWithInput
                title={'Customer list name'}
                customClass='width-400'
                defaultValue={newCustomerGroupName}
                setValue={setNewCustomerGroupName}
              />
            </div>
            <div className='d-flex flex-column'>
              <CustomTitleLabel title={'Description (optional)'} />
              <textarea
                className='custom-markdown-area-title'
                value={newCustomerGroupDescription}
                onChange={(e) => {
                  const { value } = e.target
                  setNewCustomerGroupDescription(value)
                }}
              />
            </div>
          </>}
      </div>
    )
  }

  useEffect(() => {
    const parsedSearch = queryString.parse(location.search);
    const searchKey = parsedSearch['search'] || '';
    const stringRank = parsedSearch['rank'] || 'true';
    const stringPage = parsedSearch['page'] || 1;
    const reportType = parsedSearch['report_type'];
    const timeRange = parsedSearch['time_range'];
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);
    console.log('@@202: ', parsedSearch);
    if (activeTab === TAB_LIST_OF_CUSTOMER) {
      dispatch({
        type: 'customerList/getPagedCustomers',
        payload: {
          ...parsedSearch,
          page,
          reverse: rank,
          search: searchKey,
          isListNode: true,
        },
      });
    }
    if (activeTab === TAB_REPORTS) {
      dispatch({
        type: 'customerList/getPagedCustomersReports',
        payload: {
          ...parsedSearch,
          reportType,
          timeRange,
          searchKey,
          rank,
          page,
        },
      });
    }
  }, [dispatch, location, activeTab]);

  const [showError, setShowError] = useState(false);

  useEffect(() => {
    console.log('@256', importError);
    setShowError(importError ? Object.keys(importError).length > 0 : false);
  }, [importError]);

  return (
    <>
      <CustomListComponent
        caution={{
          detail:
            'All the customers are listed here, and you can view their details and records. You can learn about the user usage of the app here.',
          title: NavBarNames.customer,
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={false}
        onTabChange={(key) => {
          // setActiveTab(key);
          history.push({ pathname: location.pathname, hash: key });
        }}
        renderCompare={() => {
          return activeTab === TAB_OVERVIEW ? <DateRangeCompare /> : null;
        }}
        renderTabsRight={() => {
          return activeTab === TAB_OVERVIEW ? (
            <DateRangeSelection
              onTab
              applyDateRangeEvent={(startDate, endDate) => {
                dispatch({
                  type: 'overview/getCustomersData',
                  payload: {
                    startDate: startDate.format('YYYY-MM-DD'),
                    endDate: endDate.format('YYYY-MM-DD'),
                  },
                });
              }}
              clearDataEvent={() => {
                dispatch({
                  type: 'overview/clearState',
                  payload: {},
                });
              }}
            />
          ) : null;
        }}
      />

      <DeletePrompt
        data={checkedList}
        title={'customers'}
        relatedName={''}
        relatedSections={DELETE_RELATED_SECTIONS.CUSTOMER}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: 'customerList/delete',
            payload: {
              afterAction: () => {
                history.push(location);
              },
            },
          });
        }}
      />
      <ExportRangeModal
        show={exportPrompt?.show}
        title={`Export ${exportPrompt?.exportResource || ''}`}
        closeAction={() => {
          setExportPrompt({
            show: false,
            exportResource: '',
          });
        }}
        rightButton={{
          text: 'Export',
          action: () => {
            if (exportPrompt?.exportResource === exportResource.reports) {
              exportReportAction()
            } else if (exportPrompt?.exportResource === exportResource.customers) {
              exportCustomersAction()
            }
            setExportPrompt({
              show: false,
              exportResource: '',
            });
          },
          requires: PermissionCodes.addExportjob,
        }}
        isExportAll ={isExportAll}
        setIsExportAll={setIsExportAll}
      />
      <BasePrompt
        customClass={'import-customer-modal'}
        show={showImportModal}
        title={
          isImportPreActiveCustomer
            ? 'Import new customers'
            : 'Batch update customer'
        }
        closeAction={closeImportModal}
        otherBody={importModalBody}
        leftButton={{
          text: 'Cancel',
          action: closeImportModal,
        }}
        rightButton={{
          text: 'Confirm',
          action: handleImportCustomer,
          disabled: !importedFile || importStatus === APIStatus.calling
        }}
      />

      <BasePrompt
        show={showError}
        title={importError?.title}
        description={importError?.content}
        closeAction={() => {
          setShowError(false);
          dispatch({
            type: 'downloadAndImport/updateState',
            payload: { error: {} },
          });
        }}
        leftButton={{
          text: 'Cancel',
          action: () => {
            setShowError(false);
            dispatch({
              type: 'downloadAndImport/updateState',
              payload: { error: {} },
            });
          },
        }}
        rightButton={{
          text: 'Import again',
          action: () => {
            handleImportClick({
              isImportNewCustomer:
                lastImportFailedType === ImportResource.customerPreActive,
            });
            setShowError(false);
            setLastImportFailedType();
            dispatch({
              type: 'downloadAndImport/updateState',
              payload: { error: {} },
            });
          },
          requires: PermissionCodes.addImportjob,
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  listFields: state.customerList.listDisplayFields,
  dataList: state.customerList.pagedCustomerList,
  pageInfo: state.customerList.pageInfo,
  totalPage: state.customerList.totalPage,
  totalCount: state.customerList.listTotalCount,
  checkedList: state.customerList.checkedList,
  importedFile: state.uploadFiles.importedFile,
  importedFileName: state.uploadFiles.importedFileName,
  isSuperuser: state.users.isSuperuser,
  hasDashboardPermission: state.users.hasDashboardPermission,
  userPermissions: state.users.userPermissions,
  importError: state.downloadAndImport.error,
  importStatus: state.downloadAndImport.status,
  reportListDisplayFields: state.customerList.reportListDisplayFields,
  customerReportList: state.customerList.pagedCustomerReportList,
});

export default connect(mapPropsToState)(CustomerList);
