import React, { useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { DropDownWithDefaultSelect } from 'components/base/ActionsDropdown';
import CustomRadios from 'components/base/CustomRadios';
import { hasReactHookFormError } from 'components/base/ErrorFieldMessage';
import { CustomTitleWithDropDown } from 'components/customer/CustomTitleWithDropDown';
import { CustomTitleLabel } from 'components/earning/CustomBaseComponments';

import InputWithMutipleDropDown from './InputWithMutipleDropDown';

import { createAction } from 'utils';

import BranchLine from 'assets/images/branchLinex3.png';

const ViewCampaignTypeEnum = {
  viewTimes: 'VIEW_TIMES',
  stayTimes: 'STAY_TIME',
};

function CampaignBehavioralSection({ title, groupName, fieldBaseName }) {
  const {
    watch,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();
  const viewTypeFieldName = `${fieldBaseName}.viewType`;
  const viewType = watch(viewTypeFieldName);
  const viewValueFieldName = `${fieldBaseName}.viewValue`;
  const viewedTimeTypeFieldName = `${fieldBaseName}.viewedTimeType`;
  const viewedTimeType =
    watch(viewedTimeTypeFieldName) || ViewCampaignTypeEnum.viewTimes;
  const viewTimesFieldName = `${fieldBaseName}.viewTimes`;
  let campaignType = '';
  if (groupName === 'couponCampaignCharacteristic') {
    campaignType = 'COUPON_CAMPAIGN';
  } else if (groupName == 'earningCampaignCharacteristic') {
    campaignType = ['EARNING_CAMPAIGN', 'STAMP_CAMPAIGN'];
  }
  const defaultCampaignFilter = { isActive: true, type: campaignType };

  const { campaignList, campaignCategoryList } = useSelector((state) => ({
    campaignList: state.campaignList.allList,
    campaignCategoryList: state.campaignCategoryList.categoryList,
  }));

  const dropDownOptions = (values, setAction) => {
    return values.map((value) => ({
      name: value,
      action: () => {
        setAction(value);
      },
    }));
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      createAction('campaignList/getCampaignList')({
        isSelectorLoad: true,
        ...defaultCampaignFilter,
      }),
    );
    dispatch(
      createAction('campaignCategoryList/getCampaignCategoryList')({
        isSelectorLoad: true,
        isActive: true,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <CustomTitleLabel title={title} />
      <div className="d-flex flex-column">
        <div style={{ display: 'flex' }}>
          <Controller
            name={viewTypeFieldName}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <DropDownWithDefaultSelect
                actions={dropDownOptions(
                  ['Campaign', 'Campaign category'],
                  (value) => {
                    setValue(field.name, value, {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                    setValue(viewValueFieldName, null, { shouldDirty: true });
                  },
                )}
                defaultValue={viewType}
                className={
                  hasReactHookFormError(errors, field.name) ? 'error-field' : ''
                }
              />
            )}
          />

          <label className="content-campaign-related-to margin-right-10-percent">
            related to
          </label>
          {viewType === 'Campaign category' ? (
            <Controller
              name={viewValueFieldName}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomTitleWithDropDown
                  setValue={(value) => {
                    const { label, value: choiceValue } = value;
                    if (choiceValue) {
                      const { id, pk } = choiceValue;
                      setValue(
                        field.name,
                        { value: { id, pk }, label },
                        { shouldDirty: true, shouldValidate: true },
                      );
                    } else {
                      setValue(field.name, null, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    }
                  }}
                  source={campaignCategoryList}
                  labelContainPk={false}
                  defaultValue={field.value}
                  loadMoreAction={
                    'campaignCategoryList/getCampaignCategoryList'
                  }
                  filterAction={'campaignCategoryList/getCampaignCategoryList'}
                  defaultFilter={{ isActive: true }}
                  customClass={
                    hasReactHookFormError(errors, field.name)
                      ? 'error-field'
                      : ''
                  }
                />
              )}
            />
          ) : (
            <Controller
              name={viewValueFieldName}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomTitleWithDropDown
                  setValue={(value) => {
                    const { label, value: choiceValue } = value;
                    if (choiceValue) {
                      const { id, pk, name } = choiceValue;
                      setValue(
                        field.name,
                        { value: { id, pk, name }, label },
                        { shouldDirty: true, shouldValidate: true },
                      );
                    } else {
                      setValue(field.name, null, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    }
                  }}
                  source={campaignList}
                  labelContainPk
                  defaultValue={{
                    value: field.value?.value,
                    label: field.value?.value?.pk
                      ? `[ID: ${field.value?.value?.pk}] ${field.value?.value?.name}`
                      : field.value?.value?.name,
                  }}
                  loadMoreAction={'campaignList/getCampaignList'}
                  filterAction={'campaignList/getCampaignList'}
                  defaultFilter={defaultCampaignFilter}
                  customClass={
                    hasReactHookFormError(errors, field.name)
                      ? 'error-field'
                      : ''
                  }
                />
              )}
            />
          )}
        </div>
        <div className="display-flex-area">
          <Image src={BranchLine} className="content-campaign-branchLine" />
          {groupName === 'viewCampaignCharacteristic' ? (
            <div className="d-flex flex-column">
              <div className="display-flex-area create-section-label create-section-label-bottom-space ">
                <Controller
                  name={viewedTimeTypeFieldName}
                  control={control}
                  render={({ field }) => (
                    <CustomRadios
                      labelClass="font-weight"
                      onChange={(value) => {
                        setValue(field.name, value, { shouldDirty: true });
                        setValue(
                          viewTimesFieldName,
                          { number: null },
                          { shouldDirty: true },
                        );
                      }}
                      default={viewedTimeType}
                      options={[
                        {
                          label: 'Number of times viewing the campaign',
                          value: ViewCampaignTypeEnum.viewTimes,
                        },
                        {
                          label: 'Period of time staying in the campaign',
                          value: ViewCampaignTypeEnum.stayTimes,
                        },
                      ]}
                    />
                  )}
                />
              </div>
              {viewedTimeType === ViewCampaignTypeEnum.viewTimes ? (
                <InputWithMutipleDropDown
                  fieldBaseName={viewTimesFieldName}
                  hasNumber
                  hasPeriodDropdown
                />
              ) : (
                <InputWithMutipleDropDown
                  fieldBaseName={viewTimesFieldName}
                  hasNumber
                  rangeOptions={['Mins', 'H']}
                  hasPeriodDropdown={false}
                />
              )}
            </div>
          ) : (
            <div className="d-flex flex-column">
              {/* <CustomNumberInputWithUnit
                title={'Number of times participating the campaign'}
                unit={'times'}
                setValue={setViewTimes}
                defaultValue={viewTimes}
              /> */}
              <CustomTitleLabel
                title={'Number of completed earning activities'}
              />
              <InputWithMutipleDropDown
                fieldBaseName={viewTimesFieldName}
                hasNumber
                hasPeriodDropdown={
                  viewedTimeType === ViewCampaignTypeEnum.viewTimes
                }
                // defaultNumber={defaultValue.viewTimes}
                // defaultRange={defaultValue.periodRange}
                // defaultPeriod={defaultValue.periodDays}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CampaignBehavioralSection;
