import {
  getGroups,
  deleteCustomerGroups,
  duplicateCustomerGroup,
  getCustomerGroup,
  createCustomerGroup,
  updateCustomerGroup,
} from '../services/CustomerGroupApiHelper';
import { apiWithResponseHandle, loading } from './LoadingUtil';
import {
  convertNumberToCursor,
  convertCursorToNumber,
  convertPKToId,
  saveToSessionStorage,
  getObjectFromSessionStorage,
  removeFromSessionStorage,
} from '../utils';
import { formatDate, TimeFormater } from '../utils/TimeFormatUtil';
import { createModel } from './BaseModel';


export const createCustomerGroupError = {
  generalName: {
    name: 'generalName',
    message: 'Please provide a customer list name.',
  },
};

export const sessionDataKey = {
  objectKey: 'createCustomerGroup',
  stepEndKey: 'createCustomerGroupStepEnd',
  origionalData: 'createCustomerGroupOriginalData',
};

const parseCustomerGroup = (item) => {
  const customers = item.customers?.edges?.map((item) => {
    const customerData = item.node;
    return {
      pk: customerData.pk,
      id: customerData.id,
      name: `${customerData.firstName} ${customerData.lastName}`,
      owner: `${customerData.firstName} ${customerData.lastName}`,
      firstName: customerData.firstName,
      lastName: customerData.lastName,
      ssoUid: customerData.ssoUid,
    };
  });
  return {
    ...item,
    displayCreateDate: formatDate(item.creationDate, TimeFormater.dayMonthWeek),
    customers: customers,
    displayShowInCustomerProfile: item.showInCustomerProfile ? "Yes" : "No"
  };
};

const parseDetailCustomerGroup = (item) => {
  const customers = item.members?.map((item) => {
    const membershipId = item?.[1];
    const firstName = item?.[2];
    const lastName = item?.[3];
    return {
      label: `[${membershipId}] ${firstName} ${lastName}`,
      value: {
        pk: parseInt(item?.[0]),
        id: parseInt(item?.[0]),
        name: `${firstName} ${lastName}`,
        owner: `${firstName} ${lastName}`,
        firstName: firstName,
        lastName: lastName,
      }
    };
  });
  return {
    ...item,
    displayCreateDate: formatDate(item.creationDate, TimeFormater.dayMonthWeek),
    customers: customers,
    displayShowInCustomerProfile: item.showInCustomerProfile ? "Yes" : "No"
  };
};

const parseCustomerGroupList = (data) => {
  return data.map((item) => {
    return parseCustomerGroup(item.node);
  });
};

const parseInputBody = (customerGroup) => {
  let inputBody = {
    name: customerGroup.name,
    description: customerGroup.description,
    customers: [],
    showInCustomerProfile: customerGroup.showInCustomerProfile || false,
  };

  let customers = customerGroup.customers;
  if (customers && customers.length > 0) {
    customers = customers.map((item) => item?.value ? item?.value?.pk : item?.pk)
    inputBody.customers = customers;
  }

  return inputBody;
};

const getInitialState = () => ({
  groupList: [],
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Name', fieldName: 'name', orderField: 'name' },
    { displayName: 'Show in Customer profile', fieldName: 'displayShowInCustomerProfile' },
    {
      displayName: 'Create date',
      fieldName: 'displayCreateDate',
      orderField: 'creationDate',
    },
    { displayName: 'Description', fieldName: 'description', isRichText: true },
  ],
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  currentLastCursor: '',
  currentPage: 0,
  totalPage: 0,
  totalCount: 0,
  checkedList: [],
  currentPageGroupList: [],
  selectedCustomerGroup: {},
  errorFields: [],
  formChanged: false,
  hasUpdatedDefaultValues: false,
  formHasSubmitted: false,
});


export default createModel({
  namespace: 'customerGroup',
  states: getInitialState(),
  params: {
    listAPI: getGroups,
    objectKey: 'customerGroups',
    parse:(data)=>{
      return data.customerGroups.edges.map(item=>item.node)
    },
  },
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    loadCustomerGroupFromSession(state) {
      const customerGroup = getObjectFromSessionStorage(sessionDataKey.objectKey);
      if (!customerGroup) {
        return state;
      }
      saveToSessionStorage(sessionDataKey.origionalData, customerGroup);
      saveToSessionStorage(sessionDataKey.objectKey, customerGroup);
      return {
        ...state,
        selectedCustomerGroup: customerGroup,
        hasUpdatedDefaultValues: true,
      };
    },
    saveOrRemoveCustomerGroupFromCookie(state, { payload }) {
      if (payload.save) {
        if (payload.values) {
          saveToSessionStorage(sessionDataKey.objectKey, payload.values);
        }
      } else {
        removeFromSessionStorage(sessionDataKey.objectKey);
        removeFromSessionStorage(sessionDataKey.origionalData);
      }
      saveToSessionStorage(sessionDataKey.stepEndKey, true);
      return {
        ...state,
      };
    },
    clearData(state, { payload }) {
      return {
        ...state,
        ...getInitialState(),
      };
    },
  },
  effects: {
    *setFieldToSession({ payload }, { select }) {
      const oldCustomerGroup = yield select(
        (state) => state.customerGroup.selectedCustomerGroup,
      );
      const customerGroup = { ...oldCustomerGroup, ...payload };
      saveToSessionStorage(sessionDataKey.objectKey, customerGroup);
    },
    getGroupList: [
      function* ({ payload }, { call, select, put }) {
        const serviceArgs = [getGroups, null, { rank:false, search:'', isAll:true, isSimpleList: payload.isSimpleList }];
        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: {
              groupList: parseCustomerGroupList(data?.customerGroups.edges),
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getCurrentPageList: [
      function* ({ payload }, { put }) {
        const { page, rank, search, moreSearch } = payload;
        let afterCursor = '';
        if (page > 1) {
          afterCursor = convertNumberToCursor((page - 1) * 20 - 1);
        }
        const serviceArgs = [
          getGroups,
          afterCursor,
          {rank,
          search,
          isAll:false,
          ...moreSearch},
        ];
        function* onSuccess(data) {
          const groupListData = data.customerGroups.edges;
          const pageInfo = data.customerGroups.pageInfo;
          const totalCount = data.customerGroups.totalCount;
          const currentLastCursor = pageInfo.endCursor;
          const groupList = groupListData.map((group) =>
            parseCustomerGroup(group.node),
          );
          yield put({
            type: 'updateState',
            payload: {
              currentPageGroupList: groupList,
              pageInfo: {
                startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
              },
              currentLastCursor,
              totalCount,
              totalPage: Math.ceil(totalCount / 20),
            }
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getCustomerGroup: [
      function* ({ payload }, { put }) {
        const { id } = payload;
        const customerGroupId = convertPKToId('CustomerGroupNode', id);
        const serviceArgs = [getCustomerGroup, customerGroupId];
        function* onSuccess(data) {
          const customerGroupData = data.customerGroup;
          const customerGroup = parseDetailCustomerGroup(customerGroupData);
          saveToSessionStorage(sessionDataKey.objectKey, customerGroup);
          saveToSessionStorage(sessionDataKey.origionalData, customerGroup);
          yield put({
            type: 'updateState',
            payload: {
              selectedCustomerGroup: customerGroup,
              hasUpdatedDefaultValues: true,
            }
          });
          const afterAction = payload.afterAction || (() => {});
          yield afterAction(customerGroup);
        }
        yield loading(serviceArgs, onSuccess);
      },
    ],
    delete: [
      function* ({ payload }, { select, put }) {
        const checkedList = yield select(
          (state) => state.customerGroup.checkedList,
        );
        const ids = checkedList.map((item) => item.pk);
        const serviceArgs = [deleteCustomerGroups, ids];
        function* onSuccess() {
          yield put({
            type: 'updateState',
            payload: {
              checkedList: [],
            },
          });
          const afterAction = payload.afterAction || (() => {});
          yield afterAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    duplicate: [
      function* ({ payload }, { select, put }) {
        const serviceArgs = [duplicateCustomerGroup, payload.data.pk];
        const afterAction = payload.afterAction || (() => {});
        function* onSuccess() {
          yield afterAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    createCustomerGroup: [
      function* ({ payload }, { select, put }) {
        const { values } = payload;
        const inputBody = parseInputBody(values);
        const serviceArgs = [createCustomerGroup, inputBody];
        saveToSessionStorage(sessionDataKey.stepEndKey, true);
        function* onSuccess() {
          yield put({
            type: 'saveOrRemoveCustomerGroupFromCookie',
            payload: false,
          });
          yield put({
            type: 'updateState',
            payload: {
              formHasSubmitted: true,
            },
          });
          const afterAction = payload.afterAction || (() => {});
          yield afterAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    updateCustomerGroup: [
      function* ({ payload }, { select, put }) {
        const customerGroup = yield select(
          (state) => state.customerGroup.selectedCustomerGroup,
        );
        const { values } = payload;
        const inputBody = parseInputBody(values);
        inputBody.id = customerGroup.pk;
        const serviceArgs = [updateCustomerGroup, inputBody];
        function* onSuccess() {
          yield put({
            type: 'saveOrRemoveCustomerGroupFromCookie',
            payload: false,
          });
          yield put({
            type: 'updateState',
            payload: {
              formHasSubmitted: true,
            },
          });
          const afterAction = payload.afterAction;
          yield afterAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
    ],
  },
});


