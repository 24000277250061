import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import BaseMultipleSelectorV2 from 'components/base/BaseMultipleSelectorV2';
import { FieldControl } from 'components/base/CommonComponent';
import CustomDateTimeSelect from 'components/base/CustomDateTimeSelect';
import CustomSwitchButton from 'components/base/CustomSwitchButton';
import SpecificCustomSwitchButton from '../../containers/merchants/stores/SpecificCustomSwitchButton';
import CustomTitleWithSwitch from 'components/base/CustomTitleWithSwitch';
import {
  ReactHookFormErrorMessage,
  hasError,
} from 'components/base/ErrorFieldMessage';
import BasePrompt from 'components/base/prompt/BasePrompt';
import { CustomTitleLabel } from 'components/earning/CustomBaseComponments';
import { MessageChannel } from 'config/CustomEnums';
import { PermissionCodes } from 'config/PermissionCodes';

const MessageNewPromptType = {
  segments: 'segments',
  customerGroup: 'customer groups',
  none: 'none',
};

function MessageSettingSection({ customerGroupList, segmentList }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [createNewType, setCreateNewType] = useState(MessageNewPromptType.none);
  const [refreshDate, setRefreshDate] = useState(new Date());

  const { watch, setValue, formState } = useFormContext();
  const { errors } = formState;

  const targetCustomerGroup = watch('targetCustomerGroup') || [];
  const targetedSegments = watch('targetedSegments') || [];
  const scheduledDate = watch('scheduledDate');
  const channels = watch('channels');
  const sendToAll = watch('sendToAll');
  const recipientsFollowCampaign = watch('recipientsFollowCampaign')
  const watchIsDirectMarketing = watch('isDirectMarketing');
  const relatedCampaign = watch('relatedCampaign');

  useEffect(() => {
    dispatch({
      type: 'customerGroup/getAllList',
      payload: { isAll: true },
    });
    dispatch({
      type: 'segments/getAllList',
      payload: {},
    });
  }, [refreshDate]);

  useEffect(() => {
    if (!channels?.includes(MessageChannel.email)) {
      setValue('isDirectMarketing', false, { shouldDirty: true });
    }
  }, [channels]);

  useEffect(() => {
    if (!relatedCampaign?.isExclusive) {
      setValue('recipientsFollowCampaign', false, { shouldDirty: true });
    }
  }, [relatedCampaign]);

  const getCreateNewPromptContent = () => {
    let title = 'segment';
    let button = 'Segment';
    let pathname = '/segments/create/';
    if (createNewType === MessageNewPromptType.customerGroup) {
      title = 'customer list';
      button = 'Customer List';
      pathname = '/customer_group/create';
    }
    return {
      title: `Go to create ${title} ?`,
      description: `You will leave message creation process.`,
      button: `Go to Create ${button}`,
      action: () => {
        window.open(pathname, '_blank');
      },
    };
  };

  console.log("message campaign:", relatedCampaign)

  return (
    <>
      <label className="create-section-title">{'SETTING'}</label>
      {channels?.find((item) => item === MessageChannel.push) && !relatedCampaign?.isExclusive ? (
        <>
          <CustomTitleLabel title="Send to every customer (including non-member)" />
          <SpecificCustomSwitchButton
            checked={sendToAll}
            defaultChecked={sendToAll}
            onChange={(checked) => {
              setValue('sendToAll', checked, { shouldDirty: true });
              if (checked) {
                setValue('recipientsFollowCampaign', !checked, { shouldDirty: true });
              }
            }}
          />
        </>
      ) : null}

      {relatedCampaign?.isExclusive ? (
        <>
          <CustomTitleLabel title="Message recipients follow campaign" />
          <label className="tips-message">{"If toggle off, the message will be delivered to customers targeted in both the campaign & message."}</label>
          <SpecificCustomSwitchButton
            checked={recipientsFollowCampaign}
            defaultChecked={recipientsFollowCampaign}
            onChange={(checked) => {
              setValue('recipientsFollowCampaign', checked, { shouldDirty: true });
              if (checked) {
                setValue('sendToAll', !checked, { shouldDirty: true });
              }
            }}
          />
        </>
      ) : null}

      {channels?.includes(MessageChannel.email) && (
        <FieldControl
          name="isDirectMarketing"
          render={() => (
            <CustomTitleWithSwitch
              title={'Is this a direct marketing message?'}
              tips={
                'If toggle on, this message will regard as direct marketing message. Only those customers who has opt-in to receive direct marketing message, could see receive the message.'
              }
              defaultValue={!!watchIsDirectMarketing}
              setValue={(value) => {
                setValue('isDirectMarketing', value, { shouldDirty: true });
              }}
            />
          )}
        />
      )}

      <BaseMultipleSelectorV2
        title={'Target customer groups'}
        data={{
          sourceData: customerGroupList,
          targetData: (sendToAll || recipientsFollowCampaign) ? [] : targetCustomerGroup,
          targetChange: (options) => {
            setValue('targetCustomerGroup', options, { shouldDirty: true });
          },
        }}
        addButton={{
          title: 'Add Customer List',
          action: () => setCreateNewType(MessageNewPromptType.customerGroup),
        }}
        requires={PermissionCodes.addCustomerGroup}
        disabled={sendToAll || recipientsFollowCampaign}
      />

      <BaseMultipleSelectorV2
        title={'Target segments'}
        data={{
          sourceData: segmentList,
          targetData: (sendToAll || recipientsFollowCampaign) ? [] : targetedSegments,
          targetChange: (options) => {
            setValue('targetedSegments', options, { shouldDirty: true });
          },
        }}
        addButton={{
          title: 'Add Segments',
          action: () => setCreateNewType(MessageNewPromptType.segments),
        }}
        requires={PermissionCodes.addSegment}
        disabled={sendToAll || recipientsFollowCampaign}
      />

      <CustomTitleLabel title="Schedule time" />
      <CustomDateTimeSelect
        defaultTime={scheduledDate ? new Date(scheduledDate) : new Date()}
        onTimeChange={(datetime) => {
          setValue('scheduledDate', datetime, { shouldDirty: true });
        }}
        error={hasError(errors, 'scheduledDate')}
      />
      <ReactHookFormErrorMessage errors={errors} id="scheduledDate" />

      <BasePrompt
        show={createNewType !== MessageNewPromptType.none}
        closeAction={() => setCreateNewType(MessageNewPromptType.none)}
        leftButton={{
          text: 'Done and refresh',
          action: () => {
            setRefreshDate(new Date());
            setCreateNewType(MessageNewPromptType.none);
          },
        }}
        rightButton={{
          text: getCreateNewPromptContent().button,
          action: getCreateNewPromptContent().action,
        }}
        title={getCreateNewPromptContent().title}
        description={getCreateNewPromptContent().description}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  customerGroupList: state.customerGroup.notPagedAllList,
  segmentList: state.segments.notPagedAllList,
});

export default connect(mapPropsToState)(MessageSettingSection);
