import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { SaveAndBackButtons } from 'components/base/BottomStepComponent';
import ContentSections from 'components/base/ContentSections';
import CustomDateTimeSelect from 'components/base/CustomDateTimeSelect';
import CustomRadios from 'components/base/CustomRadios';
import CustomTitleLabelWithEditorBar from 'components/base/CustomTitleLabelWithEditorBar';
import CustomTitleWithInput from 'components/base/CustomTitleWithInput';
import {
  ReactHookFormErrorMessage,
  hasError,
} from 'components/base/ErrorFieldMessage';
import InputFieldControl from 'components/base/InputFieldControl';
import { CustomTitleLabel } from 'components/earning/CustomBaseComponments';
import {
  EXPIRY_TYPE_CONFIG,
  POINTS_EXPIRY_DATE_VALUE,
} from 'config/CustomEnums';
import { ImportResource } from 'models/DownloadImportModel';

import { createAction } from 'utils';

const cashValueKey = 'cashValue';
const activePeriodTypeKey = 'activePeriodType';
const endDateKey = 'endDate';
const expireDaysKey = 'expireDays';
const amountKey = 'amount';

function GiftCardContent() {
  const {
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const watchCashValue = watch(cashValueKey);
  const watchActivePeriodType = watch(activePeriodTypeKey);
  const watchEndDate = watch(endDateKey);
  const watchExpireDays = watch(expireDaysKey);
  const watchAmount = watch(amountKey);

  if (!watchActivePeriodType) {
    setValue(activePeriodTypeKey, POINTS_EXPIRY_DATE_VALUE.ALL_TIME, { shouldDirty: true });
  }

  return (
    <>
      <CustomTitleWithInput
        title="Cash value"
        type="number"
        extra={{
          min: 1,
        }}
        defaultValue={watchCashValue}
        setValue={(value) => {
          setValue(cashValueKey, value, { shouldDirty: true });
        }}
      />
      <ReactHookFormErrorMessage errors={errors} id={cashValueKey} />

      <CustomTitleLabelWithEditorBar title={'Points expiry date'} />
      <div style={{ display: 'flex', marginTop: 8 }}>
        <CustomRadios
          onChange={(value) => {
            setValue(activePeriodTypeKey, value, { shouldDirty: true });
          }}
          default={watchActivePeriodType}
          options={EXPIRY_TYPE_CONFIG}
          disabled={false}
        />
      </div>

      {watchActivePeriodType === POINTS_EXPIRY_DATE_VALUE.IN_PERIOD && (
        <>
          <CustomTitleLabel title="End date" />
          <CustomDateTimeSelect
            defaultTime={watchEndDate ? new Date(watchEndDate) : new Date()}
            onTimeChange={(datetime) => {
              setValue(endDateKey, datetime, { shouldDirty: true });
            }}
          />
          <ReactHookFormErrorMessage errors={errors} id={endDateKey} />
        </>
      )}

      {watchActivePeriodType === POINTS_EXPIRY_DATE_VALUE.DAYS_SINCE_ACQUIRE && (
        <>
          <CustomTitleWithInput
            title={'Required days'}
            defaultValue={watchExpireDays}
            setValue={(value) => {
              setValue(expireDaysKey, value, { shouldDirty: true });
            }}
            type="number"
            unit="Days"
          />
          <ReactHookFormErrorMessage errors={errors} id={expireDaysKey} />
        </>
      )}

      <CustomTitleWithInput
        title="Amount"
        type="number"
        extra={{
          min: 1,
        }}
        defaultValue={watchAmount}
        setValue={(value) => {
          setValue(amountKey, value, { shouldDirty: true });
        }}
      />
      <ReactHookFormErrorMessage errors={errors} id={amountKey} />
    </>
  );
}

export default function CreatePhysicalGiftCardContent() {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { getValues, setError, clearErrors } = useFormContext();
  const validate = async () => {
    clearErrors([cashValueKey, endDateKey, expireDaysKey, amountKey]);
    const [cashValue, activePeriodType, endDate, expireDays, amount] = getValues([cashValueKey, activePeriodTypeKey, endDateKey, expireDaysKey, amountKey]);
    let isError = false;
    if (!cashValue) {
      isError = true;
      setError(
        cashValueKey,
        {
          type: 'require',
          message: 'please input cash value',
        },
        { shouldFocus: true },
      );
    } else if (!Number.isInteger(Number(cashValue)) || parseInt(cashValue) <= 0) {
      isError = true;
      setError(
        cashValueKey,
        {
          type: 'require',
          message: 'cash value invalid',
        },
        { shouldFocus: true },
      );
    }
    if (activePeriodType === POINTS_EXPIRY_DATE_VALUE.IN_PERIOD && !endDate) {
      isError = true;
      setError(
        endDateKey,
        {
          type: 'require',
          message: 'please input Points expiry date',
        },
        { shouldFocus: true },
      );
    }
    if (activePeriodType === POINTS_EXPIRY_DATE_VALUE.DAYS_SINCE_ACQUIRE) {
      if (!expireDays) {
        isError = true;
        setError(
          expireDaysKey,
          {
            type: 'require',
            message: 'please input expireDays',
          },
          { shouldFocus: true },
        );
      } else if (!Number.isInteger(Number(expireDays)) || parseInt(expireDays) <= 0) {
        isError = true;
        setError(
          expireDaysKey,
          {
            type: 'require',
            message: 'expireDays invalid',
          },
          { shouldFocus: true },
        );
      }
    }
    if (!amount) {
      isError = true;
      setError(
        amountKey,
        {
          type: 'require',
          message: 'please input amount',
        },
        { shouldFocus: true },
      );
    } else if (!Number.isInteger(Number(amount))) {
      isError = true;
      setError(
        amountKey,
        {
          type: 'require',
          message: 'amount invalid',
        },
        { shouldFocus: true },
      );
    }
    return !isError;
  };

  return (
    <>
      <ContentSections sections={[<GiftCardContent />]} hidePreview={true} />
      <SaveAndBackButtons
        saveText={'Create'}
        backAction={() => history.goBack()}
        saveAction={async () => {
          const isValid = await validate();
          if (isValid) {
            const values = getValues();
            console.log('@120', values);
        
            dispatch(createAction('giftCard/createPhysicalGiftCard')({
              resource: ImportResource.generateGiftCard,
                related: values,
              }));
          }
        }}
      />
    </>
  );
}
