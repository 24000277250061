import GeneralMessageCampaign from '../../../assets/images/campaign/general_campaign_icon.png';
import CouponCampaign from '../../../assets/images/campaign/coupon_campaign_icon.png';
import EarningCampaign from '../../../assets/images/campaign/earning_campaign_icon.png';

import { CampaignType } from '../../../config/CustomEnums';

const couponCampaignPromptConfig = {
  title: 'Go to create a Coupon Set?',
  message: `You will leave the campaign creation process. After you create a coupon set, you can come back to create a coupon campaign.`,
  button: 'Go to Create Coupon Set',
  refreshButton: 'Done and refresh',
};

const earningCampaignPromptConfig = {
  title: 'Go to create a earning rule Set?',
  message: `You will leave the campaign creation process. After you create a earning set, you can come back to create a earning campaign.`,
  button: 'Go to Create Earning Rule Set',
  refreshButton: 'Done and refresh',
};

export const CampaignTypesConfig = [
  {
    id: CampaignType.generalMessageCampaign,
    title: 'General Message',
    image: GeneralMessageCampaign,
    description: 'Present information to customers',
  },
  {
    id: CampaignType.earningRulesCampaign,
    title: 'Earning',
    image: EarningCampaign,
    description:
      'Customers will earn the points and level points by completing specific activities',
  },
  {
    id: CampaignType.couponsCampaign,
    title: 'Coupon',
    image: CouponCampaign,
    description:
      'Enables customers to acquire coupons by using points shown in this campaign',
  },
];

export const CampaignAllTypesConfig = [
  {
    id: CampaignType.generalMessageCampaign,
    title: 'General Message',
    image: GeneralMessageCampaign,
    description: 'Present information to customers',
  },
  {
    id: CampaignType.earningRulesCampaign,
    title: 'Earning',
    image: EarningCampaign,
    description:
      'Customers will earn the points and level points by completing specific activities',
  },
  {
    id: CampaignType.couponsCampaign,
    title: 'Coupon',
    image: CouponCampaign,
    description:
      'Enables customers to acquire coupons by using points shown in this campaign',
  },
  {
    id: CampaignType.couponsCampaign,
    title: 'Stamp',
    description:
      'Enables customers to acquire stamp by complete tasks shown in this campaign',
  },
];

export const getCampaignTypeSelectPromptConfig = (type) => {
  return type === CampaignType.couponsCampaign
    ? couponCampaignPromptConfig
    : earningCampaignPromptConfig;
};

export const getCampaignTypeTitle = (type) => {
  switch (type) {
    case CampaignType.earningRulesCampaign:
      return CampaignTypesConfig[1].title;
    case CampaignType.couponsCampaign:
      return CampaignTypesConfig[2].title;
    default:
      return CampaignTypesConfig[0].title;
  }
};

export const getAllCampaignTypeTitle = (type) => {
  switch (type) {
    case CampaignType.earningRulesCampaign:
      return CampaignAllTypesConfig[1].title;
    case CampaignType.couponsCampaign:
      return CampaignAllTypesConfig[2].title;
    case CampaignType.stampCampaign:
      return CampaignAllTypesConfig[3].title;
    default:
      return CampaignAllTypesConfig[0].title;
  }
};