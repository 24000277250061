import {
  getSingleCouponListWithTemplate,
  deactiveSingleCoupon,
  activeSingleCoupon,
  grantCoupon,
  getAllSingleCouponWithTemplate,
  deleteSingleCoupons,
  getSingleCoupon,
  reclaimCoupon,
  deactiveCoupons,
  activeCoupons,
  getCustomerDetailCouponList,
  selectStore,
  useCoupon,
} from '../services/CouponAPIHelper';
import { parseSingleCoupon } from './CouponUtil';
import {
  createAction,
  convertCursorToNumber,
  convertNumberToCursor,
  convertPKToId,
  addDomainToImage,
} from '../utils';
import { APIStatus } from '../config/CustomEnums';
import { loading } from './LoadingUtil';
import { COUPON_STATUS } from './CouponListModel';

const getInitialState = () => {
  return {
    singleCouponList: [],
    listDisplayFields: [
      { displayName: 'ID', fieldName: 'pk' },
      { displayName: 'Serial Number', fieldName: 'name', linked: true },
      { displayName: 'Reference ID', fieldName: 'referenceId' },
      { displayName: 'Owner', fieldName: 'customerName' },
      { displayName: 'Expiry Date', fieldName: 'displayExpiryDate' },
      { displayName: 'Create at', fieldName: 'displayCreationDate' },
      { displayName: 'Last Modified', fieldName: 'displayLastModifiedDate' },
      { displayName: 'Status', fieldName: 'status' },
    ],
    listImageDisplayFields: [
      { displayName: 'ID', fieldName: 'pk', linked: true },
      { displayName: 'Image', fieldName: 'displayImage', enlarge: true },
      { displayName: 'Owner', fieldName: 'customerName' },
      { displayName: 'Expiry Date', fieldName: 'displayExpiryDate' },
      { displayName: 'Create at', fieldName: 'displayCreationDate' },
      { displayName: 'Last Modified', fieldName: 'displayLastModifiedDate' },
      { displayName: 'Status', fieldName: 'status' },
    ],
    currentPageSingleCouponList: [],
    pageInfo: {
      startCursor: '',
      endCursor: '',
      hasNextPage: false,
      hasPreviousPage: false,
    },
    currentLastCursor: '',
    currentPage: 0,
    totalPage: 0,
    totalCount: 0,
    checkedList: [],
    singleCoupon: {},
    customerDetailCouponList: [],
    customerDetailCouponListDisplayFields: [
      { displayName: 'Name', fieldName: 'templateName', linked: true },
      { displayName: 'Coupon Set ID', fieldName: 'templateId' },
      { displayName: 'Coupon ID', fieldName: 'couponDisplayId' },
      { displayName: 'Cover Photo', fieldName: 'coverPhoto' },
      { displayName: 'Brand', fieldName: 'templateBrand' },
      { displayName: 'Expiry Date', fieldName: 'displayExpiryDate' },
    ]
  };
};

const parseCouponStatus = (singleCoupon) => {
  let status = COUPON_STATUS.ACTIVE;
  if (singleCoupon.isUsed) {
    status = COUPON_STATUS.USED;
  } else if (singleCoupon.isExpired) {
    status = COUPON_STATUS.EXPIRED;
  } else if (singleCoupon.isForcedInactive) {
    status = COUPON_STATUS.INACTIVE;
  } else if (
    singleCoupon.owner?.owner !== '-' &&
    singleCoupon.owner?.owner !== '' &&
    singleCoupon.dateOfGrant
  ) {
    status = COUPON_STATUS.ACTIVE_ACQUIRED;
  }
  return status;
};

const parseSingleCouponWithStatus = (couponSet, singleCouponList) => {
  if (singleCouponList?.length > 0) {
    let parsedSingleCouponList = singleCouponList.map((singleCoupon) => {
      return parseSingleCoupon(couponSet, singleCoupon);
    });
    parsedSingleCouponList = parsedSingleCouponList.map((singleCoupon) => {
      const status = parseCouponStatus(singleCoupon);

      return {
        ...singleCoupon,
        status: status,
      };
    });
    return parsedSingleCouponList;
  } else {
    return [];
  }
};

const assembleSingleCoupon = (coupon) => {
  if (!coupon) {
    return {};
  }
  const customerName = coupon.owner
    ? `${coupon.owner?.firstName} ${coupon.owner?.lastName}`
    : '';

  return {
    ...coupon,
    customerName: customerName,
    owner: { ...coupon.owner, owner: customerName },
    status: parseCouponStatus(coupon),
    displayImage: addDomainToImage(coupon.image),
    store: {
      ...coupon.store,
      name: coupon.store?.name ? `${coupon.store?.name}${coupon.store?.visibleInFrontEnd ? '' : '(closed)'}` : null
    }
  };
};

export default {
  namespace: 'singleCoupon',
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    clearSingleCoupon(state, { payload }) {
      return {
        ...state,
        singleCoupon: {},
      };
    },

    assembleCustomerDetailCouponList(state, { payload }) {
      const { coupons, totalCount, pageInfo } = payload;
      const couponList = coupons.map((item) =>
        parseSingleCoupon(item.node?.template, item),
      );
      const startCursor = convertCursorToNumber(pageInfo.startCursor);
      const endCursor = convertCursorToNumber(pageInfo.endCursor);
      return {
        ...state,
        customerDetailCouponList: couponList,
        totalCount,
        totalPage: Math.ceil(totalCount / 20),
        pageInfo: {
          startCursor: startCursor + 1,
          endCursor: endCursor + 1,
        },
      };
    },

    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },
  effects: {
    getAllSingleCoupons: [
      function* ({ payload }, { call, put }) {
        const { template } = payload;
        const serviceArgs = [getAllSingleCouponWithTemplate, template.id];
        function* onSuccess(data) {
          const couponList = data.couponTemplate.coupons.edges;
          const parsedSingleCouponList = parseSingleCouponWithStatus(
            template,
            couponList,
          );
          const afterAction = payload.afterAction || (() => {});
          yield put(
            createAction('updateState')({
              singleCouponList: parsedSingleCouponList,
            }),
          );
          yield afterAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    getCurrentPageSingleCouponList: [
      function* ({ payload }, { put, call, select }) {
        yield put(
          createAction('updateState')({
            getSingleCouponListStatus: APIStatus.calling,
          }),
        );
        const couponSet = yield select((state) => state.couponList.couponSet);
        const { templatePK, currentPage, rank, filterKey } = payload;
        const templateID = convertPKToId('CouponTemplateNode', templatePK);
        let afterCursor = undefined;
        if (currentPage > 1) {
          afterCursor = convertNumberToCursor((currentPage - 1) * 20 - 1);
        }
        const serviceArgs = [
          getSingleCouponListWithTemplate,
          templateID,
          afterCursor,
          rank,
          filterKey,
        ];
        function* onFailed() {
          yield put(
            createAction('updateState')({
              getSingleCouponListStatus: APIStatus.failed,
            }),
          );
        }
        function* onSuccess(data) {
          const singleCoupons = data.couponTemplate.coupons;
          const pageInfo = singleCoupons?.pageInfo;
          const totalCount = singleCoupons?.totalCount;
          const currentLastCursor = pageInfo?.endCursor;
          const singleCouponList = parseSingleCouponWithStatus(
            couponSet,
            singleCoupons.edges,
          );
          yield put(
            createAction('updateState')({
              currentPageSingleCouponList: singleCouponList,
              totalCount: totalCount,
              pageInfo: {
                startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
              },
              currentLastCursor,
              totalPage: Math.ceil(totalCount / 20),
            }),
          );
        }
        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],

    getSingleCoupon: [
      function* ({ payload }, { put }) {
        const { pk } = payload;
        const couponID = convertPKToId('CouponNode', pk);
        const serviceArgs = [getSingleCoupon, couponID];
        function* onSuccess(data) {
          const couponData = data.coupon;
          const singlecoupon = assembleSingleCoupon(couponData);
          yield put(
            createAction('updateState')({
              singleCoupon: singlecoupon,
            }),
          );
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    delete: [
      function* ({ payload }, { put, select }) {
        const checkedList = yield select(
          (state) => state.singleCoupon.checkedList,
        );
        const ids = checkedList.map((item) => item.pk);
        const serviceArgs = [deleteSingleCoupons, ids];
        function* onSuccess() {
          const deleteAction = payload.afterAction || (() => {});
          yield deleteAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    deactiveSingleCoupon: [
      function* ({ payload }, { call, select, put }) {
        const { couponPK } = payload;
        const serviceArgs = [deactiveSingleCoupon, couponPK];
        function* onSuccess() {
          const deactiveAction = payload.afterAction || (() => {});
          console.log('@@237: ', deactiveAction);
          yield deactiveAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    activeSingleCoupon: [
      function* ({ payload }, { call }) {
        const { couponPK } = payload;
        const activeAction = payload.activeAction || (() => {});
        const serviceArgs = [activeSingleCoupon, couponPK];
        function* onSuccess() {
          yield activeAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    deactiveCoupons: [
      function* ({ payload }, { call, select }) {
        const coupons = yield select(
          (state) => state.singleCoupon.singleCouponList,
        );
        let ids = [];
        if (coupons && coupons.length > 0) {
          coupons.forEach((coupon) => {
            if (!coupon.dateOfGrant) {
              ids.push(coupon.pk);
            }
          });
        }
        const serviceArgs = [deactiveCoupons, ids];
        function* onSuccess() {
          const afterAction = payload.afterAction || (() => {});
          yield afterAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    activeCoupons: [
      function* ({ payload }, { select }) {
        const coupons = yield select(
          (state) => state.singleCoupon.singleCouponList,
        );
        let ids = [];
        if (coupons && coupons.length > 0) {
          coupons.forEach((coupon) => {
            if (!coupon.dateOfGrant) {
              ids.push(coupon.pk);
            }
          });
        }
        const serviceArgs = [activeCoupons, ids];
        function* onSuccess() {
          const afterAction = payload.afterAction || (() => {});
          yield afterAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    grantSingleCoupon: [
      function* ({ payload }, { call, put }) {
        const { couponPK, customerPK } = payload;
        const serviceArgs = [grantCoupon, couponPK, customerPK];
        function* onSuccess() {
          const afterAction = payload.afterAction || (() => {});
          yield afterAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    reclaimCoupon: [
      function* ({ payload }, { put }) {
        const { couponPK } = payload;
        const serviceArgs = [reclaimCoupon, couponPK];
        function* onSuccess() {
          const afterAction = payload.afterAction || (() => {});
          yield afterAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    getCustomerDetailCouponList: [
      function* ({ payload }, { call, all, put }) {
        const page = payload.page || 1;
        const pageCursor = payload.page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const customerId = payload.customerId;
        const type = payload.type || 'ALL';
        const search = payload.search || '';
        const serviceArgs = [
          getCustomerDetailCouponList,
          {
            afterCursor: pageCursor,
            customerId,
            type,
            search,
          }
        ];
        function* onSuccess(data) {
          console.log('@@getCustomerDetailSingleCouponList data:', data);
          const coupons = data.coupons;
          yield put({
            type: 'assembleCustomerDetailCouponList',
            payload: {
              coupons: coupons.edges,
              totalCount: coupons.totalCount,
              pageInfo: coupons.pageInfo,
            },
          });
        }
        // eslint-disable-next-line require-yield
        function* onFailed(data) {
          console.log('@@getCustomerDetailSingleCouponList failed:', data);
        }

        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],

    selectStore: [
      function* ({ payload }, { call, all, put }) {
        const { id, customerId, storeId, afterAction } = payload;

        const serviceArgs = [
          selectStore,
          {
            id,
            customerId,
            storeId
          }
        ];

        // eslint-disable-next-line require-yield
        function* onSuccess(data) {
          console.log('@@selectStore success data:', data);
        }
        // eslint-disable-next-line require-yield
        function* onFailed(data) {
          console.log('@@selectStore failed:', data);
        }

        yield loading(serviceArgs, onSuccess, onFailed);

        yield afterAction();
      },
      { type: 'takeLatest' },
    ],

    useCoupon: [
      function* ({ payload }, { call, all, put }) {
        const { id, customerId, storeId, afterAction } = payload;

        const serviceArgs = [
          useCoupon,
          {
            id,
            customerId,
            storeId
          }
        ];

        // eslint-disable-next-line require-yield
        function* onSuccess(data) {
          console.log('@@useCoupon success data:', data);
        }
        // eslint-disable-next-line require-yield
        function* onFailed(data) {
          console.log('@@useCoupon failed:', data);
        }

        yield loading(serviceArgs, onSuccess, onFailed);
        
        yield afterAction();
      },
      { type: 'takeLatest' },
    ],
  },
};
