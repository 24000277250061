import React, { useEffect, useState } from 'react';
import '../../campaign/campaignCreation/CreateCampaignStepTwo.scss';
import { useDispatch, connect } from 'react-redux';
import { CampaignType, LanguageConfig } from '../../../config/CustomEnums';
import ContentSections from '../../base/ContentSections';
import GeneralSection from '../../campaign/campaignCreation/GeneralSection';
import DetailSection from '../../campaign/campaignCreation/DetailSection';
import InstructionSection from '../../campaign/campaignCreation/InstructionSection';
import KeyVisualSection from '../../stampCampaign/stampCampaignCreation/KeyVisualSection';
import {
  OnlyContinueButton,
} from '../../base/BottomStepComponent';
import {
  defaultPreviewImage,
  getPreviewLayoutImages,
  // getTipsImages,
} from '../../campaign/campaignCreation/CampaignStepTwoPreviewConfig';
import { useFormContext } from 'react-hook-form';
import { validate } from '../../../containers/engagement/campaign/CreateCampaignValidate';
import TaskSection from './TaskSection';
import RewardSection from './RewardSection';
import { scrollToFirstError } from 'components/campaign/campaignCreation/CreateCampaignHandleError';

function CreateStampCampaignStepOne({ languages }) {
  const { getValues, formState, clearErrors, setError, setValue } =
    useFormContext();
  const errors = formState.errors;
  const fieldError = Object.keys(errors || []).length;
  // const [onCheck, setOnCheck] = useState({
  //   value: false,
  //   isBack: false,
  // });
  const onCheck= {
    value: false,
    isBack: false,
  };
  const watchCampaignType = CampaignType.stampCampaign;
  const dispatch = useDispatch();
  const defaultImage = defaultPreviewImage(watchCampaignType);

  const [activeSection, setActiveSection] = useState();
  const inputWithImageConfig = getPreviewLayoutImages(watchCampaignType);
  // const tipsImageConfig = getTipsImages(watchCampaignType);

  useEffect(() => {
    console.log("@@scrollToFirstError", errors)
    scrollToFirstError(
      errors,
      [
        "translations.en.generalName",
        "translations.en.coverPhoto",
        "translations.en.squareCoverPhoto",
        "stampCampaignTypeEmptySlotStampImage",
        "stampCampaignTypeAcquiredStampImage",
        "stampCampaignTypeEmptyGiftSlotImage",
        "stampCampaignTypeCollectedGiftImage",
        "linkedEarningRules",
        "stampRewardHasError",
      ]
    )
  }, [errors]);

  useEffect(() => {
    if (getValues('campaignType')) {
      return;
    }
    setValue('campaignType', watchCampaignType);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languages]);

  const getTabs = (language, setActiveSection) => {
    return language === LanguageConfig.english
      ? [
          <GeneralSection onFocus={setActiveSection} language={language} />,
          <KeyVisualSection onFocus={setActiveSection} language={language} />,
          <TaskSection onFocus={setActiveSection} />,
          <RewardSection onFocus={setActiveSection} />,
          <InstructionSection
            focusChange={setActiveSection}
            language={language}
            // titleTips={tipsImageConfig.instructionTitle}
            // contentTips={tipsImageConfig.instructionContent}
          />,
          <DetailSection
            focusChange={setActiveSection}
            language={language}
            onSubmit={onCheck.value}
            // titleTips={tipsImageConfig.detailsTitle}
            // contentTips={tipsImageConfig.detailsContent}
          />,
        ]
      : [
          <GeneralSection onFocus={setActiveSection} language={language} />,
          <KeyVisualSection onFocus={setActiveSection} language={language} />,
          <InstructionSection
            focusChange={setActiveSection}
            language={language}
            // titleTips={tipsImageConfig.instructionTitle}
            // contentTips={tipsImageConfig.instructionContent}
          />,
          <DetailSection
            focusChange={setActiveSection}
            language={language}
            onSubmit={onCheck.value}
            // titleTips={tipsImageConfig.detailsTitle}
            // contentTips={tipsImageConfig.detailsContent}
          />,
        ];
  };

  const stepChangeAction = () => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 0,
      languages: languages?.map(item=>item.code),
    });
    // const isValid=true
    dispatch({
      type: 'createCampaign/stepChange',
      payload: { step: 0, isValid: isValid, isStamp: true },
    });
  };

  return (
    <>
      <ContentSections
        fieldsError={fieldError}
        languageTabContent={{
          containers: languages?.map((language) => {
            return {
              container: getTabs(language.code, setActiveSection),
              key: language.code,
              title: language.sourceName,
            };
          }),
        }}
        inputWithImageConfig={inputWithImageConfig}
        defaultImage={defaultImage}
        activeSection={activeSection}
      />
      <OnlyContinueButton
        continueAction={() => {
          stepChangeAction();
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  languages: state.language.allList,
});

export default connect(mapPropsToState)(CreateStampCampaignStepOne);
