import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import queryString from 'query-string';

import BaseFilter, { FilterTypeEnum } from 'components/base/BaseFilter';
import {
  EarningRuleLinkedCampaign,
  EarningRuleRewardType,
  EarningRuleType,
} from 'config/CustomEnums';

import { ArrayStringData } from 'utils';

function CustomerEarningRuleListFilter({ backAction = () => { } }) {
  const location = useLocation();

  const parsedSearch = queryString.parse(location.search.slice(1));
  const searchType = parsedSearch['type'] || '';
  const searchRewardType = parsedSearch['reward_type'] || '';
  const searchCampaignType = parsedSearch['linked_campaign_type'] || '';

  const [type, setType] = useState(searchType);
  const [rewardType, setRewardType] = useState(
    ArrayStringData(searchRewardType),
  );
  const [linkedCampaignType, setLinkedCampaignType] = useState(
    ArrayStringData(searchCampaignType),
  );

  const content = [
    {
      title: 'Type',
      data: [
        { pk: EarningRuleType.qrCodeScanning, name: 'QR Code' },
        { pk: EarningRuleType.gpsCheckIn, name: 'GPS' },
        { pk: EarningRuleType.memberReferral, name: 'Referral' },
        { pk: EarningRuleType.generalPurchase, name: 'Purchase' },
        { pk: EarningRuleType.newMember, name: 'New Member' },
        { pk: EarningRuleType.birthday, name: 'Birthday' },
        { pk: EarningRuleType.fillingForm, name: 'Webhook' },
        { pk: EarningRuleType.levelUpgrade, name: 'Level Upgrade' },
      ],
      value: type,
      setValue: setType,
      component: FilterTypeEnum.singleChoice,
    },
    {
      title: 'Reward type',
      data: [
        { name: 'Coupons', pk: EarningRuleRewardType.coupons },
        { name: 'Points', pk: EarningRuleRewardType.points },
        { name: 'Stamps', pk: EarningRuleRewardType.stamps },
      ],
      value: rewardType,
      setValue: setRewardType,
      component: FilterTypeEnum.choice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Linked campaign type',
      data: [
        {
          name: 'Stamp campaign',
          pk: EarningRuleLinkedCampaign.stampCampaign,
        },
        {
          name: 'Earning campaign',
          pk: EarningRuleLinkedCampaign.earningCampaign,
        },
      ],
      value: linkedCampaignType,
      setValue: setLinkedCampaignType,
      component: FilterTypeEnum.choice,
      className: 'fit-content-height-area',
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default CustomerEarningRuleListFilter;
