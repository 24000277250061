import React from 'react';
import { useFormContext } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';

import { OnlyContinueButton } from 'components/base/BottomStepComponent';
import ContentSections from 'components/base/ContentSections';
import CustomSwitchButton from 'components/base/CustomSwitchButton';
import CustomTitleWithInput from 'components/base/CustomTitleWithInput';
import { MIN_WIDTH, SPACE_BETWEEN } from 'components/base/ItemTypeSet';
import Loading from 'components/base/Loading';
import { CustomTitleWithDropDown } from 'components/customer/CustomTitleWithDropDown';
import { CustomTitleLabel } from 'components/earning/CustomBaseComponments';
import { MessageChannelConfig } from 'components/message/CreateMessageConfig';
import MessageChannelCard from 'components/message/MessageChannelCard';
import { APIStatus, LanguageConfig, MessageChannel } from 'config/CustomEnums';
import { validate } from 'containers/engagement/message/CreateMessageValidate';

import { MessageCreditCardContent } from './MessageCredit';

import { getScrollbarWidth, useMainContentWidth } from 'utils/ScreenUtil';

const inboxTip = "We suggest also keeping your message in user's inbox";
const allSmsTip =
  'The message will be sent to any one of the possible channels to the customer by the order of push, email, SMS. (Only one message will be sent per customer.)\n' +
  '\n' +
  'There is a fallback logic applied when sending out the message:\n' +
  '● Send via push notification if the customer has been reachable in the past 3 days via push.\n' +
  '● Send via email if the customer has provided it in their My Profile.\n' +
  '● Send via SMS text message.\n' +
  '\n' +
  'By default, a copy of the message will be sent to the Inbox in the app. The content of the inbox message will follow the message which successfully sent.\n';
const allWhatsappTip =
  'The message will be sent to any one of the possible channels to the customer by the order of push, email, Whatsapp. (Only one message will be sent per customer.)\n' +
  '\n' +
  'There is a fallback logic applied when sending out the message:\n' +
  '● Send via push notification if the customer has been reachable in the past 3 days via push.\n' +
  '● Send via email if the customer has provided it in the My Profile.\n' +
  '● Send via Whatsapp message (using Whatsapp message template).\n' +
  '\n' +
  'By default, a copy of the message will be sent to the Inbox in the app. The content of the inbox message will follow the message which successfully sent.\n' +
  '\n' +
  'Kindly reminded that, the message content might be limited by the choice of message templates in Whatsapp.';

const mapPropsToState = (state) => ({
  status: state.createMessage.createStatus,
  message: state.createMessage.message,
  realCreditTotalSpent: state.messageList.realCreditTotalSpent,
  messageTemplateList: state.messageList.messageTemplateList,
  languages: state.language.allList,
  whatsappCreditTotalSpent: state.messageList.whatsappCreditTotalSpent,
  smsCreditTotalSpent: state.messageList.smsCreditTotalSpent,
  emailCreditTotalSpent: state.messageList.emailCreditTotalSpent,
});

function CreateMessageStepOne({
  status,
  message,
  realCreditTotalSpent,
  messageTemplateList,
  languages,
  whatsappCreditTotalSpent,
  smsCreditTotalSpent,
  emailCreditTotalSpent,
}) {
  const dispatch = useDispatch();
  const mainContentWidth = useMainContentWidth();
  const fullContainerWith = mainContentWidth - 80 - 60 - getScrollbarWidth();

  const isEdit = !!message?.pk;

  const { watch, setValue, getValues, setError, formState, clearErrors } =
    useFormContext();
  const { errors } = formState;

  const channelsField = 'channels';
  const channels = watch(channelsField);
  const relatedCampaignField = 'relatedCampaign';

  const startWithTemplateField = `isStartWithTemplate`;
  const isStartWithTemplate = watch(startWithTemplateField);
  const messageTemplateField = `messageTemplate`;
  const messageTemplate = watch(messageTemplateField);
  const nameField = 'name';
  const name = watch(nameField);

  const updateMessageStep = (selectedChannels) => {
    console.log('selectedChannels', selectedChannels);
    dispatch({
      type: 'createMessage/updateStepConfig',
      payload: {
        selectedChannels: selectedChannels,
      },
    });
  };

  const channelSelected = (channel) => {
    if (channel === MessageChannel.all) {
      // select all channels
      const allChannels = [
        MessageChannel.all,
        MessageChannel.push,
        MessageChannel.email,
        MessageChannel.sms,
        MessageChannel.inbox,
      ];
      setValue(channelsField, allChannels, { shouldDirty: true });
      updateMessageStep(allChannels);
      return;
    }
    if (channel === MessageChannel.allWhatsapp) {
      // select all channels
      const allChannels = [
        MessageChannel.allWhatsapp,
        MessageChannel.push,
        MessageChannel.email,
        MessageChannel.whatsapp,
        MessageChannel.inbox,
      ];
      setValue(channelsField, allChannels, { shouldDirty: true });
      updateMessageStep(allChannels);
      return;
    }
    let selectedChannels = channels.filter(
      (newChannel) => newChannel !== MessageChannel.inbox,
    );
    selectedChannels.push(MessageChannel.inbox);
    if (channel === MessageChannel.sms) {
      selectedChannels = selectedChannels.filter(
        (newChannel) =>
          newChannel !== MessageChannel.allWhatsapp &&
          newChannel !== MessageChannel.whatsapp,
      );
    }
    if (channel === MessageChannel.whatsapp) {
      selectedChannels = selectedChannels.filter(
        (newChannel) =>
          newChannel !== MessageChannel.all &&
          newChannel !== MessageChannel.sms,
      );
    }
    if (channel !== MessageChannel.inbox) {
      // select other channel will auto select inbox
      selectedChannels.push(channel);
    }
    setValue(channelsField, selectedChannels, { shouldDirty: true });
    updateMessageStep(selectedChannels);
    return;
  };

  const channelUnselected = (channel) => {
    if (
      channel === MessageChannel.all ||
      channel === MessageChannel.allWhatsapp
    ) {
      // remove all selected channels
      const emptyChannels = [];
      setValue(channelsField, emptyChannels, { shouldDirty: true });
      updateMessageStep(emptyChannels);
      return;
    }
    if (channels.includes(channel)) {
      // cancel select
      const selectedChannels = channels.filter(
        (newChannel) =>
          newChannel !== channel &&
          newChannel !== MessageChannel.all &&
          newChannel !== MessageChannel.allWhatsapp,
      );
      setValue(channelsField, selectedChannels, { shouldDirty: true });
      updateMessageStep(selectedChannels);
      return;
    }
  };

  const stepChangeAction = () => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 0,
      isBack: false,
    });
    dispatch({
      type: 'createMessage/stepChange',
      payload: {
        isBack: false,
        step: 0,
        isValid,
      },
    });
  };

  const applyTemplateChannels = (channels) => {
    if (!channels || channels?.length < 0) {
      setValue(channelsField, [], { shouldDirty: true });
      updateMessageStep([]);
      return;
    }
    let selectedChannels = [];
    if (channels.indexOf(MessageChannel.all) > -1) {
      selectedChannels = [
        MessageChannel.all,
        MessageChannel.push,
        MessageChannel.email,
        MessageChannel.sms,
        MessageChannel.inbox,
      ];
    } else if (channels.indexOf(MessageChannel.allWhatsapp) > -1) {
      selectedChannels = [
        MessageChannel.allWhatsapp,
        MessageChannel.push,
        MessageChannel.email,
        MessageChannel.whatsapp,
        MessageChannel.inbox,
      ];
    } else {
      selectedChannels = channels;
    }
    setValue(channelsField, selectedChannels, { shouldDirty: true });
    updateMessageStep(selectedChannels);
  };

  const applyTemplateTranslations = (data) => {
    languages.forEach((item) => {
      const language = item.code;
      const messageTitleField = `translations.${language}.name`;
      const messageContentField = `translations.${language}.content`;
      const messagePhotoField = `translations.${language}.photo`;

      const smsContentField = `translations.${language}.smsContent`;

      const emailTitleField = `translations.${language}.emailTitle`;
      const emailContentField = `translations.${language}.emailContent`;

      const whatsappTemplateField = `translations.${language}.whatsappTemplate`;
      const whatsappTemplateNameField = `translations.${language}.whatsappTemplateName`;
      const whatsappImageField = `translations.${language}.whatsappImage`;
      const whatsappHeaderField = `translations.${language}.whatsappHeader`;
      const whatsappBodyField = `translations.${language}.whatsappBody`;

      if (!data?.translations?.[language]) {
        setValue(messageTitleField, null, { shouldDirty: true });
        setValue(messageContentField, null, { shouldDirty: true });
        setValue(messagePhotoField, null, { shouldDirty: true });

        setValue(emailTitleField, null, { shouldDirty: true });
        setValue(emailContentField, null, { shouldDirty: true });

        setValue(smsContentField, null, { shouldDirty: true });

        setValue(whatsappTemplateField, null, { shouldDirty: true });
        setValue(whatsappTemplateNameField, null, { shouldDirty: true });
        setValue(whatsappImageField, null, { shouldDirty: true });
        setValue(whatsappHeaderField, null, { shouldDirty: true });
        setValue(whatsappBodyField, null, { shouldDirty: true });

        return;
      }

      const {
        title,
        content,
        photo,

        emailContent,
        emailTitle,

        smsContent,

        whatsappTemplate,
        whatsappTemplateName,
        whatsappImage,
        whatsappHeader,
        whatsappBody,
      } = data.translations?.[language];

      setValue(messageTitleField, title, { shouldDirty: true });
      setValue(messageContentField, content, { shouldDirty: true });
      setValue(messagePhotoField, photo, { shouldDirty: true });

      setValue(emailTitleField, emailTitle, { shouldDirty: true });
      setValue(emailContentField, emailContent, { shouldDirty: true });

      setValue(smsContentField, smsContent, { shouldDirty: true });

      setValue(whatsappTemplateField, whatsappTemplate, { shouldDirty: true });
      setValue(whatsappTemplateNameField, whatsappTemplateName, {
        shouldDirty: true,
      });
      setValue(whatsappImageField, whatsappImage, { shouldDirty: true });
      setValue(whatsappHeaderField, whatsappHeader, { shouldDirty: true });
      setValue(whatsappBodyField, whatsappBody, { shouldDirty: true });
    });
  };

  const onMessageTemplateChange = (value) => {
    applyTemplateChannels(value?.channels);
    applyTemplateTranslations(value);
    setValue(
      relatedCampaignField,
      value?.relatedCampaign?.name
        ? {
            value: value.relatedCampaign,
            label: value.relatedCampaign?.pk
              ? `[ID: ${value.relatedCampaign?.pk}] ${value.relatedCampaign?.name}`
              : value.relatedCampaign?.name,
          }
        : null,
      { shouldDirty: true },
    );
  };

  const sections = [
    <div>
      <MessageCreditCardContent 
        realCreditTotalSpent={realCreditTotalSpent}
        whatsappCreditTotalSpent={whatsappCreditTotalSpent}
        smsCreditTotalSpent={smsCreditTotalSpent}
        emailCreditTotalSpent={emailCreditTotalSpent}
      />
    </div>,
    <div>
      <div className="d-flex flex-column">
        <label className="create-section-label">
          {'Start with a template'}
        </label>
        <CustomSwitchButton
          defaultChecked={isStartWithTemplate}
          disabled={isEdit}
          onChange={(checked) => {
            setValue(startWithTemplateField, checked, { shouldDirty: true });
            if (!checked) {
              onMessageTemplateChange({});
              setValue(messageTemplateField, null, { shouldDirty: true });
            }
          }}
        />
      </div>
      {isStartWithTemplate && (
        <CustomTitleWithDropDown
          title={'Template'}
          setValue={(value) => {
            setValue(messageTemplateField, value, { shouldDirty: true });
            onMessageTemplateChange(value?.value);
          }}
          disableDropDown={isEdit}
          defaultValue={messageTemplate}
          source={messageTemplateList}
          errors={errors}
          errorName={messageTemplateField}
          loadMoreAction={'messageList/getMessageTemplate'}
          filterAction={'messageList/getMessageTemplate'}
          allType
        />
      )}
    </div>,
    <>
      {status === APIStatus.calling ? (
        <Loading />
      ) : (
        <div>
          <CustomTitleWithInput
            title={'Name'}
            tips={
              'Only for CMS name display only, will not show in the message to be sent out.'
            }
            customTitleClass={'create-section-label-no-top-space'}
            error={errors?.name ? { error: true, ...errors.name } : {}}
            useDefaultValue={true}
            defaultValue={name}
            setValue={(value) => {
              setValue(nameField, value, { shouldDirty: true });
            }}
          />
          <div>
            <CustomTitleLabel title={'Message channel'} />
            <div
              className="step-type-area"
              style={{
                justifyContent:
                  fullContainerWith < (MIN_WIDTH + SPACE_BETWEEN) * 2
                    ? 'center'
                    : 'flex-start',
              }}
            >
              {MessageChannelConfig.map((channel) => {
                let alwaysShowTip = null;
                let toolTip = null;
                if (channel.channel === MessageChannel.inbox) {
                  // inbox card tip
                  if (channels.includes(MessageChannel.inbox)) {
                    if (channels?.length > 1) {
                      toolTip = inboxTip;
                    } else {
                      // only inbox, no tip
                    }
                  } else if (
                    !channels.includes(MessageChannel.all) &&
                    !channels.includes(MessageChannel.allWhatsapp)
                  ) {
                    if (channels?.length > 0) {
                      alwaysShowTip = inboxTip;
                    } else {
                      // no channels, no tip
                    }
                  }
                } else if (channel.channel === MessageChannel.all) {
                  // all card tip
                  toolTip = allSmsTip;
                } else if (channel.channel === MessageChannel.allWhatsapp) {
                  // all card tip
                  toolTip = allWhatsappTip;
                }

                return (
                  <MessageChannelCard
                    key={channel.channel}
                    channelConfig={channel}
                    selectedChannels={channels}
                    selected={channels?.includes(channel.channel)}
                    alwaysShowTip={alwaysShowTip}
                    toolTip={toolTip}
                    select={(channel) => channelSelected(channel)}
                    unSelect={(channel) => channelUnselected(channel)}
                    disabled={isEdit}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>,
  ];

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <OnlyContinueButton
        disabledContinue={channels.length === 0}
        continueAction={() => {
          stepChangeAction();
        }}
      />
    </>
  );
}

export default connect(mapPropsToState)(CreateMessageStepOne);
