import React, { useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';

function MessageCreditRecordFilter({ backAction = () => { } }) {
  const location = useLocation();

  const parsedSearch = queryString.parse(location.search);
  const searchCreditConsumed = parsedSearch['credit_consumed'] || "";
  const searchWhatsappCreditConsumed = parsedSearch['whatsapp_credit_consumed'] || "";
  const searchSmsCreditConsumed = parsedSearch['sms_credit_consumed'] || "";
  const searchEmailCreditConsumed = parsedSearch['email_credit_consumed'] || "";
  const searchCreationPeriod = parsedSearch['create_at'] || '';

  const [creditConsumed, setCreditConsumed] = useState(searchCreditConsumed?.split(","));
  const [whatsappCreditConsumed, setWhatsappCreditConsumed] = useState(searchWhatsappCreditConsumed?.split(","));
  const [smsCreditConsumed, setSmsCreditConsumed] = useState(searchSmsCreditConsumed?.split(","));
  const [emailCreditConsumed, setEmailCreditConsumed] = useState(searchEmailCreditConsumed?.split(","));
  const [creationPeriod, setCreationPeriod] = useState(searchCreationPeriod);

  const content = [
    {
      title: 'Credit consumed',
      value: creditConsumed,
      setValue: setCreditConsumed,
      component: FilterTypeEnum.inputNumberRange,
    },
    {
      title: 'Whatsapp Credit consumed',
      value: whatsappCreditConsumed,
      setValue: setWhatsappCreditConsumed,
      component: FilterTypeEnum.inputNumberRange,
    },
    {
      title: 'SMS Credit consumed',
      value: smsCreditConsumed,
      setValue: setSmsCreditConsumed,
      component: FilterTypeEnum.inputNumberRange,
    },
    {
      title: 'Email Credit consumed',
      value: emailCreditConsumed,
      setValue: setEmailCreditConsumed,
      component: FilterTypeEnum.inputNumberRange,
    },
    {
      title: 'Create at',
      value: creationPeriod,
      setValue: setCreationPeriod,
      component: FilterTypeEnum.dateRange,
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default MessageCreditRecordFilter;
